import React from 'react'

const CircleNavigationButton = ({ direction, onClick, hideButton, color, disabled }) => {
  require('./style.scss')
  const tValue = direction === 'left' ? 'rotate(180deg)' : 'rotate(0)'
  return (
    <button
      className='circle-navigation-button'
      disabled={hideButton || disabled}
      style={{ visibility: hideButton ? 'hidden' : '' }}
      onClick={onClick}
      onKeyUp={e => {
        if (e.key === ' ') e.preventDefault()
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 71 71'
        style={{ width: '100%', height: '100%', transform: tValue }}
      >
        <g transform='translate(0.268 0.252)'>
          <g transform='translate(0 0)'>
            <g transform='translate(-0.268 -0.252)' fill='none' stroke={color} strokeWidth='1'>
              <circle cx='35.5' cy='35.5' r='35.5' stroke='none' />
              <circle cx='35.5' cy='35.5' r='35' fill='none' />
            </g>
          </g>
          <path
            d='M28.735,18.256,16.281,6.972,3.826,18.256,0,14.782,16.281,0,32.561,14.782Z'
            transform='translate(48.285 19.14) rotate(90)'
            fill={color}
          />
        </g>
      </svg>
    </button>
  )
}

export default CircleNavigationButton
