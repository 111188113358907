import reactStringReplace from 'react-string-replace'
import exerciseMistakeInfoStyle from '../../../components/ExerciseMistakeInfoButton/ExerciseMistakeInfoButton.module.scss'
import classNames from 'classnames'
import htmlToText from '../../../operations/htmlToText'

const getAnswerWithCorrectedMistake = (givenParagraphs, userAnswerList, isTypeInput) => {
  const renderUserAnswer = userAnswerData => {
    return userAnswerData?.wordByWordResult?.map((item, i) => {
      return (
        <span
          key={i}
          className={classNames({ [exerciseMistakeInfoStyle.typoWord]: item.match && item.actionToCorrect === 1 })}
        >
          {item.userStr}
          {userAnswerData.wordByWordResult.length > i + 1 && ' '}
        </span>
      )
    })
  }

  const renderGivenTextParagraph = paragraph => {
    const paragraphString = htmlToText(paragraph.string)

    if (paragraph.containsField) {
      return reactStringReplace(paragraphString, '###', (match, index) => {
        const choicesIndex = paragraph.fieldIndexStartsFrom + (index - 1) / 2
        const userAnswerData = userAnswerList?.[choicesIndex]?.userAnswerData
        return renderUserAnswer(userAnswerData)
      })
    } else {
      return paragraphString
    }
  }

  return (
    <div>
      {isTypeInput
        ? renderUserAnswer(userAnswerList.userAnswerData)
        : givenParagraphs?.map((paragraph, pIndex) => {
            return <p key={pIndex}>{renderGivenTextParagraph(paragraph)}</p>
          })}
    </div>
  )
}

export default getAnswerWithCorrectedMistake
