import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'

const initialState = {
  loading: false,
  studyDifficulty: null,
  error: ''
}

export const changeStudyHardship = createAsyncThunk('studyDifficulty/changeStudyHardship', ({ studyHardshipLevel }) => {
  return serviceCall('postStudyHardship', { studyHardshipLevel }, 'cloud')
})

const studyDifficultySlice = createSlice({
  name: 'studyDifficulty',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(changeStudyHardship.pending, state => {
      state.loading = true
    })
    builder.addCase(changeStudyHardship.fulfilled, (state, action) => {
      state.loading = false
      state.studyDifficulty = action.payload
    })
    builder.addCase(changeStudyHardship.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = studyDifficultySlice.actions

export default studyDifficultySlice.reducer
