import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  displayingBottomSheets: []
}

const bottomSheetSlice = createSlice({
  name: 'bottomSheet',
  initialState,
  reducers: {
    removeDisplayingBottomSheet: (state, action) => {
      state.displayingBottomSheets = state.displayingBottomSheets.filter(it => it !== action.payload)
    },
    addDisplayingBottomSheet: (state, action) => {
      state.displayingBottomSheets = [...state.displayingBottomSheets, action.payload]
    }
  }
})

export const { removeDisplayingBottomSheet, addDisplayingBottomSheet } = bottomSheetSlice.actions

export default bottomSheetSlice.reducer
