import * as React from 'react'

const PlaySvg = ({ color }) => {
  return (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={512}
      height={512}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g>
        <path d='M108.9,11.3C62.8-15.2,25.3,6.5,25.3,59.7v392.6c0,53.2,37.4,74.9,83.6,48.5L452,303.9c46.2-26.5,46.2-69.4,0-95.9 L108.9,11.3z' />
      </g>
    </svg>
  )
}

export default PlaySvg
