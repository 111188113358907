import React from 'react'
import textColorByBackgroundHexColor from '../../operations/textColorByBackgroundHexColor'

const SelectedColorSvg = ({ selectedColor }) => {
  const strokeColor = () => {
    return textColorByBackgroundHexColor(selectedColor)
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='49.54' height='27.42' viewBox='0 0 69.292 38.347'>
      <g transform='translate(-658.81 -585.589)'>
        <g transform='translate(659.309 585.827)'>
          <g>
            <path
              d='M147.951,230.122V192.775h68.292v26.779q0,.26-.018.519t-.055.517q-.036.258-.091.515t-.127.511q-.073.254-.163.506t-.2.5q-.108.248-.234.493t-.268.484q-.143.24-.3.474t-.335.463q-.175.229-.367.451t-.4.438q-.207.216-.429.424t-.458.409q-.236.2-.487.393t-.513.376q-.264.184-.54.358t-.564.339q-.288.164-.587.319t-.609.3q-.31.144-.629.278t-.648.256q-.329.122-.665.233t-.681.21q-.344.1-.7.187t-.707.163q-.356.075-.718.138t-.727.114q-.365.051-.734.089t-.74.063q-.37.026-.743.038t-.745.013Z'
              transform='translate(-147.949 -192.513)'
              fill={selectedColor}
              stroke={strokeColor()}
              strokeWidth='1'
            />
            <path
              d='M0,0H23.158V68.173C10.368,68.173,0,59.233,0,46.416Z'
              transform='translate(0.059 23.419) rotate(-90)'
              fill={selectedColor}
              stroke={strokeColor()}
              strokeWidth='1'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SelectedColorSvg
