// src/db/errorTranslationDb.js

import Dexie from 'dexie'
import {
  ERROR_ENGLISH_LANGUAGE,
  ERROR_PERSIAN_LANGUAGE,
  ERROR_ARABIC_LANGUAGE,
  ERROR_SPANISH_LANGUAGE,
  ERROR_FRENCH_LANGUAGE,
  ERROR_RUSSIAN_LANGUAGE,
  ERROR_TURKISH_LANGUAGE,
  ERROR_ITALIAN_LANGUAGE,
  ERROR_GERMAN_LANGUAGE
  // Add other languages as needed
} from '../enums/errorLanguageEnums/errorLanguageEnums'

// Define the table names mapping
export const errorIndexedDBTranslationTables = {
  [ERROR_ENGLISH_LANGUAGE]: 'error_english',
  [ERROR_PERSIAN_LANGUAGE]: 'error_persian',
  [ERROR_ARABIC_LANGUAGE]: 'error_arabic',
  [ERROR_SPANISH_LANGUAGE]: 'error_spanish',
  [ERROR_FRENCH_LANGUAGE]: 'error_french',
  [ERROR_RUSSIAN_LANGUAGE]: 'error_russian',
  [ERROR_TURKISH_LANGUAGE]: 'error_turkish',
  [ERROR_ITALIAN_LANGUAGE]: 'error_italian',
  [ERROR_GERMAN_LANGUAGE]: 'error_german'
}

export const errorTranslationDb = new Dexie('error_translations_db')

// Define the database schema
errorTranslationDb.version(26).stores({
  [errorIndexedDBTranslationTables[ERROR_ENGLISH_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_PERSIAN_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_ARABIC_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_SPANISH_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_FRENCH_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_RUSSIAN_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_TURKISH_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_ITALIAN_LANGUAGE]]: 'key, value',
  [errorIndexedDBTranslationTables[ERROR_GERMAN_LANGUAGE]]: 'key, value'
})
