import * as React from 'react'

const GameGoldClockSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='43.119'
    height='49.769'
    viewBox='0 0 43.119 49.769'
  >
    <defs>
      <linearGradient id='game-gold-clock-linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
        <stop offset='0' stopColor='#e1bd47' />
        <stop offset='0.331' stopColor='#f3e6b4' />
        <stop offset='0.569' stopColor='#d2c48e' />
        <stop offset='1' stopColor='#e1bd47' />
      </linearGradient>
    </defs>
    <g transform='translate(0 0)'>
      <path
        d='M87.423,32.169l2.047-2.047a.829.829,0,0,0,0-1.172l-4.685-4.685a.853.853,0,0,0-1.172,0l-2.051,2.044a21.43,21.43,0,0,0-11.194-3.654v-5h4.146a.829.829,0,0,0,0-1.658h-9.95a.829.829,0,0,0,0,1.658H68.71v5a21.43,21.43,0,0,0-11.194,3.654l-2.047-2.047a.829.829,0,0,0-1.172,0l-4.685,4.685a.829.829,0,0,0,0,1.172l2.044,2.051a21.558,21.558,0,1,0,35.767,0ZM84.2,26.02l3.512,3.512-1.284,1.284A21.787,21.787,0,0,0,82.916,27.3ZM51.364,29.532l3.512-3.512L56.16,27.3a21.785,21.785,0,0,0-3.512,3.512Zm18.175,34.56a19.9,19.9,0,1,1,19.9-19.9A19.9,19.9,0,0,1,69.539,64.092Z'
        transform='translate(-47.979 -16)'
        fill='url(#game-gold-clock-linear-gradient)'
      />
      <path
        d='M114.054,139.283a18.174,18.174,0,0,0,0-18.159c-.007-.014-.007-.029-.014-.043s-.022-.021-.03-.033a18.376,18.376,0,0,0-6.613-6.614c-.013-.008-.021-.022-.034-.03a.374.374,0,0,0-.043-.014,18.175,18.175,0,0,0-18.159,0c-.014.007-.029.007-.043.014s-.021.022-.034.03a18.374,18.374,0,0,0-6.613,6.613c-.008.013-.022.021-.03.034a.377.377,0,0,0-.014.043,18.109,18.109,0,0,0-2.4,8.126.761.761,0,0,0-.022.126.714.714,0,0,0,.019.093c-.01.245-.019.489-.019.736a18.122,18.122,0,0,0,2.429,9.078c.007.014.007.029.014.043s.028.034.041.052a18.378,18.378,0,0,0,6.6,6.6c.013.008.021.022.034.03s.042.016.063.026a18.175,18.175,0,0,0,18.116,0c.021-.01.042-.014.063-.026s.021-.022.034-.03a18.378,18.378,0,0,0,6.6-6.6c.012-.018.029-.032.041-.052a.279.279,0,0,0,.017-.043Zm-6.822,4.837L106.008,142a.829.829,0,1,0-1.442.82l.006.01,1.226,2.122a16.455,16.455,0,0,1-6.726,1.8v-4.1a.829.829,0,0,0-1.658,0v4.1a16.455,16.455,0,0,1-6.726-1.8l1.226-2.122a.829.829,0,0,0-1.431-.839l-.006.009-1.225,2.121a16.69,16.69,0,0,1-4.924-4.924l2.121-1.225a.829.829,0,1,0-.82-1.442l-.01.006L83.5,137.761a16.466,16.466,0,0,1-1.839-7.555H85.8a.829.829,0,0,0,0-1.658H81.741a16.437,16.437,0,0,1,1.755-5.9l2.122,1.226a.829.829,0,0,0,.839-1.431l-.01-.006-2.121-1.225a16.692,16.692,0,0,1,4.924-4.924l1.225,2.121a.829.829,0,0,0,1.442-.82l-.005-.01-1.226-2.122a16.455,16.455,0,0,1,6.727-1.8v4.1a.829.829,0,1,0,1.658,0v-4.1a16.455,16.455,0,0,1,6.726,1.8l-1.226,2.122a.829.829,0,1,0,1.431.839l.006-.01,1.225-2.121a16.691,16.691,0,0,1,4.924,4.924l-2.121,1.225a.829.829,0,0,0,.82,1.442l.01-.005,2.122-1.226a16.456,16.456,0,0,1,1.8,6.726H109.85a.829.829,0,1,0,0,1.658h4.933a16.456,16.456,0,0,1-1.8,6.726l-2.122-1.226a.829.829,0,1,0-.839,1.431l.01.006,2.121,1.225a16.69,16.69,0,0,1-4.924,4.924Z'
        transform='translate(-76.681 -102.013)'
        fill='url(#game-gold-clock-linear-gradient)'
      />
      <path
        d='M235.311,199.615v-6.786a.829.829,0,1,0-1.658,0v6.786a2.488,2.488,0,1,0,1.658,0Zm-.829,3.164a.829.829,0,1,1,.829-.829A.829.829,0,0,1,234.482,202.779Z'
        transform='translate(-212.921 -173.758)'
        fill='url(#game-gold-clock-linear-gradient)'
      />
    </g>
  </svg>
)

export default GameGoldClockSvg
