import * as React from 'react'

const ListeningSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>{'.ListeningSvg-st0{ stroke-width:4.8946;stroke-miterlimit:10;}'}</style>
    <g transform='matrix(1.3333333,0,0,-1.3333333,0,682.66667)'>
      <g>
        <g>
          <g>
            <g>
              <g transform='translate(115,15)'>
                <path
                  className='ListeningSvg-st0'
                  stroke={color}
                  d='M-26.8,115.3c-41.1,0-74.7,33.2-74.7,74v175.2c0,71.4,58.6,129.4,130.7,129.4s130.7-58.1,130.7-129.4 c0-54.7-27.4-105.4-73.4-135.8L59.5,211c-7.3-4.9-11.7-13-11.7-21.7C47.9,148.4,14.3,115.3-26.8,115.3z M29.2,471.6 c-59.7,0-108.4-48.1-108.4-107.2V189.2c0-28.5,23.5-51.7,52.4-51.7s52.4,23.2,52.4,51.7c0,16.3,8.1,31.3,21.8,40.3l26.9,17.7 c39.6,26.1,63.3,70,63.3,117.2C137.5,423.5,89,471.6,29.2,471.6z'
                />
              </g>
              <g transform='translate(214.583,232.5469)'>
                <path
                  className='ListeningSvg-st0'
                  stroke={color}
                  d='M-107.3-26.9c-18.5,0-33.6,15-33.6,33.3v9.9c0,10.9,4.5,21.4,12.3,29l0.5,0.4c3.3,3.1,5.2,7.4,5.2,12.1 s-1.9,9.1-5.2,12.5l-1.3,1.3c-7.8,7.8-12.3,18.6-12.1,29.7l0.6,45.7c0,20.3,8.9,39.6,24.5,53c15.7,13.5,36.6,19.5,57.4,16.4 c37.6-5.6,63.7-41,59.4-80.7C-3,105-19.8,77.5-45.9,60.3c-5.1-3.4-12-2-15.5,3.1c-3.4,5.1-2,12,3.1,15.5 c20.5,13.5,33.7,35,36.3,59.1c3,27.7-14.8,52.4-40.5,56.2C-77,196.4-91,192.4-102,183c-10.6-9.1-16.7-22.3-16.7-36.1v-0.1 l-0.6-45.8c-0.1-5.1,1.9-9.9,5.6-13.5l1.3-1.3c7.7-7.6,11.9-17.8,11.8-28.6c-0.1-10.7-4.4-20.7-12.1-28l-0.4-0.4 c-3.5-3.4-5.5-8-5.5-12.9V6.4c0-6.1,5.1-11.1,11.3-11.1s11.2-5,11.2-11.2S-101.2-26.9-107.3-26.9z'
                />
              </g>
              <g transform='translate(110.4814,317)'>
                <path
                  className='ListeningSvg-st0'
                  stroke={color}
                  d='M34.1,36.5c-6.2,0-11.2,5-11.2,11.2c0,10.2-8.4,18.4-18.7,18.4c-10.4,0-18.7-8.2-18.7-18.4 c0-6.2-5-11.2-11.2-11.2s-11.2,5-11.2,11.2c0,22.4,18.4,40.7,41,40.7s41-18.3,41-40.7C45.2,41.5,40.2,36.5,34.1,36.5z'
                />
              </g>
              <g transform='translate(455.6816,400.6396)'>
                <path
                  className='ListeningSvg-st0'
                  stroke={color}
                  d='M-113-79.4c-2.8,0-5.8,1.1-7.9,3.3c-4.3,4.4-4.3,11.5,0.1,15.8c10.6,10.5,16.3,24.4,16.3,39.2 s-5.8,28.7-16.3,39.2c-4.4,4.3-4.4,11.4-0.1,15.8c4.3,4.4,11.4,4.4,15.8,0.1c14.8-14.7,23-34.2,23-55c0-20.8-8.1-40.3-23-55 C-107.3-78.3-110.2-79.4-113-79.4z'
                />
              </g>
              <g transform='translate(413.2549,358.2129)'>
                <path
                  className='ListeningSvg-st0'
                  stroke={color}
                  d='M-102.2-5.5c-2.8,0-5.8,1.1-7.9,3.3c-4.3,4.4-4.3,11.5,0.1,15.8c2.1,2.1,3.2,4.8,3.2,7.7 s-1.2,5.7-3.2,7.7c-4.4,4.3-4.4,11.4-0.1,15.8c4.3,4.4,11.4,4.4,15.8,0.1c6.4-6.3,9.9-14.7,9.9-23.6S-88,4-94.4-2.3 C-96.6-4.5-99.5-5.5-102.2-5.5z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ListeningSvg
