import Rive, { useRive } from '@rive-app/react-canvas'
import React, { useEffect } from 'react'
import useCanvasConfetti from '../../../hooks/useCanvasConfetti'
import { useDispatch, useSelector } from 'react-redux'
import styles from './CelebrateUnitCompletion.module.scss'
import { setCelebrating } from '../../../redux/features/todayStudySlice'

const CelebrateUnitCompletion = () => {
  const { celebrating } = useSelector(state => state.todayStudy)
  const { runConfettiFireworks } = useCanvasConfetti()
  const dispatch = useDispatch()
  const { RiveComponent } = useRive({
    src: '/assets/rive/unit_completion_2.riv',
    stateMachines: celebrating,
    onStop: () => {
      dispatch(setCelebrating(null))
    },
    autoplay: true
  })

  useEffect(() => {
    if (celebrating) {
      runConfettiFireworks()
    }
  }, [celebrating])

  return (
    <>
      {celebrating && (
        <div className={styles.container}>
          <RiveComponent className={styles.rive} />
        </div>
      )}
    </>
  )
}

export default CelebrateUnitCompletion
