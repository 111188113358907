import { createSlice } from '@reduxjs/toolkit'
import { sidebarTypeEnums } from '../../enums/sidebarEnums/sidebarEnums'

const initialState = {
  loading: false,
  sidebarIsOpen: false,
  sidebarType: sidebarTypeEnums.classic,
  error: ''
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarIsOpen: (state, action) => {
      state.sidebarIsOpen = action.payload
    },
    setSidebarType: (state, action) => {
      state.sidebarType = action.payload
    }
  }
})

export const { setSidebarIsOpen, setSidebarType } = sidebarSlice.actions

export default sidebarSlice.reducer
