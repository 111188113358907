import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import matchingExactUseCase from '../../useCase/matchingExactUseCase.js/matchingExactUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  page: 'intro',
  loading: false,
  data: {},
  userData: [],
  playlist: [],
  remainingTime: null,
  countDown: '',
  selectedAnswerBox: null,
  answersChecked: false,
  checkResult: null,
  error: ''
}

export const fetchReading = baseAsyncThunk('reading/fetchReading', ({ unit }) => {
  return serviceCall(
    'fetchSection',
    {
      unit: unit,
      section: 'READING'
    },
    'cloud'
  )
})

export const checkWarmUp = createAsyncThunk('reading/checkWarmUp', ({ unit, matchCases }) => {
  return matchingExactUseCase({
    unitId: unit,
    sectionType: 'READING',
    matchType: 'EXACT_NON_CASE_SENSITIVE',
    inputType: 'SENTENCE_MATCH',
    matchCases
  })
})

const readingSlice = createSlice({
  name: 'reading',
  initialState,
  reducers: {
    setPlaylist: (state, action) => {
      state.playlist = action.payload
    },
    setCountDown: (state, action) => {
      state.countDown = action.payload
    },
    decreaseRemainingTime: (state, action) => {
      if (state.remainingTime && state.remainingTime > 0) {
        state.remainingTime--
      } else if (state.remainingTime == null) {
        state.remainingTime = action.payload
      }
    },
    setRemainingTime: (state, action) => {
      state.remainingTime = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    toggleUnlockAnswer: (state, action) => {
      state.userData = [...state.userData].map((obj, i) => {
        if (i === action.payload) {
          return { ...obj, unlocked: !obj.unlocked }
        } else {
          return obj
        }
      })
    },
    unlockAllAnswers: state => {
      state.userData = [...state.userData].map(obj => {
        return { ...obj, unlocked: true }
      })
    },
    setSelectedAnswerBox: (state, action) => {
      state.selectedAnswerBox = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    resetWarmUp: state => {
      state.answersChecked = false
      state.checkResult = null
      state.userData = state.data.warmUpQuiz.map(item => ({
        questionNo: item.questionNo,
        answerIndex: null,
        answerString: null,
        unlocked: false
      }))
    },
    resetReadingStates: state => {
      state.page = 'intro'
      state.loading = false
      state.data = {}
      state.userData = []
      state.playlist = []
      state.remainingTime = null
      state.countDown = ''
      state.selectedAnswerBox = null
      state.answersChecked = false
      state.checkResult = null
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchReading.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchReading.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.userData = action.payload.userData
    })
    builder.addCase(fetchReading.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(checkWarmUp.pending, state => {
      state.loading = true
    })
    builder.addCase(checkWarmUp.fulfilled, (state, action) => {
      state.loading = false
      state.checkResult = action.payload.data
      state.answersChecked = true
    })
    builder.addCase(checkWarmUp.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {
  setCountDown,
  decreaseRemainingTime,
  setPage,
  toggleUnlockAnswer,
  setSelectedAnswerBox,
  setUserData,
  setRemainingTime,
  resetWarmUp,
  setPlaylist,
  unlockAllAnswers,
  resetReadingStates
} = readingSlice.actions

export default readingSlice.reducer
