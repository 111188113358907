const vocabularySimpleTableMapper = data => {
  return {
    tableHeaderRows: [
      {
        text: {
          sentence: 'Words'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Phonetics'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Translation'
        },
        isTranslate: true
      }
    ],
    tableColumnsConfig: [
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: true }
    ],
    tableBodyRows: data.map(item => {
      return {
        id: item.index,
        columns: [
          {
            text: {
              sentence: item.title ?? ''
            },
            isTranslate: false
          },
          {
            text: {
              sentence: item.phonetic ?? ''
            },
            isTranslate: false
          },
          {
            text: {
              sentence: item.translation ?? ''
            },
            isTranslate: true
          }
        ]
      }
    })
  }
}

export default vocabularySimpleTableMapper
