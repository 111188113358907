const hexToRgb = hex => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return {
      red: (c >> 16) & 255,
      green: (c >> 8) & 255,
      blue: c & 255
    }
  } else {
    return {
      red: parseInt(0, 16),
      green: parseInt(0, 16),
      blue: parseInt(0, 16)
    }
  }
}

export default hexToRgb
