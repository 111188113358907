import React from 'react'
import SpeakerOnSvg from '../../icons/SpeakerOnSvg'
import classNames from 'classnames'

const AudioSpeakerButton = ({ playing, setPlaying, color, className, disabled, loading }) => {
  return (
    <button
      className={classNames('speaker', className)}
      style={{ opacity: disabled || loading ? 0.5 : 1 }}
      onClickCapture={() => (!disabled ? setPlaying(!playing) : null)}
      disabled={disabled || loading}
    >
      <SpeakerOnSvg playing={playing} color={color} />
    </button>
  )
}

export default AudioSpeakerButton
