import * as React from 'react'

const DictionarySvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {
        '.DictionarySvg-st0{stroke-width:0.9665;stroke-miterlimit:10;}.DictionarySvg-st1{stroke-width:17.3977;stroke-miterlimit:10;}.DictionarySvg-st2{stroke-width:13;stroke-miterlimit:10;}'
      }
    </style>
    <g>
      <g>
        <g>
          <path
            className='DictionarySvg-st0'
            stroke={color}
            d='M222.7,157c-1.2-3.2-4.3-5.4-7.7-5.4c-3.5,0-6.5,2.1-7.7,5.4l-28.5,81.9 c-1.1,4.2,1.3,8.3,5.2,9.8c4,1.4,8.4-0.5,10.1-4.3l6.7-19h28.5l6.7,19c1.2,3.3,4.3,5.5,7.7,5.5c1,0,1.8-0.2,2.7-0.5 c4.3-1.5,6.5-6.2,5-10.4L222.7,157z M206.3,208.9l8.2-24.6l8.2,24.6H206.3z'
          />
          <path
            className='DictionarySvg-st0'
            stroke={color}
            d='M317.3,233.4c-4.5,0-8.2-3.7-8.2-8.2v-32.8c0-3.3-2-6.3-5.1-7.5c-3.1-1.3-6.6-0.6-8.9,1.8 c-3.3-1.6-6.9-2.4-10.5-2.4c-13.5,0-24.6,11-24.6,24.6v16.3c0,13.5,11,24.6,24.6,24.6c6.1,0,11.9-2.3,16.3-6.4 c4.4,4.1,10.3,6.4,16.3,6.4c4.5,0,8.2-3.7,8.2-8.2C325.5,237.1,321.8,233.4,317.3,233.4z M284.5,233.4c-4.5,0-8.2-3.7-8.2-8.2 v-16.3c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2v16.3C292.7,229.7,289.1,233.4,284.5,233.4z'
          />
          <path
            className='DictionarySvg-st1'
            stroke={color}
            d='M387.2,11H92.6C79,11,68,22,68,35.6v441.9C68,491,79,502,92.6,502h294.6c31.6,0,57.3-25.6,57.3-57.3V68.3 C444.5,36.6,418.9,11,387.2,11z M387.3,485.6H92.6c-4.5,0.1-8.1-3.6-8.1-8.1V35.6c0-4.5,3.7-8.2,8.2-8.2h24.6v0.4h16.3v-0.4 h253.7c22.6,0,40.9,18.4,40.9,40.9v376.5l0,0C428.1,467.3,409.8,485.6,387.3,485.6z'
          />
          <path
            className='DictionarySvg-st2'
            stroke={color}
            d='M341.8,118.9h-180c-13.5,0-24.6,11-24.6,24.6V258c0,13.5,11,24.6,24.6,24.6h180.1 c13.5,0,24.6-11,24.6-24.6V143.4C366.4,129.9,355.4,118.9,341.8,118.9z M350.1,258c0,4.5-3.7,8.2-8.2,8.2H161.8 c-4.5,0-8.2-3.7-8.2-8.2V143.4c0-4.5,3.7-8.2,8.2-8.2h180.1c4.5,0,8.2,3.7,8.2,8.2L350.1,258L350.1,258z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default DictionarySvg
