import { fetchVocabularies } from '../../services/cloudServices'
import serviceCall from '../../services'
import vocabularyFullTableMapper from '../../services/mapper/vocabulary/vocabularySimpleTableMapper'
import favoritesCategoryMapper from '../../services/mapper/favorite/favoritesCategoryMapper'

const getFavoritesCategoryUseCase = async ({ type }) => {
  const result = await serviceCall('fetchFavoriteCategories', { type }, 'cloud')

  return favoritesCategoryMapper(result, type)
}

export default getFavoritesCategoryUseCase
