import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import removeExtraSpace from '../../../../operations/removeExtraSpace'
import makeUrl from '../../../../operations/makeUrl'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'
import getRandomCongratulationMessageByPercentage from '../../../../operations/getRandomCongratulationMessageByPercentage'
import { playCheckSound } from '../../../../util/playCheckSound/playCheckSound'
import matchingExactUseCase from '../../../../useCase/matchingExactUseCase.js/matchingExactUseCase'

const initialState = {
  loading: false,
  currentIndex: 0,
  isAutoRepeat: false,
  isUsingSpeakerAndMicCombination: false,
  isUsingMicrophone: false,
  initialNumberOfRepetitions: 5,
  speakingData: null,
  error: ''
}

const getStatusByPercentage = percentage => {
  if (percentage === 100) {
    return AnswerStatusEnums.CORRECT
  } else if (percentage === 0) {
    return AnswerStatusEnums.INCORRECT
  } else {
    return AnswerStatusEnums.CORRECT_AND_INCORRECT
  }
}

export const matchUserAnswer = createAsyncThunk(
  'speaking/matchUserAnswer',
  ({ matchCases, unit, trackerNumber }, { getState }) => {
    const { speaking } = getState()

    return matchingExactUseCase({
      matchType: 'EXACT_NON_CASE_SENSITIVE',
      inputType: 'SENTENCE_WORD_BY_WORD_MATCH',
      sectionType: 'DRILLING',
      toolsCheck: true,
      unitId: unit,
      trackerNumber,
      matchCases,
      save: false,
      isVoiceRecognition: true,
      quizIndex: speaking.currentIndex
    })
  }
)

const speakingSlice = createSlice({
  name: 'speaking',
  initialState,
  reducers: {
    createSpeakingData: (state, action) => {
      state.speakingData = action.payload.data?.map((item, i) => {
        return {
          index: i,
          title: removeExtraSpace(item.title),
          voiceUrl: item.voiceUri ? makeUrl(item.voiceUri) : null,
          numberOfRepetitions: state.initialNumberOfRepetitions,
          initialNumberOfRepetitions: state.initialNumberOfRepetitions,
          repetitionsData: [...new Array(state.initialNumberOfRepetitions)].map((item, i) => ({
            status: AnswerStatusEnums.NONE,
            page: i + 1
          })),
          userAnswerData: {
            userAnswer: '',
            wordByWordResult: [],
            matchingPercentage: 0,
            matchingStars: 0,
            answerIsCorrect: false,
            message: ''
          },
          voiceType: action.payload.voiceType,
          isStarted: false,
          isFinished: false
        }
      })
    },
    toggleAutoRepeat: state => {
      state.isAutoRepeat = !state.isAutoRepeat
    },
    increaseNumberOfRepetitions: (state, action) => {
      const currentPhraseNumberOfRepetitions = state.speakingData[state.currentIndex].numberOfRepetitions

      if (currentPhraseNumberOfRepetitions < 999) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentPhraseNumberOfRepetitions + 1
          state.speakingData = [...state.speakingData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentPhraseNumberOfRepetitions + 1,
                initialNumberOfRepetitions: currentPhraseNumberOfRepetitions + 1,
                repetitionsData: [...new Array(currentPhraseNumberOfRepetitions + 1)].map((item, i) => ({
                  status: AnswerStatusEnums.NONE,
                  page: i + 1
                }))
              }
            }
            return word
          })
        } else {
          ++state.speakingData[state.currentIndex].numberOfRepetitions
          ++state.speakingData[state.currentIndex].initialNumberOfRepetitions
          state.speakingData[state.currentIndex].repetitionsData = [
            ...new Array(state.speakingData[state.currentIndex].numberOfRepetitions)
          ].map((item, i) => ({
            status: AnswerStatusEnums.NONE,
            page: i + 1
          }))
        }
      }
    },
    decreaseNumberOfRepetitions: (state, action) => {
      const currentPhraseNumberOfRepetitions = state.speakingData[state.currentIndex].numberOfRepetitions

      if (currentPhraseNumberOfRepetitions > 0) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentPhraseNumberOfRepetitions - 1
          state.speakingData = [...state.speakingData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentPhraseNumberOfRepetitions - 1,
                initialNumberOfRepetitions: currentPhraseNumberOfRepetitions - 1,
                repetitionsData: [...new Array(currentPhraseNumberOfRepetitions - 1)].map((item, i) => ({
                  status: AnswerStatusEnums.NONE,
                  page: i + 1
                }))
              }
            }
            return word
          })
        } else {
          --state.speakingData[state.currentIndex].numberOfRepetitions
          --state.speakingData[state.currentIndex].initialNumberOfRepetitions
          state.speakingData[state.currentIndex].repetitionsData = [
            ...new Array(state.speakingData[state.currentIndex].numberOfRepetitions)
          ].map((item, i) => ({
            status: AnswerStatusEnums.NONE,
            page: i + 1
          }))
        }
      }
    },
    resetNumberOfRepetitions: state => {
      state.speakingData[state.currentIndex].isStarted = false
      state.speakingData[state.currentIndex].isFinished = false
      state.speakingData[state.currentIndex].numberOfRepetitions = state.initialNumberOfRepetitions
      state.speakingData[state.currentIndex].initialNumberOfRepetitions = state.initialNumberOfRepetitions
      state.speakingData[state.currentIndex].repetitionsData = [...new Array(state.initialNumberOfRepetitions)].map(
        (item, i) => ({
          status: AnswerStatusEnums.NONE,
          page: i + 1
        })
      )
      state.speakingData[state.currentIndex].userAnswerData = {
        userAnswer: '',
        wordByWordResult: [],
        matchingPercentage: 0,
        answerIsCorrect: false
      }
    },
    increaseCurrentIndex: state => {
      if (state.currentIndex + 1 < state.speakingData.length) {
        ++state.currentIndex
      }
    },
    decreaseCurrentIndex: state => {
      if (state.currentIndex > 0) {
        --state.currentIndex
      }
    },
    setAudioUrl: (state, action) => {
      if (state.speakingData?.length > 0) {
        state.speakingData[action.payload.currentIndex].audioUrl = action.payload.data
      }
    },
    setUserAnswer: (state, action) => {
      state.speakingData[state.currentIndex].userAnswerData.userAnswer = action.payload
    },
    clearUserAnswerData: state => {
      state.speakingData[state.currentIndex].userAnswerData = {
        userAnswer: '',
        wordByWordResult: [],
        matchingPercentage: 0,
        answerIsCorrect: false
      }
      state.speakingData[state.currentIndex].isFinished = false
    },
    setIsUsingSpeakerAndMicCombination: (state, action) => {
      state.isUsingSpeakerAndMicCombination = action.payload
    },
    setIsUsingMicrophone: (state, action) => {
      state.isUsingMicrophone = action.payload
    },
    setSpeakingSpeechType: (state, action) => {
      state.speakingData[action.payload.currentIndex].speechType = action.payload.speechType
    },
    clearSpeakingStates: state => {
      state.loading = false
      state.currentIndex = 0
      state.isAutoRepeat = false
      state.isUsingSpeakerAndMicCombination = false
      state.initialNumberOfRepetitions = 5
      state.speakingData = null
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(matchUserAnswer.pending, state => {
      state.loading = true
    })
    builder.addCase(matchUserAnswer.fulfilled, (state, action) => {
      state.loading = false
      const matchCasesMap = action.payload.data.matchCasesMap
      const matchCase = matchCasesMap[Object.keys(matchCasesMap)[0]]

      state.speakingData = [...state.speakingData].map((item, i) => {
        if (action.payload.quizIndex === i) {
          // const totalCorrectWords = sumBy(matchCase.wordByWordResult, o => o.match)
          // const totalWords = matchCase.wordByWordResult.length
          // const matchingPercentage = Math.round((100 * totalCorrectWords) / totalWords)
          const matchingPercentage = Math.max(0, Math.min(100, matchCase.correctPercentage))
          const matchingStars = Math.round((matchingPercentage * 5) / 100)
          const currentRepetitionIndex = item.initialNumberOfRepetitions - item.numberOfRepetitions

          const answerIsCorrect = matchCase.userAnswerStatus === AnswerStatusEnums.CORRECT

          playCheckSound(answerIsCorrect)

          return {
            ...item,
            numberOfRepetitions: item.numberOfRepetitions - 1,
            repetitionsData: [...item.repetitionsData].map((repetition, repetitionIndex) => {
              if (currentRepetitionIndex === repetitionIndex) {
                return {
                  status: matchCase.userAnswerStatus
                }
              }
              return repetition
            }),
            userAnswerData: {
              ...item.userAnswerData,
              wordByWordResult: matchCase.wordByWordResult,
              matchingPercentage,
              matchingStars,
              answerIsCorrect: answerIsCorrect,
              isCorrectWithMistake:
                answerIsCorrect &&
                matchCase.wordByWordResult?.some(obj => obj.actionToCorrect > 0 && obj.usrStr !== ''),
              message: getRandomCongratulationMessageByPercentage(matchingPercentage)
            },
            isFinished: true
          }
        }
        return item
      })
    })
    builder.addCase(matchUserAnswer.rejected, state => {
      state.loading = false
    })
  }
})

export const {
  createSpeakingData,
  toggleAutoRepeat,
  increaseNumberOfRepetitions,
  decreaseNumberOfRepetitions,
  resetNumberOfRepetitions,
  increaseCurrentIndex,
  decreaseCurrentIndex,
  setIsUsingMicrophone,
  setAudioUrl,
  setUserAnswer,
  clearUserAnswerData,
  setIsUsingSpeakerAndMicCombination,
  setSpeakingSpeechType,
  clearSpeakingStates
} = speakingSlice.actions

export default speakingSlice.reducer
