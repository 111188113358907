import { FINISH_EXERCISE, FINISH_SECTION } from '../../enums/studyLogEnums/studyLogEnums'
import UnitDataStorage from '../../classes/UnitDataStorage'
import { isNumber, toNumber } from 'lodash'
import { sectionsData } from '../../sources/sectionsData'
import { createSearchParams, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useLog from '../logHooks/useLog'
import { EXERCISES } from '../../enums/studyPageEnums/studyPageEnums'
import useQueryParams from './../useQueryParams'
import { studyTypeEnum } from '../../enums/userStudyEnums/userStudyEnums'
import { TYPE_A } from '../../enums/exerciseEnums/exerciseGroupTypeEnums'

const useSectionEvents = isBasic => {
  const navigate = useNavigate()
  const location = useLocation()
  const { removeQueryParams, addQueryParams } = useQueryParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const sectionNumberParam = searchParams.get('section')
  let { unit, sectionId } = useParams()
  if (isBasic) {
    unit = 'basic'
  }
  const { sectionsList, studyType } = useSelector(state => state.sharedSection)
  const { logPending } = useSelector(state => state.studyLog)
  const statusStates = useSelector(state => state.status)
  const { redirectBackTo, quickReviewSections } = useSelector(state => state.quickReview)
  const { sendServerLog } = useLog()

  const doNavigate = (unitDataStorage, unitData) => {
    const currentSectionIndex = sectionsList?.findIndex(it => it.isCurrentSection)

    const nextSections = statusStates.data?.sections?.filter((it, i) => i > currentSectionIndex)
    const previousSections = statusStates.data?.sections?.filter((it, i) => i < currentSectionIndex)

    const nextOnGoingSection =
      unitData?.comeFromPage === EXERCISES
        ? nextSections?.find(it => !it.isEligibleToPassFromExercisePage)
        : nextSections?.find(it => !it.isEligibleToPass)
    const nextOnGoingSectionData = sectionsData?.find(it => it.sectionType === nextOnGoingSection?.sectionType)

    const previousOnGoingSection =
      unitData?.comeFromPage === EXERCISES
        ? previousSections?.find(it => !it.isEligibleToPassFromExercisePage)
        : previousSections?.find(it => !it.isEligibleToPass)
    const previousOnGoingSectionData = sectionsData?.find(it => it.sectionType === previousOnGoingSection?.sectionType)

    let nextSectionNumber
    let nextSectionData

    if (statusStates.data && !unitData?.isViewing) {
      if (nextOnGoingSection) {
        // go to next unfinished section
        nextSectionNumber = nextOnGoingSectionData?.id
        nextSectionData = nextOnGoingSectionData
      } else if (previousOnGoingSection) {
        // Go to first unfinished section
        nextSectionNumber = previousOnGoingSectionData?.id
        nextSectionData = previousOnGoingSectionData
      } else if (currentSectionIndex !== sectionsList.length - 1) {
        // Go to statistics
        nextSectionNumber = sectionsList[sectionsList.length - 1]?.sectionNumber
        nextSectionData = sectionsData[sectionsData.length - 1] || null
      }
    } else {
      // Go to next section
      nextSectionNumber = sectionsList[currentSectionIndex + 1]?.sectionNumber
      nextSectionData = sectionsData[(nextSectionNumber || 0) - 1] || null
    }

    if (!isNumber(nextSectionNumber)) {
      if (unitData?.comeFromPage === EXERCISES) {
        navigate(`/exercise?unit=${unit}`)
      } else {
        navigate('/')
      }
    } else {
      unitDataStorage.setUnitData({
        ...unitData,
        sectionNumber: nextSectionNumber,
        sectionKey: nextSectionData?.sectionType,
        isExercise: nextSectionData.isExercise
      })

      const studyStatus = unitData?.studyStatus ?? studyTypeEnum.VIEW

      navigate({
        pathname: `/sections/${unit}/${nextSectionNumber}`,
        search: isBasic
          ? undefined
          : createSearchParams({
              studyType: studyType ?? studyStatus,
              studyStatus,
              exerciseGroupType: unitData?.exerciseGroupType ?? TYPE_A
            }).toString()
      })
    }
  }

  const navigateToNextSection = () => {
    if (!logPending) {
      if (location.pathname.includes('/sections/quick-review/') && quickReviewSections?.length > 0) {
        const currentSectionIndex = quickReviewSections?.findIndex(it => it === toNumber(sectionNumberParam))

        if (quickReviewSections?.length > currentSectionIndex + 1) {
          addQueryParams({ section: quickReviewSections[currentSectionIndex + 1] })
        } else if (redirectBackTo) {
          navigate(redirectBackTo)
        } else {
          navigate('/')
        }
      } else if (isBasic) {
        if (parseInt(sectionId) === sectionsList.length) {
          navigate('/')
        } else {
          navigate(`/sections/basic/${parseInt(sectionId) + 1}`)
        }
      } else {
        const unitDataStorage = new UnitDataStorage()
        const unitData = unitDataStorage.getUnitData()

        sendServerLog(unitData.isExercise ? FINISH_EXERCISE : FINISH_SECTION, null, () => {
          doNavigate(unitDataStorage, unitData)
        })
      }
    }
  }

  const navigateToSection = section => {
    const unitDataStorage = new UnitDataStorage()
    const unitData = unitDataStorage.getUnitData()

    sendServerLog(
      unitData?.unitNumber !== 0 ? (unitData.isExercise ? FINISH_EXERCISE : FINISH_SECTION) : null,
      null,
      () => {
        unitDataStorage.setUnitData({
          ...unitData,
          sectionNumber: toNumber(section.id),
          sectionKey: section?.sectionType,
          isExercise: section?.isExercise
        })

        const studyStatus = unitData?.studyStatus ?? studyTypeEnum.VIEW

        navigate({
          pathname: `/sections/${unit}/${section.id}`,
          search: isBasic
            ? undefined
            : createSearchParams({
                studyType: studyType ?? studyStatus,
                studyStatus,
                exerciseGroupType: unitData?.exerciseGroupType ?? TYPE_A
              }).toString()
        })
      }
    )
  }

  const finishReviewMistake = () => {
    // sendServerLog(START_SECTION)
    removeQueryParams('reviewId')
  }

  return {
    navigateToNextSection,
    navigateToSection,
    finishReviewMistake
  }
}

export default useSectionEvents
