import isOggAudioSupported from '../../operations/isOggAudioSupported'
import useHowlAudioPlayer from './useHowlAudioPlayer'
import useOgvAudioPlayer from './useOgvAudioPlayer'

function useAudioPlayer(props) {
  const { format = 'mp3' } = props

  const isOggSupported = isOggAudioSupported()

  if (format === 'ogg' && !isOggSupported) {
    return useOgvAudioPlayer(props)
  } else {
    return useHowlAudioPlayer(props)
  }
}

export default useAudioPlayer
