import serviceCall from '../../services'
import userInfoMapper from '../../services/mapper/userInfo/userInfoMapper'
import userSettingMapper from '../../services/mapper/userSetting/userSettingMapper'

const appUserUseCase = async () => {
  const result = await serviceCall('fetchAppUser', null, 'cloud')

  return {
    info: userInfoMapper(result?.info),
    setting: userSettingMapper(result?.setting),
    access: result?.access,
    action: result?.action,
    pwaVersion: result?.pwaVersion
  }
}

export default appUserUseCase
