import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkComboBoxMapper = (stackId, response, data) => {
  return {
    ...data,
    stacks: [...data.stacks].map(stack => {
      if (stack.id === stackId) {
        return {
          ...stack,
          items: [...stack.items].map(item => {
            const responseItem = response.find(obj => obj.exerciseItemId === item.id)
            const answerStatus = () => {
              if (
                responseItem.userAnswerItemResponse.every(obj => obj.userAnswerStatus === AnswerStatusEnums.CORRECT)
              ) {
                return AnswerStatusEnums.CORRECT
              } else if (
                responseItem.userAnswerItemResponse.some(obj => obj.userAnswerStatus === AnswerStatusEnums.INCORRECT)
              ) {
                return AnswerStatusEnums.INCORRECT
              } else {
                return AnswerStatusEnums.SKIPPED
              }
            }
            if (responseItem) {
              return {
                ...item,
                checked: true,
                answerIsCorrect: answerStatus === AnswerStatusEnums.CORRECT,
                userAnswerStatus: answerStatus(),
                choices: item.choices.map(choiceList => {
                  const userAnswer = responseItem.userAnswerItemResponse.find(obj => obj.listIndex === choiceList.index)
                  if (userAnswer) {
                    return {
                      ...choiceList,
                      userAnswer: {
                        userAnswerStatus: userAnswer.userAnswerStatus ?? null,
                        correctAnswer: userAnswer.correctAnswer ?? null,
                        userSelectedIndex: userAnswer.index
                      },
                      items: [...choiceList.items].map(choiceItem => {
                        return {
                          ...choiceItem,
                          selected: choiceItem.index === userAnswer.index
                        }
                      })
                    }
                  }
                  return {
                    ...choiceList.items
                  }
                })
              }
            }
            return item
          })
        }
      }
      return stack
    })
  }
}

export default checkComboBoxMapper
