import { GRAMMAR_WRAP } from '../../enums/sectionEnums/sectionEnums'
import serviceCall from '../../services'
import { CLOUD } from '../../enums/base/serviceTypeEnums'
import roboTeacherMapper from './mapper/roboTeacherMapper'

export const getRoboTeacherUseCase = async ({ sectionType = GRAMMAR_WRAP.sectionType, unitId, platform = 'ALL' }) => {
  const result = await serviceCall(
    'fetchRoboTeacher',
    {
      sectionType,
      unitId,
      platform
    },
    CLOUD
  )
  return await roboTeacherMapper(result)
}
