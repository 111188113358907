import React, { useContext } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import colorsContext from '../../contexts/ColorsContext'
import Switch from '../../atomicComponents/molecules/Switch/Switch'

const SectionsColorPalette = ({ wordColor, wordColorIsOn, setWordColorIsOn }) => {
  require('./style.scss')
  const { colors } = useContext(colorsContext)

  const popover = (
    <Popover
      className='colorpalette-popover'
      style={{ background: colors['C49'], borderColor: colors['C49'], zIndex: '2' }}
    >
      <Popover.Body>
        <div className='mb-3'>
          <Switch
            className='mx-auto'
            onChange={() => setWordColorIsOn(!wordColorIsOn)}
            value={wordColorIsOn}
            text='Text Color'
            textColor={colors['C23']}
          />
        </div>
        <ul>
          {wordColor.map((item, i) => (
            <li key={i}>
              <span className='color-badge' style={{ background: item.color }} />
              <p className='word-title' style={{ color: colors['C23'] }}>
                {item.title}
              </p>
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={popover}>
      {/* TODO: remove margin 0 after showABC in grammar wrap */}
      <span className='icon-colorpalette-t m-0 d-block cursor-pointer'>
        <img
          src={
            window.location.origin +
            (wordColorIsOn ? '/assets/images/colorpalette-t.svg' : '/assets/images/blackpalette-t.svg')
          }
          alt=''
        />
      </span>
    </OverlayTrigger>
  )
}

export default SectionsColorPalette
