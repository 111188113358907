import PlayButtonPrimary from './PlayButtonPrimary'
import useAudioPlaylist from '../../hooks/audio/useAudioPlaylist'

const PlayButtonPrimaryPlaylist = ({
  playlistUrls,
  playLimit,
  playing,
  handlePlaying,
  iconColor,
  bgColor,
  borderColor,
  loading
}) => {
  useAudioPlaylist({
    playlistUrls,
    playLimit,
    playing,
    handlePlaying
  })

  return (
    <PlayButtonPrimary
      loading={loading}
      iconColor={iconColor}
      bgColor={bgColor}
      borderColor={borderColor}
      playing={playing}
      setPlaying={handlePlaying}
    />
  )
}

export default PlayButtonPrimaryPlaylist
