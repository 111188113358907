import * as React from 'react'

const TedVideosSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>{'.TedVideosSvg-st0{stroke-width:17.7434;stroke-miterlimit:10;}'}</style>
    <g>
      <path
        className='TedVideosSvg-st0'
        stroke={color}
        d='M466.8,58H46.1c-19.4,0-35,15.8-35.1,35.2v235c0,19.4,15.7,35.2,35.1,35.2H209v92.8h-70.8 c-4.8,0.1-8.8,4-8.7,9c0.1,4.7,3.9,8.6,8.7,8.7h236.5c4.8,0,8.8-3.9,8.8-8.8c0-4.8-3.9-8.8-8.8-8.8H304v-92.8h162.9 c19.3,0,35.1-15.8,35.1-35.2V93.2C501.9,73.8,486.3,58,466.8,58z M286.4,456.1h-59.8v-92.8h59.8V456.1z M484.5,328.2 c0,9.8-7.9,17.6-17.5,17.6H46.1c-9.8,0-17.5-7.9-17.5-17.6v-235c0-9.8,7.9-17.6,17.5-17.6h420.8c9.8,0,17.5,7.9,17.5,17.6V328.2 L484.5,328.2L484.5,328.2z'
      />
      <path
        className='TedVideosSvg-st0'
        stroke={color}
        d='M332.2,190.5l-92.9-53.8c-11.2-6.5-25.4-2.7-31.9,8.6c-2.1,3.5-3.2,7.6-3.2,11.7v107.7 c0.1,13,10.7,23.5,23.7,23.4c3.9,0,7.9-1.1,11.3-3.1l92.9-53.8c11.2-6.6,14.8-21.1,8.3-32.3C338.4,195.3,335.5,192.5,332.2,190.5z  M323.4,215.8l-92.9,53.8c-2.8,1.6-6.3,0.7-8-2.2c-0.5-0.9-0.8-1.9-0.8-2.9V156.9c0-2.1,1.1-4,2.9-5c1.8-1,4-1,5.8,0l92.9,53.8 c2.8,1.6,3.7,5.1,2.2,7.9C325.1,214.5,324.3,215.2,323.4,215.8L323.4,215.8z'
      />
    </g>
  </svg>
)

export default TedVideosSvg
