import React from 'react'
import TitleTemplateModule from '../../modules/StructuredSectionModules/TitleTemplateModule'
import TextTemplate from '../../pages/Sections/StructuredComponents/TextTemplate/TextTemplate'
import GeneralTemplate from '../../pages/Sections/StructuredComponents/GeneralTemplate/GeneralTemplate'
import BubbleImageTemplate from '../../pages/Sections/StructuredComponents/BubbleImageTemplate/BubbleImageTemplate'
import TableTemplate from '../../pages/Sections/StructuredComponents/TableTemplate/TableTemplate'
import CompareSeparateTemplate from '../../pages/Sections/StructuredComponents/CompareSeparateTemplate/CompareSeparateTemplate'
import CompareMonolithicTemplate from '../../pages/Sections/StructuredComponents/CompareMonolithicTemplate/CompareMonolithicTemplate'
import ImagesTemplate from '../../pages/Sections/StructuredComponents/ImagesTemplate/ImagesTemplate'
import SingleImageTemplate from '../../pages/Sections/StructuredComponents/SingleImageTemplate/SingleImageTemplate'
import ButtonTemplate from '../../pages/Sections/StructuredComponents/ButtonTemplate/ButtonTemplate'
import CollapseTemplate from '../../pages/Sections/StructuredComponents/CollapseTemplate/CollapseTemplate'
import DividerTemplate from '../../pages/Sections/StructuredComponents/DividerTemplate/DividerTemplate'
import { ContentTypesStructureEnums } from '../../enums/structureEnums/templateType'

const StructureTemplateUseCase = ({ template }) => {
  switch (template?.type) {
    case ContentTypesStructureEnums.TITLE:
      return <TitleTemplateModule template={template} />
    case ContentTypesStructureEnums.TEXT:
      return <TextTemplate template={template} />
    case ContentTypesStructureEnums.GENERAL:
      return <GeneralTemplate template={template} />
    case ContentTypesStructureEnums.BUBBLE_IMAGE:
      return <BubbleImageTemplate template={template} />
    case ContentTypesStructureEnums.TABLE:
      return <TableTemplate template={template} />
    case ContentTypesStructureEnums.COMPARE_SEPARATE:
      return <CompareSeparateTemplate template={template} />
    case ContentTypesStructureEnums.COMPARE_MONOLITHIC:
      return <CompareMonolithicTemplate template={template} />
    case ContentTypesStructureEnums.IMAGES:
      return <ImagesTemplate template={template} />
    case ContentTypesStructureEnums.SINGLE_IMAGE:
      return <SingleImageTemplate template={template} />
    case ContentTypesStructureEnums.BUTTON:
      return <ButtonTemplate template={template} />
    case ContentTypesStructureEnums.COLLAPSE:
      return <CollapseTemplate template={template} />
    case ContentTypesStructureEnums.DIVIDER:
      return <DividerTemplate template={template} />
    default:
      return null
  }
}

export default StructureTemplateUseCase
