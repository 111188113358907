import { Howl } from 'howler'

// Create the audio objects using Howler.js
const correctAudio = new Howl({
  src: [`${window.location.origin}/assets/sounds/correct.mp3`]
})

const wrongAudio = new Howl({
  src: [`${window.location.origin}/assets/sounds/wrong.mp3`]
})

const skipAudio = new Howl({
  src: [`${window.location.origin}/assets/sounds/skip.mp3`]
})

export const playCheckSound = (isCorrect, isSkipped) => {
  if (isSkipped) {
    skipAudio.play()
  } else if (isCorrect) {
    correctAudio.play()
  } else {
    wrongAudio.play()
  }
}
