import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import { languagesEnums } from '../../enums/languageEnums/languageEnums'
import { ENGLISH, NATIVE } from '../../enums/roboTeacher/dataStatusEnums'

const initialState = {
  loading: false,
  recommendationData: null,
  responseData: null,
  error: ''
}

export const getRecommendation = createAsyncThunk(
  'recommendations/getRecommendations',
  async ({ type }, { getState }) => {
    const { translation } = getState()
    const response = await serviceCall('fetchRecommendation', { type }, 'cloud')

    return {
      response,
      selectedTranslationLanguage: translation.selectedTranslationLanguage
    }
  }
)

const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    setRecommendationLanguage: (state, action) => {
      if (action.payload === ENGLISH) state.recommendationData = state.responseData?.englishLanguage
      else if (action.payload === NATIVE) state.recommendationData = state.responseData?.nativeLanguage
    }
  },
  extraReducers: builder => {
    builder.addCase(getRecommendation.pending, state => {
      state.loading = true
    })
    builder.addCase(getRecommendation.fulfilled, (state, action) => {
      state.loading = false
      state.responseData = action.payload.response

      if (action.payload.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE) {
        state.recommendationData = action.payload.response.englishLanguage
      } else {
        state.recommendationData = action.payload.response.nativeLanguage
      }
    })
    builder.addCase(getRecommendation.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { setRecommendationLanguage } = recommendationSlice.actions

export default recommendationSlice.reducer
