import * as React from 'react'

const SingleTemplateSvg = ({ color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    fill={color}
  >
    <path d='M433 283.3H175.4c-7.1 0-12.9-5.7-12.9-12.9 0-7.1 5.7-12.9 12.9-12.9H433c7.1 0 12.9 5.7 12.9 12.9 0 7-5.7 12.9-12.9 12.9zm-321.9-38.7c-14.2 0-25.7 11.6-25.7 25.7S96.9 296 111.1 296s25.7-11.6 25.7-25.7-11.5-25.7-25.7-25.7z' />
    <path d='M111.1 296.4c-14.4 0-26.1-11.7-26.1-26.1 0-14.4 11.7-26.1 26.1-26.1 14.4 0 26.1 11.7 26.1 26.1 0 14.4-11.7 26.1-26.1 26.1zm0-51.4c-14 0-25.3 11.4-25.3 25.3 0 14 11.4 25.3 25.3 25.3 14 0 25.3-11.4 25.3-25.3 0-14-11.3-25.3-25.3-25.3zM433 283.7H175.4c-7.3 0-13.3-6-13.3-13.3 0-7.3 6-13.3 13.3-13.3H433c7.3 0 13.3 6 13.3 13.3 0 7.3-6 13.3-13.3 13.3zm-257.6-25.8c-6.9 0-12.5 5.6-12.5 12.5s5.6 12.5 12.5 12.5H433c6.9 0 12.5-5.6 12.5-12.5s-5.6-12.5-12.5-12.5H175.4z' />
    <path d='M436 511.8H76.3C34.5 511.8.5 477.8.5 436V76.3C.5 34.5 34.5.5 76.3.5H436c41.8 0 75.8 34 75.8 75.8V436c0 41.8-34 75.8-75.8 75.8zM76.3 18.5c-31.9 0-57.8 25.9-57.8 57.8V436c0 31.9 25.9 57.8 57.8 57.8H436c31.9 0 57.8-25.9 57.8-57.8V76.3c0-31.9-25.9-57.8-57.8-57.8H76.3z' />
  </svg>
)

export default SingleTemplateSvg
