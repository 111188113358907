import componentStyle from './CollapseTemplate.module.scss'
import React, { useContext } from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import { Accordion } from 'react-bootstrap'
import ArrowMoreSvg from '../../../../icons/ArrowMoreSvg'
import StructureTemplateUseCase from '../../../../useCase/StructureUseCase/StructureTemplateUseCase'
import classNames from 'classnames'

const CollapseTemplate = ({ template }) => {
  const { colors } = useContext(colorsContext)

  const isMain = template.config.displayMode === 'MAIN'

  if (template) {
    return (
      <div>
        <Accordion
          className={componentStyle.accordion}
          defaultActiveKey={template.config.isOpen ? template.index : null}
        >
          <Accordion.Item eventKey={template.index}>
            <div
              className={classNames('container container-1', {
                [componentStyle.mainAccordionItemContainer]: isMain,
                [componentStyle.subAccordionItemContainer]: !isMain
              })}
            >
              <Accordion.Header>
                <div
                  className={classNames(componentStyle.accordionHeader, {
                    [componentStyle.main]: isMain,
                    [componentStyle.sub]: !isMain
                  })}
                  style={{
                    background: template.config.backgroundColor
                      ? template.config.backgroundColor
                      : isMain
                      ? colors['C205']
                      : colors['C184'],
                    borderColor: template.config.borderColor
                      ? template.config.borderColor
                      : isMain
                      ? colors['C160']
                      : 'transparent'
                  }}
                >
                  <h4 className={componentStyle.accordionTitle} style={{ color: colors['C179'] }}>
                    {template.config?.caption}
                  </h4>
                  <span className={componentStyle.arrow}>
                    <ArrowMoreSvg color={colors['C179']} />
                  </span>
                </div>
              </Accordion.Header>
            </div>
            <Accordion.Body className={componentStyle.accordionBody}>
              {template.data?.templates?.map((subTemplate, i) => {
                return <StructureTemplateUseCase template={subTemplate} key={i} />
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    )
  } else {
    return null
  }
}

export default CollapseTemplate
