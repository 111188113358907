const spellingWordCustomSyllableFactory = wordData => {
  let customSyllable = ''
  const partsArray = wordData.customSyllableArray.filter(it => it.length > 0)
  partsArray.forEach((part, partIndex) => {
    part.forEach(it => {
      customSyllable += it.char
    })
    if (partsArray.length > partIndex + 1) {
      customSyllable += '.'
    }
  })

  return customSyllable
}

export default spellingWordCustomSyllableFactory
