import md5 from 'md5'
import { lazy } from 'react'

const cleanupLocalStorage = retryCountKey => {
  window.localStorage.removeItem(retryCountKey)
}

export const lazyImport = componentImport =>
  lazy(async () => {
    const componentString = componentImport.toString()
    const componentHash = md5(componentString)
    const retryCountKey = `retry-count-${componentHash}`
    const maxRetries = 3
    const retryCount = parseInt(window.localStorage.getItem(retryCountKey) || '0', 10)

    try {
      if (retryCount >= maxRetries) {
        cleanupLocalStorage(retryCountKey)
        throw new Error('Max retries exceeded')
      }

      const component = await componentImport()

      window.localStorage.setItem(retryCountKey, '0')

      // Cleanup localStorage if everything is successful
      cleanupLocalStorage(retryCountKey)

      return component
    } catch (error) {
      if (retryCount < maxRetries) {
        window.localStorage.setItem(retryCountKey, retryCount + 1)
        if (retryCount === 0) {
          // Bust the cache by appending a version query parameter
          const currentUrl = new URL(window.location.href)
          currentUrl.searchParams.set('version', Date.now())

          return (window.location.href = currentUrl.toString())
        } else if (retryCount < maxRetries - 1) {
          // Bust the cache by appending a version query parameter
          const currentUrl = new URL(window.location.origin)
          currentUrl.searchParams.set('version', Date.now())
          currentUrl.searchParams.set('chunkErrorRedirect', window.location.href)

          return (window.location.href = currentUrl.toString())
        }
      }

      const currentUrl = new URL(`${window.location.origin}/chunk-error`)
      currentUrl.searchParams.set('redirectFrom', window.location.href)
      if (error?.message) {
        currentUrl.searchParams.set('errorMessage', error.message)
      }

      window.location.href = currentUrl.toString()

      throw error
    }
  })
