import { SAMAN, ZARINPAL } from '../../enums/payment/gatewaysEnums'
import serviceCall from '../../services'

const subscriptionIranPalUseCase = async ({ asRenewal, discountCode, planId, psp, purchaseType }) => {
  let result
  if (psp === ZARINPAL) {
    result = await serviceCall(
      'subscriptionZarinPalPlan',
      { asRenewal, discountCode, planId, psp, purchaseType },
      'cloud'
    )
  } else if (psp === SAMAN) {
    result = await serviceCall('subscriptionSamanPlan', { asRenewal, discountCode, planId, psp, purchaseType }, 'cloud')
  }

  return { ...result, psp }
}

export default subscriptionIranPalUseCase
