import fetchReading from './offlineServicesData/sections/fetchReading.json'
import fetchConversation from './offlineServicesData/sections/fetchConversation.json'
import fetchDrill from './offlineServicesData/sections/fetchDrill.json'
import fetchChooseFromCategory from './offlineServicesData/exercise/fetchChooseFromCategory.json'
import fetchMatch from './offlineServicesData/exercise/fetchMatch.json'
import fetchDragAndDrop from './offlineServicesData/exercise/fetchDragAndDropCategory.json'
import fetchMultipleChoice from './offlineServicesData/exercise/fetchMultipleChoice.json'
import fetchReadingMapper from './mapper/sections/fetchReadingMapper'
import fetchChooseFromCategoryMapper from './mapper/exercise/fetchChooseFromCategoryMapper'
import fetchMatchMapper from './mapper/exercise/fetchMatchMapper'
import fetchSayTheFollowingMapper from './mapper/exercise/fetchSayTheFollowingMapper'
import fetchMultipleChoiceMapper from './mapper/exercise/fetchMultipleChoiceMapper'
import fetchDragAndDropMapperOld from './mapper/exercise/fetchDragAndDropMapperOld'

const localServices = (serviceName, data) => {
  const serviceFunc = eval(serviceName)
  const serviceData = serviceFunc(data)

  return {
    data: serviceData,
    status: 'OK'
  }
}

export const fetchSection = ({ section }) => {
  switch (section) {
    case 'READING': {
      return fetchReadingMapper({
        data: fetchReading,
        status: 200
      })
    }
    case 'CONVERSATION': {
      return fetchConversation
    }
    case 'DRILL': {
      return fetchDrill
    }
    default:
      return null
  }
}

export const fetchUnitById = ({ unitId }) => {
  if (unitId === '10') {
    return {
      userId: '532604db-a03f-4a3f-b285-87f9396a13f8',
      level: 'BEGINNER_1',
      levelStr: 'Beginner 1 (A1)',
      studyType: 'REPEAT_1',
      group: 'TYPE_B',
      unitId: 10,
      unitTitle: 'Frequency Adverbs 1',
      unitDescription: 'What time is it?',
      doneSections: 3,
      skippedSections: 0,
      totalSections: 19,
      doneSectionsPercentage: 15.789474,
      skippedSectionsPercentage: 0,
      totalCorrect: 13,
      totalIncorrect: 5,
      totalSkipped: 11,
      totalCount: 331,
      correctPercentage: 10.323575,
      incorrectPercentage: 9.090909,
      skippedPercentage: 1.6949153,
      startDate: '2024-08-05T11:00:21.751Z',
      finishDate: null,
      isOn: true,
      isActive: false,
      isFinished: false,
      isInProgress: true
    }
  } else {
    return {
      level: 'BEGINNER_1',
      levelStr: 'Beginner 1 (A1)',
      studyType: null,
      group: null,
      unitId: 1,
      unitTitle: 'To Be 1 (am/is)',
      unitDescription: 'Greetings 1',
      doneSections: 23,
      skippedSections: 0,
      totalSections: 23,
      doneSectionsPercentage: 100,
      skippedSectionsPercentage: 0,
      totalCorrect: 206,
      totalIncorrect: 3,
      totalSkipped: 0,
      totalCount: 178,
      correctPercentage: 115.73034,
      incorrectPercentage: 1.6853932,
      skippedPercentage: 0,
      startDate: '2024-01-17T07:47:50.756Z',
      finishDate: '2024-02-23T18:19:10.04Z',
      isOn: true,
      isActive: true,
      isFinished: true,
      isInProgress: false
    }
  }
}

export const fetchSectionExercise = ({ section }) => {
  switch (section) {
    case 'CONVERSATION_EXERCISE_1':
      return fetchChooseFromCategoryMapper({
        data: fetchChooseFromCategory,
        status: 200
      })
    case 'CONVERSATION_EXERCISE_2':
      return fetchMatchMapper({
        data: fetchMatch,
        status: 200
      })
    case 'GRAMMAR_EXERCISE_3':
      return fetchMultipleChoiceMapper({
        data: fetchMultipleChoice,
        status: 200
      })
    case 'SPEAKING_EXERCISE_6':
      return fetchSayTheFollowingMapper({
        data: fetchMatch,
        status: 200
      })
    case 'LISTENING_EXERCISE_5':
      return fetchDragAndDropMapperOld({
        data: fetchDragAndDrop,
        status: 200
      }).data
    default:
      return fetchChooseFromCategoryMapper({
        data: fetchChooseFromCategory,
        status: 200
      })
  }
}

export default localServices
