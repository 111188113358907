import { ALL, VOCABULARY } from '../../../enums/globalEnums/globalEnums'
import moment from 'moment'
import { getTimestampMilliSeconds } from '../../../util/utility'

const favoritesCategoryMapper = (data, type) => {
  return data?.map(item => {
    return {
      ...item,
      showCount:
        type === ALL ? item?.totalCount ?? 0 : type === VOCABULARY ? item?.vocabCount ?? 0 : item?.sentenceCount ?? 0,
      showDate: moment(getTimestampMilliSeconds(item?.createDate)).format('YYYY/MM/DD  hh:mm:ss')
    }
  })
}

export default favoritesCategoryMapper
