const aiChatRoleDescriptionMapper = data => {
  return (
    data?.map((item, i) => {
      return {
        index: i,
        id: item.id ?? 0,
        title: item.title ?? '',
        context: item.context ?? '',
        description: item.description ?? '',
        greetingText: item.greetingText ?? '',
        unitId: item.unitId ?? null,
        promptType: item.promptType ?? null
      }
    }) ?? []
  )
}

export default aiChatRoleDescriptionMapper
