import React, { useContext } from 'react'
import style from './SecondaryToplineTitle.module.scss'
import colorsContext from '../../contexts/ColorsContext'

const SecondaryToplineTitle = ({ title }) => {
  const { colors } = useContext(colorsContext)
  return (
    <div className={style.secondaryToplineTitleWrapper + ' mt-2'}>
      <span className={style.toplineTitleBefore} style={{ background: colors['C213'] }} />
      {title && (
        <div
          className={style.toplineTitle}
          style={{ background: colors['C56'], color: colors['C160'], borderColor: colors['C160'] }}
        >
          {title}
        </div>
      )}
      <span className={style.toplineTitleAfter} style={{ background: colors['C213'] }} />
    </div>
  )
}
export default SecondaryToplineTitle
