import React from 'react'

const TitleBoxSvg = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 445.15 63.716'>
      <defs>
        <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#e4e4e4' />
          <stop offset='0' stopColor='#3d244b' />
          <stop offset='0.32' stopColor='#775377' />
          <stop offset='0.462' stopColor='#8c5e8c' />
          <stop offset='0.519' stopColor='#8c5e8c' />
          <stop offset='0.649' stopColor='#8c5e8c' />
          <stop offset='1' stopColor='#3d244b' />
        </linearGradient>
      </defs>
      <g transform='translate(6.482 0)'>
        <path
          d='M1095.8,1706h432.141l-32.962,63.431H1128.535Z'
          transform='translate(-1095.8 -1705.999)'
          fill='url(#linear-gradient)'
        />
        <g transform='translate(399.109 0)'>
          <path d='M6.558,15.63,0,0,10.647.1Z' transform='translate(39.559 15.63) rotate(180)' fill='#3d244b' />
          <path
            d='M1481.458,1707.05l-3.434,15.547-29.573,48.169Z'
            transform='translate(-1448.45 -1707.049)'
            fill='#3d244b'
          />
        </g>
      </g>
      <g transform='translate(0 0)'>
        <path d='M6.558,0,0,15.629l10.647-.1Z' transform='translate(0 0)' fill='#3d244b' />
        <path
          d='M1448.45,1707.05l3.434,15.547,29.574,48.169Z'
          transform='translate(-1441.899 -1707.049)'
          fill='#3d244b'
        />
      </g>
    </svg>
  )
}

export default TitleBoxSvg
