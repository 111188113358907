const convertMillisToReadableTime = milliseconds => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  seconds = seconds % 60
  let hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  let days = Math.floor(hours / 24)
  hours = hours % 24

  let readableTime = ''
  if (days > 0) {
    readableTime += days + ' day' + (days > 1 ? 's' : '') + ', '
  }
  if (hours > 0) {
    readableTime += hours + ' hour' + (hours > 1 ? 's' : '') + ', '
  }
  if (minutes > 0) {
    readableTime += minutes + ' minute' + (minutes > 1 ? 's' : '')
  }
  // Trim any trailing comma and space
  readableTime = readableTime.replace(/, $/, '')

  return readableTime
}

export default convertMillisToReadableTime
