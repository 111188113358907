/**
 * @file index.tsx
 *
 * Index file for the main entry point of the React application.
 * It imports necessary styles, initializes Sentry for error tracking, sets up the Redux store,
 * and renders the main `App` component to the DOM.
 */

import './styles/tailwind.css'
import React from 'react'
import { Provider } from 'react-redux'
import App from './App'
import store from './redux/store'
import { createRoot } from 'react-dom/client'
import './initialScripts'
import * as Sentry from '@sentry/react'
import { removeQueryParamsAndNavigate } from './util/utility'

// Clean extra query params
removeQueryParamsAndNavigate(['phraseOptions', 'openPhraseOptions', 'phraseType', 'phraseUnit'])

// Sentry Initialization
const isProduction = window.location.hostname === 'app.vellso.com'
const isStage = window.location.hostname === 'stage-app.vellso.com'
const isDevelopment = window.location.hostname === 'dev-app.vellso.com'

/**
 * Initializes Sentry for error tracking and session replay in production and stage environments.
 * Configures error filtering and adjusts session replay and performance tracing rates.
 */
if (isProduction || isStage) {
  Sentry.init({
    dsn: 'https://3a5abe7b54f6f2aaa53c4b80e36392a7@sentry.vellso.com/2',
    integrations: [
      Sentry.replayIntegration({
        maskAllInputs: false,
        blockAllMedia: false,
        maskAllText: false
      })
    ],
    /**
     * Filters out events where the error status is 401 or 403 before sending them to Sentry.
     */
    beforeSend(event, hint) {
      const errorStatus = event?.breadcrumbs?.[event.breadcrumbs.length - 1]?.data?.status_code

      if (errorStatus === 401 || errorStatus === 403) {
        return null
      }
      return event
    },
    // tracesSampleRate: 0.2, // Adjust performance sampling rate
    replaysSessionSampleRate: 0, // Adjust session replay sampling rate
    replaysOnErrorSampleRate: 0,
    environment: isProduction ? 'production' : isStage ? 'stage' : 'development'
  })
}

/**
 * The root DOM element for the React application.
 * @constant {HTMLElement|null} rootElement - The root DOM element with the id 'root', or null if not found.
 */
const rootElement = document.getElementById('root')

if (!rootElement) {
  throw new Error('Root element not found')
}

// Create root
const root = createRoot(rootElement)

// Render the main application
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
