import * as React from 'react'

const LevelUnitSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {
        '.LevelUnitSvg-st0{fill:none;stroke-width:32.3464;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}'
      }
    </style>
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  className='LevelUnitSvg-st0'
                  stroke={color}
                  d='M23.5,485.9c-3.5,0.2-6.5-2.5-6.5-6.1v-87.1c0-8.6,7-15.6,15.6-15.6h74.7c9.4,0,16.9-7.6,16.9-16.9v-51.9 c0-9.4,7.6-16.9,16.9-16.9h73.4c9.4,0,16.9-7.6,16.9-16.9v-51.9c0-9.4,7.6-16.9,16.9-16.9h73.6c9.4,0,16.9-7.6,16.9-16.9V138 c0-9.4-7.6-16.9-16.9-16.9h-14.5c-2.5,0-4.7-1.6-5.6-3.7c-1-2.4-0.5-4.8,1.4-6.7l91.2-91.1c1.2-1.2,2.7-1.9,4.3-1.9 c1.7,0,3.1,0.7,4.3,1.9l91.1,91c1.9,1.9,2.4,4.3,1.4,6.7c-1,2.4-2.9,3.6-5.3,3.7c-0.2,0-0.4,0.1-0.6,0.2L476,129'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <path className='LevelUnitSvg-st0' stroke={color} d='M24.3,488.9' />
    </g>
    <path className='LevelUnitSvg-st0' stroke={color} d='M17,488.9l434.4-2.8C465,486,476,474.9,476,461.3V121' />
  </svg>
)

export default LevelUnitSvg
