import { isRejectedWithValue } from '@reduxjs/toolkit'
import thunkActionRegistry from '../thunkActions'

let retries = {}

const retryMiddleware = storeAPI => next => action => {
  let retryScheduled = false

  if (action?.meta?.retryId && isRejectedWithValue(action) && action.payload?.status === 'ERR_NETWORK') {
    const retryId = action.meta.retryId

    const handleOnline = () => {
      if (retryScheduled) return
      retryScheduled = true

      // Remove listener once online
      window.removeEventListener('online', handleOnline)

      // Wait a little longer to ensure network stability
      setTimeout(() => {
        if (retries[retryId]) {
          delete retries[retryId]

          const { thunkId, arg } = action.meta
          if (thunkId && thunkActionRegistry[thunkId]) {
            // Re-dispatch the original thunk with the saved arguments
            storeAPI.dispatch(thunkActionRegistry[thunkId](arg))
          }
          retryScheduled = false
        }
      }, 3000) // Delay the retry for 3 seconds after coming online
    }

    retries[retryId] = handleOnline

    window.addEventListener('online', handleOnline)
    return
  }

  if (action?.type === 'CANCEL_RETRY') {
    const { retryId } = action.payload

    const handler = retries[retryId]

    if (handler) {
      window.removeEventListener('online', handler)
      delete retries[retryId]
    }
  }

  return next(action)
}

export default retryMiddleware
