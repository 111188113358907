const exerciseStatisticsMapper = data => {
  const getLevelStatusColorCode = percentage => {
    if (percentage === 100) {
      return {
        background: 'C30',
        title: 'C160',
        badge: 'C160',
        percentage: 'C30'
      }
    } else if (percentage >= 80) {
      return {
        title: 'C2',
        badge: 'C499',
        percentage: 'C160'
      }
    } else if (percentage >= 50) {
      return {
        title: 'C2',
        badge: 'C17',
        percentage: 'C233'
      }
    } else if (percentage >= 30) {
      return {
        title: 'C2',
        badge: 'C18',
        percentage: 'C160'
      }
    } else if (percentage >= 10) {
      return {
        title: 'C2',
        badge: 'C29',
        percentage: 'C160'
      }
    } else {
      return {
        title: 'C2',
        badge: 'C160',
        percentage: 'C233'
      }
    }
  }

  return data?.map((level, index) => {
    return {
      level: level.level,
      title: level.title,
      levelNumber: index + 1,
      levelProgress: Math.floor(level.levelProgress),
      colors: getLevelStatusColorCode(Math.floor(level.levelProgress)),
      units: level?.units?.map(unit => {
        return {
          title: unit.title,
          description: unit.description,
          unitId: unit.unitId,
          exerciseA: {
            correctPercentage: Math.floor(unit.correctPercentageTypeA ?? 0),
            totalCorrect: unit.totalCorrectTypeA ?? 0,
            totalIncorrect: unit.totalIncorrectTypeA ?? 0,
            totalSkipped: unit.skippedTypeA ?? 0,
            totalLeft: unit.totalCountTypeA - unit.totalCorrectTypeA,
            studyType: unit.studyTypeA
          },
          exerciseB: {
            correctPercentage: Math.floor(unit.correctPercentageTypeB ?? 0),
            totalCorrect: unit.totalCorrectTypeB ?? 0,
            totalIncorrect: unit.totalIncorrectTypeB ?? 0,
            totalSkipped: unit.skippedTypeB ?? 0,
            totalLeft: unit.totalCountTypeB - unit.totalCorrectTypeB,
            studyType: unit.studyTypeB
          }
        }
      })
    }
  })
}

export default exerciseStatisticsMapper
