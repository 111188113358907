import * as React from 'react'

const PointPlusSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {'.PointPLusSvg-st0{stroke-width:19.1798;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}'}
    </style>
    <g>
      <g>
        <g>
          <path
            className='PointPLusSvg-st0'
            stroke={color}
            d='M15.8,372.2l92.3-28.5c-5.1-13.8-7.2-28.6-6.1-43.3c3.1-31,18.6-59.4,43.1-78.7c0.1-0.1,0.2-0.2,0.3-0.2 l89.6-89.6c28.5-28.4,71-37.7,108.7-23.6l28.5-92.3c1-3.1,3.8-5.3,7.1-5.3c25.2,0,45.7,20.5,45.7,45.8c0,17.1,13.9,30.9,31,30.9 c25.3,0,45.7,20.5,45.8,45.8c0,3.3-2.1,6.1-5.2,7.1l-92.4,28.6c13.9,37.7,4.7,80.1-23.7,108.7l-103,103 c-28.5,28.5-71,37.7-108.7,23.7l-28.5,92.3c-1,3.2-3.8,5.3-7.1,5.3c-25.3,0-45.7-20.5-45.8-45.8c0-17.1-13.9-30.9-31-31 c-25.3-0.1-45.8-20.6-45.8-45.8C10.5,376.1,12.6,373.2,15.8,372.2L15.8,372.2z M486.2,128.1c-2.6-14.8-15.4-25.6-30.5-25.6 c-25.3,0-45.8-20.5-45.8-45.8c0-15.1-10.8-27.8-25.6-30.5l-27.3,88.3c1.4,0.8,2.9,1.6,4.2,2.5l22.5-30c2.4-3.4,7-4.2,10.4-1.8 c3.4,2.4,4.2,7,1.8,10.4c-0.1,0.1-0.2,0.3-0.3,0.4l-22.2,29.7c4.9,4,9.3,8.4,13.3,13.3l29.7-22.2c3.3-2.5,8-1.8,10.5,1.4 c2.5,3.3,1.8,8-1.4,10.5l0,0l-30,22.5c0.9,1.4,1.7,2.8,2.6,4.2L486.2,128.1z M395.6,204.9c1.2-77.5-96.4-117.7-150.4-62.3 l-64.1,64.1c13.8-0.1,27.1,5.4,36.9,15.2c14.4,14.3,37.7,14.3,52.1,0c20.1-20.1,52.8-20.2,73.1,0l0,0 c13.9,13.8,36.2,14.3,50.6,1.2C395,217,395.5,210.9,395.6,204.9z M341.8,295.2c12.5-13.1,37.3-34.1,44.4-50.8 c-18.7,6.6-39.6,1.9-53.7-12.2c-14.4-14.3-37.6-14.3-52.1,0c-20.1,20.1-52.8,20.2-73.1,0l0,0c-14.4-14.3-37.5-14.3-51.9-0.1 l-13.4,13.4c-10.8,10.8-18.7,24.4-22.6,39.2c18.6-8,40.2-3.7,54.5,10.5c13.5,13.5,35.4,13.5,48.9,0c19.4-19.3,50.6-19.3,70,0 C306.4,308.7,328.3,308.7,341.8,295.2L341.8,295.2z M266.8,370.2l50.1-50.1c-12.9-0.1-25.3-5.3-34.4-14.5 c-13.5-13.5-35.4-13.5-48.9,0c-19.4,19.3-50.6,19.3-70,0c-12.8-12.8-33.3-13.5-46.9-1.6c-1.7,48.7,36.3,89.8,85.1,91.5 C226,396.5,249.5,387.3,266.8,370.2L266.8,370.2z M56.3,410.3c25.3,0,45.8,20.5,45.8,45.8c0,15,10.8,27.8,25.6,30.5l27.3-88.3 c-1.4-0.8-2.9-1.6-4.2-2.5l-22.5,30c-2.5,3.3-7.1,3.9-10.5,1.5c-3.3-2.5-3.9-7.1-1.5-10.5l22.2-29.7c-4.9-4-9.3-8.4-13.2-13.3 l-29.8,22.3c-3.3,2.5-8,1.8-10.5-1.5c-2.5-3.3-1.8-8,1.5-10.5l0,0l30-22.6c-0.9-1.4-1.6-2.9-2.5-4.3l-88.4,27.3 C28.3,399.5,41.3,410.3,56.3,410.3L56.3,410.3z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default PointPlusSvg
