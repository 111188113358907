import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getAllFlashCardUseCase from '../../../useCase/memoryBoxUseCase/getAllFlashCardUseCase'
import getAllHistoryFlashCardUseCase from '../../../useCase/memoryBoxUseCase/getAllHistoryFlashCardUseCase'
import getUnitsSummeryUseCase from '../../../useCase/memoryBoxUseCase/getUnitsSummeryUseCase'
import getAllUnitFlashCardUseCase from '../../../useCase/memoryBoxUseCase/getAllUnitFlashCardUseCase'
import getAllFlashCardByIsVocabUseCase from '../../../useCase/memoryBoxUseCase/getAllFlashCardByIsVocabUseCase'
import getAllHistoryFlashCardByIsVocabUseCase from '../../../useCase/memoryBoxUseCase/getAllHistoryFlashCardByIsVocabUseCase'
import getAllUnitHistoryFlashCardUseCase from '../../../useCase/memoryBoxUseCase/getAllUnitHistoryFlashCardUseCase'

const initialState = {
  loading: false,
  response: null,
  unitsSummer: [],
  flashCardsData: [],
  currentCard: null,
  currentIndex: 0,
  total: 0,
  error: ''
}

export const getAllFlashCards = createAsyncThunk(
  'memoryBoxList/getAllFlashCards',
  ({ categoryId, searchTerm, sortBy, sortKey }) => {
    return getAllFlashCardUseCase({ categoryId, searchTerm, sortBy, sortKey })
  }
)

export const getAllFlashCardsByIsVocab = createAsyncThunk(
  'memoryBoxList/getAllFlashCardsByIsVocab',
  ({ categoryId, searchTerm, sortBy, sortKey, isVocab }) => {
    return getAllFlashCardByIsVocabUseCase({ categoryId, searchTerm, sortBy, sortKey, isVocab })
  }
)

export const getAllUnitFlashCards = createAsyncThunk(
  'memoryBoxList/getAllUnitFlashCards',
  ({ categoryId, searchTerm, sortBy, sortKey, unitId, isVocab }) => {
    return getAllUnitFlashCardUseCase({ categoryId, searchTerm, sortBy, sortKey, unitId, isVocab })
  }
)

export const getAllHistoryFlashCards = createAsyncThunk(
  'memoryBoxList/getAllHistoryFlashCards',
  ({ categoryId, searchTerm, sortBy, sortKey }) => {
    return getAllHistoryFlashCardUseCase({ categoryId, searchTerm, sortBy, sortKey })
  }
)

export const getAllUnitHistoryFlashCards = createAsyncThunk(
  'memoryBoxList/getAllUnitHistoryFlashCards',
  ({ categoryId, searchTerm, sortBy, sortKey, unitId, isVocab }) => {
    return getAllUnitHistoryFlashCardUseCase({ categoryId, searchTerm, sortBy, sortKey, unitId, isVocab })
  }
)

export const getAllHistoryFlashCardsByIsVocab = createAsyncThunk(
  'memoryBoxList/getAllHistoryFlashCardsByIsVocab',
  ({ categoryId, searchTerm, sortBy, sortKey, isVocab }) => {
    return getAllHistoryFlashCardByIsVocabUseCase({ categoryId, searchTerm, sortBy, sortKey, isVocab })
  }
)

export const getUnitsSummery = createAsyncThunk('memoryBoxList/getUnitsSummery', ({ categoryId }) => {
  return getUnitsSummeryUseCase({ categoryId })
})

const listSlice = createSlice({
  name: 'memoryBoxList',
  initialState,
  reducers: {
    selectUnit: (state, action) => {
      state.unitsSummer = state.unitsSummer.map(item => {
        if (item?.unitId === action.payload) {
          return {
            ...item,
            isSelect: !item.isSelect
          }
        } else {
          return item
        }
      })
    },
    selectCard: (state, action) => {
      state.flashCardsData = state.flashCardsData.map(item => {
        if (item?.id === action.payload) {
          return {
            ...item,
            isSelect: !item.isSelect
          }
        } else {
          return item
        }
      })
    },
    selectAllUnits: (state, action) => {
      state.unitsSummer = state.unitsSummer.map(item => {
        return {
          ...item,
          isSelect: action.payload
        }
      })
    },
    unselectAllUnits: (state, action) => {
      state.unitsSummer = state.unitsSummer.map(item => {
        return {
          ...item,
          isSelect: false
        }
      })
    },
    selectAllCards: (state, action) => {
      state.flashCardsData = state.flashCardsData.map(item => {
        return {
          ...item,
          isSelect: action.payload
        }
      })
    },
    unselectAllCards: state => {
      state.flashCardsData = state.flashCardsData.map(item => {
        return {
          ...item,
          isSelect: false
        }
      })
    },
    setCurrentToView: (state, action) => {
      state.currentIndex = action.payload
      state.currentCard = state.response?.results?.[state.currentIndex]
    },
    setCurrentMemoryCard: (state, action) => {
      state.currentCard = action.payload
      if (state.response?.results?.[state.currentIndex]) {
        state.response.results[state.currentIndex] = action.payload
      }
    },
    goNext: (state, action) => {
      if (state.total > state.currentIndex + 1) {
        state.currentIndex = state.currentIndex + 1
        state.currentCard = state.response?.results?.[state.currentIndex]
      }
    },
    goPre: (state, action) => {
      if (state.currentIndex - 1 >= 0) {
        state.currentIndex = state.currentIndex - 1
        state.currentCard = state.response?.results?.[state.currentIndex]
      }
    }
  },
  extraReducers: builder => {
    //get all flashcards
    builder.addCase(getAllFlashCards.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllFlashCards.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(getAllFlashCards.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get all flashcards by isVocab
    builder.addCase(getAllFlashCardsByIsVocab.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllFlashCardsByIsVocab.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(getAllFlashCardsByIsVocab.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get all history flashcards
    builder.addCase(getAllHistoryFlashCards.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllHistoryFlashCards.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(getAllHistoryFlashCards.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get all history flashcards by isVocab
    builder.addCase(getAllHistoryFlashCardsByIsVocab.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllHistoryFlashCardsByIsVocab.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(getAllHistoryFlashCardsByIsVocab.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get all unit history flashcards by isVocab
    builder.addCase(getAllUnitHistoryFlashCards.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllUnitHistoryFlashCards.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(getAllUnitHistoryFlashCards.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get all unit flashcards
    builder.addCase(getAllUnitFlashCards.pending, state => {
      state.loading = true
    })
    builder.addCase(getAllUnitFlashCards.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(getAllUnitFlashCards.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get units summery
    builder.addCase(getUnitsSummery.pending, state => {
      state.loading = true
    })
    builder.addCase(getUnitsSummery.fulfilled, (state, action) => {
      state.loading = false
      state.unitsSummer = action.payload
    })
    builder.addCase(getUnitsSummery.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {
  selectUnit,
  selectCard,
  selectAllUnits,
  unselectAllUnits,
  selectAllCards,
  unselectAllCards,
  setCurrentToView,
  setCurrentMemoryCard,
  goNext,
  goPre
} = listSlice.actions

export default listSlice.reducer
