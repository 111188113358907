const removeWrapperTag = (text, tag = 'p') => {
  if (typeof text === 'string') {
    let replace = `</?${tag}[^>]*>`
    // return text.replace(/<\/?[^>]*>/g, '')
    const re = new RegExp(replace, 'g')
    return text.replace(re, '')
  }
  return ''
}

export default removeWrapperTag
