import * as React from 'react'

const BubbleSvg = ({ color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 48 48'
    width={48}
    height={48}
    xmlSpace='preserve'
  >
    <path
      fill={color}
      d='M42.59,46.37c0.08-0.7-0.09-1.41-0.42-2.03c-0.71-1.34-2.09-4.83,0.34-8.36c3.45-5.02,9.61-19.59-4.24-30.75 C22.74-7.28,1.09,4.37,1.11,23.9c0.01,17.41,14.61,22.07,20.92,22.4c5.42,0.28,10.47,0.11,12.54,0.16c1.17,0.03,2.32,0.3,3.39,0.77 C39.66,47.96,42.32,48.72,42.59,46.37z'
    />
  </svg>
)

export default BubbleSvg
