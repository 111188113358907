import * as React from 'react'

const ToolsOutLineSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={512}
    height={512}
    viewBox='0 0 512 512'
  >
    <style>
      {
        '.ToolsOutLineSvg-st0,.ToolsOutLineSvg-st1{fill:none;stroke-width:34.7147;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.ToolsOutLineSvg-st1{stroke-width:21.5794}'
      }
    </style>
    <path
      d='m475 158.9-438.3-1.6-15.8 86-2.1 31.3 20.9 187.9 433 2.3 21-188-2.2-31.3-15.8-85.9M155.7 116.5V54h200.1v62.5'
      className='ToolsOutLineSvg-st0'
      stroke={color}
    />
    <path
      d='M38.2 243.3h48.6v-27.7h37.6v27.7h265.9v-27.7h43.1v27.7h41'
      className='ToolsOutLineSvg-st0'
      stroke={color}
    />
    <path
      d='M116.8 337.6h57.3l10.4 20.5-10.6 20.5h-57.3M119 383.4c9.7 20.1 30.8 31.8 52.1 29.2 18.9-2.3 35.1-15.5 41.7-33.9h53.9M117.5 331c9.7-20.1 30.8-31.8 52.1-29.2 18.9 2.3 35.1 15.5 41.7 33.9h55.3M409.3 337.2H352l-10.4 20.5 10.6 20.5h57.3'
      className='ToolsOutLineSvg-st1'
      stroke={color}
    />
    <path
      d='M407.1 383c-9.7 20.1-30.8 31.8-52.1 29.2-18.9-2.3-35.1-15.5-41.7-33.9h-53.9M408.6 330.6c-9.7-20.1-30.8-31.8-52.1-29.2-18.9 2.3-35.1 15.5-41.7 33.9h-55.3'
      className='ToolsOutLineSvg-st1'
      stroke={color}
    />
  </svg>
)

export default ToolsOutLineSvg
