import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import addFileUseCase from '../../useCase/notepadUseCase/addFileUseCase'
import addFolderUseCase from '../../useCase/notepadUseCase/addFolderUseCase'
import deleteItemsUseCase from '../../useCase/notepadUseCase/deleteItemsUseCase'
import editFileUseCase from '../../useCase/notepadUseCase/editFileUseCase'
import editFolderUseCase from '../../useCase/notepadUseCase/editFolderUseCase'
import getAllNotepadFileAndFolderUseCase from '../../useCase/notepadUseCase/getAllNotepadFileAndFolderUseCase'
import getNoteUseCase from '../../useCase/notepadUseCase/getNoteUseCase'
import moveItemsUseCase from '../../useCase/notepadUseCase/moveItemsUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  source: [],
  filesAndFolders: [],
  currentFolderId: null,
  selectedItems: [],
  folders: [],
  destinationFolderId: null,
  currentNote: null,
  searchPhrase: '',
  sortType: 'ASC',
  sortBy: 'TYPE',
  error: null,
  loading: false,
  getNoteLoading: false,
  getAllNotepadFileAndFoldersLoading: false,
  AddFolderLoading: false
}

export const addFolder = baseAsyncThunk('notepad/addFolder', async ({ parentFolderId, title, color }) => {
  return await addFolderUseCase({ parentFolderId, title, color })
})

export const editFolder = baseAsyncThunk('notepad/editFolder', async ({ parentFolderId, folderId, title, color }) => {
  return await editFolderUseCase({ parentFolderId, folderId, title, color })
})

export const deleteItems = createAsyncThunk('notepad/deleteItems', ({ parentFolderId, items }) => {
  return deleteItemsUseCase({ parentFolderId, items })
})

export const getAllNotepadFileAndFolders = baseAsyncThunk(
  'notepad/getAllNotepadFileAndFolders',
  async ({ currentFolderId }) => {
    return await getAllNotepadFileAndFolderUseCase({ folderId: currentFolderId })
  }
)

export const getAllFolders = createAsyncThunk('notepad/getAllFolders', ({ currentFolderId }) => {
  return getAllNotepadFileAndFolderUseCase({ folderId: currentFolderId })
})

export const moveItems = createAsyncThunk('notepad/moveItems', ({ currentFolderId, destinationFolderId, data }) => {
  return moveItemsUseCase({ currentFolderId, destinationFolderId, data })
})

export const getNote = createAsyncThunk('notepad/getNote', noteId => {
  return getNoteUseCase({ noteId: noteId })
})

export const addFile = baseAsyncThunk('notepad/addFile', data => {
  return addFileUseCase(data)
})

export const editFile = baseAsyncThunk('notepad/editFile', data => {
  return editFileUseCase(data)
})

const sortAndSearch = (source, searchPhrase, sortBy, sortType) => {
  let result

  if (searchPhrase !== '')
    result = source.items.filter(item => {
      return item.title.toLowerCase().includes(searchPhrase.toLowerCase())
    })
  else result = source.items

  switch (sortBy) {
    case 'TYPE':
      result?.sort((a, b) => {
        if (a.fileType === b.fileType) return 0
        else if (a.fileType === 'FOLDER') return -1
        else return 1
      })
      break

    case 'NAME':
      result?.sort((a, b) => {
        const aTitle = a.title.toLowerCase()
        const bTitle = b.title.toLowerCase()
        if (aTitle === bTitle) return 0
        else if (aTitle < bTitle) return -1
        else return 1
      })
      break
  }

  return {
    path: source.path,
    items: sortType === 'ASC' ? result : result.reverse()
  }
}

const notepadSlice = createSlice({
  name: 'notepad',
  initialState,
  reducers: {
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload
    },
    setCurrentFolderId: (state, action) => {
      if (state.currentFolderId !== action.payload) {
        state.currentFolderId = action.payload
        state.source = []
      }
    },
    changeSelectStatus: (state, action) => {
      state.selectedItems = []

      state.filesAndFolders.items.forEach(item => {
        if (item.id === action.payload) item.isSelected = !item.isSelected

        if (item.isSelected) state.selectedItems.push(item)
      })
    },
    cancelSelection: (state, action) => {
      state.filesAndFolders.items.forEach(item => {
        item.isSelected = false
      })
      state.selectedItems = []
    },
    setSearchPhrase: (state, action) => {
      state.searchPhrase = action.payload
    },
    setSortType: (state, action) => {
      state.sortType = action.payload
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload
    },
    setDestinationFolderId: (state, action) => {
      state.destinationFolderId = action.payload
    },
    search: (state, action) => {
      state.filesAndFolders = sortAndSearch(state.source, state.searchPhrase, state.sortBy, state.sortType)
    },
    clearNoteData: (state, action) => {
      state.currentNote = null
    }
  },
  extraReducers: builder => {
    builder
      // add folder
      .addCase(addFolder.pending, state => {
        state.AddFolderLoading = true
      })
      .addCase(addFolder.fulfilled, (state, action) => {
        state.AddFolderLoading = false
      })
      .addCase(addFolder.rejected, (state, action) => {
        state.AddFolderLoading = false
        state.error = action.error.message
      })

      // delete items
      .addCase(deleteItems.pending, state => {
        state.loading = true
      })
      .addCase(deleteItems.fulfilled, (state, action) => {
        state.loading = false
        state.selectedItems = []
      })
      .addCase(deleteItems.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // edit folder
      .addCase(editFolder.pending, state => {
        state.loading = true
      })
      .addCase(editFolder.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editFolder.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // add file
      .addCase(addFile.pending, state => {
        state.loading = true
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(addFile.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // edit file
      .addCase(editFile.pending, state => {
        state.loading = true
      })
      .addCase(editFile.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(editFile.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // get note
      .addCase(getNote.pending, state => {
        state.getNoteLoading = true
      })
      .addCase(getNote.fulfilled, (state, action) => {
        state.getNoteLoading = false
        state.currentNote = action.payload
      })
      .addCase(getNote.rejected, (state, action) => {
        state.getNoteLoading = false
        state.error = action.error.message
      })

      // get all files and folders
      .addCase(getAllNotepadFileAndFolders.pending, state => {
        state.getAllNotepadFileAndFoldersLoading = true
      })
      .addCase(getAllNotepadFileAndFolders.fulfilled, (state, action) => {
        state.getAllNotepadFileAndFoldersLoading = false
        state.source = action.payload
        state.filesAndFolders = sortAndSearch(state.source, state.searchPhrase, state.sortBy, state.sortType)
      })
      .addCase(getAllNotepadFileAndFolders.rejected, (state, action) => {
        state.getAllNotepadFileAndFoldersLoading = false
        state.error = action.error.message
      })

      // get all folders
      .addCase(getAllFolders.pending, state => {
        state.loading = true
      })
      .addCase(getAllFolders.fulfilled, (state, action) => {
        state.loading = false
        state.folders = action.payload

        state.folders.items = state.folders.items?.filter(item => {
          return (
            item.fileType === 'FOLDER' &&
            state.selectedItems.findIndex(selectedItem => {
              return item.id === selectedItem.id
            }) === -1
          )
        })
      })
      .addCase(getAllFolders.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })

      // move items
      .addCase(moveItems.pending, state => {
        state.loading = true
      })
      .addCase(moveItems.fulfilled, (state, action) => {
        state.loading = false
        state.source = action.payload
        state.filesAndFolders = sortAndSearch(state.source, state.searchPhrase, state.sortBy, state.sortType)
      })
      .addCase(moveItems.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const {
  setSelectedItems,
  setCurrentFolderId,
  changeSelectStatus,
  cancelSelection,
  setSearchPhrase,
  setSortType,
  setSortBy,
  search,
  setDestinationFolderId,
  clearNoteData,
  loading
} = notepadSlice.actions
export default notepadSlice.reducer
