import serviceCall from '../../services'
import reviewMistakeDragAndDropConversationMapper from '../../services/mapper/reviewMistake/reviewMistakeDragAndDropConversationMapper'
import exerciseTypeEnums from '../../enums/exerciseEnums/exerciseEnums'
import reviewMistakeDragAndDropMapper from '../../services/mapper/reviewMistake/reviewMistakeDragAndDropMapper'

const getReviewMistakeExerciseUseCase = async ({
  unit,
  section,
  sectionNumber,
  groupType,
  filterByIds,
  filterByUserAnswerData
}) => {
  const response = await serviceCall('fetchSecticonExercise', { unit, section, sectionNumber, groupType }, 'cloud')

  if (response?.type === exerciseTypeEnums.dragAndDropConversation) {
    return reviewMistakeDragAndDropConversationMapper(response, filterByIds)
  } else if (response?.type === exerciseTypeEnums.dragAndDrop) {
    return reviewMistakeDragAndDropMapper(response, filterByUserAnswerData)
  }
}

export default getReviewMistakeExerciseUseCase
