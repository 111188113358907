import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkDragAndDropCategoryMapper = (stackId, response, data) => {
  let incorrectAnswers = []
  data.stack.choices.filter(choice => {
    const responseItem = response.find(obj => obj.exerciseItemId === choice.itemId)
    const answerResponse = responseItem.userAnswerItemResponse.find(el => el.userAnswer === choice.text.trackerNumber)

    if (!(answerResponse && answerResponse.userAnswerStatus === AnswerStatusEnums.CORRECT)) {
      incorrectAnswers.push({ ...choice, addedToCorrectAnswer: false })
    }
  })
  return {
    ...data,
    checked: true,
    checkable: false,
    stack: {
      ...data.stack,
      answerFields: [...data.stack.answerFields].map(answerField => {
        const responseItem = response.find(obj => obj.exerciseItemId === answerField.itemId)
        const answerResponse = responseItem.userAnswerItemResponse[answerField.inChoiceItemsIndex]
        const userAnswer = data.stack.choices.find(obj => obj.text.trackerNumber === answerResponse.userAnswer)

        const correctAnswer = () => {
          if (answerResponse.userAnswerStatus === AnswerStatusEnums.CORRECT) {
            return userAnswer
          } else {
            const found = incorrectAnswers
              .filter(el => el.itemId === answerField.itemId)
              .find(el => !el.addedToCorrectAnswer)
            incorrectAnswers = [...incorrectAnswers].map(obj => {
              if (obj.choiceIndex === found.choiceIndex) {
                return {
                  ...obj,
                  addedToCorrectAnswer: true
                }
              } else {
                return obj
              }
            })
            return found
          }
        }

        return {
          ...answerField,
          userAnswer,
          userAnswerStatus: answerResponse.userAnswerStatus,
          correctAnswer: correctAnswer()
        }
      })
    }
  }
}

export default checkDragAndDropCategoryMapper
