const AICreditBadgeSvg = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44.972' height='16.001' viewBox='0 0 44.972 16.001'>
      <g transform='translate(-298 -17)'>
        <path
          d='M340.77-5276h-29a2.989,2.989,0,0,1-2.037-.8,2.99,2.99,0,0,1-.953-1.951,6.215,6.215,0,0,0,2.76-5.251,6.219,6.219,0,0,0-2.76-5.252,2.99,2.99,0,0,1,.953-1.951,2.993,2.993,0,0,1,2.037-.8h29a3,3,0,0,1,3,3v10A3,3,0,0,1,340.77-5276Z'
          transform='translate(-0.798 5309)'
          fill='#FCFCFC'
        />
        <g transform='translate(41.54 -97.96)'>
          <path
            d='M151.63,81.76a5.77,5.77,0,1,0,5.77,5.77A5.768,5.768,0,0,0,151.63,81.76Zm0,8.591a2.821,2.821,0,1,1,2.821-2.821A2.824,2.824,0,0,1,151.63,90.351Z'
            transform='translate(110.6 35.5)'
          />
          <circle cx='1.621' cy='1.621' r='1.621' transform='translate(260.609 121.394)' fill='#e98020' />
          <circle cx='0.634' cy='0.634' r='0.634' transform='translate(261.596 122.38)' />
        </g>
      </g>
    </svg>
  )
}

export default AICreditBadgeSvg
