import serviceCall from '../../services'
import levelsMapper from '../../services/mapper/levelAndUnit/levelsMapper'

const levelsAndUnitsUseCase = async () => {
  const response = await serviceCall('fetchLevelsAndUnits', null, 'cloud')

  return levelsMapper(response)
}

export default levelsAndUnitsUseCase
