import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkDragAndDropMapper = (stackId, response, data) => {
  return {
    ...data,
    stack: {
      ...data.stack,
      items: [...data.stack.items].map(item => {
        const responseItem = response.find(obj => obj.exerciseItemId === item.id)
        if (responseItem) {
          const answerStatus = () => {
            if (responseItem.userAnswerItemResponse.every(obj => obj.userAnswerStatus === AnswerStatusEnums.CORRECT)) {
              return AnswerStatusEnums.CORRECT
            } else if (
              responseItem.userAnswerItemResponse.some(obj => obj.userAnswerStatus === AnswerStatusEnums.INCORRECT)
            ) {
              return AnswerStatusEnums.INCORRECT
            } else {
              return AnswerStatusEnums.SKIPPED
            }
          }

          return {
            ...item,
            checked: true,
            showLock: answerStatus() === AnswerStatusEnums.INCORRECT,
            answerFields: [...item.answerFields].map(field => {
              const answerResponse = responseItem.userAnswerItemResponse.find(el => el.index === field.index)
              if (answerResponse) {
                return {
                  ...field,
                  userAnswerStatus: answerResponse.userAnswerStatus
                }
              }
              return field
            }),
            userAnswerStatus: answerStatus()
          }
        }
        return item
      })
    }
  }
}

export default checkDragAndDropMapper
