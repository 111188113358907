const AISvg = ({ color }) => {
  return (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={512}
      height={512}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <style type='text/css'>{`.AISvg-st0{fill:none;stroke-width:33;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:9.9417;}`}</style>
      <g>
        <path
          className='AISvg-st0'
          stroke={color}
          d='M158.2,304.3l42.6-111.7c0.9-2.1,3.9-2.1,4.8,0l42.2,111.7 M171.5,276.5h63.1 M304.1,189.3v113.3'
        />
        <path
          className='AISvg-st0'
          stroke={color}
          d='M459.5,317.5c25.8-78.3,7.5-167.8-54.7-230.1c-88-88-230.8-88-318.8,0s-88,230.8,0,318.8s230.8,88,318.8,0 c0.5-0.5,0.9-1,1.4-1.5l85.9,85.8'
        />
      </g>
    </svg>
  )
}

export default AISvg
