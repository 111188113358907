import * as React from 'react'

const AboutVellsoSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={512}
    height={512}
    viewBox='0 0 512 512'
  >
    <path d='M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0zm0 465.5c-115.5 0-209.5-94-209.5-209.5S140.5 46.5 256 46.5s209.5 94 209.5 209.5-94 209.5-209.5 209.5z' />
    <path d='M256 108.6c-17.1 0-31 13.9-31 31s13.9 31 31 31 31-13.9 31-31-13.9-31-31-31zM256 217.2c-12.9 0-23.3 10.4-23.3 23.3v139.6c0 12.9 10.4 23.3 23.3 23.3s23.3-10.4 23.3-23.3V240.5c0-12.9-10.4-23.3-23.3-23.3z' />
  </svg>
)

export default AboutVellsoSvg
