import serviceCall from '../../services'
import getUnitMapper from '../../services/mapper/levelAndUnit/getUnitMapper'

const getUnitUseCase = async ({ unitId }) => {
  const response = await serviceCall('fetchUnitById', { unitId }, 'cloud')

  return getUnitMapper(response?.[0])
}

export default getUnitUseCase
