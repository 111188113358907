import * as React from 'react'

const TableListSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={48} height={37.74} viewBox='0 0 48 37.74'>
    <g fill={color}>
      <path d='M0 0v37.74h48V0Zm45.99 2.01V7.3H2.01V2.01ZM25 16.28h9.57v4.97H25Zm-2 4.97h-9.57v-4.97H23Zm2-6.98V9.3h9.57v4.97Zm-2 0h-9.57V9.3H23Zm-11.58 0H2.01V9.3h9.41Zm0 2.01v4.97H2.01v-4.97Zm0 6.98v4.97H2.01v-4.97h9.41Zm2.01 0H23v4.97h-9.57ZM23 30.24v5.5h-9.57v-5.5Zm2 0h9.57v5.5H25Zm0-2.01v-4.97h9.57v4.97Zm11.58-4.97h9.41v4.97h-9.41Zm0-2.01v-4.97h9.41v4.97Zm0-6.98V9.3h9.41v4.97ZM2.01 30.24h9.41v5.5H2.01Zm34.57 5.49v-5.5h9.41v5.5Z' />
      <path d='M19.08 3.49h19.77V5.5H19.08z' />
      <path d='M40.37 3.49h4.65V5.5h-4.65z' />
    </g>
  </svg>
)

export default TableListSvg
