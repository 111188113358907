import * as React from 'react'

const LimbicPowerSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={48.14} height={48} viewBox='0 0 48.14 48'>
    <g>
      <path fill='#fff' d='M0 0h21.27v21.27H0z' />
      <path fill='#fff' d='M26.87 26.73h21.27V48H26.87z' />
      <path fill={color} d='M26.87 0h21.27v21.27H26.87z' />
      <path fill={color} d='M0 26.73h21.27V48H0z' />
    </g>
  </svg>
)

export default LimbicPowerSvg
