import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import evaluationReportUseCase from '../../useCase/evaluationReportUseCase/evaluationReportUseCase'

const root = document.querySelector(':root')

const initialState = {
  loading: false,
  learningType: null,
  memoryTest: null,
  spacedRepetition: null,
  courseIntensity: null,
  placementTest: null,
  error: ''
}

export const getEvaluationReport = createAsyncThunk('evaluationReport/getEvaluationReport', () => {
  return evaluationReportUseCase()
})

const evaluationReportSlice = createSlice({
  name: 'evaluationReport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEvaluationReport.pending, state => {
      state.loading = true
    })
    builder.addCase(getEvaluationReport.fulfilled, (state, action) => {
      state.loading = false
      state.learningType = action.payload.data?.learningTypeTest
      if (action.payload.data?.memoryTestResult) {
        root.style.setProperty('--memorytest-result-percent', `"${action.payload.data?.memoryTestResult.percentage}%"`)
      }

      state.memoryTest = action.payload.data?.memoryTestResult
      state.spacedRepetition = action.payload.data?.studyPreferences?.spacedRepetition
      state.courseIntensity = action.payload.data?.studyPreferences?.courseIntensity
      state.placementTest = action.payload.data?.placementTestResult
    })
    builder.addCase(getEvaluationReport.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = evaluationReportSlice.actions

export default evaluationReportSlice.reducer
