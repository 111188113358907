import { htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { INLINE, INPUT, TEXT_BOX_AREA, TEXTAREA } from '../../../enums/globalEnums/globalEnums'

const fetchTypeTheAnswerMapper = data => {
  const getInputType = (given, placeHoldersCount) => {
    if (given.template === TEXT_BOX_AREA) {
      return TEXTAREA
    } else if (placeHoldersCount > 0) {
      return INLINE
    } else {
      return INPUT
    }
  }

  return {
    pageType: pageGuidEnums.TYPE_THE_ANSWER_EXERCISE,
    data: {
      paginable: true,
      type: data.type,
      title: data.title ?? null,
      stacks: [...data.exerciseStacks].map(stack => {
        return {
          id: stack.id,
          title: stack.title,
          joined: stack.joined,
          sentenceMatchType: stack.sentenceMatchType,
          items: [...stack.items].map(item => {
            const given = item.data.givens?.[0]
            const placeHoldersCount = given.text ? (given.text.sentence.match(/###/g) || []).length : 0
            return {
              id: item.id,
              inputType: getInputType(given, placeHoldersCount),
              checked: false,
              unlocked: false,
              showLock: false,
              userAnswer: {
                userInputText: placeHoldersCount
                  ? [...new Array(placeHoldersCount)].map((blankItem, blankIndex) => {
                      return {
                        index: blankIndex,
                        userAnswerData: null,
                        userAnswer: ''
                      }
                    })
                  : {
                      userAnswerData: null,
                      userAnswer: ''
                    },
                answerIsCorrect: null,
                userAnswerStatus: null,
                hasAlsoCorrect: null,
                isCorrectWithMistake: false
              },
              given: {
                index: given.index,
                type: given.type,
                template: given.template,
                text: htmlTextConversion(given.text, true),
                voiceUri: given.voiceUri ?? null
              },
              answers: [...item.data.answers].map(answer => {
                return {
                  index: answer.index,
                  text: htmlTextConversion(answer.items?.[0]?.text)
                }
              }),
              fullTextAnswers: [...item.extra.fullTextAnswers].map(fullTextItem => {
                return htmlTextConversion(fullTextItem)
              }),
              additionalData: item.extra.additionalData ?? ''
            }
          })
        }
      }),
      checked: false,
      checkable: false
    }
  }
}

export default fetchTypeTheAnswerMapper
