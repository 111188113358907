import React, { Suspense } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import LoadingPage from '../pages/LoadingPage/LoadingPage'
import AIChat from '../pages/AIChat/AIChat'
// import { AI_TEACHER, CHAT_WITH_AI } from '../enums/pageGuideEnums/pageGuideEnums'
import { lazyImport } from '../util/lazyImport/lazyImport'
import ChunkErrorPage from '../pages/Errors/ChunkErrorPage'
import CourseIntensityIntro from '../components/EnrollmentIntro/CourseIntensityIntro/CourseIntensityIntro'

const PrivacyPolicy = lazyImport(() => import('../pages/Authentication/PrivacyPolicy'))
const TermsAndConditions = lazyImport(() => import('../pages/Authentication/TermsAndConditions'))
const AuthRoute = lazyImport(() => import('./AuthRoute'))
const SelectLanguage = lazyImport(() => import('../pages/Intro/SelectLanguage/SelectLanguage'))
// const Introduction1 = lazyImport(() => import('../pages/Intro/Introduction/Introduction1'))
// const Introduction2 = lazyImport(() => import('../pages/Intro/Introduction/Introduction2'))
// const EvaluationReports = lazyImport(() => import('../pages/Intro/EvaluationReports'))
const MemoryTest = lazyImport(() => import('../pages/Intro/MemoryTest/MemoryTest'))
const Payment = lazyImport(() => import('../pages/Payment/Payment'))
const Home = lazyImport(() => import('../pages/Home/Home'))
const LevelUnit = lazyImport(() => import('../pages/LevelAndUnit/LevelUnit'))
const LevelUnitContents = lazyImport(() => import('../pages/LevelUnitContents'))
const Dictionary = lazyImport(() => import('../pages/Dictionary/Dictionary'))
const TodayStudy = lazyImport(() => import('../pages/TodayStudy/TodayStudy'))
const Sections = lazyImport(() => import('../pages/Sections/Sections'))
const Archive = lazyImport(() => import('../pages/Archive'))
const MemoryBox = lazyImport(() => import('../pages/MemoryBox'))
const PrivateRoute = lazyImport(() => import('./PrivateRoute'))
const Settings = lazyImport(() => import('../pages/Settings/Settings'))
const MessagesAndNotifications = lazyImport(() => import('../pages/MessagesAndNotifications/MessagesAndNotifications'))
const Radio = lazyImport(() => import('../pages/Radio/Radio'))
const ContactUs = lazyImport(() => import('../pages/ContactUs/ContactUs'))
const Newspaper = lazyImport(() => import('../pages/Newspaper/Newspaper'))
const Ted = lazyImport(() => import('../pages/Ted/Ted'))
const StatisticsRoutes = lazyImport(() => import('../pages/Statistics/StatisticsRoutes'))
const HelpAndGuidePage = lazyImport(() => import('../pages/HelpAndGuide/HelpAndGuidePage'))
const GameRoutes = lazyImport(() => import('../pages/Games/GameRoutes'))
const Favorites = lazyImport(() => import('../pages/Favorites/Favorites'))
const About = lazyImport(() => import('../pages/About/AboutPage'))
const Faq = lazyImport(() => import('../pages/Faq/Faq'))
const LearningTypeTestRoutes = lazyImport(() => import('./LearningTypeTestRoutes'))
const MainMiddlewareRoute = lazyImport(() => import('./MainMiddlewareRoute'))
// const SelectLanguageMiddlewareRoute = lazyImport(() => import('./SelectLanguageMiddlewareRoute'))
// const SelectCourse = lazyImport(() => import('../pages/Intro/CourseIntensity/SelectCourse'))
const CourseIntensityMiddlewareRoute = lazyImport(() => import('./CourseIntensityMiddlewareRoute'))
const MemoryTestMiddlewareRoute = lazyImport(() => import('./MemoryTestMiddlewareRoute'))
const LearningTypeTestMiddlewareRoute = lazyImport(() => import('./LearningTypeTestMiddlewareRoute'))
// const EvaluationReportsMiddlewareRoute = lazyImport(() => import('./EvaluationReportsMiddlewareRoute'))
const ExercisePage = lazyImport(() => import('../pages/Exercise/ExercisePage'))
const ToolsRoute = lazyImport(() => import('./ToolsRoute'))
const TurnOffUnits = lazyImport(() => import('../pages/LevelAndUnit/TurnOffUnits'))
const VocabularyRoute = lazyImport(() => import('./VocabularyRoute'))
const NotepadRoute = lazyImport(() => import('./NotepadRoute'))
const PaymentCallBackResult = lazyImport(() => import('../pages/Payment/callback/PaymentCallBackResult'))
const RecommendationsRoute = lazyImport(() => import('./RecommendationsRoute'))

const AppRoutes = () => {
  const location = useLocation()

  return (
    <AnimatePresence mode='wait' initial={false}>
      <Suspense fallback={<LoadingPage />}>
        <Routes location={location} key={location.pathname}>
          {/* Home */}
          <Route
            path='/android/www/index.html'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Home />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Home />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/android/www/'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Home />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/ios/www/'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Home />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/home'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Home />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Authentication Routes */}
          <Route path='/auth/*' exact element={<AuthRoute />} />
          <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
          <Route path='/terms-conditions' exact element={<TermsAndConditions />} />
          {/* Select Native Language Route */}
          <Route
            path='/select-language'
            exact
            element={
              <PrivateRoute>
                {/* <SelectLanguageMiddlewareRoute> */}
                <SelectLanguage />
                {/* </SelectLanguageMiddlewareRoute> */}
              </PrivateRoute>
            }
          />
          {/* Intro Wizard Routes */}
          {/* <Route
						path="/introduction/1"
						exact
						element={
							<PrivateRoute>
								<IntroMiddlewareRoute>
									<Introduction1 />
								</IntroMiddlewareRoute>
							</PrivateRoute>
						}
					/>
					<Route
						path="/introduction/2"
						exact
						element={
							<PrivateRoute>
								<IntroMiddlewareRoute>
									<Introduction2 />
								</IntroMiddlewareRoute>
							</PrivateRoute>
						}
					/> */}
          {/* Select Course Intensity Wizard Route */}
          <Route
            path='/course-intensity'
            exact
            element={
              <PrivateRoute>
                <CourseIntensityMiddlewareRoute>
                  <CourseIntensityIntro />
                </CourseIntensityMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Memory Test Wizard Route */}
          <Route
            path='/memory-test/*'
            element={
              <PrivateRoute>
                <MemoryTestMiddlewareRoute>
                  <MemoryTest />
                </MemoryTestMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Learning Type Test Wizard Route */}
          <Route
            path='/learning-type-test/*'
            element={
              <PrivateRoute>
                <LearningTypeTestMiddlewareRoute>
                  <LearningTypeTestRoutes />
                </LearningTypeTestMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Reports Wizard Route */}
          {/* <Route
						path="/evaluation/reports"
						element={
							<PrivateRoute>
								<EvaluationReportsMiddlewareRoute>
									<EvaluationReports />
								</EvaluationReportsMiddlewareRoute>
							</PrivateRoute>
						}
					/> */}
          {/* Payment Route */}
          <Route
            path='/payment/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Payment />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Contact Us */}
          <Route
            path='/contact-us'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <ContactUs />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* About */}
          <Route
            path='/about'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <About />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* FAQ */}
          <Route
            path='/faq'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Faq />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Levels and Units */}
          <Route
            path='/level-unit'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <LevelUnit />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Table of Content */}
          <Route
            path='/level-unit/contents'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <LevelUnitContents />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Levels and Units Turn Off */}
          <Route
            path='/level-unit/turn-off'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <TurnOffUnits />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Vocabulary */}
          <Route
            path='/vocabulary/*'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <VocabularyRoute />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Dictionary */}
          <Route
            path='/dictionary'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Dictionary />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Study Plan*/}
          <Route
            path='/study-plan'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <TodayStudy />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Sections */}
          <Route
            path='/sections/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Sections />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Archive */}
          <Route
            path='/archive'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Archive />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Tools */}
          <Route
            path='/tools/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <ToolsRoute />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Exercises */}
          <Route
            path='/exercise/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <ExercisePage />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Games */}
          <Route
            path='/games/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <GameRoutes />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Memory Box */}
          <Route
            path='/memory-box/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <MemoryBox />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Settings */}
          <Route
            path='/settings/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Settings />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Messages and Notifications */}
          <Route
            path='/messages/'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <MessagesAndNotifications />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Notepad */}
          <Route
            path='/notepad/*'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <NotepadRoute />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/radio-station'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Radio />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/newspaper'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Newspaper />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/ted-videos'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Ted />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Statistics */}
          <Route
            path='/statistics/*'
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <StatisticsRoutes />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Help and Guide */}
          <Route
            path='/help-and-guide/*'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <HelpAndGuidePage />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Favorites */}
          <Route
            path='/favorites/*'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <Favorites />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* payment callback */}
          <Route
            path='/payment/callback/result'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <PaymentCallBackResult />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          {/* Recommendations */}
          <Route
            path='/recommendations/*'
            exact
            element={
              <PrivateRoute>
                <RecommendationsRoute />
              </PrivateRoute>
            }
          />
          {/* AI Chat */}
          <Route
            path='/ai-chat/general'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <AIChat promptType='AI_HOMEPAGE' />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/ai-chat/teacher/:unitId'
            exact
            element={
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <AIChat promptType='AI_ROBOT_TEACHER' hasHelp />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />
          <Route
            path='/ai-chat/:unitId'
            exact
            element={ 
              <PrivateRoute>
                <MainMiddlewareRoute>
                  <AIChat promptType='AI_CHAT' />
                </MainMiddlewareRoute>
              </PrivateRoute>
            }
          />

          {/* Error pages */}
          <Route path='/chunk-error' exact element={<ChunkErrorPage />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  )
}

export default AppRoutes
