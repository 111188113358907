import TranslatedText from '../../components/TranslatedText/TranslatedText'
import React from 'react'

export const EASY = 'EASY'
export const MODERATE = 'MEDIUM'
export const CHALLENGING = 'HARD'

export const studyDifficultyLevelsData = [
  {
    title: <TranslatedText translationKey='easy' />,
    key: EASY,
    percentage: 50,
    description: <TranslatedText translationKey={'fifty.percent.exercise.correct.answer'} />,
    color: '#e9a92f'
  },
  {
    title: <TranslatedText translationKey='moderate' />,
    key: MODERATE,
    percentage: 70,
    description: <TranslatedText translationKey={'seventy.percent.exercise.correct.answer'} />,
    color: '#00ADEE'
  },
  {
    title: <TranslatedText translationKey='challenging' />,
    key: CHALLENGING,
    percentage: 95,
    description: <TranslatedText translationKey={'ninety.five.percent.exercise.correct.answer'} />,
    color: '#37B34A'
  }
]
