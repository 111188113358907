export const studyTypeEnum = {
  BASIC: 'BASIC',
  NEW: 'NEW',
  VIEW: 'VIEW',
  REPEAT_1: 'REPEAT_1',
  REPEAT_2: 'REPEAT_2',
  REPEAT_3: 'REPEAT_3',
  REPEAT_4: 'REPEAT_4',
  REPEAT_5: 'REPEAT_5',
  REPEAT_6: 'REPEAT_6',
  REPEAT_7: 'REPEAT_7',
  REVIEW: 'REVIEW',
  QUICK_REVIEW: 'QUICK_REVIEW',
  EXERCISE: 'EXERCISE'
}

const data = {
  [studyTypeEnum.NEW]: {
    backgroundColor: '#DAEACB',
    color: '#0E6B07',
    title: 'New',
    actionTitle: 'Study',
    name: 'New'
  },
  [studyTypeEnum.REPEAT_1]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R1'
  },
  [studyTypeEnum.REPEAT_2]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R2'
  },
  [studyTypeEnum.REPEAT_3]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R3'
  },
  [studyTypeEnum.REPEAT_4]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R4'
  },
  [studyTypeEnum.REPEAT_5]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R5'
  },
  [studyTypeEnum.REPEAT_6]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R6'
  },
  [studyTypeEnum.REPEAT_7]: {
    backgroundColor: '#E7DADE',
    color: '#7F32A7',
    title: 'Repeat',
    actionTitle: 'Repeat',
    name: 'R7'
  },
  [studyTypeEnum.REVIEW]: {
    backgroundColor: '#D8E7E6',
    color: '#009EE2',
    title: 'Review',
    actionTitle: 'Review',
    name: 'RV'
  }
}

const sectionsData = {
  [studyTypeEnum.BASIC]: {
    sections: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  },
  [studyTypeEnum.NEW]: {
    sections: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.VIEW]: {
    sections: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_1]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_2]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_3]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_4]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_5]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_6]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REPEAT_7]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  },
  [studyTypeEnum.REVIEW]: {
    sections: [1, 2, 11, 13, 20]
  },
  [studyTypeEnum.QUICK_REVIEW]: {
    sections: [1, 2, 11, 13, 20]
  },
  [studyTypeEnum.EXERCISE]: {
    sections: [3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 22, 23]
  }
}

export const getStudyStatusDataByKey = key => {
  return data[key]
}

export const getStudyStatusSectionsDataByKey = key => {
  return sectionsData[key]?.sections ?? []
}

export const getRepeatNumberByStudyStatus = key => {
  switch (key) {
    case studyTypeEnum.REPEAT_1:
      return 1
    case studyTypeEnum.REPEAT_2:
      return 2
    case studyTypeEnum.REPEAT_3:
      return 3
    case studyTypeEnum.REPEAT_4:
      return 4
    case studyTypeEnum.REPEAT_5:
      return 5
    case studyTypeEnum.REPEAT_6:
      return 6
    case studyTypeEnum.REPEAT_7:
      return 7
    default:
      return 0
  }
}
