import * as React from 'react'

const LockOpenWithKeySvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 14.533 33.667'>
    <defs>
      <linearGradient id='lock_open_key_svg_gradient_a' y1={0.5} x2={1} y2={0.5} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#ebca83' />
        <stop offset={0} stopColor='#e5ba5d' />
        <stop offset={0.228} stopColor='#ffffd0' />
        <stop offset={0.414} stopColor='#e2b854' />
        <stop offset={0.586} stopColor='#f4c96c' />
        <stop offset={0.772} stopColor='#d09f26' />
        <stop offset={1} stopColor='#c2973b' />
      </linearGradient>
      <linearGradient id='lock_open_key_svg_gradient_b' y1={0.5} x2={1} y2={0.5} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#ebca83' />
        <stop offset={0} stopColor='#e5ba5d' />
        <stop offset={0.414} stopColor='#e2b854' />
        <stop offset={0.586} stopColor='#f4c96c' />
        <stop offset={0.772} stopColor='#d09f26' />
        <stop offset={1} stopColor='#c2973b' />
      </linearGradient>
    </defs>
    <g>
      <g>
        <path
          d='M71.71 32.826H59.445l-.876.876v7.74l.876.876H71.71l.876-.876V33.7Z'
          transform='translate(-58.312 -24.308)'
          fill='url(#lock_open_key_svg_gradient_a)'
        />
        <path
          d='m58.569 33.7.876-.876H71.71l.876.876Z'
          transform='translate(-58.311 -24.308)'
          fill='url(#lock_open_key_svg_gradient_a)'
        />
        <path d='m14.275 17.206-.876.876H1.134l-.876-.876Z' fill='#e6a64a' />
        <path
          d='M13.505 18.268H1.024L-.003 17.24V9.287L1.024 8.26h12.481l1.028 1.027v7.953ZM1.24 17.753h12.052l.726-.726V9.501l-.726-.726H1.24l-.725.726v7.526Z'
          fill='#333030'
        />
        <g fill='#333030'>
          <path d='M.258 16.925h10.819v.309H.258z' />
          <path d='M11.077 16.925h3.274v.309h-3.274z' />
        </g>
        <g fill='#333030'>
          <path d='M5.747 13.088h.438v.438h-.438z' />
          <path d='M6.996 13.088h.438v.438h-.438z' />
          <path d='M8.246 13.088h.438v.438h-.438z' />
        </g>
        <g>
          <g>
            <path
              d='M7.309.225c-3.037 0-5.5 2.158-5.5 4.821v3.5h1.727V5.058A3.562 3.562 0 0 1 7.309 1.75'
              fill='#e7e6e6'
            />
            <path
              d='M7.308 1.978a3.322 3.322 0 0 0-3.513 3.081v3.717H1.553v-3.73C1.553 2.264 4.135 0 7.309 0m0 .452c-2.89 0-5.24 2.061-5.24 4.595v3.277h1.212V5.058a3.809 3.809 0 0 1 4.027-3.532'
              fill='#333030'
            />
          </g>
          <g>
            <path
              d='M7.123.225c3.037 0 5.5 2.158 5.5 4.821v3.5h-1.729V5.058A3.562 3.562 0 0 0 7.123 1.75'
              fill='#e7e6e6'
            />
            <path
              d='M7.123 0c3.174 0 5.756 2.264 5.756 5.046v3.729h-2.243V5.058a3.322 3.322 0 0 0-3.513-3.081m0-.452a3.809 3.809 0 0 1 4.028 3.532v3.266h1.212V5.046c0-2.533-2.35-4.595-5.24-4.595'
              fill='#333030'
            />
          </g>
          <path
            d='M6.556.499A5.768 5.768 0 0 1 7.598.471c.408.047.073 1.088.073 1.088a3.927 3.927 0 0 0-1.281.06c-.555.152.166-1.12.166-1.12Z'
            fill='#e7e6e6'
          />
        </g>
      </g>
      <path
        d='M332.154 32.664a.117.117 0 0 1-.086-.113V28.87a.058.058 0 0 1 .058-.058h1.114a.088.088 0 0 0 .088-.088v-.912a.088.088 0 0 0-.088-.088h-.717a.059.059 0 0 1-.059-.059v-.574a.058.058 0 0 1 .059-.058h.889a.088.088 0 0 0 .088-.088v-.912a.088.088 0 0 0-.088-.088h-1.287a.058.058 0 0 1-.058-.059v-.219a.7.7 0 0 0-.666-.707.691.691 0 0 0-.716.691v6.9a.117.117 0 0 1-.086.113 2.936 2.936 0 1 0 1.554 0Zm-.777 4.74a1.908 1.908 0 1 1 1.909-1.908 1.908 1.908 0 0 1-1.909 1.904Z'
        transform='translate(-323.941 -4.766)'
        fill='url(#lock_open_key_svg_gradient_b)'
      />
    </g>
  </svg>
)

export default LockOpenWithKeySvg
