import serviceCall from '../../services'
import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'

const addFileUseCase = async data => {
  const result = await serviceCall('notepadAddFile', data, 'cloud')

  return result?.folderAndFiles?.map(item => {
    return filesAndFoldersMapper(item)
  })
}

export default addFileUseCase
