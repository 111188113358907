import * as React from 'react'

const SkipSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={47.131} height={47.917} viewBox='0 0 47.131 47.917'>
    <path
      d='M45.65 21.117 23.87.517a1.889 1.889 0 0 0-2.6 2.74l20.61 19.49H1.89a1.89 1.89 0 1 0 0 3.78h39.77l-20.35 18.09a1.9 1.9 0 0 0-.16 2.67 1.881 1.881 0 0 0 2.66.15l21.72-19.31a4.778 4.778 0 0 0 1.6-3.49 4.7 4.7 0 0 0-1.48-3.52Z'
      fill={color}
    />
  </svg>
)

export default SkipSvg
