import * as React from 'react'

const CircleAddSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <path d='M256,512C114.8,512,0,397.2,0,256S114.8,0,256,0s256,114.8,256,256S397.2,512,256,512z M256,32C132.5,32,32,132.5,32,256 s100.5,224,224,224s224-100.5,224-224S379.5,32,256,32z' />
    <path d='M368,272H144c-8.8,0-16-7.2-16-16s7.2-16,16-16h224c8.8,0,16,7.2,16,16S376.8,272,368,272z' />
    <path d='M256,384c-8.8,0-16-7.2-16-16V144c0-8.8,7.2-16,16-16s16,7.2,16,16v224C272,376.8,264.8,384,256,384z' />
  </svg>
)

export default CircleAddSvg
