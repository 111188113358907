import * as React from 'react'

const PictionarySvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 682.7 682.7'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {
        '.PictionarySvg-st0{clip-path:url(#SVGID_00000095334707942398015980000017586057938388656278_);}.PictionarySvg-st1{fill:none;stroke-width:35.0731;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:17.7777;}'
      }
    </style>
    <g transform='matrix(1.3333333,0,0,-1.3333333,0,682.66667)'>
      <g>
        <g>
          <g>
            <defs>
              <rect x={-96.7} y={-191.2} width={689.8} height={892.7} />
            </defs>
            <clipPath>
              <use
                xlinkHref='#SVGID_1_'
                style={{
                  overflow: 'visible'
                }}
              />
            </clipPath>
            <g
              style={{
                clipPath: 'url(#SVGID_00000160896255709896253540000009905900950472742800_)'
              }}
            >
              <g transform='translate(344.9997,392)'>
                <path className='PictionarySvg-st1' stroke={color} d='M-34.2-3.5h74.4v-266.8h-238.1V-3.5H-3.7' />
              </g>
              <g transform='translate(458,10)'>
                <path
                  className='PictionarySvg-st1'
                  stroke={color}
                  d='M-12.8,3.8H-370c-10.9,0-19.8,8.8-19.8,19.6v443.3c0,10.8,8.9,19.6,19.8,19.6h357.2 c10.9,0,19.8-8.8,19.8-19.6V23.4C7,12.6-1.9,3.8-12.8,3.8z'
                />
              </g>
              <g transform='translate(122,13.25)'>
                <path className='PictionarySvg-st1' stroke={color} d='M-10.2,479.9' />
                <path className='PictionarySvg-st1' stroke={color} d='M-10.2,3.7' />
              </g>
              <g transform='translate(180,162)'>
                <path className='PictionarySvg-st1' stroke={color} d='M-32.9,0.9l78.1,77.2L164.7-40.1' />
              </g>
              <g transform='translate(278.9167,227.3333)'>
                <path className='PictionarySvg-st1' stroke={color} d='M-33.7-0.4l73,72.2l66.5-65.7' />
              </g>
              <g transform='translate(280.5,318)'>
                <path
                  className='PictionarySvg-st1'
                  stroke={color}
                  d='M-33.7-2.1c0-13-10.6-23.5-23.8-23.5S-81.4-15.2-81.4-2.1s10.6,23.5,23.8,23.5 S-33.7,10.9-33.7-2.1z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default PictionarySvg
