import * as React from 'react'

const GameMedalSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={44.672}
    height={84.05}
    viewBox='0 0 44.672 84.05'
  >
    <defs>
      <style>
        {
          '.cls-1 {fill: url(#GameMedalSvgLinearGradient);}.cls-2 {fill: url(#GameMedalSvgLinearGradient-2);}.cls-3 {fill: url(#GameMedalSvgLinearGradient-3);}.cls-4 {fill: url(#GameMedalSvgLinearGradient-4);}.cls-5 {fill: url(#GameMedalSvgLinearGradient-5);}.cls-6 {fill: url(#GameMedalSvgLinearGradient-6);}.cls-7 {fill: url(#GameMedalSvgLinearGradient-7);}.cls-8 {fill: url(#GameMedalSvgLinearGradient-8);}.cls-12, .cls-9 {fill: #fff1bc;}.cls-9 {opacity: 0.29;}.cls-10 {fill: url(#GameMedalSvgLinearGradient-9);}.cls-11 {fill: url(#GameMedalSvgLinearGradient-10);}.cls-12 {opacity: 0.23;}'
        }
      </style>
      <linearGradient
        id='GameMedalSvgLinearGradient'
        x1={0.81}
        y1={-0.177}
        x2={0.333}
        y2={0.864}
        gradientUnits='objectBoundingBox'
      >
        <stop offset={0} stopColor='#e63536' />
        <stop offset={1} stopColor='#c82827' />
      </linearGradient>
      <linearGradient id='GameMedalSvgLinearGradient-2' x1={0.5} y1={1} x2={0.5} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#fff1bc' />
        <stop offset={0.226} stopColor='#edda9e' />
        <stop offset={0.715} stopColor='#c0a154' />
        <stop offset={1} stopColor='#a47e27' />
      </linearGradient>
      <linearGradient id='GameMedalSvgLinearGradient-3' x1={0.5} x2={0.5} y2={1} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#eec339' />
        <stop offset={0.5} stopColor='#fff1bc' />
        <stop offset={1} stopColor='#eec339' />
      </linearGradient>
      <linearGradient
        id='GameMedalSvgLinearGradient-4'
        x1={0.782}
        y1={1.013}
        x2={0.2}
        y2={-0.045}
        gradientUnits='objectBoundingBox'
      >
        <stop offset={0} stopColor='#e63536' />
        <stop offset={1} stopColor='#d53130' />
      </linearGradient>
      <linearGradient id='GameMedalSvgLinearGradient-5' x1={0.5} x2={0.5} y2={1} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#eec339' />
        <stop offset={0.051} stopColor='#fff1bc' />
        <stop offset={0.055} stopColor='#fbecb3' />
        <stop offset={0.073} stopColor='#ead68f' />
        <stop offset={0.092} stopColor='#ddc572' />
        <stop offset={0.11} stopColor='#d3b95d' />
        <stop offset={0.128} stopColor='#cdb251' />
        <stop offset={0.145} stopColor='#ccb04d' />
        <stop offset={0.186} stopColor='#d8bd56' />
        <stop offset={0.261} stopColor='#f9e06d' />
        <stop offset={0.273} stopColor='#ffe772' />
        <stop offset={0.309} stopColor='#f9e16e' />
        <stop offset={0.57} stopColor='#d8bd56' />
        <stop offset={0.71} stopColor='#ccb04d' />
        <stop offset={0.741} stopColor='#ceb251' />
        <stop offset={0.775} stopColor='#d4ba5e' />
        <stop offset={0.81} stopColor='#dec774' />
        <stop offset={0.846} stopColor='#ecd892' />
        <stop offset={0.883} stopColor='#fdefb9' />
        <stop offset={0.885} stopColor='#fff1bc' />
        <stop offset={0.901} stopColor='#feeeb6' />
        <stop offset={0.923} stopColor='#fce8a5' />
        <stop offset={0.948} stopColor='#f8df89' />
        <stop offset={0.975} stopColor='#f3d162' />
        <stop offset={1} stopColor='#eec339' />
      </linearGradient>
      <linearGradient
        id='GameMedalSvgLinearGradient-6'
        x1={0.137}
        y1={0.137}
        x2={0.853}
        y2={0.853}
        gradientUnits='objectBoundingBox'
      >
        <stop offset={0} stopColor='#acadb0' />
        <stop offset={0.159} stopColor='#f2f2f2' />
        <stop offset={0.184} stopColor='#ececec' />
        <stop offset={0.216} stopColor='#dcdcdc' />
        <stop offset={0.253} stopColor='#c1c1c1' />
        <stop offset={0.292} stopColor='#9c9c9c' />
        <stop offset={0.333} stopColor='#6c6c6c' />
        <stop offset={0.338} stopColor='#676767' />
        <stop offset={0.561} stopColor='#dddfe0' />
        <stop offset={0.74} stopColor='#676767' />
        <stop offset={0.874} stopColor='#fcfcfc' />
        <stop offset={1} stopColor='#abacae' />
      </linearGradient>
      <linearGradient
        id='GameMedalSvgLinearGradient-7'
        x1={0.5}
        y1={-0.014}
        x2={0.5}
        y2={0.999}
        xlinkHref='#GameMedalSvgLinearGradient-2'
      />
      <linearGradient
        id='GameMedalSvgLinearGradient-8'
        x1={0.5}
        y1={-0.014}
        x2={0.5}
        y2={0.999}
        gradientUnits='objectBoundingBox'
      >
        <stop offset={0} stopColor='#fbea83' />
        <stop offset={0.108} stopColor='#ead570' />
        <stop offset={0.255} stopColor='#d9c05c' />
        <stop offset={0.4} stopColor='#cfb450' />
        <stop offset={0.538} stopColor='#ccb04d' />
        <stop offset={0.793} stopColor='#e7d366' />
        <stop offset={1} stopColor='#fbeb78' />
      </linearGradient>
      <linearGradient
        id='GameMedalSvgLinearGradient-9'
        x1={0.5}
        y1={1}
        x2={0.5}
        y2={0}
        xlinkHref='#GameMedalSvgLinearGradient-2'
      />
      <linearGradient id='GameMedalSvgLinearGradient-10' x1={0.5} x2={0.5} y2={1} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#fbea83' />
        <stop offset={0.2} stopColor='#ead570' />
        <stop offset={0.475} stopColor='#d9c05c' />
        <stop offset={0.744} stopColor='#cfb450' />
        <stop offset={1} stopColor='#ccb04d' />
      </linearGradient>
    </defs>
    <g transform='translate(1.643 0)'>
      <path
        className='cls-1'
        d='M241.169,402.793h15.278l-13.053,33.449H228.115Z'
        transform='translate(-215.062 -402.793)'
      />
      <g transform='translate(11.802 32.355)'>
        <path
          className='cls-2'
          d='M227.591,418.72v4.309h17.237V418.72Zm16.565,3.7H228.261v-3.085h15.895Z'
          transform='translate(-227.411 -418.532)'
        />
        <path
          className='cls-3'
          d='M227.5,418.628v4.687H245.1v-4.687Zm16.91,4.021H228.188v-3.355h16.226Z'
          transform='translate(-227.503 -418.628)'
        />
      </g>
      <path
        className='cls-4'
        d='M237.005,402.793H221.727l13.053,33.449h15.278Z'
        transform='translate(-221.727 -402.793)'
      />
    </g>
    <g transform='translate(19.082 35.973)'>
      <rect className='cls-5' width={6.506} height={3.785} />
    </g>
    <path
      className='cls-6'
      d='M263.053,443.542q0,.812-.061,1.61a20.654,20.654,0,0,1-41.182,0c-.041-.531-.063-1.069-.063-1.61a20.653,20.653,0,1,1,41.307,0Z'
      transform='translate(-220.064 -381.828)'
    />
    <path
      className='cls-7'
      d='M265.6,444.4q0,.88-.067,1.741a22.338,22.338,0,0,1-44.539,0q-.064-.861-.065-1.741a22.336,22.336,0,1,1,44.672,0Z'
      transform='translate(-220.923 -382.687)'
    />
    <path
      className='cls-8'
      d='M264.766,444.121q0,.858-.065,1.7a21.789,21.789,0,0,1-43.444,0q-.064-.843-.065-1.7a21.787,21.787,0,1,1,43.575,0Z'
      transform='translate(-220.643 -382.407)'
    />
    <path
      className='cls-9'
      d='M264.766,444.121q0,.858-.065,1.7a29.943,29.943,0,0,0-43.444,0q-.064-.843-.065-1.7a21.787,21.787,0,1,1,43.575,0Z'
      transform='translate(-220.643 -382.407)'
    />
    <circle className='cls-10' cx={18.357} cy={18.357} r={18.357} transform='translate(3.978 43.358)' />
    <circle className='cls-11' cx={17.769} cy={17.769} r={17.769} transform='translate(4.566 43.946)' />
    <path
      className='cls-12'
      d='M240.926,435.643a22.478,22.478,0,0,1,17.646,8.529,17.768,17.768,0,1,0-35.291,0A22.478,22.478,0,0,1,240.926,435.643Z'
      transform='translate(-218.591 -380.354)'
    />
  </svg>
)

export default GameMedalSvg
