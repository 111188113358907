import { TYPE_A } from '../../../enums/exerciseEnums/exerciseGroupTypeEnums'
import sectionsMapper from './sectionsMapper'

const getUnitMapper = data => {
  return {
    levelNumber: 1,
    levelName: data.levelStr,
    levelKey: data.level,
    unitId: data.unitId,
    unitTitle: data.unitTitle,
    unitDescription: data.unitDescription,
    studyStatus: data.studyType,
    group: data.group ?? TYPE_A,
    doneSections: data.doneSections,
    doneSectionsPercentage: Math.floor(data.doneSectionsPercentage),
    isFinished: data.isFinished
  }
}

export default getUnitMapper
