import userAxiosInstance from '../apis/userAxiosInstance'

const getBinaryData = async url => {
  if (url.startsWith('object-store')) {
    const response = await userAxiosInstance.get(`/v1/${url}`, { responseType: 'blob' })
    return URL.createObjectURL(response)
  } else {
    return url
  }
}

export default getBinaryData
