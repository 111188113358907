import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const showFullTextAnswer = (checked, hasAlsoCorrect, userAnswerStatus, unlocked) => {
  let show = false
  if (checked) {
    if (userAnswerStatus === AnswerStatusEnums.SKIPPED) {
      show = true
    } else if (userAnswerStatus === AnswerStatusEnums.INCORRECT) {
      show = !!unlocked
    } else if (userAnswerStatus === AnswerStatusEnums.CORRECT) {
      show = !hasAlsoCorrect
    }
  } else {
    show = false
  }
  return show
}

export default showFullTextAnswer
