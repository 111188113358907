import React, { useContext, useEffect } from 'react'
import Header from '../../../../components/Header/Header'
import colorsContext from '../../../../contexts/ColorsContext'
import { fetchMoreSections } from '../../../../redux/features/structuredSectionSlice'
import { useDispatch } from 'react-redux'
import StructureModule from '../../../../modules/StructuredSectionModules/StructureModule'
import structuresContext from '../../../../contexts/StructuresContext'
import { setRoboTeacherMoreSampleData } from '../../../../redux/features/roboTeacherSlice'
import useActionRetryerWrapper from '../../../../hooks/useDispatchWrapper'

const MoreStructuresPage = ({ template }) => {
  const { colors } = useContext(colorsContext)
  const { isRoboTeacher } = useContext(structuresContext)
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    if (!template?.structuresData) {
      networkLossRetryerDispatch(retryId => {
        dispatch(fetchMoreSections({ moreSectionId: template?.config?.moreSectionId, retryId })).then(res => {
          if (isRoboTeacher) {
            dispatch(setRoboTeacherMoreSampleData(res.payload))
          }
        })
      })
    }

    return () => {
      document.body.style.overflow = null
    }
  }, [])

  return (
    <div data-jsx='Section' style={{ paddingBottom: 0 }}>
      <div className='minh-100vh pb-2' style={{ background: colors['C183'] }}>
        <Header
          withBackButton
          iconsColor={colors['C23']}
          title={template.config.caption}
          backgroundColor={colors['C14']}
          titleColor={colors['C167']}
        />
        {template.structuresData && (
          <StructureModule
            structuresData={template.structuresData}
            templateId={template.id}
            displayNextButton={false}
            haveWordColor
          />
        )}
      </div>
    </div>
  )
}

export default MoreStructuresPage
