import componentStyle from './DictionaryModal.module.scss'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import { getDictionary, setSelectedDictionary, setWordVoice } from '../../redux/features/dictionarySlice'
import DictionaryVoiceButton from '../DictionaryVoiceButton/DictionaryVoiceButton'
import { isEmpty } from 'lodash'
import WordMarkLogoSvg from '../../icons/logo/WordMarkLogoSvg'
import { FREE_DICTIONARY_API, VELLSO_DICTIONARY } from '../../enums/dictionaryEnums/dictionaryEnums'

const DictionaryCard = ({ phrase }) => {
  require('./../../styles/dictionary.scss')

  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()

  const { loading, selectedDictionary, wordSearchResult } = useSelector(state => state.dictionary)
  const [dictionaryData, setDictionaryData] = useState(null)

  useEffect(() => {
    if (wordSearchResult?.phrase !== phrase) {
      dispatch(getDictionary({ phrase }))
    }

    return () => {
      dispatch(setSelectedDictionary(VELLSO_DICTIONARY))
    }
  }, [])

  useEffect(() => {
    if (wordSearchResult && wordSearchResult.phrase === phrase) {
      if (selectedDictionary === VELLSO_DICTIONARY) setDictionaryData(wordSearchResult?.vellsoDictionary)
      else setDictionaryData(wordSearchResult?.freeDictionary)
    }
  }, [wordSearchResult, selectedDictionary])

  return (
    <div className={componentStyle.dictionaryModalCard} style={{ background: colors['C73'] }}>
      <div className={componentStyle.dictionaryHeader}>
        <div style={{ color: colors['C593'] }}>
          <h2 className={classNames(componentStyle.word, 'res:fs-lr')}>{dictionaryData?.word}</h2>
          <p className={classNames(componentStyle.phonetic, 'res:fs-md')}>{dictionaryData?.phonetic}</p>
        </div>
        {!isEmpty(wordSearchResult) && (
          <div className={componentStyle.listen}>
            <DictionaryVoiceButton
              accent='US'
              phrase={dictionaryData?.word}
              setAudioUrl={voiceUrl => {
                dispatch(setWordVoice({ voiceUrl, accent: 'US' }))
              }}
              audioUrl={wordSearchResult?.voices?.US}
            />
            <DictionaryVoiceButton
              accent='UK'
              phrase={dictionaryData?.word}
              setAudioUrl={voiceUrl => {
                dispatch(setWordVoice({ voiceUrl, accent: 'UK' }))
              }}
              audioUrl={wordSearchResult?.voices?.UK}
            />
          </div>
        )}
      </div>
      <div className={classNames('align-items-center justify-content-center d-flex dictionary-types')}>
        <div
          className={`dictionary ${selectedDictionary === FREE_DICTIONARY_API ? 'selected' : ''}`}
          onClick={() => {
            if (!!wordSearchResult?.freeDictionary) {
              dispatch(setSelectedDictionary(FREE_DICTIONARY_API))
            }
          }}
          disabled={!wordSearchResult?.freeDictionary}
          style={{ background: '#302865', padding: 4 }}
        >
          <WordMarkLogoSvg primaryColor='#FCFCFC' oInnerColor='transparent' secondaryColor='#E98020' />
        </div>
        <div
          className={classNames('dictionary', { ['selected']: selectedDictionary === VELLSO_DICTIONARY })}
          onClick={() => dispatch(setSelectedDictionary(VELLSO_DICTIONARY))}
          disabled={!wordSearchResult?.vellsoDictionary}
        >
          <div className='native'>
            <span className='res:fs-2xs font-weight-600'>Native</span>
          </div>
        </div>
      </div>
      <div className={componentStyle.dictionaryContent} style={{ background: colors['C183'] }}>
        {loading && <div className='spinner spinner-border' />}
        {dictionaryData?.meanings?.length > 0 && (
          <div className={componentStyle.inner} style={{ borderColor: colors['C594'] }}>
            {dictionaryData?.meanings?.map((item, i) => {
              return (
                <div key={i}>
                  <div className={classNames(componentStyle.pos, componentStyle.verb)}>
                    <span style={{ backgroundColor: colors['C594'] }} className={classNames(componentStyle.line)} />
                    <span style={{ color: colors['C2'] }} className={classNames(componentStyle.name, 'font-size-34')}>
                      {item.partOfSpeech}
                    </span>
                  </div>
                  <div className={classNames(componentStyle.text, 'res:fs-md')} style={{ color: colors['C2'] }}>
                    {item.definitions?.map((definition, i) => {
                      return (
                        <div dir='auto' className='mb-3' key={i}>
                          <p className='mb-1'>
                            {item.definitions?.length > 1 && <span className='font-weight-600'>{i + 1}. </span>}
                            {definition.definition}
                          </p>
                          {definition.example && (
                            <p>
                              <span className='font-weight-600'>Example: </span>
                              <span dangerouslySetInnerHTML={{ __html: definition.example }} />
                            </p>
                          )}
                        </div>
                      )
                    })}
                    {item.synonyms?.length > 0 && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>synonyms:</span> [{item.synonyms.join(', ')}]
                      </p>
                    )}
                    {item.antonyms?.length > 0 && (
                      <p className='antonyms res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>antonyms:</span> [{item.antonyms.join(', ')}]
                      </p>
                    )}
                    {item.ingForm && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>ing form:</span> {item.ingForm}
                      </p>
                    )}
                    {item.nounForm && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>noun form:</span> {item.nounForm}
                      </p>
                    )}
                    {item.adverbForm && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>adverb form:</span> {item.adverbForm}
                      </p>
                    )}
                    {item.adjectiveForm && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>adjective form:</span> {item.adjectiveForm}
                      </p>
                    )}
                    {item.thirdPerson && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>third person form:</span> {item.thirdPerson}
                      </p>
                    )}
                    {item.base && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>base form:</span> {item.base}
                      </p>
                    )}
                    {item.nounForm && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>noun form:</span> {item.nounForm}
                      </p>
                    )}
                    {item.past && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>past form:</span> {item.past}
                      </p>
                    )}
                    {item.pastParticiple && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>pastParticiple form:</span> {item.pastParticiple}
                      </p>
                    )}
                    {item.plural && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>plural form:</span> {item.plural}
                      </p>
                    )}
                    {item.syllable && (
                      <p className='res:fs-sm mb-3' style={{ color: colors['C2'] }}>
                        <span className='text-decoration-underline'>syllable form:</span> {item.syllable}
                      </p>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default DictionaryCard
