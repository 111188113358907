import React, { useContext, useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import style from './TextInfo.module.scss'
import InfoSvg from '../../icons/InfoSvg'
import classNames from 'classnames'
import colorsContext from '../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import { getSentenceData } from '../../redux/features/translationSlice'
import { debounce, isEmpty, throttle } from 'lodash'
import NativeText from '../NativeText/NativeText'

const tabOptions = {
  info: 'info',
  infoTranslation: 'infoTranslation'
}

const TextInfo = ({ info, trackerNumber, color }) => {
  const { colors } = useContext(colorsContext)

  const dispatch = useDispatch()
  const { translations } = useSelector(state => state.translation)
  const [sentenceData, setSentenceData] = useState(null)

  const [selectedTab, setSelectedTab] = useState(tabOptions.info)
  const [show, setShow] = useState(false)

  useEffect(() => {
    let p = true
    if (!sentenceData && !isEmpty(trackerNumber)) {
      const translationData = translations.find(_o => _o.trackerNumber === trackerNumber)
      if (!!translationData) {
        setSentenceData(translationData)
      } else if (!sentenceData) {
        dispatch(getSentenceData({ trackerNumber })).then(res => {
          if (p) {
            if (res.payload) {
              setSentenceData(res.payload)
            }
          }
        })
      }
    }

    return () => {
      p = false
    }
  }, [])

  const onScroll = throttle(() => {
    setShow(false)
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const renderInfoContent = () => {
    if (selectedTab === tabOptions.info) {
      return <p>{info}</p>
    } else if (selectedTab === tabOptions.infoTranslation) {
      if (!!sentenceData?.translation) {
        return <NativeText>{sentenceData.translation}</NativeText>
      }
      return (
        <div className='d-flex justify-content-center'>
          <div className='spinner-border' style={{ borderColor: `${colors['C553']} transparent` }} />
        </div>
      )
    }
  }

  const handleToggle = () => setShow(!show)

  const tabButtonsStyles = {
    default: {
      background: 'transparent',
      borderColor: colors['C594'],
      color: colors['C593']
    },
    selected: {
      background: colors['C594'],
      borderColor: colors['C594'],
      color: colors['C179']
    }
  }

  const popover = (
    <Popover
      className={classNames(style.textinfoPopover, 'textinfo-popover')}
      style={{ background: colors['C73'], borderColor: colors['C594'] }}
    >
      <Popover.Body className={style.textinfoPopoverBody}>
        <div className={style.tabsWrapper}>
          <button
            className={selectedTab === tabOptions.info ? 'selected' : ''}
            style={selectedTab === tabOptions.info ? tabButtonsStyles.selected : tabButtonsStyles.default}
            onClick={() => setSelectedTab(tabOptions.info)}
          >
            English
          </button>
          <button
            className={selectedTab === tabOptions.infoTranslation ? 'selected' : ''}
            style={selectedTab === tabOptions.infoTranslation ? tabButtonsStyles.selected : tabButtonsStyles.default}
            onClick={() => setSelectedTab(tabOptions.infoTranslation)}
          >
            Translation
          </button>
        </div>
        <div className={style.infoContent} style={{ color: colors['C593'] }}>
          {renderInfoContent()}
        </div>
      </Popover.Body>
    </Popover>
  )

  return (
    <OverlayTrigger rootClose trigger='click' placement='auto' show={show} onToggle={handleToggle} overlay={popover}>
      <button className={style.sectionTextInfoButton}>
        <InfoSvg color={color} />
      </button>
    </OverlayTrigger>
  )
}

export default TextInfo
