import * as React from 'react'

const MicrophoneResetSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'></style>
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill={color}
                  d='M370.6,253.8c0-7.3-5.6-12.8-12.8-12.8s-12.8,5.6-12.8,12.8c0,50-40.6,90.6-90.6,90.6 s-90.6-40.6-90.6-90.6c0-7.3-5.6-12.8-12.8-12.8c-7.3,0-12.8,5.6-12.8,12.8c0,59.4,44.4,109.4,103.4,115.8v34.2H195 c-7.3,0-12.8,5.6-12.8,12.8s5.6,12.8,12.8,12.8h118.8c7.3,0,12.8-5.6,12.8-12.8s-5.6-12.8-12.8-12.8h-46.6v-34.2 C326.1,363.1,370.6,313.1,370.6,253.8z'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill={color}
                  d='M254.3,66.2c-39.3,0-71.3,32-71.3,71.3v115.8c0,39.7,32,71.3,71.3,71.8c39.3,0,71.3-32,71.3-71.3V137.5 C325.7,98.2,293.7,66.2,254.3,66.2z'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g>
      <path d='M254.4,26.5c40.3,0,80.8,11.5,116.9,33.1c22.9,13.8,43.5,31.1,61,51.3l-60.3-2c-7.1-0.2-13,5.3-13.3,12.4 c-0.2,7.1,5.3,13,12.4,13.3l83.5,2.8c4.8,2,10.3,0.9,14-2.8c3.7-3.7,4.8-9.2,2.8-14l-2.7-83.4c-0.2-7.1-6-12.7-13.1-12.5 c-7.1,0.2-12.7,6-12.5,13.1c0,0.1,0,0.2,0,0.3l1.6,48.1c-17.7-18.9-37.8-35.2-60-48.5C344.4,13.6,299.4,0.9,254.4,0.9 c-71.9,0-138.2,29.6-186.6,83.5C24.9,132-0.7,196.2-0.7,256c0,7.1,5.7,12.8,12.8,12.8s12.8-5.7,12.8-12.8 c0-53.6,23.2-111.4,61.9-154.5C130.4,53.2,189.9,26.5,254.4,26.5z' />
      <path d='M499.9,243.2c-7.1,0-12.8,5.7-12.8,12.8c0,53.6-23.2,111.4-61.9,154.5c-43.5,48.3-103,75-167.5,75 c-40.3,0-80.8-11.4-116.9-33.1c-22.9-13.8-43.5-31.1-61-51.3l60.3,2c7.1,0.2,13-5.3,13.3-12.4c0.2-7.1-5.3-13-12.4-13.3l-83.5-2.8 c-4.8-2-10.3-0.9-14,2.8c-3.7,3.7-4.8,9.2-2.8,14l2.7,83.4c0.2,7.1,6,12.7,13.1,12.6c7.1-0.2,12.7-6,12.5-13.1c0-0.1,0-0.2,0-0.3 l-1.6-48.1c17.7,18.9,37.8,35.2,60,48.5c40.1,24.1,85.1,36.8,130.1,36.8c71.9,0,138.2-29.6,186.6-83.5 c42.9-47.7,68.5-111.9,68.5-171.7C512.7,248.9,507,243.2,499.9,243.2z' />
    </g>
  </svg>
)

export default MicrophoneResetSvg
