import { createSlice } from '@reduxjs/toolkit'
import { SPELLING_SYSTEM } from '../../../../enums/toolsEnums/spellingSeparationEnums'
import spellingWordLinearFactory from '../../../../factory/spellingWordLinearFactory'

const initialState = {
  initialNumberOfRepetitions: 5,
  letterSeparation: SPELLING_SYSTEM,
  currentIndex: 0,
  linearData: []
}

const spellingWordLinearSlice = createSlice({
  name: 'spellingWordLinear',
  initialState,
  reducers: {
    createLinearData: (state, action) => {
      state.linearData = spellingWordLinearFactory(
        action.payload.data,
        state.initialNumberOfRepetitions,
        state.letterSeparation
      )
    },
    setInputValue: (state, action) => {
      state.linearData[state.currentIndex].items[action.payload.itemIndex].input = action.payload.value?.toLowerCase()
    },
    setInputStatus: (state, action) => {
      state.linearData[state.currentIndex].status = action.payload.status
    },
    setTypeFinished: (state, action) => {
      state.linearData[state.currentIndex].isFinished = action.payload.isFinished
    },
    clearInputItems: state => {
      state.linearData[state.currentIndex].items = [...state.linearData[state.currentIndex].items].map(item => {
        return {
          ...item,
          input: ''
        }
      })
    },
    setInitialNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.linearData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions < 999) {
        ++state.linearData[state.currentIndex].numberOfRepetitions
        state.linearData = [...state.linearData].map(word => {
          return {
            ...word,
            numberOfRepetitions: currentWordNumberOfRepetitions + 1
          }
        })
      }
    },
    increaseNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.linearData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions < 999) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentWordNumberOfRepetitions + 1
          state.linearData = [...state.linearData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentWordNumberOfRepetitions + 1
              }
            }
            return word
          })
        } else {
          ++state.linearData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    decreaseNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.linearData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions > 0) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentWordNumberOfRepetitions - 1
          state.linearData = [...state.linearData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentWordNumberOfRepetitions - 1
              }
            }
            return word
          })
        } else {
          --state.linearData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    setLetterSeparation: (state, action) => {
      if (action.payload === state.letterSeparation) {
        state.letterSeparation = SPELLING_SYSTEM
      } else {
        state.letterSeparation = action.payload
      }
    },
    resetNumberOfRepetitions: state => {
      state.linearData[state.currentIndex].numberOfRepetitions = state.initialNumberOfRepetitions
    },
    setWrongAnswersCount: (state, action) => {
      state.linearData[state.currentIndex].wrongAnswersCount = action.payload
    },
    setCorrectAnswersCount: (state, action) => {
      state.linearData[state.currentIndex].correctAnswersCount = action.payload
    },
    increaseCurrentIndex: state => {
      if (state.currentIndex + 1 < state.linearData.length) {
        ++state.currentIndex
      }
    },
    decreaseCurrentIndex: state => {
      if (state.currentIndex > 0) {
        --state.currentIndex
      }
    },
    clearSpellingWordLinearStates: state => {
      state.initialNumberOfRepetitions = 5
      state.letterSeparation = SPELLING_SYSTEM
      state.currentIndex = 0
      state.linearData = []
    }
  }
})

export const {
  createLinearData,
  setInputValue,
  setInputStatus,
  setTypeFinished,
  clearInputItems,
  increaseNumberOfRepetitions,
  decreaseNumberOfRepetitions,
  setLetterSeparation,
  resetNumberOfRepetitions,
  setWrongAnswersCount,
  setCorrectAnswersCount,
  increaseCurrentIndex,
  decreaseCurrentIndex,
  clearSpellingWordLinearStates
} = spellingWordLinearSlice.actions

export default spellingWordLinearSlice.reducer
