import serviceCall from '../../services'
import unitStatusMapper from '../../services/mapper/unitStatus/unitStatusMapper'

const unitStatusUseCase = async ({ unitId, sectionNumber, studyType, sections }) => {
  const response = await serviceCall('fetchUnitStatus', { unitId, studyType }, 'cloud')

  return unitStatusMapper(response, sections, sectionNumber)
}

export default unitStatusUseCase
