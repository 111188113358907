import { studyTypeEnum } from '../enums/userStudyEnums/userStudyEnums'

const studyTypeIsRepeat = studyStatus => {
  return (
    studyStatus === studyTypeEnum.REPEAT_1 ||
    studyStatus === studyTypeEnum.REPEAT_2 ||
    studyStatus === studyTypeEnum.REPEAT_3 ||
    studyStatus === studyTypeEnum.REPEAT_4 ||
    studyStatus === studyTypeEnum.REPEAT_5 ||
    studyStatus === studyTypeEnum.REPEAT_6 ||
    studyStatus === studyTypeEnum.REPEAT_7
  )
}

export default studyTypeIsRepeat
