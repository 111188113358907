const checkMultipleChoiceMapper = (stackId, response, data) => {
  return {
    ...data,
    stacks: [...data.stacks].map(stack => {
      if (stack.id === stackId) {
        return {
          ...stack,
          items: [...stack.items].map(item => {
            const responseItem = response.find(obj => obj.exerciseItemId === item.id)
            if (responseItem) {
              const userAnswer = responseItem.userAnswerItemResponse[0]
              return {
                ...item,
                checked: true,
                userAnswer: {
                  userAnswerStatus: userAnswer.userAnswerStatus ?? null,
                  correctAnswer: userAnswer.correctAnswer ?? null,
                  userSelectedIndex: userAnswer.index
                },
                choices: item.choices.map(choice => {
                  return {
                    ...choice,
                    selected: choice.index === userAnswer.index
                  }
                })
              }
            }
            return item
          })
        }
      }
      return stack
    })
  }
}

export default checkMultipleChoiceMapper
