import serviceCall from '../../services'

const removeAllUnitFromBoxUseCase = async ({ unitIds }) => {
  const result = await serviceCall(
    'removeFromBoxFlashCard',
    {
      unitIds: unitIds
    },
    'cloud'
  )

  return result
}

export default removeAllUnitFromBoxUseCase
