import React, { useContext } from 'react'
import ExerciseFullTexts from '../../Components/ExerciseFullTexts'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import htmlToText from '../../../../operations/htmlToText'
import colorsContext from '../../../../contexts/ColorsContext'
import InlineInputField from '../../../../components/InlineInputField/InlineInputField'
import renderUserAnswer from './renderUserAnswer'
import { moreOptionsList } from '../../../../data'
import showFullTextAnswer from './getShowFullTextAnswer'

const TypeTheAnswerGivenTextTemplateInput = ({ exerciseItem }) => {
  const { colors } = useContext(colorsContext)

  const showFullText = showFullTextAnswer(
    exerciseItem.checked,
    exerciseItem.userAnswer.hasAlsoCorrect,
    exerciseItem.userAnswer.userAnswerStatus,
    exerciseItem.unlocked
  )

  if (showFullText) {
    const userAnswerStatusList = [exerciseItem.userAnswer.userAnswerStatus]
    return (
      <ExerciseFullTexts
        unlocked={exerciseItem.unlocked}
        fullTexts={exerciseItem.fullTextAnswers}
        userAnswerStatus={userAnswerStatusList}
        template='INPUT'
        textColor={colors['C233']}
        inputColor={colors['C233']}
      />
    )
  }

  const renderGivenTextParagraph = (paragraph, pIndex = 0) => {
    return (
      <HtmlTextWrapper
        textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
        optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
        data={{
          ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
          sentence: htmlToText(paragraph.string)
        }}
        phraseOptionsDisabled
        showPhraseOptions={exerciseItem.checked}
        style={{ display: 'inline' }}
        key={pIndex}
      />
    )
  }

  const renderGivenParagraphs = () => {
    const givenText = exerciseItem.given.text
    if (givenText) {
      return givenText.paragraphList.map((paragraph, pIndex) => {
        return (
          <div className='exercise-paragraph' style={{ ...paragraph.paragraphStyle }} key={pIndex}>
            {renderGivenTextParagraph(paragraph, pIndex)}
            {givenText.paragraphList.length === pIndex + 1 && !exerciseItem.checked && (
              <span style={{ color: colors['C23'] }}> {exerciseItem.additionalData}</span>
            )}
          </div>
        )
      })
    } else {
      return null
    }
  }

  return (
    <>
      {renderGivenParagraphs()}
      {exerciseItem.checked && (
        <div className='exercise-paragraph'>
          <HtmlTextWrapper
            phraseOptionsDisabled
            showPhraseOptions
            moreOptionsList={moreOptionsList}
            style={{ display: 'inline' }}
            textColor={colors['C233']}
            data={{
              ...(exerciseItem.fullTextAnswers.length > 0 ? exerciseItem.fullTextAnswers[0] : {}),
              sentence: (
                <InlineInputField
                  value={renderUserAnswer(exerciseItem.userAnswer.userInputText.userAnswerData)}
                  status={exerciseItem.userAnswer.userAnswerStatus}
                />
              )
            }}
          />
        </div>
      )}
    </>
  )
}

export default TypeTheAnswerGivenTextTemplateInput
