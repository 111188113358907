import React, { useContext, useEffect, useState } from 'react'
import ClockTimer from '../../../components/ClockTimer'
import { useDispatch, useSelector } from 'react-redux'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { decreaseRemainingTime, setCountDown } from '../../../redux/features/readingSlice'
import ToplineTitle from '../../../components/ToplineTitle/ToplineTitle'
import Image from '../../../components/Image'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import { useSearchParams } from 'react-router-dom'
import PlayButtonPrimaryPlaylist from '../../../components/PlayButtonPrimary/PlayButtonPrimaryPlaylist'
import HelpButton from '../../../components/HelpButton/HelpButton'
import colorsContext from '../../../contexts/ColorsContext'
import componentStyle from '../../../components/Instruction/Instruction.module.scss'
import PageLayer from '../../../components/PageLayer/PageLayer'
import ReadingWarmUp from './ReadingWarmUp'
import useSectionEvents from '../../../hooks/sectionHooks/useSectionEvents'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useWakeLockApi from '../../../hooks/useWakeLockApi'
import useQueryParams from '../../../hooks/useQueryParams'

const ReadingMain = () => {
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [searchParams] = useSearchParams()
  const isWarmUp = searchParams.get('warmup')
  const { addQueryParams } = useQueryParams()

  const { colors } = useContext(colorsContext)

  const states = useSelector(state => state.reading)
  const { logPending } = useSelector(state => state.studyLog)
  const dispatch = useDispatch()

  const { navigateToNextSection } = useSectionEvents()

  const { requestWakeLock, releaseWakeLock } = useWakeLockApi()
  useEffect(() => {
    if (audioPlaying) requestWakeLock()
    else releaseWakeLock()
  }, [audioPlaying])

  useEffect(() => {
    if (states.remainingTime !== 0 && states.data.countDown) {
      const intervalId = setInterval(() => {
        dispatch(decreaseRemainingTime(states.data.countDown))
      }, 1000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [])

  useEffect(() => {
    const minutes = Math.floor(states.remainingTime / 60)
    const seconds = (states.remainingTime - minutes * 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
    const ms = `${minutes}:${seconds}`
    dispatch(setCountDown(ms))
  }, [states.remainingTime])

  // Get user screen width
  const { windowWidth } = useWindowDimensions()

  const renderReadingImageContainer = () => {
    return (
      <div className='reading-image-container'>
        {states.remainingTime === 0 && (
          <div className='play-btn-wrapper'>
            <PlayButtonPrimaryPlaylist
              iconColor={colors['C160']}
              bgColor={colors['C66']}
              playing={audioPlaying}
              handlePlaying={setAudioPlaying}
              loading={states.playlist?.length === 0}
              playlistUrls={states.playlist}
            />
          </div>
        )}
        {states.remainingTime !== 0 && (
          <div className='clock-wrapper' style={{ background: colors['C160'] }}>
            <ClockTimer />
            <span className='content'>{states.countDown}</span>
          </div>
        )}
        <div className='reading-image' style={{ background: colors['C160'] }}>
          <Image uri={states.data.pictureUri} onClickZoomIn />
        </div>
      </div>
    )
  }

  const renderWarmUpButton = () => {
    if (states.data.warmUpQuiz.length > 0) {
      return (
        <div className='warm-up-button-wrapper'>
          <button
            onClick={() => {
              addQueryParams({ warmup: true })
            }}
            className='warm-up-button'
            style={{ color: colors['C152'], background: colors['C188'], borderColor: colors['C160'] }}
          >
            {states.answersChecked ? 'Warm-Up Results' : 'Warm-Up'}
          </button>
        </div>
      )
    }
  }

  return (
    <div data-jsx='Reading'>
      <div className='d-flex align-items-start justify-content-between pt-3'>
        <div className='  d-flex align-items-baseline'>
          <span className={componentStyle.introLeftLineText} style={{ background: colors['C170'] }} />
          <HtmlTextWrapper
            textColor={colors['C2']}
            optionsColor={colors['C2']}
            showPhraseOptions
            moreOptionsList={moreOptionsList}
            className='res:fs-md'
            data={states.data.description}
          />
        </div>
        <div className='me-3 me-md-5 ms-2'>
          <HelpButton color={colors['C2']} pageType={pageGuidEnums.READING_SECTION} />
        </div>
      </div>
      {windowWidth >= 992 && <ToplineTitle titleData={states.data.title} />}
      <div className='reading-page-container container-fluid pt-2 pt-lg-3'>
        <div className='d-flex justify-content-center h-100'>
          {windowWidth >= 992 && (
            // image box and warm up button in desktop screen
            <div className='reading-info-container'>{renderReadingImageContainer()}</div>
          )}
          <div className='reading-title-card-container'>
            <div className='mobile-title'>
              <HtmlTextWrapper
                showPhraseOptions
                moreOptionsList={moreOptionsList}
                data={states.data.title}
                textColor={colors['C592']}
                optionsColor={colors['C592']}
              />
            </div>
            <div className='reading-card-container' style={{ background: colors['C231'] }}>
              {windowWidth < 992 && (
                // image box in mobile screen
                <div className='reading-info-container'>{renderReadingImageContainer()}</div>
              )}
              <div className='reading-card-wrapper' style={{ borderColor: colors['C160'] }}>
                <div className='reading-card'>
                  {states.data.readingData.map((item, i) => {
                    return (
                      <div key={i} className='text res:fs-md'>
                        <HtmlTextWrapper
                          textColor={colors['C23']}
                          optionsColor={colors['C23']}
                          showPhraseOptions={states.remainingTime === 0}
                          moreOptionsList={['BOX', 'FAVORITES', 'TRANSLATE']}
                          data={item}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pagebelow-buttons-container'>
        {renderWarmUpButton()}
        <PrimaryButton loading={logPending} onClick={navigateToNextSection}>
          Next
        </PrimaryButton>
      </div>
      {isWarmUp && (
        <PageLayer>
          <ReadingWarmUp />
        </PageLayer>
      )}
    </div>
  )
}

export default ReadingMain
