import serviceCall from '../../services'
import staticContentMapper from '../../services/mapper/staticContent/staticContentMapper'

const staticContentUseCase = async ({ language, type }) => {
  const response = await serviceCall('fetchStaticContentData', { language, type }, 'cloud')

  return staticContentMapper(response)
}

export default staticContentUseCase
