import { errorTranslationDb } from '../db/errorTranslationDb'
import { errorTranslationsLanguagesData } from '../enums/errorLanguageEnums/errorLanguageEnums'
import { addErrorTranslation } from '../errorTranslations/addErrorTranslation'

export async function getErrorTranslatedText(translationKey = '', middleware) {
  try {
    // Retrieve the selected language key from localStorage
    const selectedLanguageKey = localStorage.getItem('selectedErrorTranslationLanguageKey') || 'ENGLISH'

    // Get the translation metadata for the selected language
    const selectedTranslationLanguage = errorTranslationsLanguagesData[selectedLanguageKey]

    if (!selectedTranslationLanguage) {
      return ''
    }

    // Always call addErrorTranslation to check for updates
    await addErrorTranslation(selectedTranslationLanguage)

    // Fetch the primary translation from IndexedDB
    let translation = await errorTranslationDb.table(selectedTranslationLanguage.dbTable).get(translationKey)

    // If primary translation is not found, search for 'OOPS_WRONG' key
    if (!translation) {
      translation = await errorTranslationDb.table(selectedTranslationLanguage.dbTable).get('OOPS_SOMETHING_WENT_WRONG')
    }

    if (translation) {
      // Apply middleware if provided, otherwise return the translation value
      return typeof middleware === 'function' ? middleware(translation.value) : translation.value
    } else {
      return 'Oops! Something went wrong.' // Final fallback string if no translations are found
    }
  } catch (error) {
    return 'Oops! Something went wrong.'
  }
}
