import structureBaseTitleMapper from './structureBaseTitleMapper'

const structureTitleTemplateMapper = ({ item, events }) => {
  return {
    ...item,
    data: {
      title: structureBaseTitleMapper({ title: item?.data?.title, events })
    },
    hiddenTime: parseInt(events?.view?.find(it => it?.templateId === item?.id)?.startTime) || 0
  }
}

export default structureTitleTemplateMapper
