import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import helpUseCase from '../../useCase/helpUseCase/helpUseCase'
import { languagesEnums } from '../../enums/languageEnums/languageEnums'
import { ENGLISH, NATIVE } from '../../enums/roboTeacher/dataStatusEnums'

const initialState = {
  getHelpPageDataLoading: false,
  data: null,
  helpData: null,
  error: ''
}

export const getHelpPageData = createAsyncThunk('help/getHelpPageData', async ({ page }, { getState }) => {
  const { translation } = getState()
  const response = await helpUseCase({ page })
  return {
    response,
    selectedTranslationLanguage: translation.selectedTranslationLanguage
  }
})

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    setHelpPageLanguage: (state, action) => {
      if (action.payload === ENGLISH) state.helpData = state.data?.english
      else if (action.payload === NATIVE) state.helpData = state.data?.native
    }
  },
  extraReducers: builder => {
    builder.addCase(getHelpPageData.pending, state => {
      state.getHelpPageDataLoading = true
    })
    builder.addCase(getHelpPageData.fulfilled, (state, action) => {
      state.getHelpPageDataLoading = false
      state.data = action.payload?.response
      if (action.payload.selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE) {
        state.helpData = action.payload?.response?.english
      } else {
        state.helpData = action.payload?.response?.native
      }
    })

    builder.addCase(getHelpPageData.rejected, (state, action) => {
      state.getHelpPageDataLoading = false
      state.error = action.error.message
    })
  }
})

export const { setHelpPageLanguage } = helpSlice.actions

export default helpSlice.reducer
