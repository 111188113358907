import AISvg from '../../icons/AISvg'
import { useContext, useState } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import componentStyle from './ExerciseAIAssistantButton.module.scss'
import ExerciseAIAssistantModal from '../ExerciseAIAssistantModal/ExerciseAIAssistantModal'

const ExerciseAIAssistantButton = ({ exerciseItemId, correctAnswer, userAnswer }) => {
  const [showAssistant, setShowAssistant] = useState(false)
  const { colors } = useContext(colorsContext)

  return (
    <>
      <button
        className={componentStyle.button}
        style={{ background: colors['C160'] }}
        onClick={() => setShowAssistant(true)}
      >
        <AISvg color={colors['C233']} />
      </button>
      {showAssistant && (
        <ExerciseAIAssistantModal
          exerciseItemId={exerciseItemId}
          correctAnswer={correctAnswer}
          userAnswer={userAnswer}
          closeModal={() => setShowAssistant(false)}
        />
      )}
    </>
  )
}

export default ExerciseAIAssistantButton
