import { AnswerStatusEnums } from '../enums/globalEnums/globalEnums'

const getUserStatusColor = (status, unlocked = false) => {
  switch (status) {
    case AnswerStatusEnums.CORRECT:
      return 'C30'
    case AnswerStatusEnums.INCORRECT:
      return 'C29'
    case AnswerStatusEnums.SKIPPED:
      return 'C233'
    case AnswerStatusEnums.OVERLOOKED:
      return 'C233'
    default:
      return 'C2'
  }
}

export default getUserStatusColor
