const getTimeFromSeconds = seconds => {
  if (typeof seconds !== 'number') {
    return '00:00'
  }

  let hour = Math.floor(seconds / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2 })

  let minute = Math.floor((seconds - hour * 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })

  let updSecond = (seconds - (hour * 3600 + minute * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2 })

  if (hour === '00') {
    return minute + ':' + updSecond
  } else {
    return hour + ':' + minute + ':' + updSecond
  }
}

export default getTimeFromSeconds
