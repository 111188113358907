import { htmlTextConversion } from '../mapperConversions'

const ordinalNumbersSectionMapper = data => {
  return {
    ...data,
    tableHeaderRows: [
      {
        text: {
          sentence: 'Numbers'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Letters'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Translation'
        },
        isTranslate: true
      }
    ],
    tableColumnsConfig: [
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: true }
    ],
    numbers: data.numbers.map(number => {
      return {
        id: number.index,
        columns: [
          {
            text: htmlTextConversion(number.letter),
            showVoice: true,
            isTranslate: false
          },
          {
            text: htmlTextConversion(number.number),
            isTranslate: false
          },
          {
            text: htmlTextConversion({ sentence: number.translation }),
            isTranslate: true
          }
        ]
      }
    })
  }
}

export default ordinalNumbersSectionMapper
