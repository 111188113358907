import serviceCall from '../../services'
import aiChatRoleDescriptionMapper from '../../services/mapper/aiChatRoleDescription/aiChatRoleDescriptionMapper'

const aiChatRoleDescriptionUseCase = async ({ unitId, promptType }) => {
  const result = await serviceCall('fetchAiChatRoleDescriptions', { unitId, promptType }, 'cloud')

  return aiChatRoleDescriptionMapper(result)
}

export default aiChatRoleDescriptionUseCase
