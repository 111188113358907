import ColorsContext from 'contexts/ColorsContext'
import { useContext } from 'react'
import componentStyles from './RetryServiceButton.module.scss'
import SecondaryButton from 'atomicComponents/atoms/SecondaryButton/SecondaryButton'

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const RetryServiceButton = (props: Props) => {
  const { colors }: any = useContext(ColorsContext)

  return (
    <div
      className={`${componentStyles.container} tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[calc(100dvh-130px)]`}
    >
      <p className='tw-text-xl tw-font-bold tw-text-center tw-mb-4'>Oops! We couldn't fetch the content.</p>
      {/* <ResetButton color={colors['C2']} text='Retry' onClick={props.onClick} /> */}
      <SecondaryButton onClick={props.onClick}>Retry</SecondaryButton>
    </div>
  )
}

export default RetryServiceButton
