import * as React from 'react'

const MyNotepadSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {
        '.MyNotepadSvg-st0{display:none;}.MyNotepadSvg-st1{display:inline;fill:none;stroke:#9CFF55;stroke-width:37;stroke-linecap:round;stroke-miterlimit:10;}.MyNotepadSvg-st2{stroke-width:18.5884;stroke-miterlimit:10;}.MyNotepadSvg-st3{fill:none;stroke-width:35;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}'
      }
    </style>
    <g>
      <g className='MyNotepadSvg-st0'>
        <line className='MyNotepadSvg-st1' x1={440.9} y1={139} x2={440.9} y2={277} />
      </g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  className='MyNotepadSvg-st2'
                  stroke={color}
                  d='M332.4,41.3H110c-17.5,0-31.8,14.3-31.8,31.8v31.8h-8c-4.4,0-8,3.5-8,8c0,4.4,3.5,8,8,8h8v31.8h-8 c-4.4,0-8,3.5-8,8c0,4.4,3.5,8,8,8h8v31.8h-8c-4.4,0-8,3.5-8,8c0,4.4,3.5,8,8,8h8v31.6h-8c-4.4,0-8,3.5-8,8s3.5,8,8,8h8v31.3 h-8c-4.4,0-8,3.5-8,8s3.5,8,8,8h8v31.8h-8c-4.4,0-8,3.5-8,8s3.5,8,8,8h8V391h-8c-4.4,0-8,3.5-8,8s3.5,8,8,8h8v31.8h-8 c-4.4,0-8,3.5-8,8s3.5,8,8,8h8V471c0,17.5,9.4,31.8,26.9,31.8H407c17.5,0,36.7-14.3,36.7-31.8V73.1 c0-17.7-14.1-31.8-31.8-31.8H396 M348.4,41.3 M396.4,57.2h15.7c8.8,0,15.8,7.1,15.8,15.8l0.2,397.4 c-0.2,8.8-12.2,15.9-20.9,15.9H180.5c-0.6,0.2-1.2,0.4-2,0.4h-61.8c-0.7,0-1.2-0.1-1.7-0.3h-9.8c-8.8,0-11-7.1-11-15.8v-15.8 h8c4.4,0,8-3.5,8-8s-3.5-8-8-8h-8v-31.7h8c4.4,0,8-3.5,8-8s-3.5-8-8-8h-8v-31.8h8c4.4,0,8-3.5,8-8s-3.5-8-8-8h-8v-31.8h8 c4.4,0,8-3.5,8-8s-3.5-8-8-8h-8v-31.8h8c4.4,0,8-3.5,8-8s-3.5-8-8-8h-8v-31.8h8c4.4,0,8-3.5,8-8c0-4.4-3.5-8-8-8h-8v-31.8h8 c4.4,0,8-3.5,8-8c0-4.4-3.5-8-8-8h-8v-31.8h8c4.4,0,8-3.5,8-8c0-4.4-3.5-8-8-8h-8V73.1c0-8.8,7.1-15.8,15.8-15.8h12 c0.5-0.2,1-0.3,1.7-0.3h63.4c0.5,0,0.9,0.1,1.3,0.2h144.2v5.5 M389,64.8 M380,41.3'
                />
              </g>
            </g>
          </g>
        </g>
        <line className='MyNotepadSvg-st3' stroke={color} x1={318} y1={49.2} x2={410.9} y2={49.2} />
      </g>
    </g>
    <g>
      <line className='MyNotepadSvg-st3' stroke={color} x1={364.4} y1={20} x2={364.4} y2={268.2} />
      <line className='MyNotepadSvg-st3' stroke={color} x1={327.1} y1={70.6} x2={327.1} y2={124.2} />
      <line className='MyNotepadSvg-st3' stroke={color} x1={357.8} y1={282} x2={367.2} y2={348.3} />
      <line className='MyNotepadSvg-st3' stroke={color} x1={373.6} y1={282.7} x2={367.2} y2={348.3} />
    </g>
  </svg>
)

export default MyNotepadSvg
