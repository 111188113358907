import { useContext, useEffect, useRef, useState } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import componentStyle from './RoundedInput.module.scss'
import EyeSvg from '../../icons/EyeSvg'

const RoundedInput = ({
  type = 'text',
  name,
  value,
  onChange = () => 0,
  backgroundColor,
  onKeyDown,
  color,
  placeholder = '',
  placeholderColor = 'gray',
  disabled,
  maxLength,
  required,
  autoFocus,
  error = false
}) => {
  const { colors } = useContext(colorsContext)
  const [passwordType, setPasswordType] = useState('password')
  const inputElement = useRef()

  useEffect(() => {
    if (autoFocus) {
      inputElement.current.focus()
    }
  }, [])

  return (
    <div className={componentStyle.inputContainer}>
      <input
        ref={inputElement}
        disabled={disabled}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        className={componentStyle.input}
        type={type === 'password' ? passwordType : type}
        name={name ?? ''}
        onChange={e => onChange(e)}
        value={value}
        placeholder={placeholder}
        required={required}
        style={{
          border: error ? `1px solid ${colors['C29']}` : null,
          background: backgroundColor ?? colors['C2'],
          color: color ?? colors['C23'],
          '--placeholder-color': placeholderColor
        }}
      />
      {type === 'password' && (
        <button
          className={componentStyle.visibilityButton}
          type='button'
          onClick={() => {
            if (passwordType === 'password') {
              setPasswordType('text')
              return
            }
            setPasswordType('password')
          }}
        >
          <EyeSvg color='#bab6b6' haveLine={passwordType === 'password'} />
        </button>
      )}
    </div>
  )
}

export default RoundedInput
