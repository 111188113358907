import { createSlice } from '@reduxjs/toolkit'
import { SPELLING_PYRAMID_PAGE } from '../../../../enums/toolsEnums/spellingEnums'
import spellingWordCustomSyllableFactory from '../../../../factory/spellingWordCustomSyllableFactory'

const initialState = {
  page: SPELLING_PYRAMID_PAGE,
  data: null,
  currentPhrase: null
}

const sharedSpellingSlice = createSlice({
  name: 'sharedSpelling',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setCurrentPhrase: (state, action) => {
      state.currentPhrase = action.payload
    },
    setPartCharacters: (state, action) => {
      const wordData = state.data[action.payload.currentIndex]
      state.data[action.payload.currentIndex] = {
        ...wordData,
        titleCharacters: wordData.titleCharacters.map(it => {
          if (it.index === action.payload.char?.index) {
            return {
              ...it,
              used: true
            }
          }
          return it
        }),
        customSyllableArray: wordData.customSyllableArray.map((it, i) => {
          if (i === action.payload.partIndex) {
            return [...it, action.payload.char]
          }
          return it
        })
      }
      state.data[action.payload.currentIndex].customSyllable = spellingWordCustomSyllableFactory(
        state.data[action.payload.currentIndex]
      )
    },
    removePartCharacters: (state, action) => {
      const wordData = state.data[action.payload.currentIndex]
      let removedFromListCharacters = []
      state.data[action.payload.currentIndex] = {
        ...wordData,
        customSyllableArray: wordData.customSyllableArray.map((it, i) => {
          if (action.payload.partIndex <= i) {
            it.forEach(char => {
              removedFromListCharacters.push(char.index)
            })
            return []
          }
          return it
        }),
        titleCharacters: wordData.titleCharacters.map(it => {
          if (removedFromListCharacters.includes(it.index)) {
            return {
              ...it,
              used: false
            }
          }
          return it
        })
      }
      state.data[action.payload.currentIndex].customSyllable = spellingWordCustomSyllableFactory(
        state.data[action.payload.currentIndex]
      )
    },
    clearSharedSpellingStates: state => {
      state.page = SPELLING_PYRAMID_PAGE
      state.data = null
    }
  }
})

export const {
  setPage,
  setData,
  setCurrentPhrase,
  setPartCharacters,
  removePartCharacters,
  clearSharedSpellingStates
} = sharedSpellingSlice.actions

export default sharedSpellingSlice.reducer
