import serviceCall from '../../services'

const subscriptionStripeUseCase = async ({ asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType }) => {
  const result = await serviceCall(
    'subscriptionStripePlan',
    { asRenewal, discountCode, planId, psp, paymentMethodId, purchaseType },
    'cloud'
  )

  return result
}

export default subscriptionStripeUseCase
