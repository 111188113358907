import React, { useState } from 'react'
import useAudioPlayer from '../../hooks/audio/useAudioPlayer'
import Bar from './Bar'
import Play from './Play'
import Pause from './Pause'
import InputModal from '../InputModal/InputModal'
import { useDispatch } from 'react-redux'
import { renameArchiveFile, renameFile } from '../../redux/features/archiveSlice'
import { renameRolePlayFile } from '../../redux/features/conversationSlice'

export const AudioPlayerComponent = props => {
  require('./audioPlayer.scss')

  const [renameModalIsOpen, setRenameModalIsOpen] = useState(false)

  const dispatch = useDispatch()

  const { curTime, duration, playing, setPlaying, setAudioSeekTime } = useAudioPlayer({
    audioUrl: props.blobSrc
    // audioElementId: 'audio_' + props.audioId,
  })

  // function formatDuration(duration) {
  //   return moment
  //     .duration(duration, 'seconds')
  //     .format('mm:ss', { trim: false })
  // }

  const elementsColor = playing ? '#FFFFFF' : '#604581'
  const backgroundColor = playing ? '#604581' : '#DBDEF1'

  const rename = inputData => {
    if (props.isArchive) {
      dispatch(
        renameFile({
          fileId: props.audioId,
          newName: inputData[0].value
        })
      ).then(
        () => {
          dispatch(
            renameArchiveFile({
              fileId: props.audioId,
              newName: inputData[0].value
            })
          )
          setRenameModalIsOpen(false)
        },
        () => {
          setRenameModalIsOpen(false)
        }
      )
    } else {
      dispatch(renameRolePlayFile(inputData[0].value))
      setRenameModalIsOpen(false)
    }
  }

  return (
    <div
      className='audio-player-container'
      style={{
        backgroundColor: backgroundColor,
        opacity: props.disabled ? 0.5 : 1,
        pointerEvents: props.disabled ? 'none' : 'unset'
      }}
      data-jsx='AudioPlayer'
    >
      <audio id={'audio_' + props.audioId}>
        <source src={props.blobSrc ?? null} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className='audio-info font-size-23'>
        <span className='text audio-title' style={{ color: elementsColor }}>
          {props.audioTitle}
        </span>
        <span className='text audio-name' style={{ color: elementsColor }}>
          {props.audioName}
        </span>
      </div>
      {/*<div className='audio-details font-size-18' style={{borderColor: elementsColor, color: elementsColor}}>*/}
      {/*	*/}
      {/*  <span className='duration'>*/}
      {/*		{formatDuration(duration)}*/}
      {/*	</span>*/}
      {/*  <span className='size'>*/}
      {/*		/!*0.1 MB*!/*/}
      {/*	</span>*/}
      {/*</div>*/}
      <div className='audio-controls'>
        {playing ? <Pause handleClick={() => setPlaying(false)} /> : <Play handleClick={() => setPlaying(true)} />}
        <Bar
          curTime={curTime}
          duration={duration}
          onTimeUpdate={time => setAudioSeekTime(time)}
          elementsColor={elementsColor}
        />
      </div>
      <div className='audio-buttons font-size-21'>
        {props.onClickRemove && (
          <button onClick={props.onClickRemove} style={{ borderColor: elementsColor, color: elementsColor }}>
            {props.isArchive ? 'Delete' : 'Cancel'}
          </button>
        )}
        {props.onClickSave && (
          <button onClick={props.onClickSave} style={{ borderColor: elementsColor, color: elementsColor }}>
            Save
          </button>
        )}
        <button onClick={() => setRenameModalIsOpen(true)} style={{ borderColor: elementsColor, color: elementsColor }}>
          Rename
        </button>
      </div>
      <div className='font-size-18' style={{ color: elementsColor, textAlign: 'right', marginTop: '16px' }}>
        <span className='date'>{props.audioDate}</span>
      </div>
      {renameModalIsOpen && (
        <InputModal
          closeModal={() => setRenameModalIsOpen(false)}
          inputList={[
            {
              label: 'Enter new name'
            }
          ]}
          onSave={inputData => rename(inputData)}
        />
      )}
    </div>
  )
}

const AudioPlayer = props => {
  return <AudioPlayerComponent {...props} blobSrc={props.audioSrc} disabled={!props.audioSrc} />
}

export default AudioPlayer
