import React, { useState, useContext } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import exerciseStyles from '../ExerciseModules.module.scss'
import DraggableItemsCard from '../../../components/DraggableItemsCard/DraggableItemsCard'
import Image from '../../../components/Image'
import './../../../operations/dndPollyfill'
import classNames from 'classnames'
import { onClickAnswerBoxDADL, selectChoiceDNDL, toggleLockCFC } from '../../../redux/features/exerciseSlice'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import Lock from '../../../components/Lock/Lock'
import DragDropInlineAnswerBox from '../../../components/DragDropInlineAnswerBox/DragDropInlineAnswerBox'
import Instruction from '../../../components/Instruction/Instruction'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const DragAndDropCategoryModule = ({ data }) => {
  const [draggingField, setDraggingField] = useState(null)
  const [draggingItem, setDraggingItem] = useState(null)

  const { checkingItem } = useSelector(state => state.exercise)
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()

  const onClickOption = choice => {
    const selectedAnswerBox = data.stack.answerFields.find(el => el.selected)
    if (!checkingItem && !data.checked && selectedAnswerBox) {
      dispatch(selectChoiceDNDL({ choice, field: selectedAnswerBox }))
    }
  }

  const onDropOptionToDefaultPlace = (e, choice) => {
    if (e.stopPropagation) e.stopPropagation()

    if (draggingField) {
      dispatch(selectChoiceDNDL({ choice, field: draggingField }))
    }

    setDraggingField(null)
  }

  const onDropOptionToAnswerBox = field => {
    if (draggingItem && draggingItem?.choiceIndex !== field.userAnswer?.choiceIndex) {
      dispatch(selectChoiceDNDL({ choice: draggingItem, field }))
    }

    setDraggingItem(null)
  }

  const renderAnswerFields = given => {
    return data.stack.answerFields
      .filter(el => el.itemId === given.itemId)
      .map((field, fieldIndex) => {
        return (
          <div
            className={classNames(exerciseStyles.answerBoxWrapper, { ['justify-content-center']: !data.checked })}
            key={fieldIndex}
          >
            {field.isSolvedBefore ? (
              <DragDropInlineAnswerBox
                pending={!data.checked}
                checked={data.checked}
                userAnswerStatus={AnswerStatusEnums.NONE}
                userAnswer={field.correctAnswer?.text ?? null}
                correctAnswer={field.correctAnswer?.text ?? null}
                bgColor={colors['C525']}
                phraseOptions={field.correctAnswer?.text ?? null}
              />
            ) : (
              <DragDropInlineAnswerBox
                pending={checkingItem}
                checked={data.checked}
                unlocked={data.unlocked}
                userAnswerStatus={field.userAnswerStatus}
                userAnswer={field.userAnswer ? field.userAnswer.text : null}
                correctAnswer={field.correctAnswer ? field.correctAnswer.text : null}
                selected={field.selected}
                onDragStart={() => {
                  setDraggingField(field)
                  setDraggingItem(field.userAnswer)
                }}
                onClick={() => {
                  dispatch(onClickAnswerBoxDADL({ fieldIndex: field.index }))
                }}
                onDrop={() => onDropOptionToAnswerBox(field)}
                bgColor={colors['C525']}
                phraseOptions={data.checked && field.correctAnswer ? field.correctAnswer.text : null}
              />
            )}
          </div>
        )
      })
  }

  const renderExerciseItem = () => {
    return data.stack.givens.map((given, givenIndex) => {
      return (
        <div className={exerciseStyles.dragDropCategoryContainer} key={givenIndex}>
          <div className={exerciseStyles.dragDropGivenContainer}>
            {given.text && given.text.sentence && (
              <div className={exerciseStyles.givenText}>
                <HtmlTextWrapper
                  data={given.text}
                  showPhraseOptions
                  moreOptionsList={moreOptionsList}
                  textColor={colors['C2']}
                  optionsColor={colors['C2']}
                />
              </div>
            )}
            {given.imageUri && <Image onClickZoomIn className={exerciseStyles.img} uri={given.imageUri} />}
          </div>
          <div className={exerciseStyles.exerciseCard} style={{ background: colors['C62'] }}>
            {renderAnswerFields(given)}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {data.stack.title && <Instruction data={data.stack.title} />}
      <div className={exerciseStyles.dragDropContainer}>
        <div className='container-fluid'>
          {!data.checked && (
            <DraggableItemsCard
              backgroundColor={colors['C569']}
              optionsBackgroundColor={colors['C568']}
              optionsTextColor={colors['C576']}
              itemsData={data.stack.choices?.filter(it => !it.isSolvedBefore)}
              onClickOption={onClickOption}
              onDragStart={item => setDraggingItem(item)}
              onDrop={onDropOptionToDefaultPlace}
              isNavMenu
            />
          )}
          <div
            className={classNames(exerciseStyles.exerciseCard, exerciseStyles.dragDropCategoriesCard)}
            style={{ background: colors['C62'] }}
          >
            <div className={exerciseStyles.dragDropCategoriesContainer}>{renderExerciseItem()}</div>
            {data.checked && data.stack.answerFields.some(el => el.userAnswerStatus) && (
              <div className={exerciseStyles.dragDropCategoryLockContainer}>
                <Lock isLocked={!data.unlocked} onClickLock={() => dispatch(toggleLockCFC())} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DragAndDropCategoryModule
