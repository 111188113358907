import serviceCall from '../../services'
import todayStudyPlanMapper from '../../services/mapper/todayStudy/todayStudyPlanMapper'

const todayStudyPlanUseCase = async ({ baseData, pageIndex }) => {
  const result = await serviceCall('fetchTodayStudyPlan', { pageIndex }, 'cloud')

  return todayStudyPlanMapper(result, baseData)
}

export default todayStudyPlanUseCase
