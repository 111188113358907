import { toFarsiNumber } from '../../../util/utility'
import { sortBy } from 'lodash'
import { paymentTypeEnums } from '../../../enums/payment/paymentTypeEnums'

const aiPackagesMapper = data => {
  const getIRRPriceAmount = amount => {
    if (amount >= 10000000) {
      const value = amount / 10000000
      return {
        paymentCurrencyCode: 'IRR',
        value: value,
        symbol: ' میلیون تومان',
        displayValue: toFarsiNumber((Math.round(value * 1000) / 1000).toFixed(3)).replace('.', '/')
      }
    } else if (amount >= 10000) {
      const value = amount / 10000
      return {
        paymentCurrencyCode: 'IRR',
        value: value,
        symbol: ' هزار تومان',
        displayValue: toFarsiNumber(Math.floor(value).toString())
      }
    } else {
      const value = amount / 10
      return {
        paymentCurrencyCode: 'IRR',
        value: value,
        symbol: ' تومان',
        displayValue: toFarsiNumber(Math.floor(value).toString())
      }
    }
  }

  const renderPriceObject = obj => {
    if (obj.paymentCurrencyCode === 'IRR') {
      return getIRRPriceAmount(obj.value)
    } else {
      return {
        paymentCurrencyCode: obj.paymentCurrencyCode,
        symbol: obj.symbol,
        value: obj.value,
        displayValue: obj.value.toLocaleString('en-US', {
          maximumFractionDigits: 2
        })
      }
    }
  }

  const getAiTokens = (token, isIRR) => {
    const tokensAmount = token / 1000
    return isIRR ? toFarsiNumber(tokensAmount) : tokensAmount
  }

  const getCapsuleFillLevel = tokens => {
    if (tokens >= 500000) {
      return '4'
    } else if (tokens >= 300000) {
      return '3'
    } else if (tokens >= 100000) {
      return '2'
    } else {
      return '1'
    }
  }

  return data?.length > 0
    ? sortBy(data, 'aiPackage.tokens')?.map(it => {
        const isIRR = it.price.paymentCurrencyCode === 'IRR'
        const price = renderPriceObject(it?.price)

        return {
          plan: {
            id: it?.aiPackage?.id,
            aiTokens: getAiTokens(it?.aiPackage?.tokens, isIRR)
          },
          price: {
            amount: price,
            finalAmount: price
          },
          capsuleFillLevel: getCapsuleFillLevel(it?.aiPackage?.tokens),
          purchaseType: paymentTypeEnums.AI_PACKAGE
        }
      })
    : []
}

export default aiPackagesMapper
