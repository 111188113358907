import React, { useEffect, useState } from 'react'
import makeUrl from '../operations/makeUrl'
import getCachedMediaUrl from '../operations/getCachedMediaUrl'
import Modal from './Modal'
import structureImageStyle from '../pages/Sections/StructuredComponents/Components/StructureImageComponent/StructureImageComponent.module.scss'
import classNames from 'classnames'

const Image = ({ uri, className, setLoaded, onClickZoomIn = false }) => {
  const [mediaUrl, setMediaUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false)

  useEffect(() => {
    setLoading(true)
    let isMounted = true

    if (uri) {
      getCachedMediaUrl(makeUrl(uri)).then(res => {
        if (res && isMounted) {
          setMediaUrl(res)
          setLoading(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [uri])

  useEffect(() => {
    if (mediaUrl && setLoaded) {
      setLoaded()
    }
  }, [mediaUrl])

  if (loading) {
    return <div className={`shimmer fullsize-shimmer ${className ?? ''}`} />
  } else {
    return (
      <>
        <img
          className={classNames(className ?? '', 'motion-opacity-1 user-drag-none', {
            ['cursor-pointer']: onClickZoomIn
          })}
          onClick={() => {
            if (onClickZoomIn) {
              setImageModalIsOpen(true)
            }
          }}
          style={{ display: 'inline-block' }}
          src={mediaUrl}
          alt=''
        />
        {imageModalIsOpen && (
          <Modal closeModal={() => setImageModalIsOpen(false)}>
            <div className={structureImageStyle.imageModal}>
              <Image uri={uri} />
            </div>
          </Modal>
        )}
      </>
    )
  }
}

export default Image
