import { htmlTextConversion } from '../mapperConversions'

const commonDigraphsMapper = data => {
  return {
    ...data,
    digraphs:
      data?.digraphs?.map(digraph => {
        return {
          ...digraph,
          title: htmlTextConversion(digraph.title),
          samples: digraph.samples.map(sample => {
            return {
              ...sample,
              text: htmlTextConversion(sample.text)
            }
          })
        }
      }) ?? []
  }
}

export default commonDigraphsMapper
