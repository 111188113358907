import React, { useEffect } from 'react'
import CourseIntensityItemIntro from '../../../atomicComponents/molecules/CourseIntensityItemIntro/CourseIntensityItemIntro'
import FramerMotion from '../../../layouts/FramerMotion'
import CardTitleEnrollment from '../../../atomicComponents/molecules/CardTitleEnrollment/CardTitleEnrollment'
import styles from '../enrollmentIntro.module.scss'
import BackButton from '../../../atomicComponents/atoms/BackButton/BackButton'
import WordMarkLogoSvg from '../../../icons/logo/WordMarkLogoSvg'
import classNames from 'classnames'
import SwitchLanguageButton from '../../SwitchLanguageButton/SwitchLanguageButton'
import TranslatedText from '../../TranslatedText/TranslatedText'
import { useDispatch } from 'react-redux'
import { onSelectCourse, updateCourseIntensityAction } from '../../../redux/features/courseIntensitySlice'
import useAppUser from '../../../hooks/useAppUser'
import { setAppUser } from '../../../redux/features/appUserSlice'
import { useNavigate } from 'react-router-dom'
import { finishFirstTimeAction } from '../../../redux/features/firstTimeActionSlice'
import userActionEnums from '../../../enums/userActionEnums/userActionEnums'
import { INTENSIVE, REGULAR, SEMI_INTENSIVE } from '../../../enums/globalEnums/globalEnums'

const CourseIntensityIntro = () => {
  const { appUserData } = useAppUser()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(onSelectCourse(appUserData?.setting.studyPreferences.courseIntensity))
  }, [appUserData?.setting.studyPreferences.courseIntensity])

  const courseIntensityActionExists = appUserData?.action?.availableActions?.includes(
    userActionEnums.setCourseIntensity
  )

  const onClickCourse = courseIntensity => {
    dispatch(updateCourseIntensityAction({ courseIntensity })).then(res => {
      dispatch(finishFirstTimeAction(userActionEnums.setCourseIntensity))
      dispatch(setAppUser(res.payload?.data))
      navigate('/memory-test/wizard')
    })
  }

  return (
    <FramerMotion
      className={classNames(styles.framerMotion, 'minh-100vh px-3 px-sm-6')}
      style={{ background: '#FFFAEC' }}
    >
      <div className={styles.header}>
        <div className={styles.logo}>
          <WordMarkLogoSvg primaryColor={'#000000'} oInnerColor='transparent' secondaryColor={'#E98020'} />
        </div>
        <SwitchLanguageButton textColor={'#000000'} />
      </div>
      <div className={styles.container}>
        <div className={styles.imageAndTitleCard}>
          <img
            className={styles.image}
            src={`${window.location.origin}/assets/images/enrollment/courseIntensityIntro.webp`}
            alt={'course intensity image'}
          />
          <CardTitleEnrollment progress={1} title={<TranslatedText translationKey={'course.intensity.title'} />} />
        </div>
        <div className={styles.containerItems}>
          <CourseIntensityItemIntro
            onClick={() => onClickCourse(INTENSIVE)}
            isSelected={
              !courseIntensityActionExists && appUserData?.setting?.studyPreferences?.courseIntensity === INTENSIVE
            }
            background={'#E98020'}
            typeOfCourse={<TranslatedText translationKey={'course.intensity.intensive'} />}
            firstLineText={<TranslatedText translationKey={'course.intensity.intensive.title'} />}
            secondLineText={<TranslatedText translationKey={'course.intensity.intensive.description'} />}
          />
          <CourseIntensityItemIntro
            onClick={() => onClickCourse(SEMI_INTENSIVE)}
            isSelected={
              !courseIntensityActionExists && appUserData?.setting?.studyPreferences?.courseIntensity === SEMI_INTENSIVE
            }
            background={'#EF9E56'}
            typeOfCourse={<TranslatedText translationKey={'course.intensity.semi.intensive'} />}
            firstLineText={<TranslatedText translationKey={'course.intensity.semi.intensive.title'} />}
            secondLineText={<TranslatedText translationKey={'course.intensity.semi.intensive.description'} />}
          />
          <CourseIntensityItemIntro
            onClick={() => onClickCourse(REGULAR)}
            isSelected={
              !courseIntensityActionExists && appUserData?.setting?.studyPreferences?.courseIntensity === REGULAR
            }
            background={'#F7C69E'}
            typeOfCourse={<TranslatedText translationKey={'course.intensity.regular'} />}
            firstLineText={<TranslatedText translationKey={'course.intensity.regular.title'} />}
            secondLineText={<TranslatedText translationKey={'course.intensity.regular.description'} />}
          />
        </div>
      </div>
      <footer>
        <BackButton to='/select-language' textButton={<TranslatedText translationKey={'enrollment.back'} />} />
        {!courseIntensityActionExists && (
          <BackButton
            to='/memory-test/wizard'
            textButton={<TranslatedText translationKey={'enrollment.next'} />}
            isNext
          />
        )}
      </footer>
    </FramerMotion>
  )
}
export default CourseIntensityIntro
