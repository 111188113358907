const checkMatchMapper = (stackId, response, data) => {
  let copyData = { ...data, checked: true }

  copyData.stack.givens = [...copyData.stack.givens].map((given, i) => {
    const userAnswerData = response[0].userAnswerItemResponse.find(o => o.listIndex === given.index)
    if (userAnswerData) {
      return {
        ...given,
        userAnswerStatus: userAnswerData.userAnswerStatus
      }
    }
    return given
  })

  return copyData
}

export default checkMatchMapper
