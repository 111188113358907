import React, { useContext, useEffect, useRef } from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import StructureSubjectsComponent from '../Components/StructureSubjectsComponent/StructureSubjectsComponent'
import colorsContext from '../../../../contexts/ColorsContext'
import classNames from 'classnames'
import { isEmpty, isNumber } from 'lodash'
import structuresContext from '../../../../contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from '../../../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const TextTemplate = ({ template }) => {
  const { colors } = useContext(colorsContext)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0) : false

  const elRef = useRef(null)
  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <div className='container container-1'>
      <div
        ref={elRef}
        data-rt-id={template?.id}
        className={classNames(
          structureStyle.templateContainer,
          { [structureStyle.border]: !isEmpty(template?.config?.borderColor) },
          { ['hide-element']: isHidden }
        )}
        style={{
          background: isEmpty(template?.config?.backgroundColor) ? colors['C194'] : template.config.backgroundColor,
          borderColor: template?.config?.borderColor
        }}
      >
        <StructureSubjectsComponent subjects={template.data.cards} />
      </div>
    </div>
  )
}

export default TextTemplate
