import React, { useContext } from 'react'
import exerciseStyles from '../../ExerciseModules.module.scss'
import { unselectOptionPIO } from '../../../../redux/features/exerciseSlice'
import { useDispatch, useSelector } from 'react-redux'
import colorsContext from '../../../../contexts/ColorsContext'

const PutInOrderSelectedChoices = ({ stackIndex, itemIndex, choices, enabled }) => {
  const { colors } = useContext(colorsContext)
  const { checkingItem } = useSelector(state => state.exercise)
  const dispatch = useDispatch()

  if (choices.length > 0) {
    return choices.map((choice, i) => {
      return (
        <button
          className={exerciseStyles.phraseCard}
          onClick={() => {
            if (enabled && !checkingItem)
              dispatch(unselectOptionPIO({ stackIndex, itemIndex, choiceIndex: choice.index }))
          }}
          key={i}
          style={{
            background: colors['C532'],
            borderColor: 'rgba(182,183,184,0.8)',
            color: colors['C23']
          }}
        >
          {choice.text.sentence}
        </button>
      )
    })
  } else {
    return null
  }
}

export default PutInOrderSelectedChoices
