// Define constants for each supported error language
export const ERROR_ENGLISH_LANGUAGE = 'ENGLISH'
export const ERROR_PERSIAN_LANGUAGE = 'PERSIAN'
export const ERROR_ARABIC_LANGUAGE = 'ARABIC'
export const ERROR_SPANISH_LANGUAGE = 'SPANISH'
export const ERROR_FRENCH_LANGUAGE = 'FRENCH'
export const ERROR_RUSSIAN_LANGUAGE = 'RUSSIAN'
export const ERROR_TURKISH_LANGUAGE = 'TURKISH'
export const ERROR_ITALIAN_LANGUAGE = 'ITALIAN'
export const ERROR_GERMAN_LANGUAGE = 'GERMAN'

const env = process.env
export const ERROR_ENGLISH_TRANSLATIONS = {
  titleInEnglish: 'English',
  title: 'English',
  key: ERROR_ENGLISH_LANGUAGE,
  dbTable: 'error_english',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.1`
}

export const ERROR_PERSIAN_TRANSLATIONS = {
  titleInEnglish: 'Persian',
  title: 'فارسی',
  key: ERROR_PERSIAN_LANGUAGE,
  dbTable: 'error_persian',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.2`
}

export const ERROR_ARABIC_TRANSLATIONS = {
  titleInEnglish: 'Arabic',
  title: 'العربية',
  key: ERROR_ARABIC_LANGUAGE,
  dbTable: 'error_arabic',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.1`
}

export const ERROR_SPANISH_TRANSLATIONS = {
  titleInEnglish: 'Spanish',
  title: 'Español',
  key: ERROR_SPANISH_LANGUAGE,
  dbTable: 'error_spanish',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.0`
}

export const ERROR_FRENCH_TRANSLATIONS = {
  titleInEnglish: 'French',
  title: 'Français',
  key: ERROR_FRENCH_LANGUAGE,
  dbTable: 'error_french',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.0`
}

export const ERROR_RUSSIAN_TRANSLATIONS = {
  titleInEnglish: 'Russian',
  title: 'Русский',
  key: ERROR_RUSSIAN_LANGUAGE,
  dbTable: 'error_russian',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.0`
}

export const ERROR_TURKISH_TRANSLATIONS = {
  titleInEnglish: 'Turkish',
  title: 'Türkçe',
  key: ERROR_TURKISH_LANGUAGE,
  dbTable: 'error_turkish',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.0`
}

export const ERROR_ITALIAN_TRANSLATIONS = {
  titleInEnglish: 'Italian',
  title: 'Italiano',
  key: ERROR_ITALIAN_LANGUAGE,
  dbTable: 'error_italian',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.0`
}
export const ERROR_GERMAN_TRANSLATION = {
  titleInEnglish: 'German',
  title: 'Deutsch',
  key: ERROR_GERMAN_LANGUAGE,
  dbTable: 'error_german',
  dbVersionCode: `${env.REACT_APP_ERROR_TRANSLATION_DB_VERSION}.0`
}

export const errorTranslationsLanguagesData = {
  [ERROR_ENGLISH_LANGUAGE]: ERROR_ENGLISH_TRANSLATIONS,
  [ERROR_PERSIAN_LANGUAGE]: ERROR_PERSIAN_TRANSLATIONS,
  [ERROR_ARABIC_LANGUAGE]: ERROR_ARABIC_TRANSLATIONS,
  [ERROR_SPANISH_LANGUAGE]: ERROR_SPANISH_TRANSLATIONS,
  [ERROR_FRENCH_LANGUAGE]: ERROR_FRENCH_TRANSLATIONS,
  [ERROR_RUSSIAN_LANGUAGE]: ERROR_RUSSIAN_TRANSLATIONS,
  [ERROR_TURKISH_LANGUAGE]: ERROR_TURKISH_TRANSLATIONS,
  [ERROR_ITALIAN_LANGUAGE]: ERROR_ITALIAN_TRANSLATIONS,
  [ERROR_GERMAN_LANGUAGE]: ERROR_GERMAN_TRANSLATION
}
