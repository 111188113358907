export default [
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'فشل في إنشاء المستخدم'
  },
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'فشل في التحقق من الكابتشا.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'اسم المستخدم غير موجود.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'كلمة المرور غير صحيحة!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'حقل كلمة المرور لا يمكن أن يكون فارغًا.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'يجب أن تحتوي كلمة المرور على ثلاثة أحرف على الأقل.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'حقل إعادة كلمة المرور لا يمكن أن يكون فارغًا. '
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'كلمة المرور وإعادة كلمة المرور يجب أن تكونا متطابقتين.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'حقل اسم المستخدم لا يمكن أن يكون فارغًا.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'لمستخدم غير صالح.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'رمز التأكيد منتهي الصلاحية.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'رمز التأكيد غير صالح.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'يرجى إدخال رمز التأكيد.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'يرجى الانتظار لمدة دقيقتين.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'عنوان الـ IP الخاص بك غير صالح.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'رمز الخصم غير صالح في منطقتك.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'رمز الخصم منتهي الصلاحية.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'رمز الخصم لم يعد متاحًا.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'تم استخدام رمز الخصم هذا من قبل.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'ليس لديك عدد كافٍ من الرموز.'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'لا يوجد وصف دور بهذا المعرف.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'لا يوجد موجه بهذا المعرف.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'التغذية الراجعة غير موجودة.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'لا توجد مساحة كافية في التخزين.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'الفئة بهذا الاسم موجودة بالفعل!'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'الفئة غير موجودة!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'هذا العنصر موجود بالفعل في الفئة المحددة.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'هذه البطاقة موجودة بالفعل.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'فئة بطاقات الفلاش بهذا الاسم موجودة بالفعل.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'العنوان موجود بالفعل! يرجى اختيار عنوان آخر.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'لم يتم العثور على الكلمة في مكتبتنا.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'رمز الخصم غير صحيح. يرجى التحقق والمحاولة مرة أخرى.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'للأسف، حدث خطأ!'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'لا يمكنك تفعيل المستويات السابقة.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'لا يمكنك إيقاف الوحدات السابقة.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'لا يمكنك تفعيل الوحدات السابقة.'
  }
]
