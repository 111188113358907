import * as React from 'react'

const FemaleSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>{`.FemaleSvg-st0{fill:none;stroke-width:15;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}</style>
    <g>
      <g>
        <g>
          <path d='M480,504c-4.8,0-8-3.2-8-8c0-19.2-8-56-64.8-87.2c-10.4-5.6-23.2-10.4-37.6-16c-34.4-12.8-74.4-28-74.4-60v-32 c0-4.8,3.2-8,8-8s8,3.2,8,8v32c0,20.8,32.8,33.6,64,45.6c14.4,5.6,28,10.4,39.2,16.8c64,35.2,73.6,78.4,73.6,100.8 C488,500.8,484.8,504,480,504L480,504z' />
        </g>
        <g>
          <path d='M32,504c-4.8,0-8-3.2-8-8c0-22.4,9.6-66.4,72.8-100.8c11.2-6.4,24.8-11.2,39.2-16.8c31.2-12,64-24,64-45.6v-32 c0-4.8,3.2-8,8-8s8,3.2,8,8v32c0,32-39.2,47.2-74.4,60c-13.6,5.6-27.2,10.4-37.6,16c-56,31.2-64,68-64,87.2 C40,500.8,36.8,504,32,504z' />
        </g>
        <g>
          <path d='M256,456c-48,0-92-28-111.2-72c-1.6-4,0-8.8,4-10.4s8.8,0,10.4,4C176,415.2,214.4,440,256,440s80-24.8,96.8-62.4 c1.6-4,6.4-5.6,10.4-4s5.6,6.4,4,10.4C348,428,304,456,256,456L256,456z' />
        </g>
        <g>
          <path d='M256,328c-42.4,0-83.2-40-95.2-91.2c-11.2-4-24.8-16-24.8-44.8c0-7.2,0.8-14.4,3.2-20c2.4-7.2,8.8-12,16.8-12.8 c21.6-2.4,28-19.2,35.2-44c4.8-17.6,10.4-36,28.8-36c9.6,0,17.6,3.2,23.2,5.6c4-3.2,11.2-5.6,23.2-5.6 c18.4,0,31.2,18.4,45.6,38.4c13.6,19.2,28,39.2,45.6,41.6c7.2,0.8,12.8,5.6,16,12c1.6,6.4,2.4,12.8,2.4,20.8 c0,28.8-14.4,40.8-24.8,44.8C339.2,288,298.4,328,256,328z M157.6,176c-1.6,0-2.4,0.8-3.2,2.4c-1.6,3.2-2.4,8-2.4,13.6 c0,28,16,30.4,16.8,30.4c3.2,0,6.4,3.2,7.2,6.4c9.6,51.2,48.8,83.2,80,83.2s70.4-32,80-83.2c0.8-3.2,3.2-6.4,7.2-6.4 c1.6,0,16.8-3.2,16.8-30.4c0-7.2-1.6-11.2-2.4-13.6c-0.8-1.6-1.6-2.4-3.2-2.4c-24.8-3.2-41.6-27.2-56.8-48 c-11.2-15.2-22.4-31.2-32-31.2c-12,0-15.2,4-15.2,4c-0.8,1.6-2.4,3.2-4.8,3.2c-2.4,0.8-4.8,0-6.4-1.6c0,0-8-6.4-19.2-6.4 c-5.6,0-8.8,9.6-13.6,24.8C200,142.4,191.2,172.8,157.6,176L157.6,176z' />
        </g>
        <g>
          <path d='M384.8,328c-28.8,0-40-5.6-40.8-5.6c-4-1.6-5.6-6.4-3.2-10.4c1.6-4,6.4-5.6,10.4-3.2s9.6,4,33.6,4 c23.2,0,31.2-16.8,31.2-32c0-24-12-49.6-17.6-61.6c-4.8-10.4-4-23.2-3.2-36c0.8-12,1.6-24-1.6-36c-4-16-14.4-28-24-38.4 c-4.8-4.8-8.8-9.6-12-14.4S351.2,83.2,348,76c-10.4-24-23.2-52-60-52c-30.4,0-41.6,12.8-41.6,12.8c-2.4,3.2-8,4-11.2,0.8 c-3.2-2.4-4-8-1.6-11.2C234.4,26.4,248.8,8,288,8c47.2,0,64,36,75.2,60c3.2,6.4,5.6,12,8,15.2c3.2,4,7.2,8.8,11.2,12.8 c10.4,12,23.2,25.6,28,45.6c3.2,14.4,2.4,28.8,1.6,40.8s-1.6,21.6,1.6,28.8c6.4,13.6,18.4,40.8,18.4,68 C432,304,417.6,328,384.8,328L384.8,328z' />
        </g>
        <g>
          <path d='M126.2,328C93.4,328,79,304,79,280c0-27.2,12.8-55.2,18.4-68c3.2-6.4,2.4-16.8,1.6-28.8c-0.8-12.8-1.6-26.4,1.6-40.8 c4.8-20.8,16.8-33.6,28-45.6c3.9-4.7,7.8-8.6,10.9-12.5c0.2-0.2,0.4-0.5,0.5-0.7c2.4-4,5.4-9.4,7.8-14.8 c9.6-21.6,24-52.8,67.2-52.8c13.6,0,27.2,8,28,9.6c4,2.4,4.8,7.2,2.4,11.2c-2.3,3.9-6.9,4.7-10.8,2.6c-0.3-0.1-0.5-0.3-0.7-0.5 c-2.9-2.5-12-6.9-18.9-6.9c-32.8,0-43.2,22.4-52.8,43.2c-3.2,6.4-5.6,12.8-9.6,17.6s-8,9.6-12,14.4c-9.6,11.2-20,22.4-24,38.4 c-2.4,12-2.4,24.8-1.6,36c0.8,13.6,1.6,25.6-3.2,36C107,230.4,95,256,95,280c0,15.2,8,32,31.2,32c24.8,0,33.6-4,34.4-4 c4-1.6,8.8,0,10.4,4c1.2,3.1,0.6,6.7-1.3,8.9c-0.7,0.8-1.7,1.4-2.7,1.7C163.6,323.5,151.9,328,126.2,328z' />
        </g>
      </g>
      <path d='M165.1,315.2' />
      <path
        className='FemaleSvg-st0'
        stroke={color}
        d='M147.7,318.7c10.4-1.7,22.8-4.8,36.3-10.6c6-2.6,11.5-5.4,16.3-8.2'
      />
      <path
        className='FemaleSvg-st0'
        stroke={color}
        d='M366.9,319.9c-10.4-1.7-22.8-4.8-36.3-10.6c-6-2.6-11.5-5.4-16.3-8.2'
      />
    </g>
  </svg>
)

export default FemaleSvg
