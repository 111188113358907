import React from 'react'
import style from './SpeechCircleButton.module.scss'
import MicrophoneOffSvg from '../../icons/MicrophoneOffSvg'
import MicrophoneOnSvg from '../../icons/MicrophoneOnSvg'
import classNames from 'classnames'
import MicrophoneResetSvg from '../../icons/MicrophoneResetSvg'

const SpeechCircleButton = ({
  recording,
  disabled,
  isSmall,
  isLarge,
  iconColor,
  bgColor,
  borderColor,
  onClick,
  className,
  isReset,
  loading,
  startLoading
}) => {
  return (
    <>
      <button
        className={classNames(
          style.speechCircleButton,
          {
            [style.isSmall]: isSmall,
            [style.isLarge]: isLarge,
            [style.recording]: recording,
            [style.isReset]: isReset
          },
          className
        )}
        type='button'
        disabled={disabled}
        style={{ borderColor: borderColor, backgroundColor: bgColor }}
        onClick={onClick}
      >
        {(loading || startLoading) && (
          <div className={style.loadingContainer}>
            <div
              className={classNames('spinner-border', style.spinner)}
              style={{
                borderColor: borderColor,
                borderRightColor: 'transparent'
              }}
            />
          </div>
        )}
        <div style={{ opacity: startLoading ? 0 : 1 }}>
          {isReset ? (
            <MicrophoneResetSvg color={iconColor} />
          ) : recording ? (
            <MicrophoneOnSvg color={iconColor} />
          ) : (
            <MicrophoneOffSvg color={iconColor} style={{ width: '100%', height: '100%' }} />
          )}
        </div>
      </button>
    </>
  )
}

export default SpeechCircleButton
