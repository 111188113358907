import * as React from 'react'

const WarningSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={512}
    height={512}
    viewBox='0 0 512 512'
  >
    <path d='M317.4 57c-19.3-33.9-62.4-45.7-96.3-26.4-11 6.3-20.2 15.4-26.4 26.4L8.8 388.1c-12 21.1-11.8 47.1.6 68 12.6 21.6 35.8 34.8 60.7 34.6h371.6c25 .2 48.2-13 60.7-34.6 12.4-20.9 12.6-46.9.6-68L317.4 57zm148.3 377.6c-5 8.4-14.1 13.5-23.9 13.4H70.2c-9.8.1-18.9-5-23.9-13.5-4.7-7.9-4.8-17.7-.2-25.6l185.8-331c8.8-13.3 26.7-17 40-8.3 3.3 2.2 6.1 5 8.3 8.3l185.8 331c4.6 8 4.5 17.8-.3 25.7z' />
    <path d='M256 149.3c-11.8 0-21.3 9.6-21.3 21.3v128c0 11.8 9.6 21.3 21.3 21.3 11.8 0 21.3-9.6 21.3-21.3v-128c0-11.7-9.5-21.3-21.3-21.3z' />
    <circle cx={256} cy={384} r={21.3} />
  </svg>
)

export default WarningSvg
