import { TYPE_A } from '../enums/exerciseEnums/exerciseGroupTypeEnums'
import { LEVEL_AND_UNIT } from '../enums/studyPageEnums/studyPageEnums'

export default class UnitDataStorage {
  constructor() {
    this.sessionStorageKey = 'unitData' // Key for sessionStorage
    this.defaultUnitData = {
      // Default unit data
      levelNumber: 0,
      levelName: '',
      levelKey: '',
      unitNumber: 0,
      unitTitle: '',
      unitDescription: '',
      studyStatus: '',
      sectionNumber: 0,
      sectionKey: '',
      exerciseGroupType: TYPE_A,
      isExercise: false,
      comeFromPage: LEVEL_AND_UNIT,
      isViewing: true
    }
  }

  // Method to get unit data from sessionStorage
  getUnitData() {
    const unitData = JSON.parse(sessionStorage.getItem(this.sessionStorageKey))
    return unitData ? unitData : this.defaultUnitData
  }

  // Method to set unit data in sessionStorage
  setUnitData(data) {
    const unitData = { ...this.defaultUnitData, ...data }
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(unitData))
  }

  // Method to clear unit data from sessionStorage
  clearUnitData() {
    sessionStorage.removeItem(this.sessionStorageKey)
  }
}
