import componentStyle from './AICreditBadge.module.scss'
import React from 'react'
import AICreditBadgeSvg from '../../icons/AICreditBadgeSvg'
import classNames from 'classnames'
import useAppUser from '../../hooks/useAppUser'

const AIChat = ({ showTitle = false, titleColor = '#000' }) => {
  const { appUserData } = useAppUser()

  return (
    <div className={componentStyle.container}>
      {showTitle && (
        <div className='fs-sm' style={{ color: titleColor }}>
          AI Fuel
        </div>
      )}
      <div className={componentStyle.badgeContainer}>
        <span className={componentStyle.badge}>
          <AICreditBadgeSvg />
        </span>
        <span className={classNames(componentStyle.badgeContent, 'fs-md')}>{appUserData.setting.aiTokens}</span>
      </div>
    </div>
  )
}

export default AIChat
