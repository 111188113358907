const ThumbDownFillSvg = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10.611' height='9.167' viewBox='0 0 10.611 9.167'>
      <g>
        <path
          fill={color}
          d='M10.263,5.316H7.9a.348.348,0,0,1-.347-.347V.348A.348.348,0,0,1,7.9,0h2.363a.349.349,0,0,1,.348.348V4.969a.348.348,0,0,1-.348.347Zm-5.1,3.466c-.183.928-1.7.073-1.8-1.423a6.44,6.44,0,0,1,.13-1.585H1.32C.417,5.738-.371,5.091.186,4.029c-.127-.463-.146-1.006.2-1.22a1.787,1.787,0,0,1,.537-1.54A1.829,1.829,0,0,1,1.2.329c.292-.412.528-.313.988-.313H5.856A1.412,1.412,0,0,1,7.134.652V4.737c-1.093.3-1.674,1.791-1.968,2.773V8.782Z'
          transform='translate(0)'
          fillRule='evenodd'
        />
      </g>
    </svg>
  )
}

export default ThumbDownFillSvg
