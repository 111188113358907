import hexToRgb from './hexToRgb'

const textColorByBackgroundHexColor = backgroundHexColor => {
  const backgroundRGBColor = hexToRgb(backgroundHexColor)
  return backgroundRGBColor.red * 0.299 + backgroundRGBColor.green * 0.587 + backgroundRGBColor.blue * 0.114 > 186
    ? '#231f20'
    : '#fcfcfc'
}

export default textColorByBackgroundHexColor
