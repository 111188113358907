import serviceCall from '../../services'
import faqMapper from '../../services/mapper/faq/faqMapper'

const getFaqUseCase = async ({ nativeLanguage }) => {
  const result = await serviceCall('fetchFaqList', { nativeLanguage }, 'cloud')

  return faqMapper(result)
}

export default getFaqUseCase
