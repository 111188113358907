const WifiSvg = ({ color }) => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width={15} height={10} viewBox='0 0 15 10' fill='none'>
        <path
          fill={color}
          fillRule='evenodd'
          d='M13.983 2.523A10.513 10.513 0 0 0 7.133 0C4.6 0 2.18.896.266 2.537l-.162.14a.297.297 0 0 0-.017.435l1.127 1.124c.11.109.284.116.403.017l.14-.118A8.33 8.33 0 0 1 7.132 2.18a8.33 8.33 0 0 1 5.361 1.943l.14.117a.298.298 0 0 0 .402-.018l1.127-1.124a.296.296 0 0 0-.017-.436l-.163-.14Zm-6.85.947a7.04 7.04 0 0 1 4.362 1.505l.176.138c.14.11.153.318.026.443l-1.13 1.128a.299.299 0 0 1-.389.028l-.138-.102a4.858 4.858 0 0 0-2.907-.96c-1.06 0-2.08.34-2.923.971l-.138.103a.299.299 0 0 1-.39-.027L2.553 5.57a.297.297 0 0 1 .026-.443l.175-.138a7.04 7.04 0 0 1 4.38-1.52Zm0 3.47c.64 0 1.26.168 1.808.488l.222.13a.297.297 0 0 1 .06.466L7.329 9.913a.299.299 0 0 1-.422 0L5.026 8.036a.297.297 0 0 1 .058-.465l.219-.13a3.571 3.571 0 0 1 1.83-.501Z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
export default WifiSvg
