const SolidLockSvg = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='7.952' height='10.438' viewBox='0 0 7.952 10.438'>
      <path
        d='M10.958,4.479h-.5V3.485a2.485,2.485,0,1,0-4.97,0v.994h-.5A1,1,0,0,0,4,5.473v4.97a1,1,0,0,0,.994.994h5.964a1,1,0,0,0,.994-.994V5.473A1,1,0,0,0,10.958,4.479ZM7.976,7.958c-.547,0,0,.547,0,0s-.547,0,0,0ZM9.517,4.479H6.435V3.485a1.541,1.541,0,1,1,3.082,0Z'
        transform='translate(-4 -1)'
        fill={color}
      />
    </svg>
  )
}

export default SolidLockSvg
