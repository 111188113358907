import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { orderBy } from 'lodash'

const useFirstTimeActions = () => {
  const { firstTimeActions } = useSelector(state => state.firstTimeAction)

  const currentFirstTimeAction = useMemo(() => {
    let remainingActions = firstTimeActions?.filter(it => !it.isFinished)
    remainingActions = orderBy(remainingActions || [], 'priority')
    return remainingActions?.[0]?.key
  }, [JSON.stringify(firstTimeActions)])

  return { currentFirstTimeAction }
}

export default useFirstTimeActions
