import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import searchFlashCardUseCase from '../../../useCase/memoryBoxUseCase/searchFlashCardUseCase'
import studyFlashCardUseCase from '../../../useCase/memoryBoxUseCase/studyFlashCardUseCase'

const initialState = {
  loading: false,
  response: null,
  flashCardsData: [],
  currentCard: null,
  currentIndex: 0,
  total: 0,
  error: ''
}

export const searchFlashCard = createAsyncThunk('memoryBoxFlashCard/searchFlashCard', searchCriteria => {
  return searchFlashCardUseCase(searchCriteria)
})

export const studyFlashCard = createAsyncThunk('memoryBoxFlashCard/studyFlashCard', ({ id, success }) => {
  return studyFlashCardUseCase({ id, success })
})

const flashCardSlice = createSlice({
  name: 'memoryBoxFlashCard',
  initialState,
  reducers: {
    clearFlashCardStates: (state, action) => {
      state.loading = false
      state.response = null
      state.flashCardsData = []
      state.currentCard = null
      state.currentIndex = 0
      state.total = 0
      state.error = ''
    }
  },
  extraReducers: builder => {
    //search flashcard
    builder.addCase(searchFlashCard.pending, state => {
      state.loading = true
    })
    builder.addCase(searchFlashCard.fulfilled, (state, action) => {
      state.loading = false
      state.response = action.payload
      state.flashCardsData = state.response?.results
      state.currentIndex = 0
      state.currentCard = state.response?.results?.[state.currentIndex]
      state.total = state.response?.total
    })
    builder.addCase(searchFlashCard.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //study flashcard
    builder.addCase(studyFlashCard.pending, state => {
      state.loading = true
    })
    builder.addCase(studyFlashCard.fulfilled, (state, action) => {
      state.loading = false
      if (state.total >= state.currentIndex + 1) {
        state.currentIndex = state.currentIndex + 1
        state.currentCard = state.response?.results?.[state.currentIndex]
      }
    })
    builder.addCase(studyFlashCard.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { clearFlashCardStates } = flashCardSlice.actions

export default flashCardSlice.reducer
