import moment from 'moment'

const settingNotificationMapper = data => {
  return {
    fromTime: moment(data?.fromTime).hour() + ':' + moment(data?.fromTime).minute(),
    toTime: moment(data?.toTime).hour() + ':' + moment(data?.toTime).minute(),
    appBadges: data?.appBadges,
    floating: data?.floating,
    lockScreen: data?.lockScreen,
    allowAllTheTime: data?.allowAllTheTime ?? true,
    allowSound: data?.allowSound ?? true,
    allowVibration: data?.allowVibration ?? false,
    newUnitAvailable: data?.newUnitAvailable ?? true,
    incompleteUnit: data?.incompleteUnit ?? false,
    newUnitOverdue: data?.newUnitOverdue ?? false,
    reviewAvailable: data?.reviewAvailable ?? true,
    reviewCountdown: data?.reviewCountdown ?? false,
    reviewOverdue: data?.reviewOverdue ?? false,
    repetitionIncompleteExercise: data?.repetitionIncompleteExercise ?? true,
    repetitionCountdown: data?.repetitionCountdown ?? false,
    exerciseAvailable: data?.exerciseAvailable ?? true,
    repetitionOverdue: data?.repetitionOverdue ?? false,
    cardAvailable: data?.cardAvailable ?? true,
    memoryBoxOverdue: data?.memoryBoxOverdue ?? false,
    acceptedRequests: data?.acceptedRequests ?? false,
    incomingRequests: data?.incomingRequests ?? true,
    vocabMaster: data?.vocabMaster ?? false,
    accountRenewal: data?.accountRenewal ?? true,
    festival: data?.festival ?? false
  }
}
export default settingNotificationMapper
