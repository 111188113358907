import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const checkDragAndDropConversationMapper = (stackId, response, data) => {
  return {
    ...data,
    // checked: true,
    checkable: false,
    stack: {
      ...data.stack,
      items: [...data.stack.items].map(item => {
        const responseItem = response.find(obj => obj.exerciseItemId === item.id)
        if (responseItem) {
          const userAnswerStatus = responseItem.userAnswerItemResponse.every(
            el => el.userAnswerStatus === AnswerStatusEnums.CORRECT
          )
            ? AnswerStatusEnums.CORRECT
            : AnswerStatusEnums.INCORRECT
          return {
            ...item,
            answerFields: [...item.answerFields].map(answerField => {
              const answerResponse = responseItem.userAnswerItemResponse.find(el => el.listIndex === answerField.index)
              if (answerResponse) {
                return {
                  ...answerField,
                  userAnswerStatus: answerResponse.userAnswerStatus
                }
              }
              return answerField
            }),
            userAnswerStatus,
            checkable: false,
            checked: true
          }
        }
        return item
      })
    }
  }
}

export default checkDragAndDropConversationMapper
