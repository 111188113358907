import * as React from 'react'

const EyeSvg = ({ color, haveLine = false }) => {
  if (haveLine) {
    return (
      <svg
        fill={color}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width={512}
        height={512}
        viewBox='0 0 512 512'
        xmlSpace='preserve'
      >
        <path d='M36.4,155.6c-8.3-8.3-21.8-8.3-30.2,0c-8.3,8.3-8.3,21.8,0,30.2l38.6,38.6c6.3,6.3,12.8,12.2,19.4,17.8 c-0.2,1-0.2,2.1-0.2,3.2v32c0,11.8,9.6,21.3,21.3,21.3s21.3-9.6,21.3-21.3v-5.5c13.8,8,28,14.7,42.7,20.3V320 c0,11.8,9.6,21.3,21.3,21.3c11.8,0,21.3-9.6,21.3-21.3v-15.1c14.1,3.1,28.3,5.1,42.7,6.1v30.3c0,11.8,9.6,21.3,21.3,21.3 s21.3-9.6,21.3-21.3V311c14.3-1,28.6-3.1,42.7-6.1V320c0,11.8,9.6,21.3,21.3,21.3c11.8,0,21.3-9.6,21.3-21.3v-27.8 c14.6-5.6,28.9-12.3,42.7-20.3v5.5c0,11.8,9.6,21.3,21.3,21.3c11.8,0,21.3-9.6,21.3-21.3v-32c0-1.1-0.1-2.2-0.2-3.2 c6.7-5.6,13.2-11.5,19.4-17.8l38.6-38.6c8.3-8.3,8.3-21.8,0-30.2c-8.3-8.3-21.8-8.3-30.2,0L437,194.1c-100,100-262.1,100-362,0 L36.4,155.6z' />
      </svg>
    )
  } else {
    return (
      <svg
        fill={color}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        width={512}
        height={512}
        viewBox='0 0 512 512'
        xmlSpace='preserve'
      >
        <path d='M256,416C88.5,416,5.5,270,2,263.8c-2.7-4.8-2.7-10.7,0-15.5C5.5,242,88.5,96,256,96s250.5,146,254,152.2 c2.7,4.8,2.7,10.7,0,15.5C506.5,270,423.5,416,256,416z M34.8,256c19,28.8,93.8,128,221.2,128c127.8,0,202.2-99.1,221.2-128 c-19-28.8-93.8-128-221.2-128C128.2,128,53.8,227.1,34.8,256L34.8,256z M256,352c-52.9,0-96-43.1-96-96s43.1-96,96-96s96,43.1,96,96 S308.9,352,256,352z M256,192c-35.3,0-64,28.7-64,64s28.7,64,64,64s64-28.7,64-64S291.3,192,256,192z' />
      </svg>
    )
  }
}

export default EyeSvg
