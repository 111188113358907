const searchFaqUseCase = (searchTerm, faqData) => {
  if (!Array.isArray(faqData)) {
    return faqData // Return as is if faqData is not an array
  }

  if (!searchTerm) {
    return faqData // If searchTerm is empty, return all FAQ data
  }

  const lowercasedTerm = searchTerm.toLowerCase()

  return faqData.filter(
    item => item.question.toLowerCase().includes(lowercasedTerm) || item.answer.toLowerCase().includes(lowercasedTerm)
  )
}

export default searchFaqUseCase
