import React, { useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import colorsContext from '../../contexts/ColorsContext'
import componentStyle from './PageLayer.module.scss'
import OverlayScrollbar from '../../layouts/OverlayScrollbar'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'

const PageLayer = ({ close, children, background }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = null
    }
  }, [])

  return ReactDOM.createPortal(
    <div
      className={classNames(componentStyle.pageLayer, { [componentStyle.mobile]: isMobile })}
      style={{ background: background }}
    >
      <OverlayScrollbar>{children}</OverlayScrollbar>
    </div>,
    document.getElementById('page-layer')
  )
}

export default PageLayer
