export const inquiryActionEnums = {
  CONFIRM_MOBILE: 'INQUIRY_CONFIRM_MOBILE',
  CONFIRM_USERNAME: 'INQUIRY_CONFIRM_USERNAME',
  REQUEST_PASSWORD: 'INQUIRY_REQUEST_PASSWORD',
  DONE: 'INQUIRY_DONE'
}

export const inquiryStateEnums = {
  INQUIRY: 'INQUIRY',
  CONFIRMING_MOBILE: 'INQUIRY_CONFIRMING_MOBILE',
  CONFIRMING_USERNAME: 'INQUIRY_CONFIRMING_USERNAME',
  CONFIRMING_PASSWORD: 'INQUIRY_CONFIRMING_PASSWORD',
  INQUIRED: 'INQUIRY_INQUIRED',
  CREATED_USER: 'INQUIRY_CREATED_USER',
  CONFIRMED_MOBILE: 'INQUIRY_CONFIRMED_MOBILE',
  CONFIRMED_USERNAME: 'INQUIRY_CONFIRMED_USERNAME'
}

export const setPasswordActionEnums = {
  REQUEST_PASSWORD: 'UPDATE_PASSWORD_REQUEST_PASSWORD',
  CONFIRM_USERNAME: 'UPDATE_PASSWORD_CONFIRM_USERNAME',
  DONE: 'UPDATE_PASSWORD_DONE'
}

export const setPasswordStateEnums = {
  RE_SET_PASSWORD: 'UPDATE_PASSWORD_RE_SET_PASSWORD',
  SET_PASSWORD: 'UPDATE_PASSWORD_SET_PASSWORD',
  CONFIRMING_USERNAME: 'UPDATE_PASSWORD_CONFIRMING_USERNAME',
  IN_PROGRESS: 'UPDATE_PASSWORD_IN_PROGRESS',
  CONFIRMED_USERNAME: 'UPDATE_PASSWORD_CONFIRMED_USERNAME'
}

export const deleteUserActionEnums = {
  REQUEST_PASSWORD: 'DELETE_USER_REQUEST_PASSWORD',
  CONFIRM_USERNAME: 'DELETE_USER_CONFIRM_USERNAME',
  LOGOUT: 'DELETE_USER_LOGOUT'
}

export const deleteUserStateEnums = {
  DELETING: 'DELETE_USER_DELETING',
  CONFIRMING_USERNAME: 'DELETE_USER_CONFIRMING_USERNAME',
  IN_PROGRESS: 'DELETE_USER_IN_PROGRESS',
  CONFIRMED_USERNAME: 'DELETE_USER_CONFIRMED_USERNAME'
}

export const authenticationTypeEnums = {
  MOBILE: 'MOBILE',
  EMAIL: 'EMAIL'
}

export const resendCodeFlowTypeEnums = {
  INQUIRY_USER: 'INQUIRY_USER',
  RE_SET_USER_PASSWORD: 'RE_SET_USER_PASSWORD',
  SET_USER_PASSWORD: 'SET_USER_PASSWORD',
  DELETE_USER: 'DELETE_USER'
}
