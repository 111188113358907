import { useEffect, useRef, useState } from 'react'
import { setSpeechRecognitionType } from '../../redux/features/speechToTextSlice'
import { useDispatch } from 'react-redux'
import { speechToTextTypeEnums } from '../../enums/speechToTextEnums/speechToTextEnums'
import * as Sentry from '@sentry/react'

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
// const SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognition

const useWebSpeechApi = () => {
  const [speechResult, setSpeechResult] = useState(null)
  const [recording, setRecording] = useState(false)
  const [recordFinished, setRecordFinished] = useState(false)
  const [failedStartRecording, setFailedStartRecording] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()

  const speechRecognition = useRef(null)

  useEffect(() => {
    return () => {
      resetRecording()
    }
  }, [])

  const onEnded = () => {
    setRecording(false)
    setRecordFinished(true)
  }

  const onStarted = () => {
    setRecording(true)
  }

  const onError = event => {
    if (event.error === 'not-allowed' || event.error === 'service-not-allowed') {
      dispatch(setSpeechRecognitionType(speechToTextTypeEnums.whisper))
      setFailedStartRecording(true)
    } else {
      Sentry.captureMessage('webSpeechApi error', {
        level: 'error',
        extra: { error: typeof e === 'object' ? JSON.stringify(event) : event, errorMessage: event?.error }
      })
    }
  }

  useEffect(() => {
    return () => {
      if (speechRecognition?.current) {
        speechRecognition.current.removeEventListener('start', onStarted)
        speechRecognition.current.removeEventListener('end', onEnded)
      }
    }
  }, [speechRecognition])

  const resetRecording = () => {
    speechRecognition?.current?.stop()
    setSpeechResult(null)
    setRecording(false)
    setRecordFinished(false)
  }

  const startRecording = async () => {
    try {
      const recognition = new SpeechRecognition()

      recognition.continuous = false
      recognition.lang = 'en-US'
      recognition.interimResults = true
      recognition.maxAlternatives = 1

      speechRecognition.current = recognition

      // Remove previous event listeners
      speechRecognition.current.removeEventListener('start', onStarted)
      speechRecognition.current.removeEventListener('end', onEnded)
      speechRecognition.current.removeEventListener('error', onError)
      // Add new event listeners
      speechRecognition.current.addEventListener('start', onStarted)
      speechRecognition.current.addEventListener('end', onEnded)
      speechRecognition.current.addEventListener('error', onError)

      speechRecognition.current.onresult = event => {
        let text = event.results[0][0].transcript
        setSpeechResult(text)
      }

      resetRecording()
      recognition.start()
    } catch (e) {
      setError('Error: Web Speech API is not available!')
    }
  }

  const finishRecording = () => {
    speechRecognition?.current?.stop()
  }

  return {
    webSpeechRecording: recording,
    webSpeechResult: speechResult,
    webSpeechRecordFinished: recordFinished,
    webSpeechNotSupported: !SpeechRecognition,
    webSpeechError: error,
    webSpeechStartRecording: startRecording,
    webSpeechFinishRecording: finishRecording,
    webSpeechResetRecording: resetRecording,
    webSpeechFailed: failedStartRecording
  }
}

export default useWebSpeechApi
