import serviceCall from '../../services'
import tedVideoMapper from '../../services/mapper/ted/tedVideoMapper'

const tedVideoUseCase = async () => {
  const result = await serviceCall('fetchTedVideos', null, 'cloud')

  return result.map(video => {
    return tedVideoMapper(video)
  })
}

export default tedVideoUseCase
