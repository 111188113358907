import { useDispatch, useSelector } from 'react-redux'
import PurchaseRequestModal from './PurchaseRequestModal/PurchaseRequestModal'
import { setCurrentOpenModal } from '../../redux/features/modalSlice'
import { globalModalEnums } from '../../enums/globalEnums/globalEnums'
import AddToHomeScreenGuide from '../../atomicComponents/templates/AddToHomeScreenGuide/AddToHomeScreenGuide'

const GlobalModals = () => {
  const { currentOpenModal } = useSelector(state => state.modal)
  const dispatch = useDispatch()

  const renderModal = () => {
    if (currentOpenModal === globalModalEnums.purchaseRequest) {
      return <PurchaseRequestModal closeModal={() => dispatch(setCurrentOpenModal(null))} />
    } else if (currentOpenModal === globalModalEnums.addToHomeScreenGuide) {
      return <AddToHomeScreenGuide />
    } else {
      return null
    }
  }

  return <>{renderModal()}</>
}

export default GlobalModals
