import React from 'react'
import componentStyle from './LevelNumberBox.module.scss'
import classNames from 'classnames'

const LevelNumberBox = ({ children, color, className, withBorder }) => {
  return (
    <div
      className={classNames(componentStyle.levelNumberBox, className, { [componentStyle.withBorder]: withBorder })}
      style={{
        borderColor: color,
        color: color
      }}
    >
      {children}
    </div>
  )
}

export default LevelNumberBox
