import * as React from 'react'

const HelpSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={512}
    height={512}
    viewBox='0 0 512 512'
  >
    <circle cx={255.5} cy={378.5} r={25} />
    <path d='M255.5 0C114 0-.5 114.5-.5 256s114.5 256 256 256 256-114.5 256-256S397.1 0 255.5 0zm0 471.9c-119.4 0-215.9-96.6-215.9-215.9S136.1 40.1 255.5 40.1 471.4 136.6 471.4 256c.2 119.4-96.5 215.9-215.9 215.9z' />
    <path d='M255.5 128.5c-44.1 0-80 35.9-80 80 0 11.1 9 20 20 20 11.1 0 20-9 20-20 0-22.1 18-39.9 39.9-39.9s39.9 18 39.9 39.9-18 39.9-39.9 39.9c-11.1 0-20 9-20 20v50.1c0 11.1 9 20 20 20 11.1 0 20-9 20-20v-32.6c34.4-8.9 60-40.3 60-77.5.1-44-35.7-79.9-79.9-79.9z' />
  </svg>
)

export default HelpSvg
