import * as React from 'react'

const AbcSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' xmlSpace='preserve'>
    <path fill={color} d='M22.32 13.29h3.91l-1.95-5.46z' />
    <path
      fill={color}
      d='M32.73.83H16.09a2.69 2.69 0 0 0-2.69 2.69v16.64c0 1.48 1.2 2.69 2.69 2.69h16.64c1.48 0 2.69-1.2 2.69-2.69V3.51c0-1.48-1.2-2.68-2.69-2.68zM30.11 18.3c-.25.25-.56.38-.93.38-.21 0-.4-.04-.55-.12-.15-.08-.28-.18-.38-.31-.1-.13-.21-.33-.31-.58-.11-.25-.21-.49-.32-.71l-.64-1.66h-5.36l-.64 1.69c-.25.66-.47 1.11-.64 1.35-.18.23-.46.35-.86.35-.33 0-.63-.13-.89-.38s-.4-.53-.4-.84c0-.18.03-.36.1-.55.07-.19.17-.46.3-.8l3.39-8.61c.07-.23.18-.53.33-.88.14-.35.29-.64.45-.88.16-.24.36-.42.61-.56s.55-.21.9-.21c.39 0 .71.07.96.21.25.14.44.32.59.56.15.23.28.48.39.73.11.25.25.59.42 1l3.43 8.57c.28.65.41 1.12.41 1.4.01.31-.11.6-.36.85zM12.2 34.79c.37-.13.65-.37.84-.71.15-.26.23-.53.23-.83 0-.68-.24-1.13-.72-1.35-.48-.23-1.21-.34-2.18-.34H8.21v3.43h2.45c.66 0 1.17-.07 1.54-.2zM10.99 36.9H8.21v3.87h2.87c1.81 0 2.72-.65 2.72-1.96 0-.68-.24-1.16-.71-1.46-.48-.3-1.18-.45-2.1-.45z'
    />
    <path
      fill={color}
      d='M19.33 25.16H2.69C1.2 25.16 0 26.36 0 27.85v16.64c0 1.48 1.2 2.69 2.69 2.69h16.64c1.48 0 2.69-1.2 2.69-2.69V27.85a2.697 2.697 0 0 0-2.69-2.69zm-3.38 15.85c-.38.59-.89 1.03-1.54 1.31-.41.18-.87.3-1.39.36-.52.06-1.13.1-1.83.1H7.14c-.58 0-1-.13-1.26-.4-.25-.27-.38-.69-.38-1.27v-9.92c0-.58.13-1 .38-1.26.25-.25.67-.38 1.26-.38h4.28c.64 0 1.19.04 1.65.11.46.07.88.22 1.24.45.32.18.6.41.84.7s.43.61.56.96c.13.35.2.72.2 1.1 0 1.32-.66 2.28-1.98 2.89 1.73.57 2.59 1.64 2.59 3.22 0 .76-.19 1.44-.57 2.03zM45.31 25.16H28.67a2.69 2.69 0 0 0-2.69 2.69v16.64c0 1.48 1.2 2.69 2.69 2.69h16.64c1.48 0 2.69-1.2 2.69-2.69V27.85c0-1.49-1.2-2.69-2.69-2.69zm-2.74 14.45c-.21.49-.54.97-.98 1.44s-1.01.86-1.7 1.16c-.69.29-1.5.44-2.43.44-.69 0-1.33-.07-1.9-.2-.57-.14-1.09-.34-1.55-.62-.46-.28-.9-.64-1.29-1.09-.35-.41-.65-.88-.89-1.41-.24-.53-.42-1.08-.54-1.66-.12-.58-.18-1.2-.18-1.86 0-1.04.16-1.99.47-2.84.31-.85.76-1.57 1.33-2.17.57-.6 1.25-1.05 2.02-1.37a6.51 6.51 0 0 1 2.5-.48c1.06 0 2.01.21 2.84.64.83.43 1.47.95 1.92 1.58.45.63.67 1.23.67 1.8 0 .3-.11.57-.32.81-.21.24-.48.36-.8.36-.33 0-.58-.08-.75-.25-.18-.17-.37-.45-.59-.83-.34-.65-.74-1.14-1.22-1.47s-1.05-.5-1.74-.5c-1.11 0-1.99.42-2.64 1.25-.65.83-.98 2.03-.98 3.61 0 1.01.14 1.87.42 2.56.28.69.69 1.2 1.23 1.53.53.33 1.16.5 1.88.5.76 0 1.41-.19 1.94-.58.53-.38.94-.94 1.21-1.67.11-.37.25-.67.42-.88.17-.21.44-.32.8-.32.33 0 .6.11.83.33.23.22.35.5.35.85-.01.4-.12.86-.33 1.34z'
    />
  </svg>
)

export default AbcSvg
