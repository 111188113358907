import React, { useContext } from 'react'
import PlayButtonPrimaryAudio from '../../../components/PlayButtonPrimary/PlayButtonPrimaryAudio'
import colorsContext from '../../../contexts/ColorsContext'
import exerciseStyles from './../ExerciseModules.module.scss'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { useDispatch } from 'react-redux'
import { onClickOptionCFC } from '../../../redux/features/exerciseSlice'
import makeUrl from '../../../operations/makeUrl'
import { moreOptionsList } from '../../../data'
import classNames from 'classnames'
import Instruction from '../../../components/Instruction/Instruction'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const ChooseFromCategoryModule = ({ data }) => {
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()

  const onClickOption = i => {
    if (!data.checked) {
      dispatch(onClickOptionCFC({ index: i }))
    }
  }

  {
    /* TODO: Import the function from getUserStatusColor.js */
  }
  const getUserStatusColor = (status, unlocked = false) => {
    switch (status) {
      case AnswerStatusEnums.CORRECT:
        return 'C30'
      case AnswerStatusEnums.INCORRECT:
        return 'C29'
      case AnswerStatusEnums.SKIPPED:
        return 'C23'
      case AnswerStatusEnums.OVERLOOKED:
        return 'C23'
      default:
        return 'C2'
    }
  }

  const renderChoices = () => {
    return data.stack.choices.map((choice, i) => {
      if (data.checked) {
        if (choice.isAnswer) {
          return (
            <div
              className={classNames(exerciseStyles.categoryBox, exerciseStyles.checked)}
              style={{
                borderColor: choice.selected ? colors['C30'] : colors['C29'],
                background: choice.selected ? colors['C516'] : 'transparent'
              }}
              key={i}
            >
              <HtmlTextWrapper
                showPhraseOptions
                moreOptionsList={moreOptionsList}
                optionsColor={choice.selected ? colors['C233'] : colors['C23']}
                data={choice.text}
                textColor={colors[getUserStatusColor(choice.userAnswerStatus)]}
              />
            </div>
          )
        } else {
          return (
            <div
              className={classNames(exerciseStyles.categoryBox, { [exerciseStyles.checked]: choice.selected })}
              style={{
                borderColor: choice.selected ? colors['C29'] : colors['C187'],
                color: choice.selected ? colors['C29'] : colors['C23'],
                background: choice.selected ? colors['C516'] : 'transparent'
              }}
              key={i}
            >
              <HtmlTextWrapper
                showPhraseOptions
                moreOptionsList={moreOptionsList}
                optionsColor={colors['C23']}
                data={choice.text}
                textColor={choice.selected ? colors['C29'] : colors['C23']}
              />
            </div>
          )
        }
      } else {
        return (
          <button
            className={exerciseStyles.categoryBox}
            style={{
              borderColor: choice.selected ? colors['C535'] : colors['C187'],
              color: choice.selected ? colors['C2'] : colors['C23'],
              background: choice.selected ? colors['C535'] : 'transparent'
            }}
            onClick={() => onClickOption(choice.index)}
            key={i}
          >
            {choice.text.sentenceString}
          </button>
        )
      }
    })
  }

  return (
    <>
      {data.stack.title && <Instruction data={data.stack.title} />}
      <div className={exerciseStyles.chooseFromCategoryContainer}>
        <div className={exerciseStyles.playButtonContainer}>
          <PlayButtonPrimaryAudio
            audioUrl={makeUrl(data.stack.given.voiceUri)}
            bgColor={colors['C159']}
            iconColor={colors['C160']}
          />
        </div>
        <div className={exerciseStyles.categoriesBoxContainer}>
          <div className='container-fluid'>
            <div className={exerciseStyles.exerciseCard} style={{ background: colors['C527'] }}>
              {renderChoices()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseFromCategoryModule
