import serviceCall from '../../services'

const subscriptionPayPalUseCase = async ({ asRenewal, discountCode, planId, psp, purchaseType }) => {
  const result = await serviceCall(
    'subscriptionPayPalPlan',
    { asRenewal, discountCode, planId, psp, purchaseType },
    'cloud'
  )

  return result
}

export default subscriptionPayPalUseCase
