const fetchConversationMapper = response => {
  if (response.status !== 200) {
    return response
  }

  let characters = []

  for (const [key, value] of Object.entries(response.data.characterDialogs.characterIndexMap)) {
    characters.push({
      name: key,
      pictureUri: value[Object.keys(value)[0]][0].pictureUri
    })
  }

  return {
    data: {
      ...response.data,
      characters
    },
    status: response.status
  }
}

export default fetchConversationMapper
