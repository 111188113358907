import AudioSpeakerButton from './AudioSpeakerButton'
import useAudioPlaylist from '../../hooks/audio/useAudioPlaylist'
import { useEffect } from 'react'

const AudioSpeakerButtonPlaylist = ({
  playlistUrls,
  playLimit,
  playing,
  handlePlaying,
  setCurrentIndex,
  color,
  className,
  disabled
}) => {
  const { currentIndex } = useAudioPlaylist({
    playlistUrls,
    playLimit,
    playing,
    handlePlaying
  })

  useEffect(() => {
    if (setCurrentIndex) {
      setCurrentIndex(currentIndex)
    }
  }, [currentIndex])

  return (
    <AudioSpeakerButton
      disabled={disabled}
      playing={playing}
      setPlaying={handlePlaying}
      color={color}
      className={className}
    />
  )
}

export default AudioSpeakerButtonPlaylist
