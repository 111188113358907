import React, { useContext } from 'react'
import ExerciseFullTexts from '../../Components/ExerciseFullTexts'
import removeWrapperTag from '../../../../operations/removeWrapperTag'
import reactStringReplace from 'react-string-replace'
import { onChangeInputTTA } from '../../../../redux/features/exerciseSlice'
import numberIsOdd from '../../../../operations/numberIsOdd'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import htmlToText from '../../../../operations/htmlToText'
import colorsContext from '../../../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import InlineInputField from '../../../../components/InlineInputField/InlineInputField'
import renderUserAnswer from './renderUserAnswer'
import exerciseStyles from '../../ExerciseModules.module.scss'
import showFullTextAnswer from './getShowFullTextAnswer'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const TypeTheAnswerGivenTextTemplateInline = ({ stackIndex, itemIndex, exerciseItem }) => {
  const { colors } = useContext(colorsContext)
  const { checkingItem } = useSelector(state => state.exercise)
  const dispatch = useDispatch()

  const showFullText = showFullTextAnswer(
    exerciseItem.checked,
    exerciseItem.userAnswer.hasAlsoCorrect,
    exerciseItem.userAnswer.userAnswerStatus,
    exerciseItem.unlocked
  )
  const showFirstFullText =
    exerciseItem.checked &&
    exerciseItem.userAnswer.userAnswerStatus === AnswerStatusEnums.CORRECT &&
    exerciseItem.userAnswer.hasAlsoCorrect

  if (showFullText) {
    const userAnswerStatusList = exerciseItem.userAnswer.userInputText.map(field => {
      return field.userAnswerData.userAnswerStatus
    })
    return (
      <ExerciseFullTexts
        unlocked={exerciseItem.unlocked}
        fullTexts={exerciseItem.fullTextAnswers}
        userAnswerStatus={userAnswerStatusList}
        template='INPUT'
        textColor={colors['C233']}
        inputColorCode={'MC3'}
      />
    )
  } else if (showFirstFullText) {
    const userAnswerStatusList = exerciseItem.userAnswer.userInputText.map(field => {
      return field.userAnswerData.userAnswerStatus
    })
    return (
      <ExerciseFullTexts
        unlocked={exerciseItem.unlocked}
        fullTexts={exerciseItem.fullTextAnswers?.slice(0, 1) || []}
        userAnswerStatus={userAnswerStatusList}
        template='INPUT'
        textColor={colors['C233']}
        inputColorCode={'MC3'}
      />
    )
  }

  const renderGivenTextParagraph = (paragraph, pIndex) => {
    if (paragraph.containsField) {
      const cleanParagraph = removeWrapperTag(paragraph.string, 'p')
      const arr = reactStringReplace(cleanParagraph, '###', (match, index) => {
        const choicesIndex = paragraph.fieldIndexStartsFrom + (index - 1) / 2
        const userAnswerData = exerciseItem.userAnswer.userInputText?.[choicesIndex]?.userAnswerData

        const handleChange = e => {
          if (!checkingItem) {
            dispatch(
              onChangeInputTTA({
                value: e.currentTarget.textContent,
                stackIndex,
                itemIndex,
                choicesIndex
              })
            )
          }
        }

        if (exerciseItem.checked) {
          return (
            <InlineInputField
              value={
                <HtmlTextWrapper
                  style={{ display: 'inline' }}
                  textColor={colors['C233']}
                  data={{ sentence: renderUserAnswer(userAnswerData, 'C233') }}
                />
              }
              inputColorCode={'MC3'}
              status={userAnswerData?.userAnswerStatus}
              key={index}
            />
          )
        }

        return (
          <>
            <div
              key={choicesIndex}
              contentEditable={true}
              onKeyPress={e => {
                return e.key === 'Enter' ? e.preventDefault() : true
              }}
              className={exerciseStyles.middleInput}
              onInput={handleChange}
              style={{ borderColor: colors['C222'], color: colors['C23'] }}
            />
          </>
        )
      })
      /*
       * Example of arr:
       * ['<a href="text">Hello</a>', myCustomComponent, '.']
       * myCustomComponent is located in odd indexes (1, 3...)
       * */
      return arr.map((el, i) => (
        <React.Fragment key={i}>
          {numberIsOdd(i) ? (
            el
          ) : (
            <HtmlTextWrapper
              textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
              optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
              data={{
                ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
                sentence: el
              }}
              phraseOptionsDisabled
              showPhraseOptions={exerciseItem.checked && i === 0 && !exerciseItem.userAnswer.hasAlsoCorrect}
              style={{ display: 'inline' }}
            />
          )}
        </React.Fragment>
      ))
    } else {
      return (
        <HtmlTextWrapper
          textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
          optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
          data={{
            ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
            sentence: htmlToText(paragraph.string)
          }}
          phraseOptionsDisabled
          showPhraseOptions={exerciseItem.checked}
          style={{ display: 'inline' }}
        />
      )
    }
  }

  const givenText = exerciseItem.given.text
  if (givenText) {
    return givenText.paragraphList.map((paragraph, pIndex) => {
      return (
        <div className='exercise-paragraph' style={{ ...paragraph.paragraphStyle }} key={pIndex}>
          {renderGivenTextParagraph(paragraph, pIndex)}
          {givenText.paragraphList.length === pIndex + 1 && !exerciseItem.checked && (
            <span style={{ color: colors['C23'] }}> {exerciseItem.additionalData}</span>
          )}
        </div>
      )
    })
  }
}

export default TypeTheAnswerGivenTextTemplateInline
