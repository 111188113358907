import { htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { shuffle } from 'lodash'

const fetchPutInOrderMapper = data => {
  const randomizeChoices = items => {
    let newItems = shuffle(items)
    let repeated = 0
    while (items.every((o, i) => o.text.sentence === newItems[i].text.sentence) && repeated < 5) {
      newItems = shuffle(items)
      repeated++
    }
    return newItems
  }

  return {
    pageType: pageGuidEnums.PUT_IN_ORDER_EXERCISE,
    data: {
      immediateCheck: true,
      paginable: true,
      type: 'PUT_IN_ORDER',
      title: data.title ?? null,
      stacks: [...data.exerciseStacks].map(stack => {
        return {
          id: stack.id,
          title: stack.title ?? null,
          joined: stack.joined,
          items: [...stack.items].map(item => {
            const given = item.data.givens[0]

            const inputIsInsideGiven = !!given.text?.sentence?.includes('###')

            const fullTexts = [...item.extra?.fullTextAnswers]?.map(fullTextItem => {
              return htmlTextConversion(fullTextItem)
            })

            const endMark = fullTexts?.[0]?.sentenceString.slice(-1)

            return {
              id: item.id,
              enabled: false,
              unlocked: false,
              checked: false,
              userAnswer: null,
              inputIsInsideGiven,
              given: {
                ...given,
                voiceUri: given?.voiceUri ?? null,
                text: htmlTextConversion(given?.text, true)
              },
              choices: randomizeChoices([...item.data.choices?.[0]?.items])?.map(choice => {
                return {
                  ...choice,
                  selected: false
                }
              }),
              answer: {
                ...item.data.answers[0]?.items[0],
                text: htmlTextConversion(item.data.answers[0]?.items[0]?.text)
              },
              userChoices: [],
              fullTextAnswers: fullTexts,
              endMark: !inputIsInsideGiven && !!endMark.match(/^[.,:!?]/) ? endMark : ''
            }
          }),
          givens: [...stack.items[0]?.data?.givens].map(given => {
            return {
              ...given,
              text: htmlTextConversion(given.text),
              selected: false
            }
          }),
          answers: []
        }
      })
    }
  }
}

export default fetchPutInOrderMapper
