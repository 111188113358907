import { useContext, useState, useEffect, useRef } from 'react'
import componentStyle from './Switch.module.scss'
import classNames from 'classnames'
import { Spinner } from 'react-bootstrap'
import colorsContext from '../../../contexts/ColorsContext'
import TickSvg from '../../../icons/TickSvg'
import useTranslatedText from '../../../hooks/useTranslatedText' // Import your SVG here

const Switch = ({
  text,
  onChange,
  value,
  backgroundColor,
  textColor,
  className,
  pending,
  disabled,
  borderColor,
  offBackground = 'transparent',
  disableColor = 'C2'
}) => {
  const { colors } = useContext(colorsContext)
  const [isDragging, setIsDragging] = useState(false)
  const [dragValue, setDragValue] = useState(value)
  const toggleRef = useRef(null)

  const handleMouseDown = () => {
    if (!disabled && !pending) {
      setIsDragging(true)
    }
  }

  const handleMouseMove = e => {
    if (isDragging) {
      const toggleWidth = toggleRef.current.offsetWidth
      const toggleX = e.clientX - toggleRef.current.getBoundingClientRect().left
      setDragValue(toggleX > toggleWidth / 2) // Switch based on the midpoint of the toggle
    }
  }

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false)
      onChange(dragValue) // Trigger onChange with the drag state
    }
  }

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    } else {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, dragValue])

  useEffect(() => {
    setDragValue(value)
  }, [value])

  return (
    <button
      className={classNames(componentStyle.toggleOptionWrapper, { [componentStyle.pending]: pending }, className)}
      style={{ opacity: pending || disabled ? 0.5 : 1 }}
      disabled={pending || disabled}
      onMouseDown={handleMouseDown}
    >
      {text && (
        <span className={componentStyle.text} style={{ color: textColor ?? colors['C2'] }}>
          {text}
        </span>
      )}
      <div className={componentStyle.toggleContainer}>
        <div
          ref={toggleRef}
          className={classNames(componentStyle.toggleOption, { [componentStyle.active]: dragValue })}
          tabIndex={1}
          style={{
            background: dragValue ? backgroundColor ?? colors['C590'] : offBackground,
            border: dragValue
              ? borderColor ?? `2px solid ${colors['C590']}`
              : `2px solid ${colors[disableColor] + '66'}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {/* Render the circle */}
          <div
            className={classNames(componentStyle.circle, { [componentStyle.active]: dragValue })}
            style={{
              '--bg-circle-color': dragValue ? colors['C160'] : colors[disableColor] + '66',
              '--box-shadow-circle-color': colors['C590'] + '26'
            }}
          >
            {dragValue && (
              <div className={componentStyle.tickSvg}>
                <TickSvg color={colors['C233']} />
              </div>
            )}
          </div>
        </div>
        {pending && (
          <div className={classNames(componentStyle.spinner, { [componentStyle.active]: dragValue })}>
            <Spinner size='sm' />
          </div>
        )}
      </div>
    </button>
  )
}

export default Switch
