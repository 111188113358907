const RecordingSvg = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15.172' height='20.883' viewBox='0 0 15.172 20.883'>
      <g id='Group_8165' data-name='Group 8165' transform='translate(-3982.517 -373.715)'>
        <path
          id='Path_1007'
          data-name='Path 1007'
          d='M3993.875,383.945v-6.458a3.771,3.771,0,1,0-7.543,0v6.458a3.771,3.771,0,0,0,7.543,0'
          fill={color ?? '#e20613'}
        />
        <path
          id='Path_1008'
          data-name='Path 1008'
          d='M3996.8,384.332v-3.105c.383-.276.861-.689.889-1.055.045-.576-.889-1.129-.889-1.129h-1.319v5.289a5.371,5.371,0,1,1-10.742,0v-5.289h-1.321s-.945.516-.9,1.129c.029.368.513.786.9,1.061v3.1a6.7,6.7,0,0,0,5.108,6.5v2.353H3987.3V394.6h5.609v-1.414h-1.226v-2.349a6.7,6.7,0,0,0,5.117-6.5'
          fill={color ?? '#e20613'}
        />
      </g>
    </svg>
  )
}

export default RecordingSvg
