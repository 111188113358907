import { groupBy } from 'lodash'
import { getTableOfContentSectionTitleByKey } from '../../../enums/tableOfContentEnums/tableOfContentEnums'
import { v4 } from 'uuid'

const tableOfContentMapper = data => {
  const contentData = data?.englishStaticContent?.contentData

  return [...contentData]?.map(item => {
    const rowsGroup = groupBy(item.DATA, it => it.LEVEL)
    let content = []

    for (let key in rowsGroup) {
      content.push({
        key: key,
        rows: [...rowsGroup[key]].map(row => {
          return {
            unit: row['UNIT'],
            uuid: v4(),
            description: row['DESCRIPTION']
          }
        })
      })
    }

    return {
      key: item.TYPE,
      title: getTableOfContentSectionTitleByKey(item.TYPE),
      data: content
    }
  })
}

export default tableOfContentMapper
