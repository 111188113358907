import { htmlTextConversion } from '../mapperConversions'
import { shuffle } from 'lodash'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { AnswerStatusEnums, IMAGE_BUBBLE, ONE_COLUMN } from '../../../enums/globalEnums/globalEnums'
import { ContentTypesStructureEnums } from '../../../enums/structureEnums/templateType'

const fetchDragAndDropMapper = data => {
  const exerciseStack = data.exerciseStacks[0]

  let choiceIndex = 0
  let choiceList = []
  exerciseStack.items.forEach(exerciseItem => {
    if (exerciseItem.data.choices.length > 0) {
      exerciseItem.data.choices[0].items.forEach(choiceItem => {
        choiceList.push({
          ...choiceItem,
          choiceIndex,
          text: htmlTextConversion(choiceItem.text),
          itemId: exerciseItem.id,
          used: false
        })
        choiceIndex++
      })
    }
  })

  return {
    pageType: pageGuidEnums.DRAG_AND_DROP_EXERCISE,
    data: {
      immediateCheck: true,
      paginable: exerciseStack.items.length > 1,
      isSingleTemplate: true,
      hideChangeViewButton: true,
      type: data.type,
      title: data.title ?? null,
      finished: false,
      currentItemIndex: 0,
      stack: {
        ...exerciseStack,
        choices: shuffle(choiceList),
        items: [...exerciseStack.items].map(item => {
          const given = item.data.givens[0]
          return {
            id: item.id,
            type:
              item.type ??
              (given.text && given.text.sentence.includes('###')
                ? ContentTypesStructureEnums.TEXT
                : ContentTypesStructureEnums.IMAGE),
            checkable: false,
            checked: Boolean(item.isSolvedBefore),
            showLock: false,
            hasBubble: given.template === IMAGE_BUBBLE,
            isSolvedBefore: Boolean(item.isSolvedBefore),
            given: {
              text: given.text ? htmlTextConversion(given.text, true) : null,
              imageUri: given.imageUri ?? null
            },
            answerFields: choiceList
              .filter(el => el.itemId === item.id)
              .map((choice, i) => {
                return {
                  index: i,
                  itemId: item.id,
                  selected: false,
                  correctAnswer: choice,
                  userAnswer: choice.isSolvedBefore ? choice : null,
                  userAnswerStatus: choice.isSolvedBefore ? AnswerStatusEnums.CORRECT : null,
                  isSolvedBefore: choice.isSolvedBefore,
                  width: choice.template === ONE_COLUMN ? '100%' : '50%'
                }
              }),
            userAnswerStatus: null,
            fullTextAnswers: [...item.extra.fullTextAnswers].map(fullTextItem => {
              return htmlTextConversion(fullTextItem)
            }),
            unlocked: false
          }
        })
      }
    }
  }
}

export default fetchDragAndDropMapper
