export const KUALA = 'KUALA'
export const LAVENDER = 'LAVENDER'
export const AMETHYST = 'AMETHYST'
export const GLACIER = 'GLACIER'
export const OPAL = 'OPAL'
export const TANGERINE = 'TANGERINE'
export const TWILIGHT = 'TWILIGHT'
export const FOREST = 'FOREST'
export const MINT = 'MINT'
export const AURORA = 'AURORA'
export const MYSTERY = 'MYSTERY'
export const OCEAN_BREEZE = 'OCEAN_BREEZE'
export const CHERRY_BLOSSOM = 'CHERRY_BLOSSOM'
export const BUTTERFLY = 'BUTTERFLY'
export const MOONLIGHT = 'MOONLIGHT'

export const themes = [
  {
    type: KUALA,
    title: 'Koala',
    image: '/assets/images/themes/theme-koala-thumbnail.jpg'
  },
  {
    type: LAVENDER,
    title: 'Lavender',
    image: '/assets/images/themes/theme-lavender-thumbnail.jpg'
  },
  {
    type: AMETHYST,
    title: 'Amethyst',
    image: '/assets/images/themes/theme-amethyst-thumbnail.jpg'
  },
  {
    type: GLACIER,
    title: 'Glacier',
    image: '/assets/images/themes/theme-glacier-thumbnail.jpg'
  },
  {
    type: OPAL,
    title: 'Opal',
    image: '/assets/images/themes/theme-opal-thumbnail.jpg'
  },
  {
    type: TANGERINE,
    title: 'Tangerine',
    image: '/assets/images/themes/theme-tangerine-thumbnail.jpg'
  },
  {
    type: TWILIGHT,
    title: 'Twilight',
    image: '/assets/images/themes/theme-twilight-thumbnail.jpg'
  },
  {
    type: FOREST,
    title: 'Forest',
    image: '/assets/images/themes/theme-forest-thumbnail.jpg'
  },
  {
    type: MINT,
    title: 'Mint',
    image: '/assets/images/themes/theme-mint-thumbnail.jpg'
  },
  {
    type: AURORA,
    title: 'Aurora',
    image: '/assets/images/themes/theme-aurora-thumbnail.jpg'
  },
  {
    type: MYSTERY,
    title: 'Mystery',
    image: '/assets/images/themes/theme-mystery-thumbnail.jpg'
  },
  {
    type: OCEAN_BREEZE,
    title: 'Ocean Breeze',
    image: '/assets/images/themes/theme-oceanBreeze-thumbnail.jpg'
  },
  {
    type: CHERRY_BLOSSOM,
    title: 'Cherry Blossom',
    image: '/assets/images/themes/theme-cherryBlossom-thumbnail.jpg'
  },
  {
    type: BUTTERFLY,
    title: 'Butterfly',
    image: '/assets/images/themes/theme-butterfly-thumbnail.jpg'
  },
  {
    type: MOONLIGHT,
    title: 'Moonlight',
    image: '/assets/images/themes/theme-moonlight-thumbnail.jpg'
  }
]
export const getThemeByKey = key => {
  return themes.find(it => it.type === key) ?? null
}
