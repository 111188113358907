import serviceCall from '../../services'
import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'

const deleteItemsUseCase = async ({ parentFolderId, items }) => {
  items = items.map(it => {
    return {
      id: it.id,
      fileType: it.fileType
    }
  })

  const result = await serviceCall('notepadDeleteItems', { parentFolderId, items }, 'cloud')

  return filesAndFoldersMapper(result)
}

export default deleteItemsUseCase
