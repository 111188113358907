import { min } from 'lodash'
import structureBaseTitleMapper from './structureBaseTitleMapper'
import structureBaseImageMapper from './structureBaseImageMapper'

const structureGeneralTemplateMapper = ({ item, events }) => {
  return {
    ...item,
    data: {
      cards: item.data.cards.map(card => {
        return {
          ...card,
          title: card?.title ? structureBaseTitleMapper({ title: card?.title, events }) : null,
          pictureText: card?.pictureText ? structureBaseTitleMapper({ title: card?.pictureText, events }) : null,
          picture: structureBaseImageMapper({ image: card?.picture, events }),
          subjects: card.subjects.map(subject => {
            return {
              ...subject,
              texts: subject.texts.map(text => {
                return {
                  ...text,
                  text: structureBaseTitleMapper({ title: text?.text, events })
                }
              }),
              hiddenTime:
                min(
                  events?.view
                    ?.filter(it => it?.templateId === item?.id && it?.subjectId === subject?.id)
                    .map(it => parseInt(it?.startTime) || 0)
                ) || 0
            }
          }),
          hiddenTime:
            min(
              events?.view
                ?.filter(it => it?.templateId === item?.id && it?.cardId === card?.id)
                .map(it => parseInt(it?.startTime) || 0)
            ) || 0
        }
      })
    },
    hiddenTime:
      min(events?.view?.filter(it => it?.templateId === item?.id).map(it => parseInt(it?.startTime) || 0)) || 0
  }
}

export default structureGeneralTemplateMapper
