import { nanoid } from 'nanoid'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import * as CryptoJS from 'crypto-js'

const useActionRetryerWrapper = () => {
  const retryId = useMemo(() => nanoid(), []) // Generate once per component mount
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      // Cancel the retry on unmount
      dispatch({ type: 'CANCEL_RETRY', payload: { retryId } })
    }
  }, [])

  const networkLossRetryerDispatch = action => {
    const hash = CryptoJS.MD5(action.toString()).toString()
    action(`${hash}-${retryId}`)
  }

  return { networkLossRetryerDispatch }
}

export default useActionRetryerWrapper
