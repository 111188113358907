import * as React from 'react'

const BackPageSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={48} height={48} viewBox='0 0 48 48'>
    <path
      fill={color}
      d='M1.51,21.63,23.78.57A1.93,1.93,0,1,1,26.51,3.3a.46.46,0,0,0-.07.07L5.37,23.29H46.25a1.94,1.94,0,0,1,0,3.87H5.59l20.8,18.49a1.93,1.93,0,1,1-2.55,2.89h0L1.64,28.79A4.88,4.88,0,0,1,0,25.22a4.81,4.81,0,0,1,1.51-3.6Z'
    />
  </svg>
)

export default BackPageSvg
