import { createSlice } from '@reduxjs/toolkit'
import { SPELLING_SYSTEM } from '../../../../enums/toolsEnums/spellingSeparationEnums'
import spellingWordPyramidFactory from '../../../../factory/spellingWordPyramidFactory'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const initialState = {
  initialNumberOfRepetitions: 5,
  letterSeparation: SPELLING_SYSTEM,
  currentIndex: 0,
  started: false,
  activeInput: {
    lineIndex: null,
    itemIndex: null
  },
  pyramidData: null
}

const spellingWordPyramidSlice = createSlice({
  name: 'spellingWordPyramid',
  initialState,
  reducers: {
    setStarted: (state, action) => {
      state.started = action.payload
    },
    createPyramidData: (state, action) => {
      const wordsList = action.payload.data
      const type = state.letterSeparation
      const initialNOR = state.initialNumberOfRepetitions
      state.pyramidData = spellingWordPyramidFactory(wordsList, initialNOR, type)
      state.activeInput = {
        lineIndex: 0,
        itemIndex: 0
      }
    },
    setInputValue: (state, action) => {
      state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].items[action.payload.itemIndex].input =
        action.payload.value?.toLowerCase()
    },
    enableLine: (state, action) => {
      state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].isEnabled = action.payload.isEnabled
    },
    setLineStatus: (state, action) => {
      state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].status = action.payload.status
    },
    setTypeFinished: (state, action) => {
      state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].isFinished = action.payload.isFinished
    },
    setActiveInput: (state, action) => {
      state.activeInput = {
        lineIndex: action.payload.lineIndex,
        itemIndex: action.payload.itemIndex
      }
    },
    clearLineInputs: (state, action) => {
      state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].items = [
        ...state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].items
      ].map(item => {
        return {
          ...item,
          input: ''
        }
      })
    },
    increaseInitialNumberOfRepetitions: state => {
      if (state.initialNumberOfRepetitions < 999) {
        ++state.initialNumberOfRepetitions
        state.pyramidData = [...state.pyramidData].map(word => {
          return {
            ...word,
            lines: [...word.lines].map(line => {
              return {
                ...line,
                numberOfRepetitions: state.initialNumberOfRepetitions
              }
            })
          }
        })
      }
    },
    decreaseInitialNumberOfRepetitions: state => {
      if (state.initialNumberOfRepetitions > 1) {
        --state.initialNumberOfRepetitions
        state.pyramidData = [...state.pyramidData].map(word => {
          return {
            ...word,
            lines: [...word.lines].map(line => {
              return {
                ...line,
                numberOfRepetitions: state.initialNumberOfRepetitions
              }
            })
          }
        })
      }
    },
    increaseNumberOfRepetitions: (state, action) => {
      const numberOfRepetitionOfCurrentLine =
        state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].numberOfRepetitions
      if (numberOfRepetitionOfCurrentLine < 999) {
        if (action.payload.setToAll) {
          state.initialNumberOfRepetitions = numberOfRepetitionOfCurrentLine + 1
          state.pyramidData = [...state.pyramidData].map(word => {
            return {
              lines: [...word.lines].map(line => {
                return {
                  ...line,
                  numberOfRepetitions: numberOfRepetitionOfCurrentLine + 1
                }
              })
            }
          })
        } else {
          ++state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].numberOfRepetitions
        }
      }
    },
    decreaseNumberOfRepetitions: (state, action) => {
      const numberOfRepetitionOfCurrentLine =
        state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].numberOfRepetitions
      if (numberOfRepetitionOfCurrentLine > 0) {
        if (action.payload.setToAll) {
          state.initialNumberOfRepetitions = numberOfRepetitionOfCurrentLine - 1
          state.pyramidData = [...state.pyramidData].map(word => {
            return {
              lines: [...word.lines].map(line => {
                return {
                  ...line,
                  numberOfRepetitions: numberOfRepetitionOfCurrentLine - 1
                }
              })
            }
          })
        } else {
          --state.pyramidData[state.currentIndex].lines[action.payload.lineIndex].numberOfRepetitions
        }
      }
    },
    setLetterSeparation: (state, action) => {
      if (action.payload === state.letterSeparation) {
        state.letterSeparation = SPELLING_SYSTEM
      } else {
        state.letterSeparation = action.payload
      }
    },
    resetNumberOfRepetitions: state => {
      state.pyramidData[state.currentIndex].lines[state.activeInput.lineIndex].numberOfRepetitions =
        state.initialNumberOfRepetitions
    },
    resetPyramidLine: (state, action) => {
      state.pyramidData[state.currentIndex].lines = [...state.pyramidData[state.currentIndex].lines].map(line => {
        if (line.index >= action.payload.lineIndex) {
          return {
            ...line,
            numberOfRepetitions: state.initialNumberOfRepetitions,
            isFinished: false,
            isEnabled: action.payload.lineIndex === line.index,
            status: AnswerStatusEnums.NONE,
            items: [...line.items].map(item => {
              return {
                ...item,
                input: ''
              }
            })
          }
        }
        return line
      })
    },
    increaseCurrentIndex: state => {
      if (state.currentIndex + 1 < state.pyramidData.length) {
        ++state.currentIndex
      }
    },
    decreaseCurrentIndex: state => {
      if (state.currentIndex > 0) {
        --state.currentIndex
      }
    },
    clearSpellingWordPyramidStates: state => {
      state.initialNumberOfRepetitions = 5
      state.letterSeparation = SPELLING_SYSTEM
      state.currentIndex = 0
      state.started = false
      state.activeInput = {
        lineIndex: null,
        itemIndex: null
      }
      state.pyramidData = null
    }
  }
})

export const {
  setStarted,
  createPyramidData,
  setInputValue,
  setLineStatus,
  enableLine,
  setTypeFinished,
  setActiveInput,
  clearLineInputs,
  increaseInitialNumberOfRepetitions,
  decreaseInitialNumberOfRepetitions,
  increaseNumberOfRepetitions,
  decreaseNumberOfRepetitions,
  setLetterSeparation,
  resetNumberOfRepetitions,
  resetPyramidLine,
  increaseCurrentIndex,
  decreaseCurrentIndex,
  clearSpellingWordPyramidStates
} = spellingWordPyramidSlice.actions

export default spellingWordPyramidSlice.reducer
