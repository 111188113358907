import * as React from 'react'

const MatchUpSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={47.51} height={48.14} viewBox='0 0 47.51 48.14'>
    <g>
      <path fill='#fff' d='M0 0h9.93v9.93H0z' />
      <path fill='#fff' d='M12.53 0h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M25.05 0h9.93v9.93h-9.93z' />
      <path fill={color} d='M37.58 0h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M0 12.74h9.93v9.93H0z' />
      <path fill='#fff' d='M12.53 12.74h9.93v9.93h-9.93z' />
      <path fill={color} d='M25.05 12.74h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M37.58 12.74h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M0 25.47h9.93v9.93H0z' />
      <path fill='#fff' d='M12.53 25.47h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M25.05 25.47h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M37.58 25.47h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M0 38.21h9.93v9.93H0z' />
      <path fill='#fff' d='M12.53 38.21h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M25.05 38.21h9.93v9.93h-9.93z' />
      <path fill='#fff' d='M37.58 38.21h9.93v9.93h-9.93z' />
    </g>
  </svg>
)

export default MatchUpSvg
