import * as React from 'react'

const TickSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={512}
    height={512}
    viewBox='0 0 512 512'
  >
    <path d='M504.5 75.5c-10-10-26.2-10-36.2 0L161.6 382.2 43.7 264.3c-10-10-26.2-10-36.2 0-10 10-10 26.2 0 36.2l136 136c10 10 26.2 10 36.2 0l324.8-324.8c10-10 10-26.2 0-36.2z' />
  </svg>
)

export default TickSvg
