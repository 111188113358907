import serviceCall from '../../services'
import sentenceDataMapper from '../../services/mapper/sentenceData/sentenceDataMapper'

const sentenceDataUseCase = async ({ trackerNumber }) => {
  const data = await serviceCall('fetchSentenceData', { trackerNumber }, 'cloud')

  return sentenceDataMapper(data)
}

export default sentenceDataUseCase
