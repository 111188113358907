import { HISTORY, NUMBERED, SUPER_MEMORY } from '../../../enums/globalEnums/globalEnums'

const boxMapper = data => {
  let result = {
    boxNumber: data?.boxNumber || 0,
    boxType: data?.boxType || NUMBERED,
    readyToStudy: data?.readyToStudy || 0,
    total: data?.total || 0,
    isSuperMemory: false,
    isHistory: false,
    isNormal: false
  }

  switch (result.boxType) {
    case SUPER_MEMORY:
      result.boxClassName = 'superbox'
      result.isSuperMemory = true
      break

    case HISTORY:
      result.boxClassName = 'historybox'
      result.isHistory = true
      break

    case NUMBERED:
      result.boxClassName = 'normalbox'
      result.isNormal = true
      break

    default:
      result.boxClassName = 'normalbox'
      result.isNormal = true
      break
  }

  return result
}

export default boxMapper
