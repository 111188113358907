import React, { useContext } from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import removeWrapperTag from '../../../../operations/removeWrapperTag'
import reactStringReplace from 'react-string-replace'
import numberIsOdd from '../../../../operations/numberIsOdd'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import PutInOrderInputArea from './PutInOrderInputArea'
import ExerciseFullTexts from '../../Components/ExerciseFullTexts'
import GivenTemplate from '../../../../components/GivenTemplate/GivenTemplate'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const PutInOrderGivenInputTypeInline = ({ exerciseItem, stackIndex, itemIndex, enabled }) => {
  const { colors } = useContext(colorsContext)

  if (
    exerciseItem.checked &&
    (exerciseItem.userAnswerStatus !== AnswerStatusEnums.INCORRECT || exerciseItem.unlocked)
  ) {
    return (
      <ExerciseFullTexts
        unlocked={exerciseItem.unlocked}
        fullTexts={exerciseItem.fullTextAnswers}
        userAnswerStatus={[exerciseItem.userAnswerStatus]}
        template='INPUT'
        textColor={colors['C233']}
        inputColorCode={'MC3'}
      />
    )
  } else {
    const renderGivenTextParagraph = (paragraph, pIndex) => {
      const cleanParagraph = removeWrapperTag(exerciseItem.given.text.sentence, 'p')
      const arr = reactStringReplace(cleanParagraph, '###', (match, index) => {
        const choicesIndex = (index - 1) / 2

        if (choicesIndex === 0) {
          return (
            <PutInOrderInputArea
              exerciseItem={exerciseItem}
              stackIndex={stackIndex}
              itemIndex={itemIndex}
              enabled={enabled}
            />
          )
        } else {
          return ''
        }
      })

      return arr.map((el, i) => (
        <React.Fragment key={i}>
          {numberIsOdd(i) ? (
            el
          ) : (
            <HtmlTextWrapper
              textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
              optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
              data={{
                ...(exerciseItem.fullTextAnswers.length > pIndex ? exerciseItem.fullTextAnswers[pIndex] : {}),
                sentence: el
              }}
              phraseOptionsDisabled
              showPhraseOptions={exerciseItem.checked && i === 0}
              style={{ display: 'inline' }}
            />
          )}
        </React.Fragment>
      ))
    }

    const givenText = exerciseItem.given.text
    if (givenText) {
      return givenText.paragraphList.map((paragraph, pIndex) => {
        return (
          <div className='exercise-paragraph' style={{ ...paragraph.paragraphStyle }} key={pIndex}>
            {renderGivenTextParagraph(paragraph, pIndex)}
          </div>
        )
      })
    }
  }
}

export default PutInOrderGivenInputTypeInline
