const wordsMapper = data => {
  return data?.content?.map(item => {
    return {
      id: item.id,
      title: item.title,
      unitId: item.unitId,
      tag: item.tag,
      hint: item.hint,
      translation: item.translation,
      accentAndAudios: item.translation,
      partOfSpeeches: item.partOfSpeeches,
      favoriteId: item.favoriteId,
      memoryBoxId: item.memoryBoxId,
      syllable: item.syllable,
      phonetic: item.phonetic
    }
  })
}

export default wordsMapper
