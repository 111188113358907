const accentsMapper = response => {
  const data = response?.map(category => {
    return {
      accentCategory: category.accentCategory,
      categoryTitle: category.categoryTitle,
      flagAddress: category.accentCategory === 'UK' ? 'gb' : category.accentCategory?.toLowerCase(),
      accents: category.accents.map(accent => {
        return {
          category: accent.category,
          name: accent.name,
          str: accent.str,
          gender: accent.gender,
          flagAddress: accent.flagAddress,
          isUserAccent: accent.isUserAccent
        }
      })
    }
  })

  let items = []

  data?.forEach(category => {
    items.push({
      key: category.categoryTitle,
      iconSrc: window.location.origin + `/assets/flags/${category.flagAddress}.svg`,
      value: category.categoryTitle,
      isNotOption: true
    })

    category.accents.forEach(accent => {
      items.push({
        key: accent.name,
        value: accent.str,
        gender: accent.gender,
        userAccent: accent?.isUserAccent,
        origin: accent
      })
    })
  })

  return items
}

export default accentsMapper
