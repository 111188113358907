import fetchComboBoxMapper from './fetchComboBoxMapper'
import fetchComboPictionaryMapper from './fetchComboPictionaryMapper'
import fetchChooseFromCategoryMapper from './fetchChooseFromCategoryMapper'
import fetchMatchMapper from './fetchMatchMapper'
import fetchMultipleChoiceMapper from './fetchMultipleChoiceMapper'
import fetchSayTheFollowingMapper from './fetchSayTheFollowingMapper'
import fetchTypeTheAnswerMapper from './fetchTypeTheAnswerMapper'
import fetchDragAndDropMapper from './fetchDragAndDropMapper'
import fetchDragAndDropCategoryMapper from './fetchDragAndDropCategoryMapper'
import fetchDragAndDropConversationMapper from './fetchDragAndDropConversationMapper'
import fetchPutInOrderMapper from './fetchPutInOrderMapper'
import fetchPutInOrderConversationMapper from './fetchPutInOrderConversationMapper'

const fetchExerciseMapperSwitch = data => {
  switch (data.type) {
    case 'COMBO_BOX':
      return fetchComboBoxMapper(data)
    case 'COMBO_PICTIONARY':
      return fetchComboPictionaryMapper(data)
    case 'CHOOSE_FROM_CATEGORY':
      return fetchChooseFromCategoryMapper(data)
    case 'MATCH_CASE':
      return fetchMatchMapper(data)
    case 'MULTIPLE_CHOICE':
      return fetchMultipleChoiceMapper(data)
    case 'SAY_THE_FOLLOWING':
      return fetchSayTheFollowingMapper(data)
    case 'TYPE_THE_ANSWER':
      return fetchTypeTheAnswerMapper(data)
    case 'DRAG_AND_DROP':
      return fetchDragAndDropMapper(data)
    case 'DRAG_AND_DROP_CATEGORY':
      return fetchDragAndDropCategoryMapper(data)
    case 'DRAG_AND_DROP_CONVERSATION':
      return fetchDragAndDropConversationMapper(data)
    case 'PUT_IN_ORDER':
      return fetchPutInOrderMapper(data)
    case 'PUT_IN_ORDER_CONVERSATION':
      return fetchPutInOrderConversationMapper(data)
    default:
      return data
  }
}

export default fetchExerciseMapperSwitch
