import serviceCall from '../../services'
import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'

const editFileUseCase = async data => {
  const result = await serviceCall(
    'notepadEditFile',
    {
      id: data.id,
      parentFolderId: data.parentFolderId,
      body: {
        title: data.title,
        color: data.color,
        text: data.text,
        textConfig: data.textConfig
      }
    },
    'cloud'
  )

  return filesAndFoldersMapper(result)
}

export default editFileUseCase
