export const REPEAT_THREE_TIMES = 'REPEAT_THREE_TIMES'
export const REPEAT_FIVE_TIMES = 'REPEAT_FIVE_TIMES'
export const REPEAT_SEVEN_TIMES = 'REPEAT_SEVEN_TIMES'

export const getSpacedRepetitionDataByKey = key => {
  switch (key) {
    case REPEAT_THREE_TIMES:
      return {
        repeatNumber: 3
      }
    case REPEAT_FIVE_TIMES:
      return {
        repeatNumber: 5
      }
    case REPEAT_SEVEN_TIMES:
      return {
        repeatNumber: 7
      }
    default:
      return {
        repeatNumber: 5
      }
  }
}
