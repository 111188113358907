import serviceCall from '../../services'
import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'

const editFolderUseCase = async ({ parentFolderId, folderId, title, color }) => {
  const result = await serviceCall('notepadEditFolder', { parentFolderId, folderId, title, color }, 'cloud')

  return filesAndFoldersMapper(result)
}

export default editFolderUseCase
