import { isEmpty } from 'lodash'

const dictionaryMapper = data => {
  return {
    word: data?.word,
    phonetic: data?.phonetic,
    meanings: [...data?.meanings]?.map(meaning => {
      return {
        partOfSpeech: meaning?.partOfSpeech,
        definitions: [...meaning?.definitions]
          ?.filter(it => !isEmpty(it.definition))
          .map(definition => {
            return {
              definition: definition?.definition,
              example: definition?.example
            }
          }),
        synonyms: meaning?.synonyms,
        antonyms: meaning?.antonyms,
        ingForm: meaning?.ingForm,
        nounForm: meaning?.nounForm,
        adverbForm: meaning?.adverbForm,
        adjectiveForm: meaning?.adjectiveForm,
        plural: meaning?.plural,
        base: meaning?.base,
        past: meaning?.past,
        pastParticiple: meaning?.pastParticiple,
        thirdPerson: meaning?.thirdPerson,
        syllable: meaning?.syllable
      }
    })
  }
}

export default dictionaryMapper
