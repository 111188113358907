import React, { useContext, useRef, useMemo } from 'react'
import componentStyle from './ReviewMistakeCircle.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import BubbleSvg from '../../icons/BubbleSvg'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import PageLayer from '../PageLayer/PageLayer'
import ReviewExerciseMistakes from '../../pages/Sections/ReviewMistakes/ReviewExerciseMistakes'
import { sectionsData } from '../../sources/sectionsData'
import classNames from 'classnames'
import styles from '../../pages/MessagesAndNotifications/MessagesAndNotifications.module.scss'
import { useSelector } from 'react-redux'
import { unitStatusEnums } from '../../enums/levelUnitEnums/levelUnitEnums'

const ReviewMistakeCircle = ({ data, onCloseExercisePage }) => {
  const { colors } = useContext(colorsContext)
  const circularTitleRef = useRef(null)
  const { windowWidth } = useWindowDimensions()
  const [searchParams] = useSearchParams()
  const reviewIdParam = searchParams.get('reviewId')
  const settingTitlesRef = useRef([])
  const { unit, sectionId } = useParams()
  const statusStates = useSelector(state => state.status)

  const sectionData = useMemo(() => sectionsData.find(it => it.sectionType === data.sectionType), [data.sectionType])

  const statusColor = useMemo(() => {
    const studyProgressStatus = statusStates.data?.sections?.find(
      it => it.sectionType === data.sectionType
    )?.studyProgressStatus
    if (studyProgressStatus === unitStatusEnums.DONE) {
      return colors['C30']
    } else if (studyProgressStatus === unitStatusEnums.ON_GOING) {
      return colors['C29']
    }
    return 'transparent'
  }, [data.sectionType, JSON.stringify(statusStates.data?.sections ?? [])])

  return (
    <>
      <div className={componentStyle.container}>
        <div className={componentStyle.circleContainer} style={{ background: colors['C487'] }}>
          <div
            className={componentStyle.sectionCircle}
            style={{
              background: colors['C171'],
              borderColor: statusColor
            }}
          >
            <div className={componentStyle.exerciseBadge}>
              <img src={`${window.location.origin}/assets/images/BubbleExercise.png`} alt={'bubble'} />
            </div>
            {sectionData?.icon({ color: colors['C233'] })}
          </div>
          <Link
            to={{
              pathname: `/sections/${unit}/${sectionId}`,
              search: `?reviewId=${data.id}`
            }}
            className={componentStyle.reviewButton}
            style={{ color: colors['C160'], background: colors['C491'], borderColor: colors['C160'] }}
          >
            Let's Review
          </Link>
          <div style={{ color: colors['C187'] }} ref={circularTitleRef}>
            <div
              className={classNames(componentStyle.exerciseTitle, { [styles.revert]: data.revert })}
              ref={el => (settingTitlesRef.current[data.sectionName] = el)}
            >
              {data?.count > 0 && <span>({data?.count})</span>}
              <svg
                width={windowWidth >= 576 ? 200 : 120}
                height={windowWidth >= 576 ? 50 : 30}
                xmlns='http://www.w3.org/2000/svg'
              >
                <defs>
                  {windowWidth >= 768 ? (
                    data.sectionName >= 2 && data.sectionName <= 5 ? (
                      <path id={`setting-item-path-${data.sectionName}`} d='M 50 15 C 80 70, 120 5, 190 30' />
                    ) : (
                      <path id={`setting-item-path-${data.sectionName}`} d='M 57 60 C 83 20, 150 2,210 57' />
                    )
                  ) : data.sectionName >= 2 && data.sectionName <= 5 ? (
                    <path id={`setting-item-path-${data.sectionName}`} d='M 15 10 C 30 10, 90 22.5, 112.5 7.5' />
                  ) : (
                    <path id={`setting-item-path-${data.sectionName}`} d='M 12 38 C 28 6, 95 5, 112.5 41' />
                  )}
                </defs>
                <text className={componentStyle.curveSvgText} fill={colors['C187']}>
                  <textPath
                    startOffset='50%'
                    dominantBaseline='middle'
                    textAnchor='middle'
                    xlinkHref={`#setting-item-path-${data.sectionName}`}
                  >
                    {data.sectionName}
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
          <div className={componentStyle.circleInner} style={{ background: colors['C488'] }}>
            <div className={componentStyle.sectionNumberContainer}>
              <span className={componentStyle.line} style={{ background: colors['C187'] }} />
              <p className={componentStyle.sectionNumber} style={{ color: colors['C187'] }}>
                Section {data.sectionNumber}
              </p>
              <span className={componentStyle.line} style={{ background: colors['C187'] }} />
            </div>
            <div
              className={componentStyle.inCorrectsBadge}
              style={{ background: colors['C489'], color: colors['C160'] }}
            >
              {data.totalIncorrect ?? 0} incorrect
            </div>
            <p className={componentStyle.outOf} style={{ color: colors['C187'] }}>
              out of <span style={{ color: colors['C490'] }}>{data.totalCount ?? 0}</span>
            </p>
          </div>
        </div>
      </div>
      {reviewIdParam && reviewIdParam === data.id && (
        <PageLayer>
          <ReviewExerciseMistakes data={data} onClose={onCloseExercisePage} />
        </PageLayer>
      )}
    </>
  )
}

export default ReviewMistakeCircle
