import React, { useContext } from 'react'
import classNames from 'classnames'
import exerciseStyles from '../../modules/ExerciseModules/ExerciseModules.module.scss'
import colorsContext from '../../contexts/ColorsContext'
import PhrasesOptions from '../PhrasesOptions/PhrasesOptions'
import { moreOptionsList } from '../../data'
import BubbleSvg from '../../icons/BubbleSvg'
import HtmlTextWrapper from '../HtmlTextWrapper/HtmlTextWrapper'
import useReversedDebounce from '../../hooks/useReversedDebounce'
import { AnswerStatusEnums } from '../../enums/globalEnums/globalEnums'

const DragDropInlineAnswerBox = ({
  pending,
  hasBubble,
  bubbleNumber,
  checked,
  unlocked,
  userAnswerStatus,
  userAnswer,
  correctAnswer,
  selected,
  onDragStart,
  onDrop,
  onClick,
  bgColor,
  phraseOptions,
  style
}) => {
  const { colors } = useContext(colorsContext)
  const { reverseDebounce } = useReversedDebounce()

  const renderBubble = () => {
    if (hasBubble) {
      return (
        <span className={exerciseStyles.answerBoxBubble}>
          <BubbleSvg color={colors['C160']} />
          <span className={exerciseStyles.content} style={{ color: colors['C233'] }}>
            {bubbleNumber}
          </span>
        </span>
      )
    }
    return null
  }

  if (checked) {
    const getColor = () => {
      if (userAnswerStatus === AnswerStatusEnums.CORRECT) {
        return colors['C30']
      } else if (userAnswerStatus === AnswerStatusEnums.INCORRECT) {
        if (unlocked) {
          return colors['C233']
        } else {
          return colors['C29']
        }
      } else {
        return colors['C233']
      }
    }

    const getBorderColor = () => {
      if (userAnswerStatus === AnswerStatusEnums.NONE) {
        return 'transparent'
      } else if (userAnswerStatus === AnswerStatusEnums.CORRECT) {
        return colors['C30']
      } else {
        return colors['C29']
      }
    }

    return (
      <>
        <span
          className={classNames(exerciseStyles.answerBox, exerciseStyles.filled, exerciseStyles.notDraggable)}
          style={{
            background: colors['C516'],
            // color: userAnswerStatus === 'CORRECT' ? colors['C30'] : (unlocked ? colors['C233'] : colors['C29']),
            color: getColor(),
            // borderColor: userAnswerStatus === 'CORRECT' ? colors['C30'] : colors['C29'],
            borderColor: getBorderColor(),
            ...style
          }}
        >
          {renderBubble()}
          <HtmlTextWrapper
            data={{
              sentence:
                unlocked ||
                userAnswerStatus === AnswerStatusEnums.CORRECT ||
                userAnswerStatus === AnswerStatusEnums.NONE
                  ? correctAnswer.sentence
                  : userAnswer?.sentence ?? <span>&nbsp;</span>
            }}
          />
        </span>
        {phraseOptions && (
          <div className={exerciseStyles.answerBoxRightItems}>
            <PhrasesOptions
              disabled={userAnswerStatus === AnswerStatusEnums.INCORRECT && !unlocked}
              moreOptionsList={moreOptionsList}
              data={phraseOptions}
              color={colors['C23']}
            />
          </div>
        )}
      </>
    )
  }

  const debouncedOnClick = () => {
    reverseDebounce(() => {
      if (!pending) {
        onClick()
      }
    }, 100)
  }

  return (
    <>
      <span
        className={classNames(exerciseStyles.answerBox, {
          [exerciseStyles.selected]: selected,
          [exerciseStyles.filled]: Boolean(userAnswer)
        })}
        style={{
          background: bgColor,
          color: userAnswer ? colors['C23'] : 'transparent',
          borderColor: selected ? colors['C70'] : 'transparent',
          pointerEvents: pending ? 'none' : null,
          ...style
        }}
        onClick={debouncedOnClick}
        draggable={Boolean(userAnswer) && !pending}
        onDrop={e => {
          if (e.stopPropagation) e.stopPropagation()
          onDrop()
        }}
        onDragOver={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onDragStart={() => {
          if (!pending) {
            onDragStart()
          }
        }}
      >
        {renderBubble()}
        {userAnswer ? <HtmlTextWrapper data={{ sentence: userAnswer.sentence }} /> : <>&npsp;</>}
      </span>
    </>
  )
}

export default DragDropInlineAnswerBox
