import { useState, useEffect, useRef } from 'react'
import './../sources/js/web-audio-recorder/WebAudioRecorder.min.js'
import { isFirefox } from 'react-device-detect'

const useAudioRecorder = ({
  encodeType = 'mp3',
  onRecordCompleted = () => {},
  recognizeEndOfSpeech = false,
  timeLimit = 15
}) => {
  const hark = require('hark')

  const [recording, setRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState(null)
  const [isSpeaking, setIsSpeaking] = useState(false)
  const [error, setError] = useState(null)

  const [stopRecordingTimeout, setStopRecordingTimeout] = useState(0)

  const [latestSpeakTime, setLatestSpeakTime] = useState(null)

  const [second, setSecond] = useState('00')
  const [minute, setMinute] = useState('00')
  const [counter, setCounter] = useState(0)

  const [timerIntervalId, setTimerIntervalId] = useState(0)

  const recorderRef = useRef()
  const gumStreamRef = useRef()

  useEffect(() => {
    if (recording && typeof latestSpeakTime === 'number') {
      if (isSpeaking) {
        clearTimeout(stopRecordingTimeout)
      } else {
        const timeout = setTimeout(() => {
          stopRecording()
        }, 1500)
        setStopRecordingTimeout(timeout)
      }
    }
  }, [isSpeaking, recording])

  useEffect(() => {
    let intervalId

    if (recording) {
      if (counter >= timeLimit) {
        stopRecording()
      }

      intervalId = setInterval(() => {
        const secondCounter = counter % 60
        const minuteCounter = Math.floor(counter / 60)

        let computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter
        let computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter

        setSecond(computedSecond)
        setMinute(computedMinute)

        setCounter(counter => counter + 1)
      }, 1000)

      setTimerIntervalId(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [recording, counter])

  const startRecording = async () => {
    if (MediaRecorder.notSupported) {
      setError('Media recorder not supported in your browser!')
    } else {
      try {
        // Request permissions to record audio
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        })

        const audioContext = isFirefox ? new AudioContext() : new AudioContext({ sampleRate: 48000 })
        const audioStreamSource = audioContext.createMediaStreamSource(stream)

        gumStreamRef.current = stream

        const webAudioRecorder = new WebAudioRecorder(audioStreamSource, {
          workerDir: '/assets/js/web-audio-recorder/',
          encoding: encodeType,
          numChannels: 2,
          onEncoderLoading: function (recorder, encoding) {
            // show "loading encoder..." display
          },
          onEncoderLoaded: function (recorder, encoding) {
            // hide "loading encoder..." display
          }
        })

        if (recognizeEndOfSpeech) {
          const speechEvents = hark(stream, {
            threshold: -45
          })

          speechEvents.on('speaking', function () {
            console.log('speaking')
            setIsSpeaking(true)
            setLatestSpeakTime(Date.now())
          })

          speechEvents.on('stopped_speaking', function () {
            console.log('stopped_speaking')
            setIsSpeaking(false)
          })
        }

        webAudioRecorder.onComplete = function (recorder, blob) {
          console.info('Encoding complete')
          if (onRecordCompleted) {
            onRecordCompleted(blob)
          }
          setAudioBlob(blob)
          // Finish recording
          setRecording(false)
          // console.log("recording off")
          // Stop timer
          stopTimer()
          // Remove “recording” icon from browser tab
          gumStreamRef.current?.getAudioTracks()?.forEach(track => track.stop())
          gumStreamRef.current = null
        }

        webAudioRecorder.setOptions({
          encodeAfterRecord: true,
          ogg: { quality: 0.5 },
          mp3: { bitRate: 192 }
        })

        webAudioRecorder.startRecording()

        recorderRef.current = webAudioRecorder

        setRecording(true)
        // console.log("recording on")
      } catch (err) {
        console.log(err)
        setError('Error: Audio recorder is not available!')
      }
    }
  }

  const stopRecording = () => {
    recorderRef.current?.finishRecording()
  }

  const stopTimer = () => {
    if (timerIntervalId > 0) {
      clearInterval(timerIntervalId)
    }
    setCounter(0)
    setSecond('00')
    setMinute('00')
  }

  return {
    recording,
    startRecording,
    stopRecording,
    recorderError: error,
    audioBlob,
    emptyAudioBlob: () => setAudioBlob(null),
    timer: `${minute}:${second}`,
    recorder: recorderRef
  }
}

export default useAudioRecorder
