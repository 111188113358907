import { shuffle } from 'lodash'
import { htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchDragAndDropConversationMapper = data => {
  const exerciseStack = data.exerciseStacks[0]

  let choiceIndex = 0
  let choiceList = []
  exerciseStack.items.forEach(exerciseItem => {
    exerciseItem.data.choices.forEach(choiceItem => {
      if (choiceItem.items.length > 0) {
        choiceList.push({
          ...choiceItem.items[0],
          choiceIndex,
          text: htmlTextConversion(choiceItem.items[0]?.text),
          itemId: exerciseItem.id,
          used: false
        })
        choiceIndex++
      }
    })
  })

  return {
    pageType: pageGuidEnums.DRAG_AND_DROP_CONVERSATION_EXERCISE,
    data: {
      immediateCheck: true,
      paginable: false,
      isSingleTemplate: true,
      type: data.type,
      title: data.title ?? null,
      checked: false,
      checkable: false,
      stack: {
        ...exerciseStack,
        choices: shuffle(choiceList),
        items: [...exerciseStack.items].map(item => {
          const given = item.data.givens[0]
          return {
            id: item.id,
            givenText: htmlTextConversion(given.text),
            character: {
              imageUri: given.imageUri ?? null,
              name: item.extra.additionalData ?? ''
            },
            hasAnswer: given.text.sentence.includes('###'),
            answerFields: item.data.answers
              .filter(el => el.items.length > 0)
              .map((answer, i) => {
                return {
                  index: i,
                  itemId: item.id,
                  selected: false,
                  correctAnswer: {
                    ...answer.items[0],
                    text: htmlTextConversion(answer.items[0]?.text)
                  },
                  userAnswer: null,
                  userAnswerStatus: null
                }
              }),
            fullTextAnswer:
              item.extra.fullTextAnswers.length > 0 ? htmlTextConversion(item.extra.fullTextAnswers[0]) : null,
            userAnswerStatus: null,
            unlocked: false,
            checkable: false,
            checked: false
          }
        })
      }
    }
  }
}

export default fetchDragAndDropConversationMapper
