import { useEffect } from 'react'
import ReadingAndConversationIntro from '../ReadingAndConversationIntro/ReadingAndConversationIntro'
import ReadingMain from './ReadingMain'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchReading, resetReadingStates, setPage, setPlaylist } from '../../../redux/features/readingSlice'
import { isEmpty } from 'lodash'
import ReadingBook from './ReadingBook'
import Spinner from 'react-bootstrap/Spinner'
import makeUrl from '../../../operations/makeUrl'
import getCachedMediaUrl from '../../../operations/getCachedMediaUrl'
import { START_SECTION } from '../../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../../hooks/logHooks/useLog'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'
import RetryServiceButton from 'atomicComponents/molecules/RetryServiceButton/RetryServiceButton'

const Reading = () => {
  require('./reading.scss')

  const { unit } = useParams()

  const states = useSelector(state => state.reading)

  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { sendServerLog } = useLog()

  const fetchData = () => {
    networkLossRetryerDispatch(retryId => {
      dispatch(fetchReading({ unit, retryId })).then(action => {
        if (action.payload.data) {
          const createPlaylist = async () => {
            const arr = []
            for (let i = 0; i < action.payload.data.readingData.length; i++) {
              const r = await getCachedMediaUrl(makeUrl(action.payload.data.readingData[i].voiceUri))
              if (r) {
                arr.push(r)
              }
            }
            return arr
          }
          createPlaylist().then(res => {
            dispatch(setPlaylist(res))
          })
        }
      })
    })
  }

  useEffect(() => {
    return () => {
      dispatch(resetReadingStates())
    }
  }, [])

  useEffect(() => {
    if (isEmpty(states.data)) {
      fetchData()
    }
  }, [dispatch, unit])

  const renderPage = () => {
    if (states.page === 'intro') {
      return (
        <ReadingAndConversationIntro
          helpEnumLink={pageGuidEnums.READING_MAIN_PAGE}
          description={states.data.description}
          title={states.data.title}
          imgSrc={states.data.pictureUri}
          onClickStart={() => {
            sendServerLog(START_SECTION)
            dispatch(setPage('animation'))
          }}
        />
      )
    }
    if (states.page === 'animation') {
      return <ReadingBook />
    }
    if (states.page === 'reading') {
      return <ReadingMain />
    }
  }

  if (!states.loading && isEmpty(states.data) && Boolean(states.error)) {
    return <RetryServiceButton onClick={fetchData} />
  }

  if (!isEmpty(states.data)) {
    return renderPage()
  }

  return (
    <div className='pt-5 w-100 d-flex align-items-center justify-content-center'>
      <Spinner animation='border' />
    </div>
  )
}
export default Reading
