const searchTableOfContentUseCase = (phrase, sourceData) => {
  if (!phrase || phrase === '') {
    return []
  }

  let result = []

  phrase = phrase.toLowerCase()

  sourceData.forEach(section => {
    let sectionData = {
      key: section.key,
      title: section.title,
      items: []
    }
    section.data.forEach(level => {
      level.rows.forEach(row => {
        if (findInDescription(phrase, row.description)) {
          sectionData.items.push(row)
        }
      })
    })

    if (sectionData.items.length > 0) {
      result.push(sectionData)
    }
  })

  return result
}

const findInDescription = (phrase, description) => {
  return description.toLowerCase().includes(phrase)
}

export default searchTableOfContentUseCase
