const SuccessCircleSvg = ({ color = '#39B24A' }) => {
  return (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={512}
      height={512}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path d='M256.1,1.1c-141.2,0-256,114.8-256,256s114.8,256,256,256s256-114.8,256-256S397.2,1.1,256.1,1.1z M399.1,189.8 L235.5,352.1c-9.6,9.6-25,10.3-35.3,0.6l-86.6-78.9c-10.3-9.6-10.9-25.7-1.9-35.9c9.6-10.3,25.7-10.9,35.9-1.3l68.7,62.9 l146.3-146.3c10.3-10.3,26.3-10.3,36.6,0C409.4,163.5,409.4,179.5,399.1,189.8z' />
        </g>
      </g>
    </svg>
  )
}

export default SuccessCircleSvg
