import { htmlTextConversion } from '../mapperConversions'

const numbersSectionMapper = data => {
  const voiceUrisArray = []
  data?.cards?.forEach(card => {
    card.numbers.forEach(number => {
      voiceUrisArray.push(number.text.voiceUri)
    })
  })

  let i = -1

  return {
    ...data,
    voiceUrisArray,
    cards:
      data?.cards?.map(card => {
        return {
          ...card,
          numbers: card.numbers.map(number => {
            ++i
            return {
              ...number,
              numberIndex: i,
              text: htmlTextConversion(number.text)
            }
          })
        }
      }) ?? []
  }
}

export default numbersSectionMapper
