import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedBox: null,
  selectedCard: null
}

const memoryBoxSharedSlice = createSlice({
  name: 'memoryBoxShared',
  initialState,
  reducers: {
    setSelectedBox: (state, action) => {
      state.selectedBox = action.payload
    },
    setSelectedCard: (state, action) => {
      state.selectedCard = action.payload
    }
  }
})

export const { setSelectedBox, setSelectedCard } = memoryBoxSharedSlice.actions

export default memoryBoxSharedSlice.reducer
