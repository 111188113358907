import Modal from '../Modal'
import { useContext, useState } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import componentStyle from './ChangePhotoModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setUserAvatar } from '../../redux/features/settingSlice'
import { setAppUser } from '../../redux/features/appUserSlice'
import classNames from 'classnames'
import useAppUser from '../../hooks/useAppUser'
import { Spinner } from 'react-bootstrap'

const ChangePhotoModal = ({ onClose }) => {
  const dispatch = useDispatch()
  const { colors } = useContext(colorsContext)
  // const inputFile = useRef(null)
  // const captureInputRef = useRef(null)
  const { appUserData } = useAppUser()
  const [clickedItemIndex, setClickedItemIndex] = useState(0)
  const { setUserAvatarLoading } = useSelector(state => state.setting)

  // const onClickGallery = () => {
  //   inputFile.current.click()
  // }
  //
  // const onClickCamera = () => {
  //   captureInputRef.current.click()
  // }
  //
  // const handleFileChange = event => {
  //   let input = event.target.files[0];
  //   if (!input) return;
  //   dispatch(setUserAvatar({file: input})).then((res) => {
  //     dispatch(setAppUser(res.payload))
  //     onClose()
  //   })
  // };

  const onSelectAvatar = index => {
    setClickedItemIndex(index)
    dispatch(setUserAvatar({ avatarType: index })).then(res => {
      if (res?.payload) {
        dispatch(setAppUser(res.payload))
      }
      onClose()
    })
  }

  return (
    <Modal closeModal={onClose} isCenter>
      <div className='modal-type-2'>
        <div className='modal-card px-4 py-3' style={{ background: colors['C12'] }}>
          <div className='mb-3 d-flex align-items-center justify-content-between'>
            <h4 className='res:fs-lg font-weight-700' style={{ color: colors['C2'] }}>
              Change profile photo
            </h4>
          </div>
          <div className={componentStyle.avatars}>
            {[...new Array(30)].map((item, i) => {
              return (
                <button
                  disabled={setUserAvatarLoading}
                  style={{ opacity: setUserAvatarLoading && clickedItemIndex === i ? 0.5 : 1 }}
                  onClick={() => onSelectAvatar(i)}
                  className={classNames(componentStyle.avatarItem, {
                    [componentStyle.selected]: appUserData?.setting?.avatar === i.toString()
                  })}
                  key={i}
                >
                  {setUserAvatarLoading && clickedItemIndex === i && (
                    <span className={componentStyle.loadingStyle}>
                      <Spinner size='sm' />
                    </span>
                  )}
                  <img
                    style={{ borderColor: colors['C2'] }}
                    src={`${window.location.origin}/assets/images/avatars/${i}.png`}
                    alt=''
                  />
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ChangePhotoModal
