import { useEffect, useState } from 'react'
import HelpVideo from '../HelpVideo/HelpVideo'
import HelpVideoSuggestions from '../HelpVideoSuggestions/HelpVideoSuggestions'

const HelpVideoModalContent = ({ videos }) => {
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [suggestions, setSuggestions] = useState(null)

  useEffect(() => {
    if (videos?.length > 0) {
      const firstVideo = videos[0]
      setSelectedVideo(firstVideo)
      setSuggestions(videos?.filter(it => it.videoLink !== firstVideo.videoLink))
    }
  }, [videos])

  const onSelectVideo = video => {
    setSelectedVideo(video)
    setSuggestions(videos.filter(it => it.videoLink !== video.videoLink))
  }

  return (
    <>
      {selectedVideo && (
        <div className='d-flex justify-content-center mb-3'>
          <HelpVideo data={selectedVideo} />
        </div>
      )}
      {suggestions?.length > 0 && <HelpVideoSuggestions onClickItem={item => onSelectVideo(item)} data={suggestions} />}
    </>
  )
}

export default HelpVideoModalContent
