import * as React from 'react'

const SpeakerOnSvg = ({ color, playing }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51 34' width='100%' height='100%'>
    <g>
      <path
        d='M24.729 34.152a2 2 0 0 1-.9-.214l-.043-.021-13.501-9.048H3.64a3.723 3.723 0 0 1-3.64-3.8v-8.178a3.723 3.723 0 0 1 3.64-3.8l7.193-.022L23.782.234l.05-.021a2 2 0 0 1 2.892 1.788v30.15a2 2 0 0 1-.947 1.7 2.047 2.047 0 0 1-1.048.301Z'
        fill={color}
      />
      <g style={{ opacity: playing ? 1 : 0 }}>
        <g>
          <path
            d='M33.11 24.17a.779.779 0 0 1-.57-.24.809.809 0 0 1 0-1.15 7.75 7.75 0 0 0 0-10.96.813.813 0 0 1 1.15-1.15 9.37 9.37 0 0 1 2.06 10.16 9.193 9.193 0 0 1-2.06 3.1.835.835 0 0 1-.58.24Z'
            fill={color}
          />
        </g>
        <g>
          <path
            data-name='Path 8'
            d='M37.89 28.95a.779.779 0 0 1-.57-.24.809.809 0 0 1 0-1.15 13.79 13.79 0 0 0 3.1-4.71 14.132 14.132 0 0 0 1-5.39 14.426 14.426 0 0 0-1.11-5.42 14.675 14.675 0 0 0-3.21-4.79.813.813 0 0 1 1.15-1.15 16.254 16.254 0 0 1 3.56 5.32 15.994 15.994 0 0 1 1.23 6.02 15.754 15.754 0 0 1-1.11 6.01 15.528 15.528 0 0 1-3.46 5.26.818.818 0 0 1-.58.24Z'
            fill={color}
          />
        </g>
        <g>
          <path
            data-name='Path 9'
            d='M42.86 33.92a.779.779 0 0 1-.57-.24.809.809 0 0 1 0-1.15 20.706 20.706 0 0 0 4.61-7 20.926 20.926 0 0 0 1.48-8 21.65 21.65 0 0 0-6.4-15.14.813.813 0 0 1 1.15-1.15 23.421 23.421 0 0 1 5.11 7.63A23.165 23.165 0 0 1 50 17.52a22.592 22.592 0 0 1-1.6 8.62 22.247 22.247 0 0 1-4.97 7.55.827.827 0 0 1-.57.23Z'
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SpeakerOnSvg
