import React from 'react'
import ThreeDotButton from '../ThreeDot/ThreeDotButton'
import makeUrl from '../../operations/makeUrl'
import TextInfo from '../TextInfo/TextInfo'
import AudioSpeakerButtonAudio from '../AudioSpeakerButton/AudioSpeakerButtonAudio'

const PhrasesOptions = ({ data, moreOptionsList, unitId, color, disabled, hideMore = false }) => {
  require('./phrasesOptions.scss')

  if (data) {
    const disabledStyle = {
      opacity: 0.5,
      pointerEvents: 'none'
    }

    return (
      <span className='phrase-options' data-jsx='PhrasesOptions' style={disabled ? disabledStyle : {}}>
        {!hideMore && (
          <span className='more option-item' style={!data.more ? disabledStyle : {}}>
            <ThreeDotButton
              color={color}
              moreOptionsList={moreOptionsList}
              type='tools-options'
              data={data}
              unitId={unitId}
            />
          </span>
        )}
        {data.voiceUri && (
          <span className='speaker option-item'>
            <AudioSpeakerButtonAudio color={color} audioUrl={makeUrl(data.voiceUri)} />
          </span>
        )}
        {data.info && (
          <span className='option-item'>
            <TextInfo
              color={color}
              info={data.info}
              trackerNumber={data.trackerNumber ? `${data.trackerNumber}Info` : null}
            />
          </span>
        )}
      </span>
    )
  }
  return null
}

export default PhrasesOptions
