import React, { useContext } from 'react'
import ToplineTitle from '../../../components/ToplineTitle/ToplineTitle'
import Image from '../../../components/Image'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import colorsContext from '../../../contexts/ColorsContext'
import componentStyle from '../../../components/Instruction/Instruction.module.scss'
import HelpButton from '../../../components/HelpButton/HelpButton'

const ReadingAndConversationIntro = ({ description, title, imgSrc, onClickStart, hasHelp, helpEnumLink }) => {
  const { colors } = useContext(colorsContext)
  require('./style.scss')
  return (
    <div data-jsx='ReadingAndConversationIntro'>
      <div className='d-flex align-items-start justify-content-between py-4 pe-4'>
        <div className='d-flex align-items-baseline'>
          <span className={componentStyle.introLeftLineText} style={{ background: colors['C170'] }} />
          <HtmlTextWrapper
            textColor={colors['C2']}
            optionsColor={colors['C2']}
            showPhraseOptions
            moreOptionsList={moreOptionsList}
            className='res:fs-md'
            data={description}
          />
        </div>
        <div className='me-3 me-md-5 ms-2'>
          <HelpButton color={colors['C2']} pageType={helpEnumLink} />
        </div>
      </div>
      <ToplineTitle titleData={title} />
      <div className='container container-1'>
        <div className='image-container'>
          <Image uri={imgSrc} className='convs-img' onClickZoomIn />
        </div>
        <div className='pagebelow-buttons-container'>
          <PrimaryButton className='mx-auto' onClick={onClickStart}>
            Start
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default ReadingAndConversationIntro
