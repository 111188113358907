import makeUrl from '../../../operations/makeUrl'
import moment from 'moment/moment'
import getHumanReadableDate from '../../../operations/getHumanReadableDate'
import { NUMBERED, REGULAR, REPEAT_THREE_TIMES, TextAlignmentEnums } from '../../../enums/globalEnums/globalEnums'

const flashCardMapper = data => {
  return {
    id: data?.id || '',
    userId: data?.userId || 0,
    front: sideMapper(data?.front),
    back: sideMapper(data?.back),
    unitId: data?.unitId || 0,
    hint: sideMapper(data?.hint),
    spacedRepetition: data?.spacedRepetition || REPEAT_THREE_TIMES,
    boxType: data?.boxType || NUMBERED,
    category: categoryMapper(data?.category),
    insertDate: dateMapper(data?.insertDate),
    lastReviewDate: dateMapper(data?.lastReviewDate),
    modifyDate: dateMapper(data?.modifyDate),
    nextReviewDate: dateMapper(data?.nextReviewDate),
    nextStep: data?.nextStep,
    vocab: data?.vocab,
    trackerNumber: data?.trackerNumber || '',
    voiceUri: makeUrl(data?.voiceUri) || '',
    isSelect: false
  }
}

const sideMapper = data => {
  return {
    textAlign: data?.alignment ?? TextAlignmentEnums.LEFT,
    dir: data?.direction ?? 'AUTO',
    imageUrl: data?.imageUrl ? '/api/object-store/files/' + data?.imageUrl || '' : null,
    soundUrl: data?.soundUrl ? makeUrl('/api/object-store/files/' + data?.soundUrl || '') : null,
    text: data?.text || ''
  }
}
const categoryMapper = data => {
  return {
    id: data?.id || '',
    userId: data?.userId || 0,
    title: data?.title || '',
    color: data?.color || '#FFFFFF',
    sideToShow: data?.sideToShow || 'A',
    orderOfDisplay: data?.orderOfDisplay || REGULAR,
    memoryBoxCategoryType: data?.memoryBoxCategoryType || 'USER_CATEGORY'
  }
}
const dateMapper = data => {
  return getHumanReadableDate(data)
}

export default flashCardMapper
