import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import serviceCall from '../../../services'
import exerciseStatisticsUseCase from '../../../useCase/exerciseStatisticsUseCase/exerciseStatisticsUseCase'

const initialState = {
  loading: false,
  data: null,
  error: ''
}

export const getExerciseStatistic = createAsyncThunk('exerciseStatistic/getExerciseStatistic', () =>
  exerciseStatisticsUseCase()
)

const exerciseStatisticSlice = createSlice({
  name: 'exerciseStatistic',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getExerciseStatistic.pending, state => {
      state.loading = true
    })
    builder.addCase(getExerciseStatistic.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(getExerciseStatistic.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = exerciseStatisticSlice.actions

export default exerciseStatisticSlice.reducer
