import { translationDb } from '../db/translationDb'
import { ENGLISH_TRANSLATIONS } from '../enums/languageEnums/languageEnums'

const useTranslations = () => {
  const addTranslation = async (languageData = ENGLISH_TRANSLATIONS) => {
    try {
      // Add the new translation!
      const languageJson = require(`./${languageData.fileName}`)

      if (languageJson) {
        translationDb[languageData.dbTable]?.clear()
        await translationDb[languageData.dbTable]?.bulkAdd(
          languageJson?.titles?.map(item => {
            return {
              key: item.translationKey,
              value: item.translationValue
            }
          }) ?? []
        )
      }

      console._log('Successfully added translation records into dbIndex')
    } catch (error) {
      console._log(`Failed to add translation records into dbIndex.`)
    }
  }

  return { addTranslation }
}

export default useTranslations
