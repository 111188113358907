import React, { useContext } from 'react'
import styles from './GameStatistics.module.scss'
import SecondaryToplineTitle from '../SecondaryToplineTitle/SecondaryToplineTitle'
import GameGoldClockSvg from '../../icons/GameGoldClockSvg'
import colorsContext from '../../contexts/ColorsContext'
import GameMedalSvg from '../../icons/GameMedalSvg'
import { EXCELLENT, getGameRankDataByKey, GOOD, MASTER, NOT_GOOD } from '../../enums/gameEnums/gameRankEnums'
import {
  BLINK,
  getGameDataByType,
  LIMBIC_POWER,
  MATCH_UP,
  PHONO_METRIX,
  PHONO_TYPE
} from '../../enums/gameEnums/gameTypeEnums'

const GameStatistics = ({ data }) => {
  const { colors } = useContext(colorsContext)
  const GameRank = ({ game, rank, masterTime, yourTime }) => {
    const gameRankData = getGameRankDataByKey(rank)

    return (
      <div className={styles.gameRankItem}>
        <div className={styles.imageContainer}>
          <img src={`${window.location.origin}/assets/images/games/${gameRankData?.imageKey}`} alt='' />
          <p className={styles.rankTitle} style={{ color: rank === MASTER ? colors['C59'] : colors['C2'] }}>
            {gameRankData?.rankTitle}
          </p>
        </div>
        <p className={styles.gameType} style={{ color: colors['C59'] }}>
          {getGameDataByType(game)?.gameTitle}
        </p>
        <div className={styles.clockTime}>
          <span className={styles.clockIcon}>
            <GameGoldClockSvg />
          </span>
          <span className={styles.text} style={{ color: colors['C2'] }}>
            {masterTime}
          </span>
        </div>
        <div className={styles.yourTime} style={{ color: colors['C2'] }}>
          your time: {yourTime}
        </div>
      </div>
    )
  }

  return (
    <>
      <SecondaryToplineTitle title='Games' />
      <div className={styles.gameStatisticsContainer}>
        <div className={styles.gameRanksContainer}>
          {data?.gameResults.map((item, i) => {
            return (
              <GameRank
                game={item.game.gameType}
                rank={item.status}
                masterTime={item.masterTime}
                yourTime={item.bestTime}
                key={i}
              />
            )
          })}
        </div>
        <div className={styles.vicabMasterStatistic}>
          <div className='d-flex align-items-center my-2 mx-3'>
            <span className={styles.icon}>
              <GameMedalSvg />
            </span>
            <p className={styles.medalTitle} style={{ color: colors['C59'] }}>
              Vcab Master
            </p>
          </div>
          <div className={styles.progress}>
            <div
              className={styles.progressBarSection}
              style={{
                background: data?.vicabMasterNumber > 0 ? colors['C30'] : colors['C2'] + '33',
                borderColor: colors['C2']
              }}
            />
            <div
              className={styles.progressBarSection}
              style={{ background: data?.vicabMasterNumber > 1 ? colors['C30'] : colors['C2'] + '33' }}
            />
            <div
              className={styles.progressBarSection}
              style={{
                background: data?.vicabMasterNumber > 2 ? colors['C30'] : colors['C2'] + '33',
                borderColor: colors['C2']
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default GameStatistics
