import React, { useContext, useEffect, useState } from 'react'
import exerciseStyles from '../ExerciseModules.module.scss'
import Lock from '../../../components/Lock/Lock'
import colorsContext from '../../../contexts/ColorsContext'
import DraggableItemsCard from '../../../components/DraggableItemsCard/DraggableItemsCard'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import ChatBox from '../../../components/ChatBox/ChatBox'
import Image from '../../../components/Image'
import reactStringReplace from 'react-string-replace'
import {
  checkExerciseItem,
  finishExercise,
  onClickAnswerBoxDADC,
  onClickContinueDAD,
  selectChoiceDNDC,
  setExerciseItemUpdated,
  toggleLockDADC
} from '../../../redux/features/exerciseSlice'
import './../../../operations/dndPollyfill'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import DragDropInlineAnswerBox from '../../../components/DragDropInlineAnswerBox/DragDropInlineAnswerBox'
import ExerciseFullTexts from '../Components/ExerciseFullTexts'
import numberIsOdd from '../../../operations/numberIsOdd'
import Instruction from '../../../components/Instruction/Instruction'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const DragAndDropConversationModule = ({ data }) => {
  const [draggingField, setDraggingField] = useState(null)
  const [draggingItem, setDraggingItem] = useState(null)

  const { colors } = useContext(colorsContext)
  const { checkingItem, exerciseItemUpdated } = useSelector(state => state.exercise)
  const dispatch = useDispatch()

  useEffect(() => {
    if (exerciseItemUpdated) {
      if (data.immediateCheck) {
        let shouldCheckItems = data.stack.items.filter(it => !it.checked && it.checkable)
        if (shouldCheckItems?.length > 0) {
          checkItem(shouldCheckItems)
        }
      }
      dispatch(setExerciseItemUpdated(false))
    }
  }, [exerciseItemUpdated])

  const checkItem = (shouldCheckItems = []) => {
    const userAnswerData = {
      exerciseStackId: data.stack.id,
      userAnswerItems: [...shouldCheckItems]
        .filter(el => el.hasAnswer)
        .map(item => {
          return {
            exerciseItemId: item.id,
            userAnswers: [...item.answerFields].map(answer => {
              return {
                listIndex: answer.index,
                text: answer.userAnswer.text.sentence
              }
            })
          }
        })
    }
    dispatch(checkExerciseItem({ userAnswerData })).then(res => {
      dispatch(onClickContinueDAD({ isConversation: true }))
    })
  }

  const getSelectedAnswerBox = () => {
    for (let i = 0; i < data.stack.items.length; i++) {
      for (let j = 0; j < data.stack.items[i].answerFields.length; j++) {
        const field = data.stack.items[i].answerFields[j]
        if (field.selected) {
          return field
        }
      }
    }
    return null
  }

  const onClickOption = choice => {
    const selectedAnswerBox = getSelectedAnswerBox()
    if (!checkingItem && selectedAnswerBox) {
      dispatch(selectChoiceDNDC({ choice, field: selectedAnswerBox }))
    }
  }

  const onDropOptionToAnswerBox = field => {
    if (draggingItem && draggingItem?.choiceIndex !== field.userAnswer?.choiceIndex) {
      dispatch(selectChoiceDNDC({ choice: draggingItem, field }))
    }

    setDraggingItem(null)
  }

  const onDropOptionToDefaultPlace = (e, choice) => {
    if (e.stopPropagation) e.stopPropagation()

    if (draggingField) {
      dispatch(selectChoiceDNDC({ field: draggingField, choice }))
    }

    setDraggingField(null)
  }

  const renderGivenText = exerciseItem => {
    if (
      exerciseItem.checked &&
      (exerciseItem.userAnswerStatus !== AnswerStatusEnums.INCORRECT || exerciseItem.unlocked)
    ) {
      const userAnswerStatusList = exerciseItem.answerFields.map(field => {
        return field.userAnswerStatus
      })
      return (
        <ExerciseFullTexts
          unlocked={exerciseItem.unlocked}
          fullTexts={[exerciseItem.fullTextAnswer]}
          textColor={colors['C23']}
          userAnswerStatus={userAnswerStatusList}
          template='DROPBOX'
        />
      )
    }

    const arr = reactStringReplace(exerciseItem.givenText.sentence, '###', (match, index) => {
      const boxIndex = (index - 1) / 2
      const fieldData = exerciseItem.answerFields[boxIndex]

      return (
        <DragDropInlineAnswerBox
          key={boxIndex}
          pending={checkingItem}
          checked={exerciseItem.checked}
          unlocked={exerciseItem.unlocked}
          userAnswerStatus={fieldData.userAnswerStatus}
          userAnswer={fieldData.userAnswer?.text}
          correctAnswer={fieldData.correctAnswer?.text}
          selected={fieldData.selected}
          onDragStart={() => {
            setDraggingField(fieldData)
            setDraggingItem(fieldData.userAnswer)
          }}
          onClick={() => {
            dispatch(onClickAnswerBoxDADC({ itemId: exerciseItem.id, fieldIndex: fieldData.index }))
          }}
          onDrop={() => onDropOptionToAnswerBox(fieldData)}
          style={{ marginTop: 4, marginBottom: 4 }}
          bgColor={colors['C524']}
        />
      )
    })

    return arr.map((el, i) => (
      <React.Fragment key={i}>
        {numberIsOdd(i) ? (
          el
        ) : (
          <HtmlTextWrapper
            style={{ display: 'inline' }}
            textColor={colors['C23']}
            optionsColor={colors['C23']}
            showPhraseOptions={i === 0 && (exerciseItem.checked || !exerciseItem.hasAnswer)}
            moreOptionsList={moreOptionsList}
            phraseOptionsDisabled={exerciseItem.checked}
            data={{
              ...exerciseItem.fullTextAnswer,
              sentence: el
            }}
          />
        )}
      </React.Fragment>
    ))
  }

  return (
    <>
      {data.stack.title && <Instruction data={data.stack.title} />}
      <div className={exerciseStyles.dragDropContainer}>
        <div className='container-fluid'>
          {data.stack.items.some(it => !it.checked) && (
            <DraggableItemsCard
              backgroundColor={colors['C521']}
              optionsBackgroundColor={colors['C522']}
              optionsTextColor={colors['C523']}
              itemsData={data.stack.choices}
              onClickOption={onClickOption}
              onDragStart={item => setDraggingItem(item)}
              onDrop={onDropOptionToDefaultPlace}
              isNavMenu
            />
          )}
          <div className={exerciseStyles.dragDropDialogsContainer}>
            {data.stack.items.map((exerciseItem, itemIndex) => {
              return (
                <div className={classNames(exerciseStyles.singleConversationWrapper)} key={itemIndex}>
                  <div className='d-flex w-100'>
                    <div className={exerciseStyles.profile}>
                      <div className={exerciseStyles.avatar}>
                        <Image uri={exerciseItem.character.imageUri} />
                      </div>
                      <span className={exerciseStyles.username} style={{ color: '#F8B133' }}>
                        {exerciseItem.character.name}
                      </span>
                    </div>
                    <ChatBox bgColor={colors['C219']} className={exerciseStyles.conversationDialogBox}>
                      {renderGivenText(exerciseItem, itemIndex)}
                      {exerciseItem.checked && exerciseItem.userAnswerStatus === AnswerStatusEnums.INCORRECT && (
                        <div className={exerciseStyles.lockContainer}>
                          <Lock
                            onClickLock={() => dispatch(toggleLockDADC({ itemIndex }))}
                            isLocked={!exerciseItem.unlocked}
                          />
                        </div>
                      )}
                    </ChatBox>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default DragAndDropConversationModule
