import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import aboutUseCase from '../../useCase/aboutUseCase/aboutUseCase'

const initialState = {
  loading: false,
  aboutData: null,
  error: ''
}

export const getAbout = createAsyncThunk('about/getAbout', () => {
  return aboutUseCase()
})

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAbout.pending, state => {
      state.loading = true
    })
    builder.addCase(getAbout.fulfilled, (state, action) => {
      state.loading = false

      state.aboutData = action.payload
    })

    builder.addCase(getAbout.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = aboutSlice.actions

export default aboutSlice.reducer
