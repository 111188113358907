import style from './StudyDifficultyLevel.module.scss'
import { studyDifficultyLevelsData } from '../../../../enums/studyDifficultyEnums/studyDifficultyEnums'
import { changeStudyHardship } from '../../../../redux/features/studyDifficultySlice'
import RadioButton from '../../../../components/RadioButton/RadioButton'
import React, { useContext } from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import { useDispatch } from 'react-redux'
import useAppUser from '../../../../hooks/useAppUser'
import { setAppUser } from '../../../../redux/features/appUserSlice'
import TranslatedText from '../../../../components/TranslatedText/TranslatedText'
import useTranslatedText from '../../../../hooks/useTranslatedText'

const SettingStudyHardShipLevelComponent = () => {
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()
  const { appUserData } = useAppUser()
  const { dir } = useTranslatedText() // rtl, ltr

  return (
    <div className={style.cards}>
      {studyDifficultyLevelsData.map((item, i) => {
        const selected = appUserData?.setting?.studyHardshipLevel === item.key
        return (
          <button
            key={i}
            className={style.card}
            style={{ background: colors['C270'], borderColor: selected ? item.color : 'transparent' }}
            onClick={() =>
              dispatch(changeStudyHardship({ studyHardshipLevel: item.key })).then(res => {
                if (res?.payload) {
                  dispatch(setAppUser(res.payload))
                }
              })
            }
          >
            <div className={style.circle} style={{ background: item.color }}>
              <p className='font-weight-800' style={{ color: colors['C233'] }}>
                <span className='res:fs-xs'>{item.title}</span>
                <br />
                <span className='res:fs-rs'>{item.percentage}%</span>
              </p>
            </div>
            <div className={style.radioButton}>
              <RadioButton selected color={selected ? item.color : '#D5D8DB'} backgroundColor={colors['C160']} />
            </div>
            <p dir={dir} className='res:fs-md' style={{ color: colors['C2'] }}>
              <TranslatedText translationKey='first.part.difficulty.level' />{' '}
              <span style={{ color: item.color, fontWeight: 700 }}>{item.percentage}%</span>{' '}
              <TranslatedText translationKey='second.part.difficulty.level' />
            </p>
          </button>
        )
      })}
    </div>
  )
}

export default SettingStudyHardShipLevelComponent
