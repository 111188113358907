import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import sentenceDataUseCase from '../../useCase/sentenceDataUseCase/sentenceDataUseCase'
import wordDataUseCase from '../../useCase/wordDataUseCase/wordDataUseCase'
import { ENGLISH_TRANSLATIONS } from '../../enums/languageEnums/languageEnums'

const initialState = {
  translations: [],
  selectedTranslationLanguage: ENGLISH_TRANSLATIONS,
  error: ''
}

export const getSentenceData = createAsyncThunk('translation/getSentenceData', ({ trackerNumber }) =>
  sentenceDataUseCase({ trackerNumber })
)

export const getWordData = createAsyncThunk('translation/getWordData', ({ phrase }) => wordDataUseCase({ phrase }))

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setFavoriteId: (state, action) => {
      state.translations = state.translations.map(item => {
        if (item.trackerNumber === action.payload.trackerNumber || item.title === action.payload.title) {
          return {
            ...item,
            favoriteId: action.payload.favoriteId
          }
        } else {
          return item
        }
      })
    },
    setTranslationsMemoryBoxId: (state, action) => {
      state.translations = state.translations.map(item => {
        if (item.trackerNumber === action.payload.trackerNumber || item.givenTitle === action.payload.givenTitle) {
          return {
            ...item,
            memoryBoxId: action.payload.memoryBoxId
          }
        } else {
          return item
        }
      })
    },
    clearTranslations: state => {
      state.translations = []
    },
    setSelectedTranslationLanguage: (state, action) => {
      state.selectedTranslationLanguage = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getSentenceData.fulfilled, (state, action) => {
      const arr = [...state.translations]

      const i = arr.findIndex(_element => _element?.trackerNumber === action.payload?.trackerNumber)
      if (i > -1) arr[i] = { ...action.payload }
      else arr.push(action.payload)

      state.translations = arr
    })
    builder.addCase(getSentenceData.rejected, (state, action) => {
      state.error = action.error.message
      const arr = [...state.translations]

      const i = arr.findIndex(_element => _element?.trackerNumber === action.meta.arg?.trackerNumber)
      if (i > -1) arr[i] = { trackerNumber: action.meta.arg?.trackerNumber, translate: { translation: ' ' } }
      else if (action.payload?.data) arr.push(action.payload.data)

      state.translations = arr
    })
    builder.addCase(getWordData.fulfilled, (state, action) => {
      const arr = [...state.translations]

      const i = arr.findIndex(_element => _element?.trackerNumber === action.payload?.trackerNumber)
      if (i > -1) arr[i] = { ...action.payload }
      else arr.push(action.payload)

      state.translations = arr
    })
    builder.addCase(getWordData.rejected, (state, action) => {
      state.error = action.error.message
    })
  }
})

export const { setFavoriteId, setTranslationsMemoryBoxId, clearTranslations, setSelectedTranslationLanguage } =
  translationSlice.actions

export default translationSlice.reducer
