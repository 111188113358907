import componentStyle from './DictionaryModal.module.scss'
import classNames from 'classnames'
import HtmlTextWrapper from '../HtmlTextWrapper/HtmlTextWrapper'
import colorsContext from '../../contexts/ColorsContext'
import { useContext } from 'react'
import NativeText from '../NativeText/NativeText'

const SentenceTranslationCard = ({ data }) => {
  const { colors } = useContext(colorsContext)

  return (
    <div className={componentStyle.dictionaryModalCard} style={{ background: colors['C73'], minHeight: 'fit-content' }}>
      <div className={componentStyle.dictionaryHeader}>
        <div style={{ color: colors['C2'] }}>
          <h2 className={classNames(componentStyle.word, 'res:fs-lr')}>
            <HtmlTextWrapper
              textColor={colors['C593']}
              optionsColor={colors['C593']}
              showPhraseOptions
              hideMore
              data={{
                sentence: data?.title,
                voiceUri: data?.voiceUri
              }}
            />
          </h2>
        </div>
      </div>
      <div className={classNames(componentStyle.dictionaryContent, 'mt-0')} style={{ background: colors['C183'] }}>
        <div className={componentStyle.inner} style={{ borderColor: colors['C594'] }}>
          <div className={classNames(componentStyle.text, 'res:fs-md')} style={{ color: colors['C2'] }}>
            <NativeText>{data?.translation}</NativeText>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SentenceTranslationCard
