import { createSlice } from '@reduxjs/toolkit'
import unitStatusUseCase from '../../useCase/unitStatusUseCase/unitStatusUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  loading: false,
  data: null,
  error: null
}

export const getUnitStatus = baseAsyncThunk(
  'status/getUnitStatus',
  ({ unitId, sectionNumber, studyType }, thunkAPI) => {
    const { sharedSection } = thunkAPI.getState()
    return unitStatusUseCase({
      unitId,
      sectionNumber,
      studyType,
      sections: sharedSection?.sectionsList
    })
  }
)

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    clearStatusStates: state => {
      state.loading = false
      state.data = null
      state.error = null
    }
  },
  extraReducers: builder => {
    builder.addCase(getUnitStatus.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getUnitStatus.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload?.statusData
    })
    builder.addCase(getUnitStatus.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { clearStatusStates } = statusSlice.actions

export default statusSlice.reducer
