import { useEffect, useState } from 'react'
import { isAbleToJsonStringify } from '../util/utility'
import * as Sentry from '@sentry/react'

const useLocalStorage = (key, initValue) => {
  const [state, setState] = useState(() => {
    const value = localStorage.getItem(key)
    if (value !== null && value !== 'undefined') {
      return JSON.parse(value)
    }

    if (initValue !== null && isAbleToJsonStringify(initValue)) {
      localStorage.setItem(key, JSON.stringify(initValue))
      window.dispatchEvent(new Event(`storage_${key}`))
    }

    return initValue
  })

  useEffect(() => {
    const storedValue = localStorage.getItem(key)
    if (state !== null && storedValue !== JSON.stringify(state)) {
      localStorage.setItem(key, JSON.stringify(state))
      window.dispatchEvent(new Event(`storage_${key}`))
    }
  }, [key, state])

  useEffect(() => {
    const listenStorageChange = () => {
      setState(() => {
        const value = localStorage.getItem(key)
        if (value !== null) {
          return JSON.parse(value)
        }
        localStorage.setItem(key, JSON.stringify(initValue))
        window.dispatchEvent(new Event(`storage_${key}`))
        return initValue
      })
    }
    window.addEventListener(`storage_${key}`, listenStorageChange)
    return () => {
      window.removeEventListener(`storage_${key}`, listenStorageChange)
    }
  }, [])

  return [state, setState]
}

export default useLocalStorage
