import classNames from 'classnames'
import styles from './ComboBox.module.scss'
import React, { useContext } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import HtmlTextWrapper from '../HtmlTextWrapper/HtmlTextWrapper'

const ComboBox = ({ textColor, borderColor, value }) => {
  const { colors } = useContext(colorsContext)

  return (
    <div
      className={classNames(styles.comboBox, 'justify-content-center')}
      style={{
        borderColor: borderColor,
        background: colors['C516']
      }}
    >
      <HtmlTextWrapper textColor={textColor} className={styles.comboBoxValue} data={{ sentence: value }} />
    </div>
  )
}

export default ComboBox
