import { omit } from 'lodash'
import { serviceAccessTypes } from '../enums/axiosEnums'
import { retryFailedRequests } from './retryFailedRequest'

export const retryOnError = (originalRequest, error) => {
  const DEFAULT_MAX_RETRIES = 3
  const DEFAULT_RETRY_DELAY = 60000 // in milliseconds

  if (error.response?.status === 500 && originalRequest.__retry) {
    const failedRequests = JSON.parse(localStorage.getItem('failedRequests')) || []

    const axiosConfig = {
      url: originalRequest.url,
      method: originalRequest.method,
      headers: omit({ ...originalRequest.headers }, 'Authorization'),
      params: originalRequest.params,
      data: originalRequest.data
      // Other config options as needed
    }

    // Get current request object index from localStorage if exists
    const currentReqIndex = failedRequests.findIndex(it => {
      return JSON.stringify(it?.config) === JSON.stringify(axiosConfig)
    })

    if (currentReqIndex === -1) {
      failedRequests.push({
        accessType: serviceAccessTypes.STUDENT,
        config: axiosConfig,
        status: error.response?.status,
        retryCount: originalRequest.__retryCount || 0,
        maxRetries: originalRequest.__maxRetries || DEFAULT_MAX_RETRIES,
        retryDelay: originalRequest.__retryDelay || DEFAULT_RETRY_DELAY
      })
    } else {
      const currentRequestLocalData = failedRequests[currentReqIndex]
      failedRequests[currentReqIndex] = {
        ...currentRequestLocalData,
        retryCount: currentRequestLocalData?.retryCount + 1
      }
    }

    // Add failed request to localStorage
    localStorage.setItem('failedRequests', JSON.stringify(failedRequests))

    retryFailedRequests()
  }
}
