import {
  AMETHYST,
  AURORA,
  BUTTERFLY,
  CHERRY_BLOSSOM,
  FOREST,
  GLACIER,
  KUALA,
  LAVENDER,
  MINT,
  MOONLIGHT,
  MYSTERY,
  OCEAN_BREEZE,
  OPAL,
  TANGERINE,
  TWILIGHT
} from '../enums/base/themeEnums'

export const getAllColors = async theme => {
  let path = ''

  switch (theme) {
    case LAVENDER:
      path = `/assets/themes/lavenderColors.json`
      break
    case AMETHYST:
      path = `/assets/themes/amethystColors.json`
      break
    case OPAL:
      path = '/assets/themes/opalColors.json'
      break
    case GLACIER:
      path = '/assets/themes/glacierColors.json'
      break
    case KUALA:
      path = '/assets/themes/kualaColors.json'
      break
    case TANGERINE:
      path = '/assets/themes/tangerineColors.json'
      break
    case TWILIGHT:
      path = '/assets/themes/twilightColors.json'
      break
    case FOREST:
      path = '/assets/themes/forestColors.json'
      break
    case MINT:
      path = '/assets/themes/mintColors.json'
      break
    case AURORA:
      path = '/assets/themes/auroraColors.json'
      break
    case MYSTERY:
      path = '/assets/themes/mysteryColors.json'
      break
    case OCEAN_BREEZE:
      path = '/assets/themes/oceanBreezeColors.json'
      break
    case CHERRY_BLOSSOM:
      path = '/assets/themes/cherryBlossomColors.json'
      break
    case BUTTERFLY:
      path = '/assets/themes/butterflyColors.json'
      break
    case MOONLIGHT:
      path = '/assets/themes/moonlightColors.json'
      break
    default:
      path = `/assets/themes/tangerineColors.json`
      break
  }

  const response = await fetch(path, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return await response.json()
}
