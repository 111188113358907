const CartSvg = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='41.845'
      height='36.529'
      viewBox='0 0 41.845 36.529'
      fill={color}
    >
      <defs>
        <filter id='Path_1120' x='0' y='0' width='41.845' height='32.917' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='0.502' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='Path_1121' x='8.387' y='14.971' width='21.113' height='21.16' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur-2' />
          <feFlood floodOpacity='0.502' />
          <feComposite operator='in' in2='blur-2' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='Path_1122' x='17.492' y='15.399' width='21.13' height='21.131' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur-3' />
          <feFlood floodOpacity='0.502' />
          <feComposite operator='in' in2='blur-3' />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g id='Group_8529' data-name='Group 8529' transform='translate(9 6)'>
        <g transform='matrix(1, 0, 0, 1, -9, -6)' filter='url(#Path_1120)'>
          <path
            id='Path_1120-2'
            data-name='Path 1120'
            d='M23.2,23.838c.144.344.682.451,1.014.442,3.569-.116,7.136-.275,10.7-.434,1.223-.055,2.447-.16,3.672-.209.333-.013.8-.127,1.025.153a.315.315,0,0,1-.031.382,7.734,7.734,0,0,0-1.433,3.594c-.167.894-.594,1.089-1.513.931a64.54,64.54,0,0,0-6.6-1.036,27.275,27.275,0,0,0-4.745.482c-.129.015-.255.053-.438.091-.156-.525-.285-1.036-.458-1.529a9.68,9.68,0,0,0-7.123-6.272c-.216-.058-.7.016-.776.284-.085.293.309.636.544.714a10.724,10.724,0,0,1,3.814,1.953,7.655,7.655,0,0,1,2.7,7.146c-.222,1.68-.129,3.178,1.5,4.1a2.177,2.177,0,0,0,1.494.225,13.179,13.179,0,0,1,8.085.249,2.716,2.716,0,0,0,2.576-.193l.053-.275c-1.085-.378-2.163-.774-3.256-1.129a14.593,14.593,0,0,0-7.034-.14,1.729,1.729,0,0,1-2.18-1.307,1.849,1.849,0,0,1,1-2.474,12.3,12.3,0,0,1,2.62-.618,24.381,24.381,0,0,1,8.01.527c1.542.289,2.1-.087,2.429-1.591a7.243,7.243,0,0,1,1.431-3.681.827.827,0,0,0-.171-.769,3.252,3.252,0,0,0-1.429-.373c-3.04.078-6.076.222-9.114.324-1.918.064-3.838.091-5.758.138-.176.005-.355.04-.531.062C23.263,23.678,23.171,23.76,23.2,23.838Z'
            transform='translate(-7.49 -14.41)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, -9, -6)' filter='url(#Path_1121)'>
          <path
            id='Path_1121-2'
            data-name='Path 1121'
            d='M24.868,28.648a1.51,1.51,0,0,0-1.587,1.523,1.672,1.672,0,0,0,1.536,1.636,1.652,1.652,0,0,0,1.576-1.587A1.5,1.5,0,0,0,24.868,28.648Z'
            transform='translate(-5.89 -7.68)'
          />
        </g>
        <g transform='matrix(1, 0, 0, 1, -9, -6)' filter='url(#Path_1122)'>
          <path
            id='Path_1122-2'
            data-name='Path 1122'
            d='M19.8,28.883a1.543,1.543,0,0,0-1.533,1.594,1.69,1.69,0,0,0,1.567,1.536A1.647,1.647,0,0,0,21.393,30.4,1.52,1.52,0,0,0,19.8,28.883Z'
            transform='translate(8.23 -7.48)'
          />
        </g>
      </g>
    </svg>
  )
}

export default CartSvg
