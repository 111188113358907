import serviceCall from '../../services'
import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'
import { ASC, HISTORY } from '../../enums/globalEnums/globalEnums'

const getAllHistoryFlashCardUseCase = async ({ categoryId, searchTerm, sortBy, sortKey }) => {
  const result = await serviceCall(
    'fetchAllHistoryMemoryBoxFlashCards',
    {
      boxType: HISTORY,
      categoryId: categoryId,
      frontText: searchTerm,
      sortAsc: sortBy === ASC,
      sortBy: sortKey
    },
    'cloud'
  )

  return flashCardsMapper(result)
}

export default getAllHistoryFlashCardUseCase
