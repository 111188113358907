import serviceCall from '../../services'
import levelsMapper from '../../services/mapper/levelAndUnit/levelsMapper'

const levelsUseCase = async ({ oldLevels }) => {
  const response = await serviceCall('fetchLevels', null, 'cloud')

  return levelsMapper(response, oldLevels)
}

export default levelsUseCase
