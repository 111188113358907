import getStyleObjectFromString from './inlineCssToReactCss'

const getParagraphList = sentence => {
  const arr = sentence.match(/<p[^>]*>.*?<\/p>/g)

  let i = 0

  if (arr) {
    return [...arr].map(str => {
      const strFieldsCount = (str.match(/###/g) || []).length
      const paragraphStyleExec = /style=\"(.*?)\"/gi.exec(str)
      let paragraphStyle = {}
      if (paragraphStyleExec && paragraphStyleExec.length > 1) {
        paragraphStyle = getStyleObjectFromString(paragraphStyleExec[1])
      }

      const obj = {
        fieldIndexStartsFrom: i,
        containsField: strFieldsCount > 0,
        paragraphStyle: paragraphStyle ?? {},
        string: str
      }

      i += strFieldsCount

      return obj
    })
  }

  return []
}

export default getParagraphList
