const vocabularyFullTableMapper = (data, baseData) => {
  const tableBodyRows = data.map(item => {
    const partOfSpeech = item.partOfSpeeches?.map(ps => ps?.partOfSpeech ?? '')?.join('')
    return {
      id: item.index,
      columns: [
        {
          text: {
            sentence: item.unitId ?? ''
          },
          isTranslate: false
        },
        {
          text: {
            sentence: item.title ?? ''
          },
          isTranslate: false
        },
        {
          text: {
            sentence: item.phonetic ?? ''
          },
          isTranslate: false
        },
        {
          text: {
            sentence: partOfSpeech ?? ''
          },
          isTranslate: false
        },
        {
          text: {
            sentence: item.translation ?? ''
          },
          isTranslate: true
        }
      ]
    }
  })

  if (baseData) {
    return {
      ...baseData,
      tableBodyRows: [...baseData.tableBodyRows, ...tableBodyRows]
    }
  }
  return {
    tableHeaderRows: [
      {
        text: {
          sentence: 'Unit'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Words'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Phonetics'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'P.S.'
        },
        isTranslate: false
      },
      {
        text: {
          sentence: 'Translation'
        },
        isTranslate: true
      }
    ],
    tableColumnsConfig: [
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: false },
      { isCenter: true, isTranslate: true }
    ],
    tableBodyRows: tableBodyRows
  }
}

export default vocabularyFullTableMapper
