import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import setSettingStudyReminderUseCase from '../../useCase/setting/setSettingStudyReminderUseCase'

const initialState = {
  loading: false,
  setUserAvatarLoading: false,
  privacySettings: null,
  notificationSettings: null,
  error: ''
}

export const setUserAvatar = createAsyncThunk('setting/setUserAvatar', ({ avatarType }) => {
  return serviceCall('updateUserAvatar', { avatarType }, 'cloud')
})

export const setPrivacySettings = createAsyncThunk(
  'setting/setPrivacySettings',
  ({ onlineGame, profilePhoto, identifyAs }) => {
    return serviceCall('postPrivacySettings', { onlineGame, profilePhoto, identifyAs }, 'cloud')
  }
)

export const setNotificationSettings = createAsyncThunk('setting/setNotificationSettings', ({ values }) => {
  return serviceCall('updateNotificationSettings', { values }, 'cloud')
})

export const setStudyReminderSettings = createAsyncThunk('setting/setStudyReminderSettings', ({ param }) =>
  setSettingStudyReminderUseCase({ param })
)

export const setFirstNameSettings = createAsyncThunk('setting/setFirstNameSettings', ({ firstName }) => {
  return serviceCall('postFirstNameSettings', { firstName }, 'cloud')
})

export const setLastNameSettings = createAsyncThunk('setting/setLastNameSettings', ({ lastName }) => {
  return serviceCall('postLastNameSettings', { lastName }, 'cloud')
})

export const setPhoneNumberSettings = createAsyncThunk('setting/setPhoneNumberSettings', ({ phoneNumber }) => {
  return serviceCall('postPhoneNumberSettings', { phoneNumber }, 'cloud')
})

export const setEmailSettings = createAsyncThunk('setting/setEmailSettings', ({ email }) => {
  return serviceCall('postEmailSettings', { email }, 'cloud')
})

export const setAddressSettings = createAsyncThunk('setting/setAddressSettings', ({ address }) => {
  return serviceCall('postAddressSettings', { address }, 'cloud')
})

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  extraReducers: builder => {
    // set Privacy setting
    builder.addCase(setPrivacySettings.pending, state => {
      state.loading = true
    })
    builder.addCase(setPrivacySettings.fulfilled, (state, action) => {
      state.loading = false
      state.privacySettings = action.payload
    })
    builder.addCase(setPrivacySettings.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // set Study Reminder Settings
    builder.addCase(setStudyReminderSettings.pending, state => {
      state.loading = true
    })
    builder.addCase(setStudyReminderSettings.fulfilled, (state, action) => {
      state.loading = false
      state.studyReminderSettings = action.payload
    })
    builder.addCase(setStudyReminderSettings.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // set user avatar setting
    builder.addCase(setUserAvatar.pending, state => {
      state.setUserAvatarLoading = true
    })
    builder.addCase(setUserAvatar.fulfilled, (state, action) => {
      state.setUserAvatarLoading = false
    })
    builder.addCase(setUserAvatar.rejected, (state, action) => {
      state.setUserAvatarLoading = false
      state.error = action.error.message
    })
  }
})

export const {} = settingSlice.actions

export default settingSlice.reducer
