import serviceCall from '../../services'
import aboutMapper from '../../services/mapper/about/aboutMapper'

const aboutUseCase = async () => {
  const result = await serviceCall('fetchAboutDescription', null, 'cloud')

  return aboutMapper(result)
}

export default aboutUseCase
