import { isEmpty } from 'lodash'

const fetchStructureMapper = data => {
  if (typeof data === 'object') {
    return {
      id: data.id,
      unitId: data.unitId,
      sectionType: data.sectionType,
      sectionImageNumber: data.sectionImageNumber,
      createDate: data.createDate,
      disableWordColor: false,
      isSingleView: false,
      currentPageIndex: 0,
      sectionData: {
        templates: data.sectionData?.templates ?? [],
        singlePages: data.sectionData?.singlePages
          ? data.sectionData.singlePages.filter(o => !isEmpty(o) && !isEmpty(o?.templateIds))
          : [],
        wordColors: data.sectionData?.wordColors ? data.sectionData.wordColors.filter(o => !isEmpty(o?.title)) : []
      }
    }
  }
  return data
}

export default fetchStructureMapper
