import React, { useContext } from 'react'
import styles from './SecondaryButton.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import colorsContext from '../../../contexts/ColorsContext'
import { isFunction } from 'lodash'

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: any
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  linkTo?: string
  linkState?: any
  isWhite?: boolean
  className?: string
  color?: string
  backgroundColor?: string
  borderColor?: string
  style?: object
  dir?: string
  loading?: boolean
}

const SecondaryButton = ({
  onClick,
  children,
  disabled,
  type,
  linkTo,
  linkState,
  isWhite,
  className,
  color,
  backgroundColor,
  borderColor,
  style,
  dir = 'auto',
  loading
}: Props) => {
  const { colors }: any = useContext(colorsContext)
  const btnStyle = {
    background: backgroundColor ?? (isWhite ? colors['C160'] : colors['C11']),
    borderColor: borderColor ?? colors['C160'],
    color: color ?? (isWhite ? '#000' : colors['C179']),
    opacity: loading ? 0.5 : 1,
    ...style
  }

  if (linkTo) {
    return (
      <Link
        className={classNames(styles.buttonPrimary, { [styles.buttonDisabled]: disabled }, className)}
        to={linkTo}
        state={linkState}
        style={btnStyle}
        dir={dir}
      >
        {children}
        <span
          className={classNames(styles.spinner, { [styles.startSpin]: loading })}
          style={
            {
              '--spinner-color': disabled ? '#C4C9CE' : color ?? (isWhite ? '#000' : colors['C179'])
            } as React.CSSProperties
          }
        />
      </Link>
    )
  }
  return (
    <button
      className={classNames(styles.buttonPrimary, { [styles.buttonDisabled]: disabled }, className)}
      onClick={!disabled && !loading && isFunction(onClick) ? onClick : () => 0}
      disabled={loading || disabled}
      type={type}
      style={btnStyle}
      dir={dir}
    >
      {children}
      <span
        className={classNames(styles.spinner, { [styles.startSpin]: loading })}
        style={
          {
            '--spinner-color': disabled ? '#C4C9CE' : color ?? (isWhite ? '#000' : colors['C179'])
          } as React.CSSProperties
        }
      />
    </button>
  )
}

export default SecondaryButton
