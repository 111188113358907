import * as React from 'react'

const ExerciseSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <g>
      <path d='M124.7,455.8c-24.8,0-42.7-16.3-49.5-26.3c-5.4-7.9-3.3-18.6,4.6-23.9c7.8-5.3,18.5-3.3,23.9,4.5c0.5,0.7,9,12.1,22.5,11.3 c8.3-0.5,14.1-5.3,17.2-8.7V97.5c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v320.9c0,3.2-0.9,6.4-2.6,9.1 c-2.7,4.4-17.8,26.4-47,28.2C127,455.8,125.9,455.8,124.7,455.8z' />
      <path d='M89.5,437c-9.5,0-17.2-7.7-17.2-17.2V375c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v44.8C106.7,429.3,99,437,89.5,437z' />
      <path d='M89.5,390c-0.2,0-0.3,0-0.5,0l-19.5-0.5c-1.8-0.1-3.7-0.4-5.4-1c-7.9-2.9-22.5-10.2-31.4-26.8c-3.9-7.4-6.1-15.5-6.4-24.1 c-0.4-9.5,7-17.5,16.5-17.9c9.5-0.4,17.5,7,17.9,16.5c0.1,3.3,1,6.5,2.4,9.3c2.8,5.2,7.3,8.1,10.4,9.6l16.3,0.4 c9.5,0.3,17,8.2,16.8,17.7C106.4,382.6,98.8,390,89.5,390z' />
      <path d='M43.6,354.1c-9.5,0-17.2-7.7-17.2-17.2v-29.4c-19-11.7-29.8-33.4-25.9-54.7c5.2-28,31.9-38.4,35-39.5 c8.9-3.2,18.8,1.4,22.1,10.4c3.2,8.9-1.4,18.7-10.2,22c-0.5,0.2-11.3,4.6-12.9,13.4c-1.4,7.5,3.8,17.5,14.9,21.3 c6.9,2.4,11.6,8.9,11.6,16.3v40.2C60.8,346.4,53.1,354.1,43.6,354.1z' />
      <path d='M41.7,240.5c-9.5,0-17.2-7.7-17.2-17.2V184c-0.9-6.6-1.8-24.2,11.9-38.4c12.2-12.8,42.1-17.9,58-13.1 c9.1,2.7,14.3,12.3,11.6,21.4c-2.7,9.1-12.3,14.3-21.4,11.6c-4.8-1.4-19.5,1.3-23.5,4.1c-3.5,3.8-2.5,9.5-2.5,9.7 c0.2,1.1,0.3,2.2,0.3,3.3v40.7C58.9,232.8,51.2,240.5,41.7,240.5z M60.9,169.6C60.9,169.6,60.9,169.6,60.9,169.6 C60.9,169.6,60.9,169.6,60.9,169.6z' />
      <path d='M89.5,160.9c-9.5,0-17.2-7.7-17.2-17.2V97.4c-0.9-7.8,1.3-15.9,6.5-23c8-11.1,22.4-19.3,37.7-21.4c22-3,43.8,6.6,56.9,25 c5.5,7.8,3.7,18.5-4,24c-7.8,5.5-18.5,3.7-24-4c-5.7-7.9-15-12.1-24.3-10.8c-7.7,1-12.9,5.3-14.4,7.4c0,0.5,0.1,1.1,0.1,1.6v47.5 C106.7,153.2,99,160.9,89.5,160.9z M106.4,92.9C106.4,92.9,106.4,92.9,106.4,92.9C106.4,92.9,106.4,92.9,106.4,92.9z' />
      <path d='M311.5,273.2H200.3c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2h111.1c9.5,0,17.2,7.7,17.2,17.2 C328.7,265.5,321,273.2,311.5,273.2z' />
      <path d='M387,457.6c-1.1,0-2.3,0-3.5-0.1c-29.1-1.8-44.3-23.8-47-28.2c-1.7-2.7-2.6-5.9-2.6-9.1V99.2c0-9.5,7.7-17.2,17.2-17.2 s17.2,7.7,17.2,17.2v315.2c3.1,3.4,8.9,8.2,17.2,8.7c13.5,0.8,21.9-10.5,22.5-11.3c5.4-7.8,16-9.8,23.9-4.5 c7.9,5.4,9.9,16.1,4.6,23.9C429.7,441.3,411.9,457.6,387,457.6z' />
      <path d='M422.3,438.8c-9.5,0-17.2-7.7-17.2-17.2v-44.8c0-9.5,7.7-17.2,17.2-17.2s17.2,7.7,17.2,17.2v44.8 C439.5,431.1,431.8,438.8,422.3,438.8z' />
      <path d='M422.3,391.8c-9.3,0-17-7.4-17.2-16.8c-0.3-9.5,7.2-17.4,16.8-17.7l16.3-0.4c3.1-1.5,7.7-4.4,10.4-9.6 c1.5-2.8,2.3-5.9,2.4-9.3c0.4-9.5,8.4-16.9,17.9-16.5c9.5,0.4,16.9,8.4,16.5,17.9c-0.3,8.6-2.5,16.7-6.4,24.1 c-8.8,16.6-23.4,23.9-31.4,26.8c-1.7,0.6-3.6,1-5.4,1l-19.5,0.5C422.6,391.8,422.4,391.8,422.3,391.8z' />
      <path d='M468.2,355.9c-9.5,0-17.2-7.7-17.2-17.2v-40.2c0-7.3,4.7-13.9,11.6-16.3c11.1-3.8,16.2-13.9,14.9-21.3 c-1.6-8.8-12.3-13.2-12.9-13.4c-8.9-3.3-13.4-13.1-10.2-22c3.2-9,13.1-13.6,22.1-10.4c3,1.1,29.8,11.5,35,39.5 c3.9,21.3-6.8,43-25.9,54.7v29.4C485.4,348.2,477.7,355.9,468.2,355.9z' />
      <path d='M470.1,242.3c-9.5,0-17.2-7.7-17.2-17.2v-40.7c0-1.1,0.1-2.3,0.3-3.4c0-0.2,1-5.8-2.5-9.6c-4-2.9-18.7-5.5-23.5-4.1 c-9.1,2.7-18.7-2.5-21.4-11.6c-2.7-9.1,2.5-18.7,11.6-21.4c15.9-4.7,45.8,0.4,58,13.1c13.7,14.3,12.8,31.8,11.9,38.4v39.4 C487.3,234.6,479.6,242.3,470.1,242.3z M450.8,171.4C450.8,171.4,450.8,171.4,450.8,171.4L450.8,171.4z' />
      <path d='M422.3,162.6c-9.5,0-17.2-7.7-17.2-17.2V97.9c0-0.5,0-1.1,0.1-1.6c-1.4-2.1-6.6-6.4-14.4-7.4c-9.3-1.3-18.6,2.9-24.3,10.8 c-5.5,7.8-16.3,9.6-24,4c-7.8-5.5-9.6-16.3-4-24c13.1-18.4,34.9-28,56.9-25c15.2,2,29.7,10.2,37.7,21.4c5.1,7.2,7.4,15.3,6.5,23 v46.2C439.5,154.9,431.8,162.6,422.3,162.6z M405.4,94.7C405.4,94.7,405.4,94.7,405.4,94.7C405.4,94.7,405.4,94.7,405.4,94.7z' />
      <path d='M99.7,395.1c-3.6,0-6.5-2.9-6.5-6.5V151.1c0-3.6,2.9-6.5,6.5-6.5s6.5,2.9,6.5,6.5v237.5 C106.2,392.1,103.3,395.1,99.7,395.1z' />
      <path d='M412.1,381.1c-3.6,0-6.5-2.9-6.5-6.5V137.1c0-3.6,2.9-6.5,6.5-6.5s6.5,2.9,6.5,6.5v237.5 C418.6,378.2,415.7,381.1,412.1,381.1z' />
    </g>
  </svg>
)

export default ExerciseSvg
