import React from 'react'
import classNames from 'classnames'
import MoreSvg from '../../icons/MoreSvg'
import useQueryParams from '../../hooks/useQueryParams'

const ThreeDotButton = ({ type, className, moreOptionsList, color, data, unitId }) => {
  const key = type === 'dictionary' ? data : data?.trackerNumber
  const { addQueryParams } = useQueryParams()

  return (
    <>
      <button
        style={{ width: '100%' }}
        className={classNames('tools-3dot', className)}
        onClick={() => {
          addQueryParams({
            openPhraseOptions: key,
            phraseOptions: moreOptionsList,
            phraseUnit: unitId,
            phraseType: type
          })
        }}
      >
        <MoreSvg color={color} />
      </button>
    </>
  )
}

export default ThreeDotButton
