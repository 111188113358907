const tedVideoMapper = data => {
  return {
    id: data?.id || '',
    name: data?.name || '',
    url: data?.url || '',
    imageUri: data?.image || '',
    description: data?.description || ''
  }
}

export default tedVideoMapper
