import { useEffect, useMemo, useRef } from 'react'

const useWakeLockApi = () => {
  const wakeLock = useRef(null)
  const wakeLockAvailable = useMemo(() => 'wakeLock' in navigator, [])

  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden' && wakeLock !== null) {
      releaseWakeLock()
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  const requestWakeLock = async () => {
    if (wakeLockAvailable) {
      try {
        wakeLock.current = await navigator.wakeLock.request('screen')
      } catch (err) {
        console.log(`${err.name}, ${err.message}`)
      }
    }
  }

  const releaseWakeLock = () => {
    if (wakeLock != null) {
      wakeLock.current?.release().then(() => {
        wakeLock.current = null
      })
    }
  }

  return { requestWakeLock, releaseWakeLock }
}

export default useWakeLockApi
