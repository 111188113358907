import componentStyle from './BuyButton.module.scss'
import CartSvg from '../../icons/CartSvg'
import { useContext } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import { Link, useNavigate } from 'react-router-dom'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import TranslatedText from '../TranslatedText/TranslatedText'

const BuyButton = () => {
  const { colors } = useContext(colorsContext)

  const navigate = useNavigate()

  return (
    <PrimaryButton
      backgroundColor={'#E98020'}
      color={colors['C233']}
      onClick={() => {
        navigate('/payment')
      }}
    >
      <div className={componentStyle.icon}>
        <CartSvg color={colors['C233']} />
      </div>
      <p>
        <TranslatedText translationKey={'subscribe'} />
      </p>
    </PrimaryButton>
  )
}

export default BuyButton
