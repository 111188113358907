import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getBoxesUseCase from '../../../useCase/memoryBoxUseCase/getBoxesUseCase'
import getYourSelectionBoxesUseCase from '../../../useCase/memoryBoxUseCase/getYourSelectionBoxesUseCase'

const initialState = {
  loading: false,
  boxesData: [],
  error: ''
}

export const getBoxes = createAsyncThunk('memoryBoxBoxes/getBoxes', ({ categoryId }) => {
  return getBoxesUseCase({ categoryId })
})

export const getYourSelectionBoxes = createAsyncThunk(
  'memoryBoxBoxes/getYourSelectionBoxes',
  ({ page, categoryId }) => {
    return getYourSelectionBoxesUseCase({ page, categoryId })
  }
)

const boxSlice = createSlice({
  name: 'memoryBoxBoxes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //get boxes
    builder.addCase(getBoxes.pending, state => {
      state.loading = true
    })
    builder.addCase(getBoxes.fulfilled, (state, action) => {
      state.loading = false
      state.boxesData = action.payload
    })
    builder.addCase(getBoxes.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get Your Selection boxes
    builder.addCase(getYourSelectionBoxes.pending, state => {
      state.loading = true
    })
    builder.addCase(getYourSelectionBoxes.fulfilled, (state, action) => {
      state.loading = false
      state.boxesData = action.payload
    })
    builder.addCase(getYourSelectionBoxes.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = boxSlice.actions

export default boxSlice.reducer
