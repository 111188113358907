export const GRAMMAR = 'GRAMMAR'
export const CONVERSATION = 'CONVERSATION'
export const PICTIONARY = 'PICTIONARY'
export const POINT_PLUS = 'POINT_PLUS'
export const READING = 'READING'
export const WRITING = 'WRITING'

const data = {
  GRAMMAR: {
    title: 'Grammar'
  },
  CONVERSATION: {
    title: 'Conversation'
  },
  PICTIONARY: {
    title: 'Pictionary'
  },
  POINT_PLUS: {
    title: 'Point Plus'
  },
  READING: {
    title: 'Reading'
  },
  WRITING: {
    title: 'Writing'
  }
}

export const getTableOfContentSectionTitleByKey = key => {
  return data[key]?.title ?? ''
}
