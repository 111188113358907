import React, { useContext, useState } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import SpeechCircleButton from '../../../components/SpeechCircleButton/SpeechCircleButton'
import SpeechModal from '../../../components/SpeechModal/SpeechModal'
import PaginableExerciseModule from '../PaginableExerciseModule'
import { checkExerciseItem, goToNextItem, setSayTheFollowingSpeechType } from '../../../redux/features/exerciseSlice'
import { moreOptionsList } from '../../../data'
import ExerciseItemCheck from '../../../components/ExerciseItemCheck/ExerciseItemCheck'
import ExerciseMistakeInfoButton from '../../../components/ExerciseMistakeInfoButton/ExerciseMistakeInfoButton'
import { pushSpeechRecognitionHistory } from '../../../redux/features/speechToTextSlice'
import speechRecognitionHistoryFactory from '../../../factory/speechRecognitionHistoryFactory'
import { speechToTextTypeEnums } from '../../../enums/speechToTextEnums/speechToTextEnums'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const SayTheFollowingModule = ({ data }) => {
  const { colors } = useContext(colorsContext)
  const [recordingText, setRecordingText] = useState(null)
  const dispatch = useDispatch()
  const [recordModalIsOpen, setRecordModalIsOpen] = useState(false)
  const [recordingItemData, setRecordingItemData] = useState({
    stackIndex: null,
    itemIndex: null
  })
  const { isSingleTemplate, currentItem, checkingItem } = useSelector(state => state.exercise)
  const { speechRecognitionHistory, speechRecognitionType } = useSelector(state => state.speechToText)

  const focusOnCurrentItem = () => {
    const nextItemElement = document.querySelector(
      `[data-exercise-item="${currentItem.stackIndex}-${currentItem.itemIndex}"]`
    )

    nextItemElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })

    nextItemElement?.classList.add(exerciseStyles.highlightCardItem)
    setTimeout(() => {
      nextItemElement?.classList.remove(exerciseStyles.highlightCardItem)
    }, 600)
  }

  const onCheckExerciseItem = (text, stackIndex, itemIndex) => {
    if (typeof stackIndex === 'number' && typeof itemIndex === 'number') {
      const exerciseItem = data.stacks[stackIndex].items[itemIndex]
      const skipped = !text

      const userAnswerData = {
        exerciseStackId: data.stacks[stackIndex].id,
        userAnswerItems: [
          {
            exerciseItemId: exerciseItem.id,
            userAnswers: [
              {
                listIndex: 0,
                text: text,
                skipped
              }
            ]
          }
        ]
      }
      dispatch(checkExerciseItem({ userAnswerData, isVoiceRecognition: true })).then(res => {
        if (res.payload?.status === 200) {
          if (!exerciseItem.checked && !isSingleTemplate) {
            dispatch(goToNextItem())
          }

          if (skipped) {
            if (isSingleTemplate) {
              dispatch(goToNextItem())
            }
          } else {
            const currentSpeechType = exerciseItem.speechType ?? speechRecognitionType
            const isCorrect =
              res.payload?.data?.exerciseCheckItemResponse?.[0]?.userAnswerItemResponse?.[0]?.userAnswerStatus ===
              AnswerStatusEnums.CORRECT

            const speechData = {
              input: text,
              given: exerciseItem.text.sentenceString,
              practiceType: 'SPEAKING_EXERCISE',
              speechType: currentSpeechType,
              isCorrect
            }

            const { newHistory, newSpeechType, readyToSkip } = speechRecognitionHistoryFactory(
              currentSpeechType,
              speechRecognitionHistory,
              speechData
            )

            dispatch(pushSpeechRecognitionHistory(newHistory))
            dispatch(setSayTheFollowingSpeechType({ stackIndex, itemIndex, speechType: newSpeechType }))

            if (readyToSkip) {
              if (isSingleTemplate) dispatch(goToNextItem())
              else focusOnCurrentItem()
            }
          }
        }

        setRecordModalIsOpen(false)
        setRecordingText(null)
      })
    } else {
      setRecordModalIsOpen(false)
    }
  }

  const onRecordFinished = text => {
    if (text?.length > 0) {
      onCheckExerciseItem(text, recordingItemData.stackIndex, recordingItemData.itemIndex)
    } else {
      setRecordModalIsOpen(false)
      setRecordingText(null)
    }
  }

  const renderExerciseItem = (exerciseItem, exerciseItemClass, stackIndex, itemIndex, enabled) => {
    if (exerciseItem.checked) {
      return (
        <div
          data-exercise-item={`${stackIndex}-${itemIndex}`}
          className={classNames(exerciseItemClass, exerciseStyles.sayTheFollowingCard, exerciseStyles.checked)}
          style={{
            background: colors['C516'],
            borderColor: !data.stacks[stackIndex].joined
              ? exerciseItem.userAnswerData.userAnswerStatus === AnswerStatusEnums.CORRECT
                ? colors['C30']
                : colors['C29']
              : null,
            '--highlight-color': colors['C531']
          }}
          key={itemIndex}
        >
          <div className='d-flex align-items-baseline'>
            <div
              className={exerciseStyles.cardNumberBadge}
              style={{
                background: colors['C144'],
                color: colors['C233'],
                borderColor: exerciseItem.checked
                  ? exerciseItem.userAnswerData.userAnswerStatus === AnswerStatusEnums.CORRECT
                    ? colors['C30']
                    : colors['C29']
                  : null
              }}
            >
              {itemIndex + 1}
            </div>
            <div className={exerciseStyles.phrases}>
              <div className={exerciseStyles.text}>
                <HtmlTextWrapper
                  moreOptionsList={moreOptionsList}
                  showPhraseOptions
                  optionsColor={colors['C233']}
                  textColor={colors['C233']}
                  data={exerciseItem.text}
                />
                {exerciseItem.userAnswerData.userAnswerStatus !== AnswerStatusEnums.SKIPPED && (
                  <p
                    className='mt-2'
                    style={{
                      color:
                        exerciseItem.userAnswerData.userAnswerStatus === AnswerStatusEnums.CORRECT
                          ? colors['C30']
                          : colors['C29']
                    }}
                  >
                    {exerciseItem.userAnswerData.userAnswerStatus === AnswerStatusEnums.CORRECT
                      ? exerciseItem.userAnswerData.correctAnswer
                      : exerciseItem.userAnswerData.userAnswer}
                    {/*{exerciseItem.userAnswerData.wordByWordResult?.filter(word => word.userStr)?.map((word, wordIndex) => {*/}
                    {/*  return (*/}
                    {/*    <>*/}
                    {/*      <span key={wordIndex} style={{color: word.match ? colors['C30'] : colors['C29']}}>*/}
                    {/*        {(word.correctStr) + ' '}*/}
                    {/*      </span>*/}
                    {/*    </>*/}
                    {/*  )*/}
                    {/*})}*/}
                    {exerciseItem.isCorrectWithMistake && (
                      <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        <ExerciseMistakeInfoButton
                          wordByWordResult={exerciseItem.userAnswerData.wordByWordResult}
                          popoverPlacement='bottom'
                        />
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div>
            <SpeechCircleButton
              className='mx-auto mb-2'
              isReset
              onClick={() => {
                setRecordingText(exerciseItem.text)
                setRecordingItemData({ stackIndex, itemIndex })
                setRecordModalIsOpen(true)
              }}
              iconColor={colors['C233']}
              borderColor={colors['C233']}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div
          data-exercise-item={`${stackIndex}-${itemIndex}`}
          className={classNames(exerciseItemClass, exerciseStyles.sayTheFollowingCard, {
            [exerciseStyles.cardDisabled]: !enabled
          })}
          style={{ background: colors['C527'], '--highlight-color': colors['C531'] }}
          key={itemIndex}
        >
          <div className='d-flex align-items-baseline'>
            <div
              className={exerciseStyles.cardNumberBadge}
              style={{ background: colors['C144'], color: colors['C233'] }}
            >
              {itemIndex + 1}
            </div>
            <div className={exerciseStyles.phrases}>
              <div className={exerciseStyles.text}>
                <HtmlTextWrapper
                  moreOptionsList={moreOptionsList}
                  showPhraseOptions
                  optionsColor={colors['C23']}
                  textColor={colors['C23']}
                  data={exerciseItem.text}
                />
              </div>
            </div>
          </div>
          <div>
            <SpeechCircleButton
              className='mx-auto mb-2'
              onClick={() => {
                setRecordingText(exerciseItem.text)
                setRecordingItemData({ stackIndex, itemIndex })
                setRecordModalIsOpen(true)
              }}
              iconColor={colors['C531']}
              borderColor={colors['C531']}
            />
            <ExerciseItemCheck
              borderColor={colors['C531']}
              color={colors['C531']}
              isBorder
              style={{ opacity: checkingItem && enabled ? 0.6 : 1 }}
              onClick={() => {
                if (!checkingItem) {
                  onCheckExerciseItem(null, stackIndex, itemIndex)
                }
              }}
            >
              Skip
            </ExerciseItemCheck>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className={exerciseStyles.sayTheFollowingContainer}>
        <PaginableExerciseModule renderExerciseItem={renderExerciseItem} />
      </div>
      {recordModalIsOpen && (
        <SpeechModal
          speechType={data?.stacks?.[recordingItemData.stackIndex]?.items?.[recordingItemData.itemIndex]?.speechType}
          given={recordingText.sentenceString}
          sentence={recordingText.sentenceString}
          onRecordFinished={text => {
            onRecordFinished(text)
          }}
          loading={checkingItem}
        />
      )}
    </>
  )
}

export default SayTheFollowingModule
