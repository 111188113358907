import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getReviewMistakesUseCase from '../../useCase/reviewMistake/getReviewMistakesUseCase'
import getReviewMistakeExerciseUseCase from '../../useCase/reviewMistake/getReviewMistakeExerciseUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  loading: false,
  mistakesData: [],
  error: ''
}

export const getMistakes = baseAsyncThunk('reviewMistake/getMistakes', ({ unit, studyType }) => {
  return getReviewMistakesUseCase({ unit, studyType })
})

export const getReviewMistakeExercise = createAsyncThunk(
  'reviewMistake/getReviewMistakeExercise',
  ({ unit, section, sectionNumber, groupType, filterByIds, filterByUserAnswerData }) => {
    return getReviewMistakeExerciseUseCase({
      unit,
      section,
      sectionNumber,
      groupType,
      filterByIds,
      filterByUserAnswerData
    })
  }
)

const reviewMistakeSlice = createSlice({
  name: 'reviewMistake',
  initialState,
  reducers: {
    resetReviewMistakesData: state => {
      state.mistakesData = []
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(getMistakes.pending, state => {
      state.loading = true
    })
    builder.addCase(getMistakes.fulfilled, (state, action) => {
      state.loading = false
      state.mistakesData = action.payload ?? []
    })
    builder.addCase(getMistakes.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getReviewMistakeExercise.pending, state => {
      state.loading = true
    })
    builder.addCase(getReviewMistakeExercise.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(getReviewMistakeExercise.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { resetReviewMistakesData } = reviewMistakeSlice.actions

export default reviewMistakeSlice.reducer
