import flashCardMapper from './flashCardMapper'
import { shuffle } from 'lodash'

const flashCardsMapper = data => {
  if (data?.setting.orderOfDisplay === 'RANDOM') {
    data.results = shuffle(data?.results)
  }
  return {
    ...data,
    results:
      data?.results.map(flashCard => {
        return flashCardMapper(flashCard)
      }) || [],
    total: data?.total || 0
  }
}

export default flashCardsMapper
