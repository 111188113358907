import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import newspapersUseCase from '../../useCase/newspapersUseCase/newspapersUseCase'

const initialState = {
  newspapers: [],
  selectedNewspaper: null,
  error: null,
  loading: false
}

export const getNewspapers = createAsyncThunk('newspapers/getNewspapers', () => {
  return newspapersUseCase()
})

const newspaperSlice = createSlice({
  name: 'newspapers',
  initialState,
  reducers: {
    setSelectedNewspaper: (state, action) => {
      state.selectedNewspaper = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getNewspapers.pending, state => {
        state.loading = true
      })
      .addCase(getNewspapers.fulfilled, (state, action) => {
        state.loading = false
        state.newspapers = action.payload
      })
      .addCase(getNewspapers.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const { setSelectedNewspaper } = newspaperSlice.actions
export default newspaperSlice.reducer
