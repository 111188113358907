import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import colorsContext from '../../contexts/ColorsContext'
import ListeningSvg from '../../icons/ListeningSvg'
import SpeakingSvg from '../../icons/SpeakingSvg'
import SpellingSvg from '../../icons/SpellingSvg'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getSentenceData, setTranslationsMemoryBoxId } from '../../redux/features/translationSlice'
import AddToMemoryBoxSvg from '../../icons/AddToMemoryBoxSvg'
import TranslationSvg from '../../icons/TranslationSvg'
import AddToFavoriteButton from '../AddToFavoriteButton/AddToFavoriteButton'
import SentenceTranslationCard from './SentenceTranslationCard'
import { addFlashCardInLesson, removeFromBox } from '../../redux/features/memoryBox/memoryBoxActionsSlice'
import RemoveFromMemoryBoxSvg from '../../icons/RemoveFromMemoryBoxSvg'
import { BOX, FAVORITES, LISTENING, SPEAKING, SPELLING, TRANSLATE } from '../../enums/toolsEnums/moreOptionsEnums'
import { setToolsGivenStates } from '../../redux/features/tools/toolsSlice'
const ThreeDotModal = ({ optionsList, data, unitId }) => {
  require('./../../styles/tools.scss')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { colors } = useContext(colorsContext)
  const { translations } = useSelector(state => state.translation)
  const { loading } = useSelector(state => state.memoryBoxActions)
  const [sentenceData, setSentenceData] = useState(null)
  const [showTranslation, setShowTranslation] = useState(false)
  const [memoryBoxButtonTitle, setMemoryBoxButtonTitle] = useState('')
  const [isMemoryBox, setIsMemoryBox] = useState(false)
  const { unit } = useParams()

  const unitNumber = parseInt(unit || unitId) || 0

  useEffect(() => {
    setIsMemoryBox(!isEmpty(sentenceData?.memoryBoxId))
  }, [sentenceData])

  useEffect(() => {
    if (isMemoryBox) {
      setMemoryBoxButtonTitle('Remove from Memory Box')
    } else {
      setMemoryBoxButtonTitle('Add to Memory Box')
    }
  }, [isMemoryBox])

  const translationData = useMemo(() => {
    return translations.find(_o => _o.trackerNumber === data?.trackerNumber)
  }, [translations])

  useEffect(() => {
    let p = true
    if (!isEmpty(data?.trackerNumber)) {
      if (translationData) {
        setSentenceData(translationData)
      } else {
        dispatch(getSentenceData({ trackerNumber: data?.trackerNumber })).then(res => {
          if (p) {
            setSentenceData(res.payload)
          }
        })
      }
    }

    return () => {
      p = false
    }
  }, [translationData])

  const onClickMemoryBoxButton = () => {
    if (isMemoryBox) {
      dispatch(removeFromBox({ ids: [sentenceData?.memoryBoxId] })).then(res => {
        if (res?.meta?.requestStatus !== 'rejected') {
          dispatch(
            setTranslationsMemoryBoxId({
              memoryBoxId: null,
              trackerNumber: sentenceData?.trackerNumber
            })
          )
        }
      })
    } else {
      dispatch(
        addFlashCardInLesson({
          isVocab: false,
          text: sentenceData?.title,
          unitId: unitNumber
        })
      ).then(res => {
        if (res?.meta?.requestStatus !== 'rejected') {
          dispatch(
            setTranslationsMemoryBoxId({
              memoryBoxId: res.payload.id,
              trackerNumber: sentenceData?.trackerNumber
            })
          )
        }
      })
    }
  }

  const redirectToTools = url => {
    dispatch(setToolsGivenStates({ unitId: unitNumber, data: [sentenceData] }))
    navigate({
      pathname: url,
      search: createSearchParams({
        phrase: data?.trackerNumber
      }).toString()
    })
  }

  if (Array.isArray(optionsList)) {
    return (
      <>
        <div className='three-dot-modal'>
          <div
            className='circle-buttons-container'
            style={{ opacity: !sentenceData ? 0.5 : 1, pointerEvents: !sentenceData ? 'none' : 'auto' }}
          >
            {optionsList.includes(LISTENING) && (
              <div className='button-wrapper col-4 col-md-2'>
                <button
                  onClick={() => redirectToTools('/tools/listening/sentence')}
                  className='circle-button'
                  style={{ borderColor: colors['C160'] }}
                >
                  <ListeningSvg color={colors['C160']} />
                </button>
                <p className='label' style={{ color: colors['C160'] }}>
                  <button onClick={() => redirectToTools('/tools/listening/sentence')}>Listening</button>
                </p>
              </div>
            )}
            {optionsList.includes(SPEAKING) && (
              <div className='button-wrapper col-4 col-md-2'>
                <button
                  onClick={() => redirectToTools('/tools/speaking/sentence')}
                  className='circle-button'
                  style={{ borderColor: colors['C160'] }}
                >
                  <SpeakingSvg color={colors['C160']} />
                </button>
                <p className='label' style={{ color: colors['C160'] }}>
                  <button onClick={() => redirectToTools('/tools/speaking/sentence')}>Speaking</button>
                </p>
              </div>
            )}
            {optionsList.includes(SPELLING) && (
              <div className='button-wrapper col-4 col-md-2'>
                <button
                  onClick={() => redirectToTools('/tools/spelling/sentence')}
                  className='circle-button'
                  style={{ borderColor: colors['C160'] }}
                >
                  <SpellingSvg color={colors['C160']} />
                </button>
                <p className='label' style={{ color: colors['C160'] }}>
                  <button onClick={() => redirectToTools('/tools/spelling/sentence')}>Spelling</button>
                </p>
              </div>
            )}
            {optionsList.includes(BOX) && (
              <div className='button-wrapper col-4 col-md-2' style={{ opacity: loading ? 0.5 : 1 }}>
                <button
                  onClick={onClickMemoryBoxButton}
                  className='circle-button'
                  style={{ borderColor: colors['C160'] }}
                  disabled={loading}
                >
                  {!isMemoryBox ? (
                    <AddToMemoryBoxSvg color={colors['C160']} />
                  ) : (
                    <RemoveFromMemoryBoxSvg color={colors['C160']} />
                  )}
                </button>
                <button onClick={onClickMemoryBoxButton} className='label' style={{ color: colors['C160'] }}>
                  {memoryBoxButtonTitle}
                </button>
              </div>
            )}
            {optionsList.includes(FAVORITES) && (
              <div className='button-wrapper col-4 col-md-2'>
                <AddToFavoriteButton
                  unitId={unitNumber}
                  favoriteId={sentenceData?.favoriteId}
                  trackerNumber={sentenceData?.trackerNumber}
                  title={sentenceData?.title}
                />
              </div>
            )}
            {optionsList.includes(TRANSLATE) && !isEmpty(data?.trackerNumber) && (
              <div className='button-wrapper col-4 col-md-2'>
                <button
                  className='circle-button'
                  onClick={() => setShowTranslation(!showTranslation)}
                  style={{ borderColor: colors['C160'], background: showTranslation ? colors['C160'] : 'transparent' }}
                >
                  <TranslationSvg color={showTranslation ? colors['C233'] : colors['C160']} />
                </button>
                <button
                  onClick={() => setShowTranslation(!showTranslation)}
                  className='label'
                  style={{ color: colors['C160'] }}
                >
                  Translation
                </button>
              </div>
            )}
          </div>
          {showTranslation && (
            <div className='py-4 w-100'>
              <SentenceTranslationCard data={sentenceData} />
            </div>
          )}
        </div>
      </>
    )
  } else {
    return null
  }
}

export default ThreeDotModal
