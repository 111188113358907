import { shuffle } from 'lodash'
import { htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { AnswerStatusEnums } from '../../../enums/globalEnums/globalEnums'

const fetchDragAndDropCategoryMapper = data => {
  const exerciseStack = data.exerciseStacks[0]

  let choiceIndex = 0
  let choiceList = []
  let givensList = []

  exerciseStack.items.forEach((exerciseItem, itemIndex) => {
    exerciseItem.data.choices[0].items.forEach(choiceItem => {
      choiceList.push({
        ...choiceItem,
        choiceIndex,
        inChoiceItemsIndex: choiceItem.index,
        text: htmlTextConversion(choiceItem.text),
        itemId: exerciseItem.id,
        itemIndex,
        used: false
      })
      choiceIndex++
    })
    const given = exerciseItem.data.givens[0]
    givensList.push({
      ...given,
      text: given.text ? htmlTextConversion(given.text) : null,
      itemId: exerciseItem.id,
      itemIndex
    })
  })

  return {
    pageType: pageGuidEnums.DRAG_AND_DROP_CATEGORY_EXERCISE,
    data: {
      paginable: false,
      isSingleTemplate: false,
      type: data.type,
      title: data.title ?? null,
      checked: false,
      checkable: false,
      unlocked: false,
      stack: {
        id: exerciseStack.id,
        title: exerciseStack.title ?? null,
        choices: shuffle(choiceList),
        givens: givensList,
        answerFields: [...choiceList].map((choice, i) => {
          return {
            index: i,
            inChoiceItemsIndex: choice.inChoiceItemsIndex,
            itemId: choice.itemId,
            selected: false,
            correctAnswer: choice.isSolvedBefore ? choice : null,
            userAnswer: choice.isSolvedBefore ? choice : null,
            userAnswerStatus: choice.isSolvedBefore ? AnswerStatusEnums.CORRECT : null,
            isSolvedBefore: choice.isSolvedBefore
          }
        })
      }
    }
  }
}

export default fetchDragAndDropCategoryMapper
