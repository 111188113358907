const ArchiveSvg = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24.999' height='19' viewBox='0 0 24.999 19'>
      <g transform='translate(-237 -108)'>
        <g transform='translate(255 113) rotate(90)' fill='none'>
          <path
            d='M4.647,1.4a1,1,0,0,1,1.707,0L10.07,7.479A1,1,0,0,1,9.217,9H1.783A1,1,0,0,1,.93,7.479Z'
            stroke='none'
          />
          <path
            d='M 5.5 1.667726516723633 C 5.447639942169189 1.667726516723633 5.350289821624756 1.683266639709473 5.286680221557617 1.787366390228271 L 1.569740295410156 7.869636535644531 C 1.503310203552246 7.978346347808838 1.538260459899902 8.074596405029297 1.565010070800781 8.122286796569824 C 1.59175968170166 8.169986724853516 1.655659675598145 8.249996185302734 1.783060073852539 8.249996185302734 L 9.216939926147461 8.249996185302734 C 9.344340324401855 8.249996185302734 9.40824031829834 8.169986724853516 9.434989929199219 8.122286796569824 C 9.461740493774414 8.074596405029297 9.496689796447754 7.978346347808838 9.430259704589844 7.869636535644531 L 5.713329792022705 1.787376403808594 C 5.649700164794922 1.683266639709473 5.552360057830811 1.667726516723633 5.5 1.667726516723633 M 5.5 0.9177312850952148 C 5.82915735244751 0.9177312850952148 6.158315181732178 1.07724666595459 6.353280067443848 1.396276473999023 L 10.07021999359131 7.478546619415283 C 10.47743988037109 8.144906997680664 9.997869491577148 8.999996185302734 9.216939926147461 8.999996185302734 L 1.783060073852539 8.999996185302734 C 1.002129554748535 8.999996185302734 0.5225601196289062 8.144906997680664 0.9297800064086914 7.478546619415283 L 4.646719932556152 1.396276473999023 C 4.841684818267822 1.07724666595459 5.17084264755249 0.9177312850952148 5.5 0.9177312850952148 Z'
            stroke='none'
            fill={color}
          />
        </g>
        <g transform='translate(926 121)' fill='none'>
          <path
            d='M-686,6a3,3,0,0,1-3-3V-11a2.021,2.021,0,0,1,2.042-2h8.125a3.431,3.431,0,0,1,3.386,3H-667a3,3,0,0,1,3,3V3a3,3,0,0,1-3,3Z'
            stroke='none'
          />
          <path
            d='M -666.9999389648438 5.00029993057251 C -665.8972778320312 5.00029993057251 -665.000244140625 4.102829933166504 -665.000244140625 2.99970006942749 L -665.000244140625 -7.000199794769287 C -665.000244140625 -8.102840423583984 -665.8972778320312 -8.999899864196777 -666.9999389648438 -8.999899864196777 L -676.3153076171875 -8.999899864196777 L -676.436279296875 -9.860799789428711 C -676.538818359375 -10.59099006652832 -676.8701782226562 -11.15445995330811 -677.4210815429688 -11.53555011749268 C -677.9156494140625 -11.87769031524658 -678.4743041992188 -11.99960041046143 -678.8322143554688 -11.99960041046143 L -686.9573974609375 -11.99960041046143 C -687.5320434570312 -11.99960041046143 -687.99951171875 -11.55109024047852 -687.99951171875 -10.99979972839355 L -687.99951171875 2.99970006942749 C -687.99951171875 4.102829933166504 -687.1024780273438 5.00029993057251 -685.9998168945312 5.00029993057251 L -666.9999389648438 5.00029993057251 M -666.9999389648438 6.00029993057251 L -685.9998168945312 6.00029993057251 C -687.65673828125 6.00029993057251 -688.99951171875 4.656599998474121 -688.99951171875 2.99970006942749 L -688.99951171875 -10.99979972839355 C -688.99951171875 -12.10499954223633 -688.0851440429688 -12.99960041046143 -686.9573974609375 -12.99960041046143 L -678.8322143554688 -12.99960041046143 C -677.74560546875 -12.99960041046143 -675.7674560546875 -12.28845024108887 -675.4459838867188 -9.999899864196777 L -666.9999389648438 -9.999899864196777 C -665.343017578125 -9.999899864196777 -664.000244140625 -8.657099723815918 -664.000244140625 -7.000199794769287 L -664.000244140625 2.99970006942749 C -664.000244140625 4.656599998474121 -665.343017578125 6.00029993057251 -666.9999389648438 6.00029993057251 Z'
            stroke='none'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}

export default ArchiveSvg
