const userActionEnums = {
  setNativeLanguage: 'SET_NATIVE_LANGUAGE',
  setCourseIntensity: 'SET_COURSE_INTENSITY',
  setSpacedRepetition: 'SET_SPACED_REPETITION',
  setLearningType: 'SET_LEARNING_TYPE',
  updateUserInfo: 'UPDATE_USER_INFO',
  updateStudyHardShipLevel: 'UPDATE_STUDY_HARD_SHIP_LEVEL',
  updateTheme: 'UPDATE_THEME',
  helpAfterNativeLanguage: 'HELP_AFTER_SET_NATIVE_LANGUAGE',
  helpAfterUpdateTheme: 'HELP_AFTER_UPDATE_THEME',
  focusHelpButton: 'FOCUS_HELP_BUTTON',
  // TODO: handle the reset password action
  resetPassword: 'RESET_PASSWORD'
}

export const appUserActions = [
  {
    key: userActionEnums.setNativeLanguage,
    priority: 1
  },
  {
    key: userActionEnums.helpAfterNativeLanguage,
    priority: 2
  },
  {
    key: userActionEnums.setCourseIntensity,
    priority: 3
  },
  {
    key: userActionEnums.setSpacedRepetition,
    priority: 4
  },
  {
    key: userActionEnums.setLearningType,
    priority: 5
  },
  {
    key: userActionEnums.updateUserInfo,
    priority: 6
  },
  {
    key: userActionEnums.updateTheme,
    priority: 7
  },
  {
    key: userActionEnums.helpAfterUpdateTheme,
    priority: 8
  },
  {
    key: userActionEnums.updateStudyHardShipLevel,
    priority: 9
  }
]

export default userActionEnums
