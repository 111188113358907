const WifiCrossedOutSvg = ({ backLineColor, svgColor }) => {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width={444} height={399} viewBox='0 0 444 399' fill='none'>
        <path
          fill={svgColor}
          fillRule='evenodd'
          d='M435.04 130.242C375.583 81.029 300.467 54.18 221.917 54.18c-78.782 0-154.106 27.01-213.637 76.488l-5.055 4.202c-4.089 3.398-4.323 9.439-.51 13.123l35.062 33.881c3.403 3.289 8.845 3.513 12.526.517l4.344-3.537c46.882-38.166 105.744-58.941 167.27-58.941 61.318 0 119.991 20.632 166.804 58.562l4.342 3.518c3.682 2.983 9.112 2.753 12.509-.53l35.068-33.885c3.818-3.69 3.577-9.741-.525-13.136l-5.075-4.2Zm-213.123 28.545c49.495 0 97.017 15.911 135.73 45.375l5.472 4.164c4.361 3.32 4.745 9.576.819 13.369l-35.182 33.997c-3.254 3.144-8.403 3.505-12.093.848l-4.288-3.089c-26.147-18.83-57.675-28.93-90.458-28.93-32.984 0-64.696 10.226-90.93 29.272l-4.293 3.117c-3.69 2.679-8.858 2.326-12.121-.828l-35.169-33.983c-3.918-3.786-3.546-10.026.796-13.352l5.44-4.167c38.805-29.727 86.544-45.793 136.277-45.793Zm0 104.608c19.897 0 39.23 5.066 56.264 14.726l6.902 3.914c5.233 2.968 6.153 9.912 1.863 14.058l-58.93 56.944c-3.62 3.498-9.488 3.498-13.108 0l-58.551-56.578c-4.271-4.127-3.381-11.037 1.807-14.026l6.825-3.932c17.191-9.905 36.769-15.106 56.928-15.106Z'
          clipRule='evenodd'
        />
        <g filter='url(#wifiCrossedOutA)'>
          <path
            fill={backLineColor}
            d='M358.841 17.69c8.034 5.435 10.139 16.353 4.704 24.387L137.213 376.562c-5.436 8.033-16.355 10.139-24.388 4.703-8.033-5.436-10.139-16.354-4.703-24.387L334.454 22.392c5.436-8.033 16.354-10.139 24.387-4.703Z'
          />
        </g>
        <g filter='url(#wifiCrossedOutB)'>
          <path
            fill={svgColor}
            d='M355.75 22.344c5.214 3.528 6.581 10.615 3.053 15.83L131.154 374.605c-3.528 5.214-10.615 6.581-15.829 3.052-5.215-3.528-6.581-10.615-3.053-15.829L339.92 25.397c3.529-5.215 10.616-6.581 15.83-3.053Z'
          />
        </g>
        <defs>
          <filter
            id='wifiCrossedOutA'
            width={421.461}
            height={529.614}
            x={25.102}
            y={-65.33}
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feGaussianBlur in='BackgroundImageFix' stdDeviation={40} />
            <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_10803_8038' />
            <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_10803_8038' result='shape' />
          </filter>
          <filter
            id='wifiCrossedOutB'
            width={410.449}
            height={519.232}
            x={30.313}
            y={-59.615}
            colorInterpolationFilters='sRGB'
            filterUnits='userSpaceOnUse'
          >
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feGaussianBlur in='BackgroundImageFix' stdDeviation={40} />
            <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_10803_8038' />
            <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_10803_8038' result='shape' />
          </filter>
        </defs>
      </svg>
    </>
  )
}
export default WifiCrossedOutSvg
