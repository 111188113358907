import getBinaryData from './getBinaryData'
import md5 from 'md5'

const getCachedMediaUrl = async mediaUrl => {
  let getCacheList = () => {
    if (!sessionStorage.getItem('cacheList')) {
      sessionStorage.setItem('cacheList', JSON.stringify(new Array(0)))
    }
    return JSON.parse(sessionStorage.getItem('cacheList'))
  }

  const setCachedUrl = ({ id, localSrc, status }) => {
    let cacheList = getCacheList()

    const dataIndex = cacheList.findIndex(item => item.id === id)

    if (dataIndex !== -1) {
      cacheList = [...cacheList].map(item => {
        return item.id === id ? { ...item, id, localSrc, status } : item
      })
    } else {
      cacheList = [...cacheList, { id, localSrc, status }]
    }

    sessionStorage.setItem('cacheList', JSON.stringify(cacheList))
  }

  const getCachedUrl = async () => {
    const mediaHash = md5(mediaUrl)

    const cacheList = getCacheList()
    const foundMedia = cacheList.find(obj => obj.id === mediaHash)

    if (foundMedia) {
      if (foundMedia.status === 'cached') {
        return foundMedia.localSrc
      }
    }

    setCachedUrl({ id: mediaHash, localSrc: null, status: 'pending' })
    const binaryData = await getBinaryData(mediaUrl)
    setCachedUrl({ id: mediaHash, localSrc: binaryData, status: 'cached' })
    return binaryData
  }

  const getMediaUrl = async () => {
    if (mediaUrl) {
      const res = await getCachedUrl()
      if (res === 'pending') {
        setTimeout(() => getMediaUrl(), 100)
      } else {
        return res
      }
    } else {
      return ''
    }
  }

  return await getMediaUrl()
}

export default getCachedMediaUrl
