import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStructuredSection } from '../../../redux/features/structuredSectionSlice'
import StructureModule from '../../../modules/StructuredSectionModules/StructureModule'
import { START_SECTION } from '../../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../../hooks/logHooks/useLog'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'

const MoreExamples = ({ sectionName }) => {
  const { unit } = useParams()
  const states = useSelector(state => state.structuredSection)
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { sendServerLog } = useLog()

  const fetchData = () => {
    networkLossRetryerDispatch(retryId => {
      dispatch(fetchStructuredSection({ unit, sectionType: sectionName, retryId })).then(() => {
        sendServerLog(START_SECTION)
      })
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <StructureModule
        pageType={pageGuidEnums.MORE_EXAMPLES_SECTION}
        structuresData={states.data}
        haveWordColor
        fetchData={fetchData}
      />
    </>
  )
}

export default MoreExamples
