import React, { useContext, useEffect, useRef } from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import componentStyle from './GeneralTemplate.module.scss'
import StructureSubjectsComponent from '../Components/StructureSubjectsComponent/StructureSubjectsComponent'
import colorsContext from '../../../../contexts/ColorsContext'
import StructureTitleComponent from '../Components/StructureTitleComponent/StructureTitleComponent'
import classNames from 'classnames'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import { isEmpty } from 'lodash'
import structuresContext from '../../../../contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from '../../../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const generalTemplateContainerClassName = {
  IMAGE_TOP: componentStyle.generalImageTopContainer,
  IMAGE_LEFT: componentStyle.generalImageLeftContainer,
  IMAGE_RIGHT: componentStyle.generalImageRightContainer
}

export const GeneralTemplateCard = ({ card, templateConfig }) => {
  const { colors } = useContext(colorsContext)
  const elRef = useRef(null)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const cardIsHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (card?.hiddenTime ?? 0) : false

  useEffect(() => {
    if (card?.hiddenTime > 0 && !cardIsHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [cardIsHidden])

  const hasSingleSubject = card.subjects?.length === 1 && card.subjects[0]?.texts?.length === 1

  return (
    <div
      ref={elRef}
      data-rt-id={card?.id}
      className={classNames(structureStyle.templateCard, templateConfig?.isJoined, {
        [structureStyle.joined]: templateConfig?.isJoined,
        [structureStyle.templateContainer]: !templateConfig?.isJoined,
        ['hide-element']: cardIsHidden
      })}
      style={{
        background: !templateConfig?.isJoined
          ? isEmpty(templateConfig?.backgroundColor)
            ? colors['C194']
            : templateConfig?.backgroundColor
          : null,
        borderColor: !templateConfig?.isJoined ? templateConfig?.borderColor : 'transparent'
      }}
    >
      <StructureTitleComponent text={card.title} />
      <div
        className={classNames(
          componentStyle.generalTemplateContainer,
          generalTemplateContainerClassName[card.displayMode],
          {
            [componentStyle.withoutImage]: isEmpty(card.picture)
          }
        )}
      >
        {card.picture && (
          <div className={componentStyle.imageContainer}>
            <StructureImageComponent picture={{ ...card.picture, text: card.pictureText }} />
          </div>
        )}
        {!isEmpty(card.subjects) && (
          <div
            className={classNames(componentStyle.subjectsContainer, {
              [componentStyle.mdTextCenter]: hasSingleSubject
            })}
          >
            <StructureSubjectsComponent subjects={card.subjects} />
          </div>
        )}
      </div>
    </div>
  )
}

const GeneralTemplate = ({ template }) => {
  const { colors } = useContext(colorsContext)
  const elRef = useRef(null)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <div className='container container-1'>
      <div
        ref={elRef}
        data-rt-id={template?.id}
        className={classNames(structureStyle.generalTemplateWrapper, {
          [structureStyle.templateContainer]: template.config.isJoined,
          [structureStyle.border]: template.config.isJoined && !isEmpty(template?.config?.borderColor),
          ['hide-element']: isHidden
        })}
        style={{
          background: template.config.isJoined
            ? isEmpty(template?.config?.backgroundColor)
              ? colors['C194']
              : template.config.backgroundColor
            : null,
          borderColor: template.config.isJoined ? template?.config?.borderColor : 'transparent'
        }}
      >
        {template.data.cards.map((card, i) => (
          <GeneralTemplateCard key={i} card={card} templateConfig={template?.config} />
        ))}
      </div>
    </div>
  )
}

export default GeneralTemplate
