import serviceCall from '../../services'
import flashCardsMapper from '../../services/mapper/memoryBox/flashCardsMapper'

const searchFlashCardUseCase = async searchCriteria => {
  const result = await serviceCall('searchMemoryBoxFlashCard', searchCriteria, 'cloud')

  return flashCardsMapper(result)
}

export default searchFlashCardUseCase
