import serviceCall from '../../services'
import nativeLanguageMapper from '../../services/mapper/nativeLanguage/nativeLanguageMapper'

const nativeLanguageUseCase = async () => {
  const result = await serviceCall('fetchNativeLanguages', null, 'cloud')

  return nativeLanguageMapper(result)
}

export default nativeLanguageUseCase
