import { createSlice } from '@reduxjs/toolkit'
import studyLogUseCase from '../../useCase/studyLogUseCase/studyLogUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  logPending: false
}

export const sendStudyLog = baseAsyncThunk('studyLog/sendStudyLog', ({ unitId, sectionType, type, action }) => {
  return studyLogUseCase({ unitId, sectionType, type, action })
})

const studyLogSlice = createSlice({
  name: 'studyLog',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(sendStudyLog.pending, state => {
      state.logPending = true
    })
    builder.addCase(sendStudyLog.fulfilled, (state, action) => {
      state.logPending = false
    })
    builder.addCase(sendStudyLog.rejected, state => {
      state.logPending = false
    })
  }
})

export const {} = studyLogSlice.actions

export default studyLogSlice.reducer
