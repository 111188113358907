import React, { useContext, useEffect, useRef } from 'react'
import componentStyle from './StructureSubjectsComponent.module.scss'
import StructureTextUseCase from '../../../../../useCase/StructureUseCase/StructureTextUseCase'
import structuresContext from '../../../../../contexts/StructuresContext'
import classNames from 'classnames'
import { robotTeacherScrollIntoViewByElement } from '../../../../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const SubjectComponent = ({ subject }) => {
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (subject?.hiddenTime ?? 0) : false

  const elRef = useRef(null)

  useEffect(() => {
    if (subject?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <div
      ref={elRef}
      data-rt-id={subject?.id}
      className={classNames(componentStyle.subjectContainer, { ['hide-element']: isHidden })}
    >
      {subject.texts.map((text, i) => (
        <StructureTextUseCase key={i} textData={text} />
      ))}
    </div>
  )
}

const StructureSubjectsComponent = ({ subjects }) => {
  if (subjects?.length > 0) {
    return subjects.map((subject, subjectIndex) => <SubjectComponent subject={subject} key={subjectIndex} />)
  } else {
    return null
  }
}

export default StructureSubjectsComponent
