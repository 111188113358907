import * as React from 'react'

const VellsoDualSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' xmlSpace='preserve'>
    <circle
      cx={28.25}
      cy={9.2}
      r={9.2}
      style={{
        fill: color
      }}
    />
    <circle
      cx={28.25}
      cy={38.67}
      r={9.2}
      style={{
        fill: '#FCFCFC'
      }}
    />
    <path
      fill={color}
      d='M10.49 22.44h35.53v3H10.49zM3.49 28.49c-.47-.08-.84-.29-1.1-.6-.26-.32-.39-.72-.39-1.21 0-.52.14-.93.43-1.25.29-.31.67-.47 1.14-.47.25 0 .48.04.68.12.2.08.4.21.59.39.2.18.54.6 1.04 1.24.27.36.49.62.64.77.15.15.31.26.47.34v-2.87h.6v3.71c-.36-.04-.7-.15-1-.36-.3-.2-.69-.61-1.18-1.23-.46-.61-.81-1-1.05-1.18-.23-.18-.5-.26-.78-.26-.31 0-.55.09-.73.27s-.26.44-.26.78c0 .62.33 1.01 1 1.17l-.1.64zM4.86 24.04c-1.91 0-2.87-.64-2.87-1.92s.95-1.92 2.85-1.92c.96 0 1.69.17 2.18.49.49.33.74.81.74 1.43 0 .62-.25 1.1-.74 1.43-.5.33-1.22.49-2.16.49zm.01-3.17c-1.52 0-2.28.42-2.28 1.26 0 .83.75 1.24 2.26 1.24 1.54 0 2.3-.42 2.3-1.25s-.76-1.25-2.28-1.25z'
    />
  </svg>
)

export default VellsoDualSvg
