import serviceCall from '../../services'
import todayStudyFirstPlan from './../../services/offlineServicesData/todayStudy/todayStudyFirstPlan.json'
import todayStudyMapper from '../../services/mapper/todayStudy/todayStudyMapper'

const homeTodayStudyUseCase = async ({ hasPlan }) => {
  let result

  if (hasPlan) {
    result = await serviceCall('fetchHomeTodayStudy', null, 'cloud')
  } else {
    result = todayStudyFirstPlan
  }

  return todayStudyMapper(result)
}

export default homeTodayStudyUseCase
