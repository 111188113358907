import React, { useContext, useEffect } from 'react'
import style from './StructureModule.module.scss'
import AbcSvg from '../../icons/AbcSvg'
import SectionsColorPalette from '../../components/SectionsColorpalette/SectionsColorPalette'
import { clearStructuredSectionStates, toggleView, toggleWordColor } from '../../redux/features/structuredSectionSlice'
import TemplateViewTypeButton from '../../components/TemplateViewTypeButton/TemplateViewTypeButton'
import HelpButton from '../../components/HelpButton/HelpButton'
import StructurePageUseCase from '../../useCase/StructureUseCase/StructurePageUseCase'
import colorsContext from '../../contexts/ColorsContext'
import { useDispatch, useSelector } from 'react-redux'
import SinglePageControlButtons from './Components/SinglePageControlButtons'
import { useParams } from 'react-router-dom'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import useSectionEvents from '../../hooks/sectionHooks/useSectionEvents'
import RoboTeacherIcon from '../../components/RoboTeacherIcon/RoboTeacherIcon'
import RetryServiceButton from 'atomicComponents/molecules/RetryServiceButton/RetryServiceButton'
import { isEmpty, isFunction } from 'lodash'

const StructureModule = ({
  pageType,
  structuresData,
  showAbc,
  showRoboTeacher,
  haveWordColor,
  templateId,
  displayNextButton = true,
  fetchData
}) => {
  const { colors } = useContext(colorsContext)
  const { unit } = useParams()
  const { logPending } = useSelector(state => state.studyLog)
  const states = useSelector(state => state.structuredSection)
  const dispatch = useDispatch()
  const { navigateToNextSection } = useSectionEvents()

  useEffect(() => {
    return () => {
      if (!templateId) {
        dispatch(clearStructuredSectionStates())
      }
    }
  }, [])

  return (
    <>
      {!states.loading && !structuresData?.id && Boolean(states.error) && isFunction(fetchData) ? (
        <RetryServiceButton onClick={fetchData} />
      ) : (
        <div className={structuresData.isSingleView ? style.pageContainerSingleView : style.pageContainerListView}>
          <div className={style.structureTemplatesContainer}>
            <div className='container-fluid'>
              <div className='on-top py-3'>
                <div className='on-top-logos ms-3 ms-md-5 me-2'>
                  {showAbc && (
                    <button className='icon-abc'>
                      <AbcSvg color={colors['C2']} />
                    </button>
                  )}
                  {haveWordColor ? (
                    <SectionsColorPalette
                      wordColor={structuresData.sectionData.wordColors}
                      wordColorIsOn={!structuresData.disableWordColor}
                      setWordColorIsOn={() => dispatch(toggleWordColor({ templateId }))}
                    />
                  ) : null}
                  {showRoboTeacher && <RoboTeacherIcon unit={unit} />}
                </div>
                <div className='on-top-options'>
                  {structuresData?.sectionData?.singlePages?.length > 1 && (
                    <div className='me-3 me-md-4 ms-2'>
                      <TemplateViewTypeButton
                        isSingle={structuresData.isSingleView}
                        onClick={() => dispatch(toggleView({ templateId }))}
                        color={colors['C2']}
                      />
                    </div>
                  )}
                  {pageType && (
                    <div className='me-3 me-md-5 ms-2'>
                      <HelpButton color={colors['C2']} pageType={pageType} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <StructurePageUseCase structuresData={structuresData} />
          </div>
          {structuresData.isSingleView && structuresData.sectionData.singlePages?.length > 0 ? (
            <div className={style.singleViewBottomSection} style={{ background: colors['C183'] }}>
              {displayNextButton &&
                structuresData.currentPageIndex + 1 === structuresData.sectionData.singlePages?.length && (
                  <PrimaryButton className='mx-auto' loading={logPending} onClick={navigateToNextSection}>
                    Next
                  </PrimaryButton>
                )}
              <div className='container container-1'>
                <SinglePageControlButtons structuresData={structuresData} templateId={templateId} />
              </div>
            </div>
          ) : (
            displayNextButton && (
              <PrimaryButton className='mx-auto' loading={logPending} onClick={navigateToNextSection}>
                Next
              </PrimaryButton>
            )
          )}
        </div>
      )}
    </>
  )
}

export default StructureModule
