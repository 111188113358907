import { htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchSayTheFollowingMapper = data => {
  return {
    pageType: pageGuidEnums.SAY_THE_FOLLOWING_EXERCISE,
    data: {
      paginable: true,
      type: data.type,
      title: data.title ?? null,
      stacks: [...data.exerciseStacks].map(stack => {
        return {
          id: stack.id,
          title: stack.title ?? null,
          joined: stack.joined,
          items: [...stack.items].map(item => {
            const given = item.data.givens[0]
            return {
              id: item.id,
              index: given.index,
              text: htmlTextConversion(given.text),
              unlocked: false,
              checked: false,
              userAnswerData: null,
              speechType: null
            }
          })
        }
      })
    }
  }
}

export default fetchSayTheFollowingMapper
