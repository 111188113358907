import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import accentsUseCase from '../../useCase/accentsUseCase/accentsUseCase'

const initialState = {
  loading: false,
  getAccentsLoading: false,
  accentData: null,
  accents: [],
  error: ''
}

export const getAccents = createAsyncThunk('accent/getAccents', () => accentsUseCase())

export const changeUserAccent = createAsyncThunk('accent/changeUserAccent', data => {
  return serviceCall('putUserAccent', data, 'cloud')
})

const accentSlice = createSlice({
  name: 'accent',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(changeUserAccent.pending, state => {
      state.loading = true
    })
    builder.addCase(changeUserAccent.fulfilled, (state, action) => {
      state.loading = false
      state.accentData = action.payload.data
    })
    builder.addCase(changeUserAccent.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getAccents.pending, state => {
      state.getAccentsLoading = true
    })
    builder.addCase(getAccents.fulfilled, (state, action) => {
      state.getAccentsLoading = false
      state.accents = action.payload
    })

    builder.addCase(getAccents.rejected, (state, action) => {
      state.getAccentsLoading = false
      state.error = action.error.message
    })
  }
})

export const {} = accentSlice.actions

export default accentSlice.reducer
