import Cookies from 'universal-cookie'

const cookies = new Cookies()

const setCookie = (cookieName, cookieValue, hours = 1) => {
  // Setting deviceId Cookie Expire Date to 7 Days Later
  const expireDate = new Date(Date.now() + 3600 * 1000 * hours)

  // Set Cookie
  cookies.set(cookieName, cookieValue, {
    path: '/',
    expires: expireDate
  })
}

export default setCookie
