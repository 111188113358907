import { shuffle } from 'lodash'
import removeExtraSpace from '../operations/removeExtraSpace'
import { SPELLING_CUSTOM, SPELLING_SYLLABUS, SPELLING_SYSTEM } from '../enums/toolsEnums/spellingSeparationEnums'
import { AnswerStatusEnums } from '../enums/globalEnums/globalEnums'

const createSystemType = (data, initialNumberOfRepetitions) => {
  return data.map((word, wordIndex) => {
    const clearWord = removeExtraSpace(word.title.replace(/\s/g, ''))
    const characters = clearWord.split('').map((item, i) => {
      return {
        index: i,
        char: item,
        used: false
      }
    })

    return {
      index: wordIndex,
      title: clearWord,
      numberOfRepetitions: initialNumberOfRepetitions,
      status: AnswerStatusEnums.NONE,
      isFinished: false,
      wrongAnswersCount: 0,
      correctAnswersCount: 0,
      fields: characters.map(item => {
        return {
          index: item.index,
          syllableIndex: -1,
          char: item.char,
          value: null
        }
      }),
      characters: shuffle(characters)
    }
  })
}

const createSyllableType = (data, type = SPELLING_SYLLABUS, initialNumberOfRepetitions) => {
  const result = data.map((word, wordIndex) => {
    const clearWord = removeExtraSpace(word.title.replace(/\s/g, ''))

    let syllable = ''
    switch (type) {
      case SPELLING_SYLLABUS:
        syllable = removeExtraSpace(word?.syllable).replace(/\s/g, '.')
        break
      case SPELLING_CUSTOM:
        syllable = removeExtraSpace(word?.customSyllable).replace(/\s/g, '.')
        break
    }

    const items = syllable?.split('.') // ['hap', 'py']

    let fields = []
    let characters = []
    let charIndex = 0
    items.forEach((item, itemIndex) => {
      const itemChars = item.split('')

      itemChars.forEach(char => {
        fields.push({
          index: charIndex,
          syllableIndex: itemIndex,
          char: char,
          value: null
        })
        characters.push({
          index: charIndex,
          char: char,
          used: false
        })
        charIndex++
      })
    })

    return {
      index: wordIndex,
      title: clearWord,
      numberOfRepetitions: initialNumberOfRepetitions,
      status: AnswerStatusEnums.NONE,
      isFinished: false,
      wrongAnswersCount: 0,
      correctAnswersCount: 0,
      fields,
      characters: shuffle(characters)
    }
  })

  return result
}

const spellingWordUnscrambleFactory = (data, initialNumberOfRepetitions, type) => {
  switch (type) {
    case SPELLING_SYSTEM:
      return createSystemType(data, initialNumberOfRepetitions)
    case SPELLING_SYLLABUS:
      return createSyllableType(data, SPELLING_SYLLABUS, initialNumberOfRepetitions)
    case SPELLING_CUSTOM:
      return createSyllableType(data, SPELLING_CUSTOM, initialNumberOfRepetitions)
  }
}
export default spellingWordUnscrambleFactory
