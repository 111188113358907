import { AnswerStatusEnums } from '../enums/globalEnums/globalEnums'
import removeExtraSpace from '../operations/removeExtraSpace'

const create = (data, initialNumberOfRepetitions) => {
  return data.map((sentence, sentenceIndex) => {
    return {
      index: sentenceIndex,
      sentence: {
        ...sentence,
        title: removeExtraSpace(sentence.title)
      },
      numberOfRepetitions: initialNumberOfRepetitions,
      status: AnswerStatusEnums.NONE,
      isFinished: false,
      wrongAnswersCount: 0,
      correctAnswersCount: 0,
      input: '',
      fullTextAnswer: []
    }
  })
}
const spellingSentenceFactory = (data, initialNumberOfRepetitions) => {
  return create(data, initialNumberOfRepetitions)
}
export default spellingSentenceFactory
