import serviceCall from '../../services'
import favoritesCategoryContentMapper from '../../services/mapper/favorite/favoritesCategoryContentMapper'

const getFavoritesCategoryContentUseCase = async ({
  type,
  categoryId,
  currentPage,
  postsPerPage,
  searchTerm,
  sortKey,
  sortBy
}) => {
  const result = await serviceCall(
    'fetchFavoriteCategoryContent',
    { type, categoryId, currentPage, postsPerPage, searchTerm, sortKey, sortBy },
    'cloud'
  )

  return favoritesCategoryContentMapper(result)
}

export default getFavoritesCategoryContentUseCase
