import convertMillisToReadableTime from '../../../operations/convertMillisToReadableTime'
import getHumanReadableDate from '../../../operations/getHumanReadableDate'

const unitStatisticMapper = data => {
  // const startDate = new Date(data?.startDate)
  // const finishDate = new Date(data?.finishDate)

  return {
    level: data.level,
    unitId: data.unitId,
    unitTitle: data.unitTitle,
    exerciseGroup: data.exerciseGroup,
    doneSections: data.doneSections,
    skippedSections: data.skippedSections,
    totalSections: data.totalSections,
    doneSectionsPercentage: Math.floor(data.doneSectionsPercentage),
    skippedSectionsPercentage: Math.floor(data.skippedSectionsPercentage),
    totalCorrect: data.totalCorrect,
    totalIncorrect: data.totalIncorrect,
    totalSkipped: data.totalSkipped,
    totalCount: data.totalCount,
    correctPercentage: Math.floor(data.correctPercentage),
    incorrectPercentage: Math.floor(data.incorrectPercentage),
    skippedPercentage: Math.floor(data.skippedPercentage),
    startDate: getHumanReadableDate(data.startDate),
    endDate: getHumanReadableDate(data?.finishDate),
    // startDate: data.startDate ? startDate?.toLocaleDateString() : '-',
    // endDate: data?.finishDate ? finishDate?.toLocaleDateString() : '-',
    isOn: data.isOn,
    isActive: data.isActive,
    isFinished: data.isFinished,
    duration: data.duration ? convertMillisToReadableTime(data.duration) : 'In progress',
    // TODO: you can filter this list for just return usable sections
    exercises: data.userSectionDataEntityList?.map(item => {
      return {
        level: item.level,
        unitId: item.unitId,
        sectionType: item.sectionType,
        isFinished: item.isFinished,
        isExercise: false,
        correctPercentage: Math.floor(item.correctPercentage)
        // exerciseGroup: item.exerciseGroup,
        // totalCorrect: item.totalCorrect,
        // totalIncorrect: item.totalIncorrect,
        // totalSkipped: item.totalSkipped,
        // totalCount: item.totalCount,
        // incorrectPercentage: item.incorrectPercentage,
        // skippedPercentage: item.skippedPercentage,
      }
    })
  }
}

export default unitStatisticMapper
