import { createSlice } from '@reduxjs/toolkit'
import spellingSentenceFactory from '../../../../factory/spellingSentenceFactory'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const initialState = {
  initialNumberOfRepetitions: 3,
  currentIndex: 0,
  sentenceData: []
}

const spellingSentenceSlice = createSlice({
  name: 'spellingSentence',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.sentenceData = spellingSentenceFactory(action.payload, state.initialNumberOfRepetitions)
    },
    increaseNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.sentenceData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions < 999) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentWordNumberOfRepetitions + 1
          state.sentenceData = [...state.sentenceData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentWordNumberOfRepetitions + 1
              }
            }
            return word
          })
        } else {
          ++state.sentenceData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    decreaseNumberOfRepetitions: (state, action) => {
      const currentWordNumberOfRepetitions = state.sentenceData[state.currentIndex].numberOfRepetitions
      if (currentWordNumberOfRepetitions > 0) {
        if (action.payload?.setToAll) {
          state.initialNumberOfRepetitions = currentWordNumberOfRepetitions - 1
          state.sentenceData = [...state.sentenceData].map(word => {
            if (!word.isFinished) {
              return {
                ...word,
                numberOfRepetitions: currentWordNumberOfRepetitions - 1
              }
            }
            return word
          })
        } else {
          --state.sentenceData[state.currentIndex].numberOfRepetitions
        }
      }
    },
    increaseCurrentIndex: state => {
      if (state.currentIndex + 1 < state.sentenceData?.length) {
        state.currentIndex++
      }
    },
    decreaseCurrentIndex: state => {
      if (state.currentIndex > 0) {
        state.currentIndex--
      }
    },
    resetNumberOfRepetitions: state => {
      const currentWord = state.sentenceData[state.currentIndex]
      state.sentenceData[state.currentIndex] = {
        ...currentWord,
        numberOfRepetitions: state.initialNumberOfRepetitions,
        isFinished: false,
        status: AnswerStatusEnums.NONE,
        wrongAnswersCount: 0,
        correctAnswersCount: 0,
        input: ''
      }
    },
    setWrongAnswersCount: (state, action) => {
      state.sentenceData[state.currentIndex].wrongAnswersCount = action.payload
    },
    setCorrectAnswersCount: (state, action) => {
      state.sentenceData[state.currentIndex].correctAnswersCount = action.payload
    },
    setFullTextAnswer: (state, action) => {
      state.sentenceData[state.currentIndex].fullTextAnswer = action.payload
    },
    clearInputItems: state => {
      state.sentenceData[state.currentIndex].input = ''
    },
    setInputStatus: (state, action) => {
      state.sentenceData[state.currentIndex].status = action.payload.status
    },
    setInputValue: (state, action) => {
      state.sentenceData[state.currentIndex].input = action.payload.value
    },
    setTypeFinished: (state, action) => {
      state.sentenceData[state.currentIndex].isFinished = action.payload.isFinished
    },
    clearSpellingSentenceStates: state => {
      state.initialNumberOfRepetitions = 3
      state.currentIndex = 0
      state.sentenceData = []
    }
  }
})

export const {
  setData,
  increaseNumberOfRepetitions,
  decreaseNumberOfRepetitions,
  increaseCurrentIndex,
  decreaseCurrentIndex,
  setWrongAnswersCount,
  setCorrectAnswersCount,
  resetNumberOfRepetitions,
  clearInputItems,
  setInputStatus,
  setInputValue,
  setTypeFinished,
  setFullTextAnswer,
  clearSpellingSentenceStates
} = spellingSentenceSlice.actions

export default spellingSentenceSlice.reducer
