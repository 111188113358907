import { htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchChooseFromCategoryMapper = data => {
  const exerciseStack = data.exerciseStacks[0]

  return {
    pageType: pageGuidEnums.CHOOSE_FROM_CATEGORY_EXERCISE,
    data: {
      paginable: false,
      type: 'CHOOSE_FROM_CATEGORY',
      title: data.title ?? null,
      checked: false,
      checkable: false,
      stack: {
        id: exerciseStack.id,
        itemId: exerciseStack.items[0]?.id,
        title: exerciseStack.title ?? null,
        given: {
          voiceUri: exerciseStack.items[0]?.data.givens[0]?.voiceUri
        },
        choices: [...exerciseStack.items[0]?.data.choices[0]?.items].map(item => {
          const answerText = exerciseStack.items[0]?.data.answers[0]?.items.find(
            answer => answer.text.trackerNumber === item.text.trackerNumber
          )
          return {
            index: item.index,
            text: htmlTextConversion(item.text),
            selected: false,
            userAnswerStatus: null,
            isAnswer: Boolean(answerText),
            answerText: answerText ? htmlTextConversion(answerText.text) : null
          }
        })
      }
    }
  }
}

export default fetchChooseFromCategoryMapper
