const IphoneAddButtonSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none'>
    <rect width={24} height={24} fill='#787880' fillOpacity={0.12} rx={3} />
    <path
      stroke='#007AFF'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M12 9v6m-3-3h6M6.75 5.25h10.5a1.5 1.5 0 0 1 1.5 1.5v10.5a1.5 1.5 0 0 1-1.5 1.5H6.75a1.5 1.5 0 0 1-1.5-1.5V6.75a1.5 1.5 0 0 1 1.5-1.5Z'
    />
  </svg>
)
export default IphoneAddButtonSvg
