import { sample } from 'lodash'

const messages = {
  1: [
    'Keep on trying!',
    'Try again!',
    "Let's try again. You can do this!",
    'Give it another go.',
    'Give it another shot'
  ],
  2: ['One more time and you’ll have it.', 'give it your best shot', 'give it another shot'],
  3: ['Keep working on it; you’re improving.', 'One more time and you’ll have it.', 'give it your best shot'],
  4: [
    'One more time and you’ll have it.',
    'You’re getting better and better.',
    'That’s good!',
    'Keep up the good work.'
  ],
  5: ['Super-Duper!', 'Exactly right!', 'You haven’t missed a thing.', 'Terrific!', 'That’s it!', 'Sensational!']
}

const getRandomCongratulationMessageByPercentage = percentage => {
  let messageList

  if (percentage === 100) {
    messageList = messages[5]
  } else if (percentage >= 75) {
    messageList = messages[4]
  } else if (percentage >= 50) {
    messageList = messages[3]
  } else if (percentage >= 25) {
    messageList = messages[2]
  } else if (percentage >= 0) {
    messageList = messages[1]
  } else {
    messageList = messages[3]
  }

  return sample(messageList)
}

export default getRandomCongratulationMessageByPercentage
