import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getSummeryUseCase from '../../../useCase/messages/getSummeryUseCase'
import deleteAllUseCase from '../../../useCase/messages/deleteAllUseCase'
import getCategoryNotificationsUseCase from '../../../useCase/messages/getCategoryNotificationsUseCase'

const initialState = {
  loading: false,
  summeryData: [],
  categoryNotifications: [],
  error: ''
}

export const getSummery = createAsyncThunk('messagesCategory/getSummery', () => getSummeryUseCase())

export const getCategoryNotifications = createAsyncThunk('messagesCategory/getCategoryNotifications', ({ category }) =>
  getCategoryNotificationsUseCase({ category })
)

export const deleteAll = createAsyncThunk('messagesCategory/deleteAll', () => deleteAllUseCase())

const messagesCategorySlice = createSlice({
  name: 'messagesCategory',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //get summery
    builder.addCase(getSummery.pending, state => {
      state.loading = true
    })
    builder.addCase(getSummery.fulfilled, (state, action) => {
      state.loading = false
      state.summeryData = action.payload
    })
    builder.addCase(getSummery.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //get Category Notifications
    builder.addCase(getCategoryNotifications.pending, state => {
      state.loading = true
    })
    builder.addCase(getCategoryNotifications.fulfilled, (state, action) => {
      state.loading = false
      state.categoryNotifications = action.payload
    })
    builder.addCase(getCategoryNotifications.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const {} = messagesCategorySlice.actions

export default messagesCategorySlice.reducer
