import ResetSvg from '../../icons/ResetSvg'

const ResetButton = ({ onClick, color, text = 'Reset' }) => {
  require('./resetButton.scss')

  return (
    <button className='reset-button' data-jsx='ResetButton' style={{ color }} onClick={onClick}>
      <span className='reset-svg-icon'>
        <ResetSvg color={color} />
      </span>
      {text}
    </button>
  )
}

export default ResetButton
