const HelpVideo = ({ data }) => {
  return (
    <>
      <div style={{ width: '530px', aspectRatio: '3/2', height: 'auto', maxWidth: '100%' }}>
        <iframe
          width='100%'
          height='100%'
          src={`${data?.videoLink}?autoplay=true`}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        />
      </div>
    </>
  )
}

export default HelpVideo
