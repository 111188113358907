import * as React from 'react'

const SpeakingSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {
        '.SpeakingSvg-st0{stroke-width:37.1829;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.SpeakingSvg-st1{stroke-width:19.8508;stroke-miterlimit:10;}.SpeakingSvg-st2{fill:none;stroke-width:36.7239;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}.SpeakingSvg-st3{fill:none;stroke-width:37;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}'
      }
    </style>
    <g id='Layer_2'>
      <line className='SpeakingSvg-st0' stroke={color} x1={124.4} y1={493.4} x2={386.4} y2={493.4} />
      <g>
        <g id='Layer_1_00000103224190147733855920000013448586992473675159_'>
          <g id='Icons'>
            <path
              className='SpeakingSvg-st1'
              stroke={color}
              d='M255,8.7c-55.2,0-100.1,45.4-100.1,101.1v151.6c0,55.9,44.9,101,100.1,101.2 c55.2-0.1,100.1-45.4,100.1-101.1V109.9C355,54,310.2,8.7,255,8.7z M336.8,278.5c-9.2,45.5-53.2,75.1-98.3,65.7 c-32.9-6.8-58.5-32.7-65.1-65.7v-17.1h-1.9v-16.7h1.9V92.9c7.7-39.1,41.9-67.3,81.6-67.3s73.9,28.1,81.8,67.4 M338.5,244.7 l-0.1,16.8 M338.6,210.9v16.9 M338.5,177.4l-0.1,16.8 M338.6,143.6v16.7 M318.8,138.5 M338.5,110l-0.1,16.8'
            />
            <path className='SpeakingSvg-st1' stroke={color} d='M173.4,244.7v16.7h4.3' />
            <line className='SpeakingSvg-st1' stroke={color} x1={171.5} y1={126.7} x2={171.5} y2={109.9} />
            <line className='SpeakingSvg-st1' stroke={color} x1={171.6} y1={160.3} x2={171.6} y2={143.4} />
            <line className='SpeakingSvg-st1' stroke={color} x1={171.5} y1={193.9} x2={171.5} y2={177.2} />
            <line className='SpeakingSvg-st1' stroke={color} x1={171.6} y1={227.8} x2={171.6} y2={210.9} />
          </g>
        </g>
        <line className='SpeakingSvg-st1' stroke={color} x1={335.2} y1={86.7} x2={335.2} y2={284.8} />
        <line className='SpeakingSvg-st2' stroke={color} x1={256.1} y1={494.4} x2={256.1} y2={436.4} />
        <path
          className='SpeakingSvg-st2'
          stroke={color}
          d='M397.6,275.1c0,67.9-50.1,139.8-141.9,139.8s-143.4-71.3-143.4-139.3'
        />
      </g>
    </g>
    <g id='Layer_3'>
      <line className='SpeakingSvg-st3' stroke={color} x1={167.8} y1={118.3} x2={209.2} y2={118.4} />
      <line className='SpeakingSvg-st3' stroke={color} x1={168.6} y1={176.3} x2={209.9} y2={176.4} />
      <line className='SpeakingSvg-st3' stroke={color} x1={168.5} y1={231.4} x2={209.9} y2={231.4} />
      <line className='SpeakingSvg-st3' stroke={color} x1={299.4} y1={118.3} x2={340.8} y2={118.4} />
      <line className='SpeakingSvg-st3' stroke={color} x1={300.2} y1={176.3} x2={341.6} y2={176.4} />
      <line className='SpeakingSvg-st3' stroke={color} x1={300.1} y1={231.4} x2={341.5} y2={231.4} />
    </g>
  </svg>
)

export default SpeakingSvg
