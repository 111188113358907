import * as React from 'react'

const MicrophoneOnSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>{`.MicrophoneOnSvg-st0{fill:{color};}.MicrophoneOnSvg-st1{fill:{color};stroke-width:2;stroke-miterlimit:10;}`}</style>
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                className='MicrophoneOnSvg-st0'
                d='M419.6,264.5c0-10.3-7.9-18-18-18s-18,7.9-18,18c0,70.5-57.2,127.6-127.6,127.6s-127.6-57.2-127.6-127.6 c0-10.3-7.9-18-18-18s-18,7.9-18,18c0,83.7,62.6,154.2,145.8,163.3V476h-66c-10.3,0-18,7.9-18,18c0,10.2,7.9,18,18,18h167.5 c10.3,0,18-7.9,18-18c0-10.2-7.9-18-18-18h-65.6v-48.2C356.8,418.7,419.6,348.2,419.6,264.5z'
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className='MicrophoneOnSvg-st0'
                d='M255.8,0c-55.4,0-100.5,45.1-100.5,100.5v163.3c0,56,45.1,100.5,100.5,101.2 c55.4,0,100.5-45.1,100.5-100.5V100.7C356.3,45.3,311.2,0,255.8,0z'
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className='MicrophoneOnSvg-st1'
            stroke={color}
            d='M52.9,238.6c7.4,7.5-3.9,18.8-11.3,11.3c-58.2-58.2-58.2-152.4,0-210.7c7.4-7.4,18.7,3.9,11.3,11.3 C0.9,102.5,0.9,186.7,52.9,238.6z'
          />
        </g>
        <g>
          <path
            className='MicrophoneOnSvg-st1'
            stroke={color}
            d='M87.7,203.6c7.4,7.5-3.9,18.8-11.3,11.3c-38.9-38.9-38.9-102,0-140.9c7.4-7.4,18.7,3.9,11.3,11.3 C55,118.1,55,171,87.7,203.6L87.7,203.6z'
          />
        </g>
        <g>
          <path
            className='MicrophoneOnSvg-st1'
            stroke={color}
            d='M123.2,168.2c7.5,7.4-3.8,18.7-11.3,11.3c-19.3-19.3-19.3-50.6,0-69.9c7.4-7.5,18.8,3.8,11.3,11.3 C110.1,133.9,110.1,155.1,123.2,168.2z'
          />
        </g>
      </g>
    </g>
    <g>
      <path
        className='MicrophoneOnSvg-st1'
        stroke={color}
        d='M457.1,238.5c-7.4,7.5,3.9,18.8,11.3,11.3c58.2-58.2,58.2-152.4,0-210.7c-7.4-7.4-18.7,3.9-11.3,11.3 C509.1,102.5,509.1,186.6,457.1,238.5z'
      />
    </g>
    <g>
      <path
        className='MicrophoneOnSvg-st1'
        stroke={color}
        d='M422.3,203.6c-7.4,7.5,3.9,18.8,11.3,11.3c38.9-38.9,38.9-102,0-140.9c-7.4-7.4-18.7,3.9-11.3,11.3 C455,118,455,170.9,422.3,203.6L422.3,203.6z'
      />
    </g>
    <g>
      <path
        className='MicrophoneOnSvg-st1'
        stroke={color}
        d='M386.8,168.2c-7.5,7.4,3.8,18.7,11.3,11.3c19.3-19.3,19.3-50.6,0-69.9c-7.4-7.5-18.8,3.8-11.3,11.3 C399.9,133.8,399.9,155,386.8,168.2z'
      />
    </g>
  </svg>
)

export default MicrophoneOnSvg
