import useAudioPlayer from '../../hooks/audio/useAudioPlayer'
import useAppUser from '../../hooks/useAppUser'
import AudioSpeakerButton from './AudioSpeakerButton'

const AudioSpeakerButtonAudio = ({ audioUrl, disabled, color, onEnded }) => {
  const { appUserData } = useAppUser()
  const { playing, setPlaying, loading } = useAudioPlayer({
    audioUrl,
    onEnded,
    stopOnPause: true,
    group: true,
    accent: appUserData.setting.availableAccent
  })

  return (
    <AudioSpeakerButton loading={loading} playing={playing} setPlaying={setPlaying} color={color} disabled={disabled} />
  )
}

export default AudioSpeakerButtonAudio
