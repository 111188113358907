const PhonoMetrixSvg = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18.466' viewBox='0 0 18 18.466'>
      <g transform='translate(0 10.391)'>
        <rect width='8.075' height='8.075' fill={color} />
        <rect width='8.075' height='8.075' transform='translate(9.925)' fill={color} />
      </g>
      <text
        id='_BC_'
        data-name='/BC/'
        transform='translate(8.944 7)'
        fill={color}
        fontSize='8'
        fontFamily='ArialMT, Arial'
        opacity='0.7'
      >
        <tspan x='-7.779' y='0'>
          /BC/
        </tspan>
      </text>
    </svg>
  )
}

export default PhonoMetrixSvg
