const scrollIntoViewByElement = element => {
  const elementBoundingClientRect = element?.getBoundingClientRect()
  const windowViewPort = window.innerHeight

  if (elementBoundingClientRect?.bottom > windowViewPort) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
}

export default scrollIntoViewByElement
