const homeStatisticsMapper = data => {
  let labels = []
  let values = []

  data.forEach(item => {
    labels.push(`Unit ${item.unitId}`)
    values.push(Math.floor(item.correctPercentage))
  })

  const dataExists = labels.length > 0

  if (labels.length === 0) {
    labels = ['Unit 1', 'Unit 2', 'Unit 3', 'Unit 4']
    values = [15, 65, 25, 100]
  }

  return {
    labels,
    values,
    dataExists
  }
}

export default homeStatisticsMapper
