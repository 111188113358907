import serviceCall from '../../services'
import tableOfContentMapper from '../../services/mapper/tableOfContent/tableOfContentMapper'

const tableOfContentUseCase = async () => {
  const result = await serviceCall('fetchTableOfContent', null, 'cloud')

  return tableOfContentMapper(result)
}

export default tableOfContentUseCase
