import React, { useContext, useEffect, useMemo, useState } from 'react'
import { createSearchParams, Link, useNavigate, useParams } from 'react-router-dom'
import colorsContext from '../../contexts/ColorsContext'
import ListeningSvg from '../../icons/ListeningSvg'
import SpeakingSvg from '../../icons/SpeakingSvg'
import SpellingSvg from '../../icons/SpellingSvg'
import { useDispatch, useSelector } from 'react-redux'
import { getWordData, setTranslationsMemoryBoxId } from '../../redux/features/translationSlice'
import AddToMemoryBoxSvg from '../../icons/AddToMemoryBoxSvg'
import AddToFavoriteButton from '../AddToFavoriteButton/AddToFavoriteButton'
import { isEmpty } from 'lodash'
import DictionaryCard from './DictionaryCard'
import { addFlashCardInLesson, removeFromBox } from '../../redux/features/memoryBox/memoryBoxActionsSlice'
import RemoveFromMemoryBoxSvg from '../../icons/RemoveFromMemoryBoxSvg'
import { moreOptionsList } from '../../data'
import { BOX, FAVORITES, LISTENING, SPEAKING, SPELLING } from '../../enums/toolsEnums/moreOptionsEnums'
import { transliterate } from 'transliteration'
import { setToolsGivenStates } from '../../redux/features/tools/toolsSlice'

const WordMoreModal = ({ optionsList = moreOptionsList, phrase, unitId }) => {
  require('./../../styles/tools.scss')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { colors } = useContext(colorsContext)
  const { loading } = useSelector(state => state.memoryBoxActions)
  const { translations } = useSelector(state => state.translation)
  const [wordData, setWordData] = useState(null)
  // const [showDictionary, setShowDictionary] = useState(true)
  const [memoryBoxButtonTitle, setMemoryBoxButtonTitle] = useState('')
  const [isMemoryBox, setIsMemoryBox] = useState(false)
  const { unit } = useParams()

  const unitNumber = parseInt(unit || unitId) || 0

  const word = useMemo(() => {
    return transliterate(phrase)
  }, [phrase])

  useEffect(() => {
    setIsMemoryBox(!isEmpty(wordData?.memoryBoxId))
  }, [wordData])

  useEffect(() => {
    if (isMemoryBox) {
      setMemoryBoxButtonTitle('Remove from Memory Box')
    } else {
      setMemoryBoxButtonTitle('Add to Memory Box')
    }
  }, [isMemoryBox])

  const translationData = useMemo(() => {
    return translations.find(_o => _o.title?.toLowerCase() === word?.toLowerCase())
  }, [translations, word])

  useEffect(() => {
    let p = true
    if (word) {
      if (translationData) {
        setWordData(translationData)
      } else {
        dispatch(getWordData({ phrase: word })).then(res => {
          if (p) {
            setWordData(res.payload)
          }
        })
      }
    }

    return () => {
      p = false
    }
  }, [translationData, word])

  const onClickMemoryBoxButton = () => {
    if (isMemoryBox) {
      dispatch(removeFromBox({ ids: [wordData?.memoryBoxId] })).then(() => {
        dispatch(
          setTranslationsMemoryBoxId({
            memoryBoxId: null,
            givenTitle: wordData?.givenTitle
          })
        )
      })
    } else {
      dispatch(addFlashCardInLesson({ isVocab: true, text: wordData?.givenTitle, unitId: unitNumber })).then(res => {
        if (res?.meta?.requestStatus !== 'rejected') {
          dispatch(
            setTranslationsMemoryBoxId({
              memoryBoxId: res.payload.id,
              givenTitle: wordData?.givenTitle
            })
          )
        }
      })
    }
  }

  const redirectToTools = url => {
    dispatch(setToolsGivenStates({ unitId: unitNumber, data: [wordData] }))
    navigate({
      pathname: url,
      search: createSearchParams({
        phraseData: wordData?.title
      }).toString()
    })
  }

  return (
    <>
      <div className='three-dot-modal'>
        <div
          className='circle-buttons-container'
          style={{ opacity: !wordData?.givenTitle ? 0.5 : 1, pointerEvents: !wordData?.givenTitle ? 'none' : 'auto' }}
        >
          {optionsList?.includes(LISTENING) && (
            <div className='button-wrapper col-4 col-md-2'>
              <button
                onClick={() => redirectToTools('/tools/listening/word')}
                className='circle-button'
                style={{ borderColor: colors['C160'] }}
              >
                <ListeningSvg color={colors['C160']} />
              </button>
              <p className='label' style={{ color: colors['C160'] }}>
                <button onClick={() => redirectToTools('/tools/listening/word')}>Listening</button>
              </p>
            </div>
          )}
          {optionsList?.includes(SPEAKING) && (
            <div className='button-wrapper col-4 col-md-2'>
              <button
                onClick={() => redirectToTools('/tools/speaking/word')}
                className='circle-button'
                style={{ borderColor: colors['C160'] }}
              >
                <SpeakingSvg color={colors['C160']} />
              </button>
              <p className='label' style={{ color: colors['C160'] }}>
                <button onClick={() => redirectToTools('/tools/speaking/word')}>Speaking</button>
              </p>
            </div>
          )}
          {optionsList?.includes(SPELLING) && (
            <div className='button-wrapper col-4 col-md-2'>
              <button
                onClick={() => redirectToTools('/tools/spelling/word')}
                className='circle-button'
                style={{ borderColor: colors['C160'] }}
              >
                <SpellingSvg color={colors['C160']} />
              </button>
              <p className='label' style={{ color: colors['C160'] }}>
                <button onClick={() => redirectToTools('/tools/spelling/word')}>Spelling</button>
              </p>
            </div>
          )}
          {optionsList?.includes(BOX) && (
            <div className='button-wrapper col-4 col-md-2' style={{ opacity: loading ? 0.5 : 1 }}>
              <button
                onClick={onClickMemoryBoxButton}
                className='circle-button'
                style={{ borderColor: colors['C160'] }}
                disabled={loading}
              >
                {!isMemoryBox ? (
                  <AddToMemoryBoxSvg color={colors['C160']} />
                ) : (
                  <RemoveFromMemoryBoxSvg color={colors['C160']} />
                )}
              </button>
              <button onClick={onClickMemoryBoxButton} className='label' style={{ color: colors['C160'] }}>
                {memoryBoxButtonTitle}
              </button>
            </div>
          )}
          {optionsList?.includes(FAVORITES) && (
            <div className='button-wrapper col-4 col-md-2'>
              <AddToFavoriteButton
                unitId={unitNumber}
                favoriteId={wordData?.favoriteId}
                text={wordData?.givenTitle}
                title={wordData?.title}
              />
            </div>
          )}
          {/*<div className='button-wrapper col-4 col-md-2'>*/}
          {/*  <button*/}
          {/*    className='circle-button'*/}
          {/*    onClick={() => setShowDictionary(!showDictionary)}*/}
          {/*    style={{borderColor: colors['C160'], background: showDictionary ? colors['C160'] : 'transparent'}}*/}
          {/*  >*/}
          {/*    <TranslationSvg color={showDictionary ? colors['C233'] : colors['C160']}/>*/}
          {/*  </button>*/}
          {/*  <p className='label' style={{color: colors['C160']}}>Translation</p>*/}
          {/*</div>*/}
        </div>
        <div className='py-4 w-100'>
          <DictionaryCard phrase={word} />
        </div>
      </div>
    </>
  )
}

export default WordMoreModal
