import serviceCall from '../../services'
import radioStationMapper from '../../services/mapper/radioStation/radioStationMapper'

const radioStationUseCase = async () => {
  const result = await serviceCall('fetchRadioStations', null, 'cloud')

  return radioStationMapper(result)
}

export default radioStationUseCase
