import { SENTENCE, WORD } from '../enums/toolsEnums/spellingEnums'
import { AnswerStatusEnums, EVEN, ODD } from '../enums/globalEnums/globalEnums'
import numberIsOdd from '../operations/numberIsOdd'
import hasWhiteSpace from '../operations/hasWhiteSpace'
import removeExtraSpace from '../operations/removeExtraSpace'

const memorizeFactory = (data, type) => {
  return data.map((item, itemIndex) => {
    let splitItem = []
    const clearTitle = removeExtraSpace(item.title)

    if (type === SENTENCE) {
      const arr = clearTitle?.split(' ')
      arr.forEach((item, i) => {
        splitItem.push({
          value: item,
          hasWhiteSpace: i + 1 < arr.length
        })
      })
    } else if (type === WORD) {
      const arr = clearTitle?.split('')
      arr.forEach((item, i) => {
        if (!hasWhiteSpace(item)) {
          splitItem.push({
            value: item,
            hasWhiteSpace: hasWhiteSpace(arr[i + 1])
          })
        }
      })
    }

    return {
      index: itemIndex,
      title: clearTitle,
      translation: item.translation,
      status: AnswerStatusEnums.NONE,
      usedHint: false,
      isFinished: false,
      unlocked: false,
      userAnswer: null,
      isDisabled: false,
      activeInputType: null,
      type,
      items: splitItem.map((item, i) => {
        return {
          ...item,
          index: i,
          type: numberIsOdd(i) ? ODD : EVEN,
          input: '',
          status: AnswerStatusEnums.NONE
        }
      })
    }
  })
}

export default memorizeFactory
