import * as React from 'react'

const ArrowMoreSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <g>
      <g>
        <path d='M503.8,123.1L487,106.2c-5.3-5.3-12.3-8.2-19.8-8.2c-7.5,0-14.5,2.9-19.8,8.2L255.8,297.7L64.1,106 c-5.3-5.3-12.3-8.2-19.8-8.2s-14.6,2.9-19.8,8.2L7.6,122.8c-10.9,10.9-10.9,28.7,0,39.7l228.3,229.1c5.3,5.3,12.3,9,19.9,9h0.1 c7.5,0,14.5-3.7,19.8-9L503.8,163c5.3-5.3,8.2-12.5,8.2-20C512,135.5,509.1,128.3,503.8,123.1z' />
      </g>
    </g>
  </svg>
)

export default ArrowMoreSvg
