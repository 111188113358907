import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  changeGroupType,
  checkExerciseItem,
  fetchSectionExercise,
  finishExercise,
  goToNextItem,
  onClickContinueDAD,
  resetAll,
  resetStates,
  setSectionExercise,
  toggleViewType
} from '../../../redux/features/exerciseSlice'
import ExerciseUseCase from '../../../useCase/ExerciseUseCase/ExerciseUseCase'
import ToplineTitle from '../../../components/ToplineTitle/ToplineTitle'
import HelpButton from '../../../components/HelpButton/HelpButton'
import colorsContext from '../../../contexts/ColorsContext'
import TemplateViewTypeButton from '../../../components/TemplateViewTypeButton/TemplateViewTypeButton'
import exerciseSectionStyles from './ExerciseSection.module.scss'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import ResetButton from '../../../components/ResetButton/ResetButton'
import LevelNumberBox from '../../../components/LevelNumberBox/LevelNumberBox'
import classNames from 'classnames'
import { isEmpty, orderBy, toNumber } from 'lodash'
import { RESET_EXERCISE, START_EXERCISE } from '../../../enums/studyLogEnums/studyLogEnums'
import useSectionEvents from '../../../hooks/sectionHooks/useSectionEvents'
import useLog from '../../../hooks/logHooks/useLog'
import useAppUser from '../../../hooks/useAppUser'
import UnitDataStorage from '../../../classes/UnitDataStorage'
import exerciseTypeEnums from '../../../enums/exerciseEnums/exerciseEnums'
import SkipRepeatButton from '../../../atomicComponents/molecules/SkipRepeatButton/SkipRepeatButton'
import { studyTypeEnum } from '../../../enums/userStudyEnums/userStudyEnums'
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'
import { ContentTypesStructureEnums } from '../../../enums/structureEnums/templateType'
import { unitStatusEnums } from '../../../enums/levelUnitEnums/levelUnitEnums'
import { TYPE_A, TYPE_B } from '../../../enums/exerciseEnums/exerciseGroupTypeEnums'
import RetryServiceButton from 'atomicComponents/molecules/RetryServiceButton/RetryServiceButton'

const ExerciseSection = ({ sectionName, hideHeader, sectionData = null, isBasic, isReviewMistake = false }) => {
  const { colors } = useContext(colorsContext)
  const states = useSelector(state => state.exercise)
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { navigateToNextSection, finishReviewMistake } = useSectionEvents(isBasic)
  const { unit, sectionId } = useParams()
  const { sendServerLog } = useLog()
  const { appUserData } = useAppUser()
  const [fetchAllowed, setFetchAllowed] = useState(false)
  const [unitData, setUnitData] = useState({})
  const { logPending } = useSelector(state => state.studyLog)
  const statusStates = useSelector(state => state.status)

  const localStorageUnitData = useMemo(() => {
    const unitDataStorage = new UnitDataStorage()
    return unitDataStorage.getUnitData()
  }, [])

  const isEligibleSectionForDisplayingSkip = useMemo(() => {
    return (
      unitData?.unitNumber <= 21 &&
      (unitData?.studyStatus === studyTypeEnum.REPEAT_2 ||
        unitData?.studyStatus === studyTypeEnum.REPEAT_3 ||
        unitData?.studyStatus === studyTypeEnum.REPEAT_4 ||
        unitData?.studyStatus === studyTypeEnum.REPEAT_5 ||
        unitData?.studyStatus === studyTypeEnum.REPEAT_6 ||
        unitData?.studyStatus === studyTypeEnum.REPEAT_7)
    )
  }, [JSON.stringify(unitData)])

  const fetchData = () => {
    if (toNumber(unit) !== states.unitNumber || toNumber(sectionId) !== states.sectionNumber) {
      dispatch(resetStates())
      if (sectionData) {
        dispatch(setSectionExercise({ sectionData }))
      } else {
        networkLossRetryerDispatch(retryId => {
          dispatch(
            fetchSectionExercise({
              unit: isBasic ? '0' : unit,
              section: sectionName,
              sectionNumber: sectionId,
              retryId
            })
          ).then(() => {
            sendServerLog(START_EXERCISE)
          })
        })
      }
    }
  }

  const resetExercise = () => {
    dispatch(resetAll())
    sendServerLog(RESET_EXERCISE)
  }

  useEffect(() => {
    setFetchAllowed(true)
    setUnitData(localStorageUnitData ?? {})
    dispatch(changeGroupType(localStorageUnitData.exerciseGroupType))
  }, [])

  useEffect(() => {
    if (fetchAllowed) {
      fetchData()
    }
  }, [fetchAllowed, states.exerciseGroupType])

  const onClickCheck = () => {
    switch (states.data.type) {
      case exerciseTypeEnums.putInOrderConversation: {
        const userAnswerData = {
          exerciseStackId: states.data.stack.id,
          userAnswerItems: [
            {
              exerciseItemId: states.data.stack.itemId,
              userAnswers: orderBy(states.data.stack.givens, 'order').map(given => {
                return {
                  listIndex: given.index
                }
              })
            }
          ]
        }
        dispatch(checkExerciseItem({ userAnswerData })).then(res => {
          if (res.payload?.status === 200) {
            dispatch(finishExercise())
          }
        })
        break
      }
      case exerciseTypeEnums.matchCase: {
        const userAnswerData = {
          exerciseStackId: states.data.stack.id,
          userAnswerItems: [
            {
              exerciseItemId: states.data.stack.itemId,
              userAnswers: orderBy(states.data.userData, o => o.given.index).map((u, i) => {
                return {
                  listIndex: u.choice.index
                }
              })
            }
          ]
        }
        dispatch(checkExerciseItem({ userAnswerData })).then(res => {
          if (res.payload?.status === 200) {
            dispatch(finishExercise())
          }
        })
        break
      }
      case exerciseTypeEnums.chooseFromCategory: {
        const userAnswerData = {
          exerciseStackId: states.data.stack.id,
          userAnswerItems: [
            {
              exerciseItemId: states.data.stack.itemId,
              userAnswers: states.data.stack.choices
                .filter(o => o.selected)
                .map(choice => {
                  return {
                    listIndex: 0,
                    index: choice.index
                  }
                })
            }
          ]
        }
        dispatch(checkExerciseItem({ userAnswerData })).then(res => {
          if (res.payload?.status === 200) {
            dispatch(finishExercise())
          }
        })
        break
      }
      case exerciseTypeEnums.dragAndDropConversation: {
        const userAnswerData = {
          exerciseStackId: states.data.stack.id,
          userAnswerItems: [...states.data.stack.items]
            .filter(el => el.hasAnswer)
            .map(item => {
              return {
                exerciseItemId: item.id,
                userAnswers: [...item.answerFields].map(answer => {
                  return {
                    listIndex: answer.index,
                    text: answer.userAnswer.text.sentence
                  }
                })
              }
            })
        }
        dispatch(checkExerciseItem({ userAnswerData })).then(res => {
          if (res.payload?.status === 200) {
            dispatch(finishExercise())
          }
        })
        break
      }
      case exerciseTypeEnums.dragAndDropCategory: {
        const userAnswerData = {
          exerciseStackId: states.data.stack.id,
          userAnswerItems: [...states.data.stack.givens].map(given => {
            return {
              exerciseItemId: given.itemId,
              userAnswers: [...states.data.stack.answerFields]
                .filter(el => el.itemId === given.itemId)
                .map(field => {
                  return {
                    listIndex: 0,
                    text: field.userAnswer.text.sentence,
                    trackerNumber: field.userAnswer.text.trackerNumber
                  }
                })
            }
          })
        }
        dispatch(checkExerciseItem({ userAnswerData })).then(res => {
          if (res.payload?.status === 200) {
            dispatch(finishExercise())
          }
        })
        break
      }
      case exerciseTypeEnums.dragAndDrop: {
        const currentExerciseItem = states.data.stack.items[states.data.currentItemIndex]

        const userAnswerData = {
          exerciseStackId: states.data.stack.id,
          userAnswerItems: [
            {
              exerciseItemId: currentExerciseItem.id,
              indexImportant:
                currentExerciseItem.type === ContentTypesStructureEnums.TEXT || currentExerciseItem.hasBubble,
              userAnswers: [...currentExerciseItem.answerFields].map(field => {
                if (currentExerciseItem.type === ContentTypesStructureEnums.TEXT) {
                  return {
                    listIndex: 0,
                    index: field.index,
                    text: field.userAnswer.text.sentence
                  }
                } else {
                  return {
                    listIndex: 0,
                    index: field.index,
                    trackerNumber: field.userAnswer.text.trackerNumber
                  }
                }
              })
            }
          ]
        }
        dispatch(checkExerciseItem({ userAnswerData })).then(res => {
          if (res.payload?.status === 200 && !states.data.paginable) {
            dispatch(onClickContinueDAD({ isConversation: false }))
          }
        })
        break
      }
      default:
        break
    }
  }

  const renderPageNumber = () => {
    if (states.data.type === exerciseTypeEnums.dragAndDrop && !states.data.finished && states.data.paginable) {
      return (
        <div className='d-flex align-items-center justify-content-center mb-3'>
          <LevelNumberBox color={colors['C2']}>
            {states.data.currentItemIndex + 1}/{states.data.stack.items.length}
          </LevelNumberBox>
        </div>
      )
    } else if (states.isSingleTemplate && states.data.paginable) {
      let itemsLength = 0
      states.data.stacks.forEach(stack => {
        itemsLength += stack.items.length
      })
      return (
        <div className='d-flex align-items-center justify-content-center mb-3'>
          <LevelNumberBox color={colors['C2']}>
            {states.currentItem.pageNumber}/{itemsLength}
          </LevelNumberBox>
        </div>
      )
    } else {
      return null
    }
  }

  const renderButtons = () => {
    if (states.data.type === exerciseTypeEnums.dragAndDrop) {
      if (states.finished) {
        return (
          <>
            <ResetButton color={colors['C2']} onClick={resetExercise} />
            <PrimaryButton loading={logPending} onClick={isReviewMistake ? finishReviewMistake : navigateToNextSection}>
              Next
            </PrimaryButton>
          </>
        )
      } else if (
        states.data.paginable &&
        states.data.stack.items.length >= states.data.currentItemIndex + 1 &&
        states.data.stack.items[states.data.currentItemIndex].checked
      ) {
        return (
          <PrimaryButton
            onClick={() => {
              dispatch(onClickContinueDAD({ isConversation: false }))
            }}
          >
            Continue
          </PrimaryButton>
        )
      } else if (!states.data.immediateCheck) {
        return (
          <PrimaryButton
            disabled={!states.data.stack.items[states.data.currentItemIndex].checkable}
            loading={states.checkingItem}
            onClick={() => (!states.checkingItem ? onClickCheck() : null)}
          >
            Check
          </PrimaryButton>
        )
      }
    } else if (states.isSingleTemplate) {
      const checked = states.data.stacks[states.currentItem.stackIndex].items[states.currentItem.itemIndex].checked
      const isTheLastItem =
        states.data.stacks.length <= states.currentItem.stackIndex + 1 &&
        states.data.stacks[states.currentItem.stackIndex].items.length <= states.currentItem.itemIndex + 1

      if (isTheLastItem && checked) {
        return (
          <PrimaryButton loading={logPending} onClick={isReviewMistake ? finishReviewMistake : navigateToNextSection}>
            Next
          </PrimaryButton>
        )
      } else {
        return (
          <PrimaryButton
            disabled={!checked}
            onClick={() => {
              if (checked) dispatch(goToNextItem())
            }}
          >
            Continue
          </PrimaryButton>
        )
      }
    } else {
      if (states.finished) {
        return (
          <>
            <ResetButton color={colors['C2']} onClick={resetExercise} />
            <PrimaryButton loading={logPending} onClick={isReviewMistake ? finishReviewMistake : navigateToNextSection}>
              Next
            </PrimaryButton>
          </>
        )
      } else if (
        !states.data.paginable &&
        states.data.type !== exerciseTypeEnums.matchCase &&
        states.data.type !== exerciseTypeEnums.dragAndDropConversation
      ) {
        return (
          <PrimaryButton
            disabled={!states.data.checkable}
            onClick={() => (!states.checkingItem ? onClickCheck() : null)}
            loading={states.checkingItem}
          >
            Check
          </PrimaryButton>
        )
      }
    }
    return null
  }

  const renderSkipButton = () => {
    const studyProgressStatus = statusStates.data?.sections?.find(
      it => it.sectionType === sectionData?.sectionType
    )?.studyProgressStatus

    if (
      isEligibleSectionForDisplayingSkip &&
      ((isReviewMistake && studyProgressStatus && studyProgressStatus !== unitStatusEnums.DONE) || !states.finished)
    ) {
      return (
        <div
          className={classNames(
            exerciseSectionStyles?.skipButtonContainer,
            'container container-1 exercise-skip-button-container'
          )}
        >
          <div className={exerciseSectionStyles?.skipButtonWrapper}>
            <div className={exerciseSectionStyles?.skipButton}>
              <SkipRepeatButton isReviewMistake={isReviewMistake} sectionType={sectionData?.sectionType} />
            </div>
          </div>
        </div>
      )
    } else return null
  }

  if (!states.loading && isEmpty(states.data) && Boolean(states.error)) {
    return <RetryServiceButton onClick={fetchData} />
  }
  if (states.data) {
    return (
      <div className='pt-3'>
        {!hideHeader && (
          <div className='container-fluid'>
            <div className='d-flex justify-content-end'>
              {appUserData?.access?.roles?.includes('app_administrator') && (
                <button
                  className='font-weight-600 px-2 rounded-pill fs-sm'
                  style={{ color: colors['C2'], background: 'transparent', border: `1px solid ${colors['C2']}` }}
                  onClick={() => {
                    dispatch(resetStates())
                    dispatch(changeGroupType())
                  }}
                >
                  {states.exerciseGroupType === TYPE_A && 'Type A'}
                  {states.exerciseGroupType === TYPE_B && 'Type B'}
                </button>
              )}
              {states.data.paginable && !states.data.hideChangeViewButton && (
                <div className='me-3 me-md-4 ms-2'>
                  <TemplateViewTypeButton
                    onClick={() => {
                      dispatch(toggleViewType())
                      if (
                        states.isSingleTemplate &&
                        states.data.stacks[states.currentItem.stackIndex].items[states.currentItem.itemIndex].checked
                      ) {
                        dispatch(goToNextItem())
                      }
                    }}
                    isSingle={states.isSingleTemplate}
                    color={colors['C2']}
                  />
                </div>
              )}
              <div className='me-3 me-md-5 ms-2'>
                <HelpButton disabled={!states.pageType} color={colors['C2']} pageType={states.pageType} />
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames(exerciseSectionStyles.exerciseSectionStacksContainer, {
            [exerciseSectionStyles.havePagination]:
              (states.isSingleTemplate && states.data.paginable) ||
              (states.data.type === exerciseTypeEnums.dragAndDrop && !states.data.finished)
          })}
        >
          <ToplineTitle titleData={states.data.title} />
          <div className='my-2 my-md-3'>
            <ExerciseUseCase />
          </div>
        </div>
        {renderPageNumber()}
        <div className='pagebelow-buttons-container'>
          {renderButtons()}
          {renderSkipButton()}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default ExerciseSection
