import htmlToText from '../../operations/htmlToText'
import getParagraphList from '../../operations/getParagraphList'
import getStyleObjectFromString from '../../operations/inlineCssToReactCss'
import { ContentTypesStructureEnums } from '../../enums/structureEnums/templateType'
import { imageTypesEnums } from '../../enums/imageTypesEnums/imageTypesEnums'

export const htmlTextConversion = (data, withParagraphList = false) => {
  if (data) {
    const paragraphStyleExec = /style=\"(.*?)\"/gi.exec(data.sentence)
    let paragraphStyle = {}
    if (paragraphStyleExec && paragraphStyleExec.length > 1) {
      paragraphStyle = getStyleObjectFromString(paragraphStyleExec[1])
    }

    return {
      sentence: data.sentence,
      paragraphStyle,
      paragraphList: withParagraphList ? getParagraphList(data.sentence) : null,
      sentenceString: htmlToText(data.sentence),
      voiceUri: data.voiceUri ?? null,
      info: data.info ?? null,
      more: data.more ?? false,
      trackerNumber: data.trackerNumber ?? null
    }
  } else {
    return null
  }
}

export const generateExerciseTemplateClassName = (type, template) => {
  if (type.includes(ContentTypesStructureEnums.IMAGE)) {
    switch (template) {
      case imageTypesEnums.IMAGE_UP:
        return 'giventype-column'
      case imageTypesEnums.IMAGE_DOWN:
        return 'giventype-column-reverse'
      case imageTypesEnums.IMAGE_LEFT:
        return 'giventype-row'
      case imageTypesEnums.IMAGE_RIGHT:
        return 'giventype-row-reverse'
      case imageTypesEnums.THREE_COLUMNS:
        return 'col-6 col-md-4'
      case imageTypesEnums.TWO_COLUMNS:
        return 'col-6'
      default:
        return null
    }
  }
  return null
}
