import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import speechToTextUseCase from '../../useCase/speechToTextUseCase/speechToTextUseCase'

const initialState = {
  loading: false,
  speechRecognitionType: null,
  speechRecognitionHistory: [],
  data: '',
  error: ''
}

export const getTextFromAudio = createAsyncThunk('archive/getTextFromAudio', async ({ formData }, { signal }) => {
  return await speechToTextUseCase({ formData, signal })
})

const speechToTextSlice = createSlice({
  name: 'speechToText',
  initialState,
  reducers: {
    setSpeechRecognitionType: (state, action) => {
      state.speechRecognitionType = action.payload
    },
    pushSpeechRecognitionHistory: (state, action) => {
      state.speechRecognitionHistory = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTextFromAudio.pending, state => {
        state.loading = true
      })
      .addCase(getTextFromAudio.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
      })
      .addCase(getTextFromAudio.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const { setSpeechRecognitionType, pushSpeechRecognitionHistory } = speechToTextSlice.actions

export default speechToTextSlice.reducer
