import serviceCall from 'services'
import plansMapper from 'services/mapper/payment/plansMapper'

const applyDiscountCodeUseCase = async ({ discountCode, asRenewal, purchaseType }) => {
  const result = await serviceCall('fetchDiscounted', { discountCode, asRenewal }, 'cloud')

  return plansMapper(result, purchaseType)
}

export default applyDiscountCodeUseCase
