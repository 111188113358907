import { getAppUser } from './features/appUserSlice'
import { checkConversationMemorize, fetchConversation, getConversationMemorizeHint } from './features/conversationSlice'
import { fetchDrill } from './features/drillSlice'
import { checkExerciseItem, fetchSectionExercise, speechToText } from './features/exerciseSlice'
import { getHomeStatistics } from './features/homepageSlice'
import {
  getLevels,
  getLevelsAndUnits,
  getSections,
  getUnits,
  toggleLevelStatus,
  toggleUnitStatus
} from './features/levelUnitSlice'
import { getAllCategories } from './features/memoryBox/memoryBoxCategorySlice'
import { getAllNotepadFileAndFolders } from './features/notepadSlice'
import { fetchReading } from './features/readingSlice'
import { getMistakes } from './features/reviewMistakeSlice'
import { fetchRoboChat } from './features/roboChatSlice'
import {
  getGameStatistics,
  getStatistics,
  getUnitGameStatistics,
  getUnitStatistics,
  getUserUnitStatus
} from './features/statisticsSlice'
import { getUnitStatus } from './features/statusSlice'
import { fetchMoreSections, fetchStructuredSection } from './features/structuredSectionSlice'
import { sendStudyLog } from './features/studyLogSlice'
import { getHomeTodayStudy, getTodayStudy, getTodayStudyPlan, postSkipStudyPlan } from './features/todayStudySlice'

// Map identifiers to thunk action creators
const thunkActionRegistry = {
  'appUser/getAppUser': getAppUser,
  'homepage/getHomeTodayStudy': getHomeTodayStudy,
  'homepage/getHomeStatistics': getHomeStatistics,
  'todayStudy/postSkipStudyPlan': postSkipStudyPlan,
  'todayStudy/getTodayStudyPlan': getTodayStudyPlan,
  'todayStudy/getTodayStudy': getTodayStudy,
  'memoryBoxCategorySlice/getAllCategories': getAllCategories,
  'notepad/getAllNotepadFileAndFolders': getAllNotepadFileAndFolders,
  'levelUnit/getLevelsAndUnits': getLevelsAndUnits,
  'levelUnit/getLevels': getLevels,
  'levelUnit/getUnits': getUnits,
  'levelUnit/getSections': getSections,
  'levelUnit/toggleLevelStatus': toggleLevelStatus,
  'levelUnit/toggleUnitStatus': toggleUnitStatus,
  'structuredSection/fetchStructuredSection': fetchStructuredSection,
  'structuredSection/fetchMoreSections': fetchMoreSections,
  'conversation/fetchConversation': fetchConversation,
  'conversation/checkConversationMemorize': checkConversationMemorize,
  'conversation/getConversationMemorizeHint': getConversationMemorizeHint,
  'reading/fetchReading': fetchReading,
  'exercise/fetchSectionExercise': fetchSectionExercise,
  'exercise/speechToText': speechToText,
  'exercise/checkExerciseItem': checkExerciseItem,
  'drill/fetchDrill': fetchDrill,
  'roboChat/fetchRoboChat': fetchRoboChat,
  'reviewMistake/getMistakes': getMistakes,
  'statistics/getUserUnitStatus': getUserUnitStatus,
  'statistics/getStatistics': getStatistics,
  'statistics/getGameStatistics': getGameStatistics,
  'statistics/getUnitGameStatistics': getUnitGameStatistics,
  'statistics/getUnitStatistics': getUnitStatistics,
  'studyLog/sendStudyLog': sendStudyLog,
  'status/getUnitStatus': getUnitStatus
  // Add other thunks as needed
}

export default thunkActionRegistry
