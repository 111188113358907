import { studyTypeEnum } from '../enums/userStudyEnums/userStudyEnums'
import { sectionsData } from '../sources/sectionsData'

const getSectionsList = sectionNumbers => {
  return sectionNumbers?.map(sectionNumber => {
    return { sectionNumber, isCurrentSection: false }
  })
}

const studySectionsListFactory = (studyType, selectedSectionsToReview = [1, 2, 11, 13, 20]) => {
  switch (studyType) {
    case studyTypeEnum.BASIC: {
      return getSectionsList([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    }
    case studyTypeEnum.NEW: {
      return getSectionsList([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.VIEW: {
      return getSectionsList([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_1: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_2: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_3: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_4: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_5: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_6: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REPEAT_7: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    }
    case studyTypeEnum.REVIEW: {
      return getSectionsList(selectedSectionsToReview?.filter(it => [1, 2, 11, 13, 20].includes(it))) ?? []
    }
    case studyTypeEnum.QUICK_REVIEW: {
      return getSectionsList(selectedSectionsToReview?.filter(it => [1, 2, 11, 13, 20].includes(it))) ?? []
    }
    case studyTypeEnum.EXERCISE: {
      return getSectionsList([3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 22, 23])
    }
  }
}

export default studySectionsListFactory
