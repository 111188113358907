import paymentAmountsRound from '../../../operations/paymentAmountsRound'
import { toFarsiNumber } from '../../../util/utility'

const plansMapper = (data, purchaseType) => {
  const getIRRPriceAmount = amount => {
    if (amount >= 10000000) {
      const value = amount / 10000000
      return {
        paymentCurrencyCode: 'IRR',
        value: value,
        symbol: ' میلیون تومان',
        displayValue: toFarsiNumber(value.toFixed(3)).replace('.', '٫')
      }
    } else if (amount >= 10000) {
      const value = amount / 10000
      return {
        paymentCurrencyCode: 'IRR',
        value: value,
        symbol: ' هزار تومان',
        displayValue: toFarsiNumber(value.toString())
      }
    } else {
      const value = amount / 10
      return {
        paymentCurrencyCode: 'IRR',
        value: value,
        symbol: ' تومان',
        displayValue: toFarsiNumber(value.toString())
      }
    }
  }

  const renderPriceObject = obj => {
    if (obj.paymentCurrencyCode === 'IRR') {
      return getIRRPriceAmount(obj.value)
    } else {
      return {
        paymentCurrencyCode: obj.paymentCurrencyCode,
        symbol: obj.symbol,
        value: obj.value,
        displayValue: obj.value.toLocaleString('en-US', {
          maximumFractionDigits: 2
        })
      }
    }
  }

  const renderMonthlyPriceObject = (obj, duration) => {
    const monthlyValue =
      obj.monthlyAmount ?? (obj.value / duration).toLocaleString('en-US', { maximumFractionDigits: 2 })

    if (obj.paymentCurrencyCode === 'IRR') {
      return getIRRPriceAmount(monthlyValue)
    } else {
      return {
        paymentCurrencyCode: obj.paymentCurrencyCode,
        symbol: obj.symbol,
        value: monthlyValue,
        displayValue: monthlyValue
      }
    }
  }

  const getDiscountAmount = (base, final, isIRR) => {
    let value = base.value - final.value

    if (isIRR) {
      return getIRRPriceAmount(value)
    } else {
      return {
        paymentCurrencyCode: base.paymentCurrencyCode,
        symbol: base.symbol,
        value: value,
        displayValue: value.toLocaleString('en-US', {
          maximumFractionDigits: 2
        })
      }
    }
  }

  const getAiTokens = (token, isIRR) => {
    const tokensAmount = token / 1000

    return isIRR ? toFarsiNumber(tokensAmount) : tokensAmount
  }

  const getDiscountPercentage = (base, final, isIRR) => {
    // Calculate based on amount and finalAmount
    const value = paymentAmountsRound(((base - final) / base) * 100)
    return {
      value,
      displayValue: isIRR ? toFarsiNumber(value) : value
    }
  }

  const result = data?.map(item => {
    const isIRR = item.price.amount.paymentCurrencyCode === 'IRR'
    let duration = Math.round(item.plan.duration / 30)

    return {
      ...item,
      isDiscountedByReferenceId: item.discount?.codes?.length > 0,
      purchaseType,
      isIRR,
      plan: {
        ...item.plan,
        aiTokens: getAiTokens(item.plan.token, isIRR)
      },
      price: {
        ...item.price,
        amount: renderPriceObject(item.price.amount),
        finalAmount: renderPriceObject(item.price.finalAmount),
        monthlyAmount: renderMonthlyPriceObject(item.price.amount, duration),
        finalMonthlyAmount: renderMonthlyPriceObject(item.price.finalAmount, duration),
        // discountPercentage: getDiscountPercentage(item.price.amount.value, item.price.finalAmount.value, isIRR),
        discountPercentage: {
          value: parseInt(item.plan.description) || 0,
          displayValue: isIRR
            ? toFarsiNumber(parseInt(item.plan.description) || 0)
            : parseInt(item.plan.description) || 0
        },
        discountAmount: getDiscountAmount(item.price.amount, item.price.finalAmount, isIRR),
        trace: [...item.price.trace]?.map(trace => {
          return {
            ...trace,
            appliedAmount: renderPriceObject(trace.appliedAmount),
            baseAmount: renderPriceObject(trace.baseAmount)
          }
        })
      },
      duration: { value: isIRR ? toFarsiNumber(duration) : duration }
    }
  })

  return result
}

export default plansMapper
