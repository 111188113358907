import { fetchVocabularies } from '../../services/cloudServices'
import serviceCall from '../../services'
import vocabularyFullTableMapper from '../../services/mapper/vocabulary/vocabularySimpleTableMapper'
import favoritesCategoryMapper from '../../services/mapper/favorite/favoritesCategoryMapper'

const updateFavoritesCategoryUseCase = async ({ type, id, name, color }) => {
  const result = await serviceCall('updateFavoriteCategories', { id, name, color }, 'cloud')

  return favoritesCategoryMapper(result, type)
}

export default updateFavoritesCategoryUseCase
