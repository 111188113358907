const radioStationMapper = data => {
  return data.map((station, index) => {
    return {
      index,
      id: station.id,
      name: station.name,
      url: station.url,
      imageUri: station.image
    }
  })
}

export default radioStationMapper
