import _ from 'lodash'
import { speechToTextTypeEnums } from '../enums/speechToTextEnums/speechToTextEnums'
import * as Sentry from '@sentry/react'

const speechRecognitionHistoryFactory = (speechType, history = [], data) => {
  const maxHistoryLength = 5
  const skipAfter = 5
  const switchAfter = 3

  history = [
    ...history,
    {
      input: data.input,
      given: data.given,
      practiceType: data.practiceType,
      speechType: data.speechType,
      isCorrect: data.isCorrect
    }
  ].slice(-maxHistoryLength)

  // (last {switchAfter} items)
  const lastHistoryItems = history.slice(-switchAfter)
  // Items in history have the same given, which means that the last three tries of user are in one exercise item
  const sameGivens = _.every(lastHistoryItems, o => {
    return _.isEqual(o.given, _.head(lastHistoryItems).given) && _.isEqual(o.practiceType, _.head(history).practiceType)
  })

  if (
    speechType === speechToTextTypeEnums.speechRecognitionApi &&
    sameGivens &&
    lastHistoryItems?.length === switchAfter &&
    !lastHistoryItems?.some(it => it.isCorrect) //  The same as: lastHistoryItems?.every(it => !it.isCorrect)
  ) {
    Sentry.captureMessage(`Speech type switched to Whisper after ${switchAfter} times of wrong answer.`, {
      level: 'info',
      extra: { history }
    })
    speechType = speechToTextTypeEnums.whisper
  }

  const readyToSkip =
    history.length === skipAfter &&
    _.every(history, o => {
      return _.isEqual(o.given, _.head(history).given) && _.isEqual(o.practiceType, _.head(history).practiceType)
    }) &&
    !history?.some(it => it.isCorrect)

  if (readyToSkip) {
    Sentry.captureMessage(`Exercise item skipped after ${skipAfter} times of wrong answers.`, {
      level: 'info',
      extra: { history }
    })
  }

  return {
    newHistory: history,
    newSpeechType: speechType,
    readyToSkip
  }
}

export default speechRecognitionHistoryFactory
