const aiPromptMapper = (result, data) => {
  return {
    content: result?.content,
    totalTokens: result?.totalTokens,
    role: result?.role,
    aiPromptId: result?.aiPromptId,
    id: result?.id,
    userAnswer: data?.userAnswer
  }
}

export default aiPromptMapper
