export default [
  {
    translationKey: 'GATEWAY_CAPTCHA_VALIDATION_FAILED',
    translationValue: 'اعتبارسنجی ناموفق بود.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_CREATE_FAILED',
    translationValue: 'ایجاد حساب کاربری ناموفق بود.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_NOT_FOUND',
    translationValue: 'حسابی با این نام کاربری وجود ندارد!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_INCORRECT',
    translationValue: 'رمز عبور اشتباه است!'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_EMPTY',
    translationValue: 'وارد کردن رمز عبور الزامی است.'
  },
  {
    translationKey: 'USER_MANAGEMENT_PASSWORD_LENGTH',
    translationValue: 'رمز عبور انتخابی حداقل ۳ کاراکتر باشد.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_EMPTY',
    translationValue: 'وارد کردن مجدد رمز عبور الزامی است.'
  },
  {
    translationKey: 'USER_MANAGEMENT_REPASSWORD_NOT_MATCH',
    translationValue: 'رمز عبور های وارد شده یکسان نیستند!'
  },
  {
    translationKey: 'USER_MANAGEMENT_USERNAME_EMPTY',
    translationValue: 'نام کاربری را وارد کنید.'
  },
  {
    translationKey: 'USER_MANAGEMENT_USER_INVALID',
    translationValue: 'حساب کاربری نامعتبر است.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EXPIRED',
    translationValue: 'کد تایید منقضی شده است.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_INVALID',
    translationValue: 'کد تایید نامعتبر است.'
  },
  {
    translationKey: 'USER_MANAGEMENT_CODE_EMPTY',
    translationValue: 'لطفا کد تایید را وارد کنید.'
  },
  {
    translationKey: 'USER_MANAGEMENT_OTP_RETRY',
    translationValue: 'لطفا چند دقیقه صبر کنید.'
  },
  {
    translationKey: 'PAYMENT_IP_INVALID',
    translationValue: 'آی‌پی شما نامعتبر است.'
  },
  {
    translationKey: 'PAYMENT_REGION_INVALID',
    translationValue: 'کد تخفیف وارد شده در کشور شما نامعتبر است.'
  },
  {
    translationKey: 'PAYMENT_CODE_EXPIRED',
    translationValue: 'کد تخفیف منقضی شده است.'
  },
  {
    translationKey: 'PAYMENT_CODE_UNAVAILABLE',
    translationValue: 'کد تخفیف نامعتبر است.'
  },
  {
    translationKey: 'PAYMENT_CODE_USED',
    translationValue: 'این کد تخفیف قبلاً استفاده شده است.'
  },
  {
    translationKey: 'CORE_AI_TOKEN_AMOUNT',
    translationValue: 'تعداد توکن‌های شما کافی نیست!'
  },
  {
    translationKey: 'CORE_AI_ROLE_DESCRIPTION_INVALID',
    translationValue: 'تعریفی برای این شناسه وجود ندارد.'
  },
  {
    translationKey: 'CORE_AI_PROMPT_ID_INVALID',
    translationValue: 'هیچ درخواستی با این شناسه وجود ندارد.'
  },
  {
    translationKey: 'CORE_AI_FEEDBACK_NOT_FOUND',
    translationValue: 'بازخوردی یافت نشد.'
  },
  {
    translationKey: 'CORE_FILE_NOT_ENOUGH_SPACE',
    translationValue: 'فضای کافی برای ذخیره‌سازی موجود نیست.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_EXISTS',
    translationValue: 'دسته‌بندی با این نام قبلاً ایجاد شده‌است.'
  },
  {
    translationKey: 'CORE_FAVORITE_CATEGORY_NOT_FOUND',
    translationValue: 'دسته‌بندی یافت نشد!'
  },
  {
    translationKey: 'CORE_FAVORITE_ITEM_EXISTS',
    translationValue: 'این مورد در این دسته‌بندی وجود دارد.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CARD_EXISTS',
    translationValue: 'این کارت قبلاً ایجاد شده‌است.'
  },
  {
    translationKey: 'CORE_MEMORY_BOX_CATEGORY_EXISTS',
    translationValue: 'دسته‌بندی فلش‌کارت با این نام قبلاً ایجاد شده‌است.'
  },
  {
    translationKey: 'CORE_MEMORY_NOTEPAD_TITLE_EXISTS',
    translationValue: 'این عنوان قبلاً انتخاب شده‌است. عنوان دیگری انتخاب کنید.'
  },
  {
    translationKey: 'CORE_DICTIONARY_WORD_NOT_FOUND',
    translationValue: 'کلمه‌ای یافت نشد.'
  },
  {
    translationKey: 'PAYMENT_CODE_INVALID',
    translationValue: 'کد تخفیف وارد شده اشتباه است. لطفاً بررسی و دوباره تلاش کنید.'
  },
  {
    translationKey: 'OOPS_SOMETHING_WENT_WRONG',
    translationValue: 'متاسفانه خطایی رخ داده‌‌است!'
  },
  {
    translationKey: 'CORE_TURN_OFF_LEVEL_INVALID',
    translationValue: 'امکان فعال کردن سطح قبلی وجود ندارد.'
  },
  {
    translationKey: 'CORE_TURN_OFF_UNIT_INVALID',
    translationValue: 'امکان غیرفعال کردن درس‌های قبلی  وجود ندارد.'
  },
  {
    translationKey: 'CORE_TURN_ON_UNIT_INVALID',
    translationValue: 'امکان فعال کردن درس‌های قبلی وجود ندارد.'
  }
]
