import serviceCall from '../../services'
import memoryTypeTestMapper from '../../services/mapper/memoryTypeTest/memoryTypeTestMapper'

const memoryTypeTestUseCase = async data => {
  const result = await serviceCall('fetchMemoryTypeTest', data, 'cloud')

  return memoryTypeTestMapper(result)
}

export default memoryTypeTestUseCase
