import { htmlTextConversion } from '../mapperConversions'

const alphabetMapper = data => {
  return {
    ...data,
    letters: data.letters.map(letter => {
      return {
        ...letter,
        text: htmlTextConversion(letter.text),
        sample: htmlTextConversion(letter.sample)
      }
    })
  }
}

export default alphabetMapper
