import { createSlice } from '@reduxjs/toolkit'
import { appUserActions } from '../../enums/userActionEnums/userActionEnums'

const initialState = {
  firstTimeActions: appUserActions.map(it => ({ ...it, isFinished: true })),
  displayingAction: null
}

const firstTimeActionSlice = createSlice({
  name: 'firstTimeAction',
  initialState,
  reducers: {
    setFirstTimeActions: (state, action) => {
      state.firstTimeActions = state.firstTimeActions.map(it => {
        if (action.payload?.includes(it.key)) {
          return { ...it, isFinished: false }
        }
        return { ...it, isFinished: true }
      })
    },
    finishFirstTimeAction: (state, action) => {
      state.firstTimeActions = state.firstTimeActions.map(it => {
        if (it.key === action.payload) {
          return { ...it, isFinished: true }
        }
        return it
      })
    },
    setDisplayingAction: (state, action) => {
      state.displayingAction = action.payload
    }
  }
})

export const { setFirstTimeActions, finishFirstTimeAction, setDisplayingAction } = firstTimeActionSlice.actions

export default firstTimeActionSlice.reducer
