import serviceCall from '../../services'
import sectionsMapper from '../../services/mapper/levelAndUnit/sectionsMapper'

const sectionsUseCase = async ({ level, unitId, studyType }) => {
  const response = await serviceCall('fetchSections', { unitId, studyType }, 'cloud')

  return {
    level,
    unitId,
    sections: sectionsMapper(response)
  }
}

export default sectionsUseCase
