import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cachedUrls: []
}

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMediaCache: (state, action) => {
      const { url, localSrc, status } = action.payload

      const dataIndex = state.cachedUrls.findIndex(item => item.url === url)

      if (dataIndex !== -1) {
        state.cachedUrls = [...state.cachedUrls].map(item => {
          return item.url === url ? { ...item, url, localSrc, status } : item
        })
      } else {
        state.cachedUrls = [...state.cachedUrls, { url, localSrc, status }]
      }
    }
  }
})

export const { setMediaCache } = mediaSlice.actions

export default mediaSlice.reducer
