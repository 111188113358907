import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ReadingAndConversationIntro from '../ReadingAndConversationIntro/ReadingAndConversationIntro'
import ConversationMain from './ConversationMain'
import Memorize from './Memorize'
import RolePlay from './RolePlay'
import { useDispatch, useSelector } from 'react-redux'
import { clearConversationStates, fetchConversation, setPage } from '../../../redux/features/conversationSlice'
import { isEmpty } from 'lodash'
import SecondaryHeader from '../../../components/SecondaryHeader/SecondaryHeader'
import colorsContext from '../../../contexts/ColorsContext'
import { START_SECTION } from '../../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../../hooks/logHooks/useLog'
import PageLayer from '../../../components/PageLayer/PageLayer'
import Archive from '../../Archive'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useActionRetryerWrapper from '../../../hooks/useDispatchWrapper'
import RetryServiceButton from 'atomicComponents/molecules/RetryServiceButton/RetryServiceButton'

const Conversation = () => {
  require('./conversation.scss')

  const { colors } = useContext(colorsContext)
  const { unit } = useParams()
  const states = useSelector(state => state.conversation)
  const dispatch = useDispatch()
  const { networkLossRetryerDispatch } = useActionRetryerWrapper()
  const { sendServerLog } = useLog()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const archiveIsOpen = searchParams.get('archive')

  const getData = () => {
    networkLossRetryerDispatch(retryId => {
      dispatch(fetchConversation({ unit, retryId }))
    })
  }

  useEffect(() => {
    getData()

    return () => {
      dispatch(clearConversationStates())
    }
  }, [])

  const getDialogBgColor = characterName => {
    const characterIndex = states.data.characters.map(c => c.name).indexOf(characterName)
    switch (states.data.characters.length / (characterIndex + 1)) {
      case states.data.characters.length:
        return colors['C219']
      case states.data.characters.length - 1:
        return colors['C220']
      case states.data.characters.length - 2:
        return colors['C63']
      default:
        return colors['C219']
    }
  }

  const renderPageContent = () => {
    if (states.page === 'conversation')
      return (
        <ConversationMain
          unit={unit}
          nextPage={() => dispatch(setPage('memorize'))}
          getDialogBgColor={getDialogBgColor}
        />
      )
    if (states.page === 'memorize')
      return (
        <Memorize unit={unit} nextPage={() => dispatch(setPage('role-play'))} getDialogBgColor={getDialogBgColor} />
      )
    if (states.page === 'role-play')
      return <RolePlay data={states.data} unit={unit} getDialogBgColor={getDialogBgColor} />
  }

  if (archiveIsOpen) {
    return (
      <PageLayer close={() => navigate(-1)}>
        <Archive />
      </PageLayer>
    )
  }

  if (!states.loading && isEmpty(states.data) && Boolean(states.error)) {
    return <RetryServiceButton onClick={getData} />
  }
  return (
    <>
      {states.page === 'intro' ? (
        <div>
          {!isEmpty(states.data) && (
            <ReadingAndConversationIntro
              helpEnumLink={pageGuidEnums.CONVERSATION_INTRO}
              description={states.data.description}
              title={states.data.title}
              imgSrc={states.data.pictureUri}
              onClickStart={() => {
                sendServerLog(START_SECTION)
                dispatch(setPage('conversation'))
              }}
            />
          )}
        </div>
      ) : (
        <>
          <SecondaryHeader
            background={colors['C563']}
            color={colors['C2']}
            data={[
              {
                name: 'Conversation',
                path: () => dispatch(setPage('conversation')),
                active: states.page === 'conversation'
              },
              {
                name: 'Memorize',
                path: () => dispatch(setPage('memorize')),
                active: states.page === 'memorize'
              },
              {
                name: 'Role-play',
                path: () => dispatch(setPage('role-play')),
                active: states.page === 'role-play'
              }
            ]}
          />
          {renderPageContent()}
        </>
      )}
    </>
  )
}

export default Conversation
