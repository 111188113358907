const DrillingSvg = ({ color }) => {
  return (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={512}
      height={512}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g transform='matrix(1.3333333,0,0,-1.3333333,0,682.66667)'>
        <g>
          <g>
            <g>
              <g transform='translate(336.333,416.6665)'>
                <path d='M12.2-93.5h-192c-9.6,0-19,3.9-25.8,10.6c-6.9,6.9-10.6,16-10.6,25.8l0.2,71.9c0,9.6,3.9,19,10.6,25.7 c6.9,6.9,16,10.6,25.8,10.6H12.2c9.6,0,19-3.9,25.7-10.6c6.9-6.9,10.6-16,10.6-25.8v-71.9c0-9.6-3.9-19-10.6-25.8 C31.1-89.7,22-93.5,12.2-93.5z M-179.6,26.4c-3.1,0-6.1-1.2-8.2-3.4c-2.1-2.1-3.4-5.2-3.4-8.2c0,0,0,0,0,0l-0.2-71.9 c0-3.1,1.2-6,3.4-8.2c2.1-2.1,5.2-3.4,8.2-3.4h192c3.2,0,6,1.2,8.2,3.4c2.1,2.1,3.4,5.2,3.4,8.3v71.9c0,3.1-1.2,6.1-3.4,8.2 c-2.1,2.1-5.2,3.4-8.2,3.4H-179.6z M-203.6,14.8L-203.6,14.8L-203.6,14.8z' />
              </g>
              <g transform='translate(191.7334,288.1333)'>
                <path d='M180.8-60.9H96.9c-4.7,0-9,2.6-11.1,6.8l-48,95.9c-1.9,3.8-1.7,8.4,0.5,12c2.3,3.6,6.2,5.9,10.5,5.9h83.9 c4.7,0,9-2.6,11.1-6.8l48-95.9c1.9-3.8,1.7-8.4-0.5-12C189.1-58.7,185.1-60.9,180.8-60.9z M104.5-36.1h56.2L125.2,35H68.9 L104.5-36.1z' />
              </g>
              <g transform='translate(400.5996,368.4668)'>
                <path d='M-267.8-21.3h-12c-12.8,0-25,5-34.2,14.1c0,0-0.1,0.1-0.1,0.1c-9.1,9.2-14.1,21.3-14.1,34.1c0,12.8,5,25,14.1,34.2 c9,9.2,21.2,14.2,34.3,14.2h12c6.8,0,12.4-5.5,12.4-12.4V-8.9C-255.4-15.7-261-21.3-267.8-21.3z M-296.6,10.4 c4.4-4.3,10.2-6.8,16.4-6.9v47.1c-6.3-0.1-12.1-2.5-16.3-6.8c-4.5-4.5-6.9-10.5-6.9-16.8C-303.4,20.8-301,14.9-296.6,10.4z' />
              </g>
              <g transform='translate(400.5996,368.4668)'>
                <path d='M-315.8,14.6h-72c-6.8,0-12.4,5.5-12.4,12.4s5.5,12.4,12.4,12.4h72c6.8,0,12.4-5.5,12.4-12.4S-309,14.6-315.8,14.6z' />
              </g>
              <g transform='translate(191.7334,95.3335)'>
                <path d='M180.8,59.9H48.9c-6.8,0-12.4,5.5-12.4,12.4v24c0,33.3,27.1,60.4,60.4,60.4h83.9c6.8,0,12.4-5.5,12.4-12.4v-72 C193.2,65.5,187.6,59.9,180.8,59.9z M61.3,84.7h107.1v47.3H96.9c-19.6,0-35.6-16-35.6-35.6V84.7z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DrillingSvg
