export const MONDAY = 'MONDAY'
export const TUESDAY = 'TUESDAY'
export const WEDNESDAY = 'WEDNESDAY'
export const THURSDAY = 'THURSDAY'
export const FRIDAY = 'FRIDAY'
export const SATURDAY = 'SATURDAY'
export const SUNDAY = 'SUNDAY'

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const dayName = num => {
  return days[num]
}

export const getDayByKey = key => {
  switch (key) {
    case MONDAY:
      return {
        key: MONDAY,
        title: 'monday',
        shortTitle: 'Mo'
      }
    case TUESDAY:
      return {
        key: TUESDAY,
        title: 'tuesday',
        shortTitle: 'Tu'
      }
    case WEDNESDAY:
      return {
        key: WEDNESDAY,
        title: 'wednesday',
        shortTitle: 'We'
      }
    case THURSDAY:
      return {
        key: THURSDAY,
        title: 'thursday',
        shortTitle: 'Th'
      }
    case FRIDAY:
      return {
        key: FRIDAY,
        title: 'friday',
        shortTitle: 'Fr'
      }
    case SATURDAY:
      return {
        key: SATURDAY,
        title: 'saturday',
        shortTitle: 'Sa'
      }
    case SUNDAY:
      return {
        key: SUNDAY,
        title: 'sunday',
        shortTitle: 'Su'
      }
  }
}
