import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import staticContentUseCase from '../../useCase/staticContentUseCase/staticContentUseCase'

const initialState = {
  loading: false,
  language: 'English',
  termsAndConditionData: null,
  error: ''
}

export const getTermsAndCondition = createAsyncThunk('staticContent/getTermsAndCondition', ({ language }) => {
  return staticContentUseCase({ language, type: 'TERMS_AND_CONDITIONS' })
})

const staticContentSlice = createSlice({
  name: 'staticContent',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getTermsAndCondition.pending, state => {
      state.loading = true
    })
    builder.addCase(getTermsAndCondition.fulfilled, (state, action) => {
      state.loading = false

      state.termsAndConditionData = action.payload
    })

    builder.addCase(getTermsAndCondition.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { setLanguage } = staticContentSlice.actions

export default staticContentSlice.reducer
