import serviceCall from '../../services'
import filesAndFoldersMapper from '../../services/mapper/notepad/filesAndFoldersMapper'

const addFolderUseCase = async ({ parentFolderId, title, color }) => {
  const result = await serviceCall('notepadAddFolder', { parentFolderId, title, color }, 'cloud')

  return filesAndFoldersMapper(result)
}

export default addFolderUseCase
