import * as React from 'react'

const PhonoTypeSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={47.88} height={46.36} viewBox='0 0 47.88 46.36'>
    <g fill={color}>
      <path d='M2.864 17.139 7.847.322l1.045.31L3.91 17.448z' />
      <path d='M40.91 17.145 45.888.326l1.045.31-4.978 16.818z' />
      <path d='M19.99 16.73a5.518 5.518 0 0 0 1.83-.82 4.318 4.318 0 0 0 1.23-1.55 4.923 4.923 0 0 0 .5-2.19 4.072 4.072 0 0 0-.81-2.53 4.341 4.341 0 0 0-2.34-1.51 4.285 4.285 0 0 0 1.76-1.53 3.758 3.758 0 0 0 .58-2.02 4.122 4.122 0 0 0-.66-2.21A3.829 3.829 0 0 0 20.27.81 7.749 7.749 0 0 0 17.19.3h-6.27v16.72h6.38a11.755 11.755 0 0 0 2.69-.29ZM13.13 2.27h3.34a9.3 9.3 0 0 1 2.54.23 1.97 1.97 0 0 1 1.14.87 2.741 2.741 0 0 1 .4 1.47 2.446 2.446 0 0 1-.42 1.46 2.381 2.381 0 0 1-1.27.84 8.051 8.051 0 0 1-2.11.19h-3.62V2.27Zm-.01 12.78V9.3h3.87a8.771 8.771 0 0 1 2.44.27 2.579 2.579 0 0 1 1.33.97 2.753 2.753 0 0 1 .48 1.63 2.852 2.852 0 0 1-.33 1.41 2.4 2.4 0 0 1-.85.93 3.447 3.447 0 0 1-1.28.45 9.968 9.968 0 0 1-1.5.08h-4.16Z' />
      <path d='M29.42 16.17a7.969 7.969 0 0 0 4.4 1.1 6.935 6.935 0 0 0 4.42-1.43 7.447 7.447 0 0 0 2.51-4.16l-2.21-.56a5.468 5.468 0 0 1-1.77 3.2 4.718 4.718 0 0 1-3.15 1.07 5.47 5.47 0 0 1-2.84-.78 4.606 4.606 0 0 1-1.93-2.38 10.28 10.28 0 0 1-.62-3.72 10 10 0 0 1 .53-3.21 4.849 4.849 0 0 1 1.82-2.48 5.443 5.443 0 0 1 3.22-.92 4.4 4.4 0 0 1 2.78.84 5.151 5.151 0 0 1 1.69 2.65l2.18-.52a6.639 6.639 0 0 0-2.38-3.59A6.807 6.807 0 0 0 33.85 0a8.437 8.437 0 0 0-4.05 1 6.86 6.86 0 0 0-2.84 2.95 9.794 9.794 0 0 0-.99 4.56 11.337 11.337 0 0 0 .89 4.48 6.585 6.585 0 0 0 2.56 3.18Z' />
      <path d='M45.92 19.31H1.97A1.965 1.965 0 0 0 0 21.27v23.12a1.967 1.967 0 0 0 1.97 1.97h43.94a1.967 1.967 0 0 0 1.97-1.97V21.27a1.957 1.957 0 0 0-1.96-1.96Zm0 26.04H1.97a.95.95 0 0 1-.95-.95V21.27a.95.95 0 0 1 .95-.95h43.94a.95.95 0 0 1 .95.95v23.12h.01a.952.952 0 0 1-.95.96Z' />
      <path d='M2.94 22.02h1.52v1.52H2.94z' />
      <path d='M5.84 22.02h1.52v1.52H5.84z' />
      <path d='M8.74 22.02h1.52v1.52H8.74z' />
      <path d='M10.06 34.61h1.52v1.52h-1.52z' />
      <path d='M12.83 34.61h1.52v1.52h-1.52z' />
      <path d='M15.6 34.61h1.52v1.52H15.6z' />
      <path d='M18.36 34.61h1.52v1.52h-1.52z' />
      <path d='M21.12 34.61h1.52v1.52h-1.52z' />
      <path d='M23.88 34.61h1.52v1.52h-1.52z' />
      <path d='M26.65 34.61h1.52v1.52h-1.52z' />
      <path d='M29.42 34.61h1.52v1.52h-1.52z' />
      <path d='M32.18 34.61h1.52v1.52h-1.52z' />
      <path d='M34.95 34.61h1.52v1.52h-1.52z' />
      <path d='M11.65 22.02h1.52v1.52h-1.52z' />
      <path d='M2.94 42.1h1.52v1.52H2.94z' />
      <path d='M5.84 42.1h1.52v1.52H5.84z' />
      <path d='M8.74 42.1h1.52v1.52H8.74z' />
      <path d='M11.65 42.1h1.52v1.52h-1.52z' />
      <path d='M14.21 42.1H32.8v1.52H14.21z' />
      <path d='M33.84 42.1h1.52v1.52h-1.52z' />
      <path d='M36.74 42.1h1.52v1.52h-1.52z' />
      <path d='M39.64 42.1h1.52v1.52h-1.52z' />
      <path d='M42.55 42.1h2.65v1.52h-2.65z' />
      <path d='M36.31 38.37h8.88v1.52h-8.88z' />
      <path d='M3.18 38.37h6.88v1.52H3.18z' />
      <path d='M38.26 34.61h6.94v1.52h-6.94z' />
      <path d='M4.46 36.13h4.28v-1.52H4.46v-3.76H2.94v3.76h-.01v1.52h1.53Z' />
      <path d='M41.73 30.85h3.47v1.52h-3.47z' />
      <path d='M17.25 22.02h1.52v1.52h-1.52z' />
      <path d='M20.15 22.02h1.52v1.52h-1.52z' />
      <path d='M23.05 22.02h1.52v1.52h-1.52z' />
      <path d='M25.96 22.02h1.52v1.52h-1.52z' />
      <path d='M28.99 22.02h1.52v1.52h-1.52z' />
      <path d='M31.89 22.02h1.52v1.52h-1.52z' />
      <path d='M34.79 22.02h1.52v1.52h-1.52z' />
      <path d='M37.7 22.02h1.52v1.52H37.7z' />
      <path d='M5.78 30.85H7.3v1.52H5.78z' />
      <path d='M8.54 30.85h1.52v1.52H8.54z' />
      <path d='M11.31 30.85h1.52v1.52h-1.52z' />
      <path d='M14.08 30.85h1.52v1.52h-1.52z' />
      <path d='M16.83 30.85h1.52v1.52h-1.52z' />
      <path d='M19.6 30.85h1.52v1.52H19.6z' />
      <path d='M22.36 30.85h1.52v1.52h-1.52z' />
      <path d='M25.13 30.85h1.52v1.52h-1.52z' />
      <path d='M27.9 30.85h1.52v1.52H27.9z' />
      <path d='M30.66 30.85h1.52v1.52h-1.52z' />
      <path d='M33.42 30.85h1.52v1.52h-1.52z' />
      <path d='M36.19 30.85h1.52v1.52h-1.52z' />
      <path d='M38.95 30.85h1.52v1.52h-1.52z' />
      <path d='M11.31 38.37h1.52v1.52h-1.52z' />
      <path d='M14.08 38.37h1.52v1.52h-1.52z' />
      <path d='M16.83 38.37h1.52v1.52h-1.52z' />
      <path d='M19.6 38.37h1.52v1.52H19.6z' />
      <path d='M22.36 38.37h1.52v1.52h-1.52z' />
      <path d='M25.13 38.37h1.52v1.52h-1.52z' />
      <path d='M27.9 38.37h1.52v1.52H27.9z' />
      <path d='M30.66 38.37h1.52v1.52h-1.52z' />
      <path d='M33.42 38.37h1.52v1.52h-1.52z' />
      <path d='M17.25 27.1h1.52v1.52h-1.52z' />
      <path d='M20.15 27.1h1.52v1.52h-1.52z' />
      <path d='M23.05 27.1h1.52v1.52h-1.52z' />
      <path d='M25.96 27.1h1.52v1.52h-1.52z' />
      <path d='M28.99 27.1h1.52v1.52h-1.52z' />
      <path d='M2.87 27.1h1.52v1.52H2.87z' />
      <path d='M5.78 27.1H7.3v1.52H5.78z' />
      <path d='M8.68 27.1h1.52v1.52H8.68z' />
      <path d='M11.58 27.1h1.52v1.52h-1.52z' />
      <path d='M14.61 27.1h1.52v1.52h-1.52z' />
      <path d='M31.89 27.1h1.52v1.52h-1.52z' />
      <path d='M34.79 27.1h1.52v1.52h-1.52z' />
      <path d='M37.7 27.1h1.52v1.52H37.7z' />
      <path d='M40.47 27.1h1.52v1.52h-1.52z' />
      <path d='M43.68 27.1h1.52v1.52h-1.52z' />
      <path d='M43.68 22.02h1.52v1.52h-1.52z' />
    </g>
  </svg>
)

export default PhonoTypeSvg
