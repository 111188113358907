export const AnswerStatusEnums = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  CLUE: 'CLUE',
  CORRECT_AND_INCORRECT: 'CORRECT_AND_INCORRECT',
  SKIPPED: 'SKIPPED',
  NONE: 'NONE',
  OVERLOOKED: 'OVERLOOKED',
  SELECTED: 'SELECTED'
}

export const TextAlignmentEnums = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  CENTER: 'CENTER'
}

export const sizeEnums = {
  X_LARGE: 'X_LARGE',
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL'
}

export const RTL = 'RTL'
export const LTR = 'LTR'
export const MOBILE = 'MOBILE'
export const EMAIL = 'EMAIL'
export const NUMBERED = 'NUMBERED'
export const HISTORY = 'HISTORY'
export const SUPER_MEMORY = 'SUPER_MEMORY'
export const REPEAT_THREE_TIMES = 'REPEAT_THREE_TIMES'
export const REPEAT_FIVE_TIMES = 'REPEAT_FIVE_TIMES'
export const REPEAT_SEVEN_TIMES = 'REPEAT_SEVEN_TIMES'
export const REGULAR = 'REGULAR'
export const SEMI_INTENSIVE = 'SEMI_INTENSIVE'
export const INTENSIVE = 'INTENSIVE'
export const MALE = 'MALE'
export const FEMALE = 'FEMALE'
export const VOICE = 'VOICE'
export const TWO_COLUMN = 'TWO_COLUMN'
export const ONE_COLUMN = 'ONE_COLUMN'
export const IMAGE_BUBBLE = 'IMAGE_BUBBLE'
export const TEXT_BOX_AREA = 'TEXT_BOX_AREA'
export const TEXTAREA = 'TEXTAREA'
export const INLINE = 'INLINE'
export const COMBOBOX = 'COMBOBOX'
export const INPUT = 'INPUT'
export const DROPBOX = 'DROPBOX'
export const ODD = 'ODD'
export const EVEN = 'EVEN'

export const VOICE_URI = 'VOICE_URI'
export const TEXT_TO_SPEECH = 'TEXT_TO_SPEECH'

export const VOCABULARY = 'VOCABULARY'
export const SENTENCE = 'SENTENCE'
export const ALL = 'ALL'

export const ASC = 'ASC'
export const DESC = 'DESC'

// export const stripePublishKey = 'pk_test_51Mb3flJRiH2hBfJVpNGX0h39YQdQtI69oLtI3ZiB00zL1jgd5W0zYCU61wX3owp4kfNTARqJAPW0Er8IGGUFHmGC00RUfEgKSz'
export const stripePublishKey =
  'pk_live_51Mb3flJRiH2hBfJVedSfDOiOKO4O1GQOpbFo5KP1eGKfBNJbTc8BJhFxhLClANlLKl3KeJOpr4x1GDi3tGFm3hGj00REbsj8ay'

export const EMOJI = 'EMOJI'
export const TEXTS = 'TEXTS'

export const LINK = 'LINK'
export const BUTTON = 'BUTTON'

export const globalModalEnums = {
  purchaseRequest: 'PURCHASE_REQUEST',
  addToHomeScreenGuide: 'HOME_SCREEN_GUIDE'
}
