export const MASTER = 'MASTER'
export const EXCELLENT = 'EXCELLENT'
export const GOOD = 'GOOD'
export const NOT_GOOD = 'NOT_GOOD'

export const getGameRankDataByKey = key => {
  switch (key) {
    case MASTER:
      return {
        rankTitle: 'Master',
        imageKey: 'master-circle.png',
        stars: 4,
        color: '#39a935',
        message: 'Perfect!'
      }
    case EXCELLENT:
      return {
        rankTitle: 'Excellent',
        imageKey: 'excellent-circle.png',
        stars: 3,
        color: '#005f88',
        message: 'Way to Go!'
      }
    case GOOD:
      return {
        rankTitle: 'Good',
        imageKey: 'good-circle.png',
        stars: 2,
        color: '#662482',
        message: 'Great Effort!'
      }
    case NOT_GOOD:
      return {
        rankTitle: 'Not Good',
        imageKey: 'notgood-circle.png',
        stars: 1,
        color: '#e20613',
        message: 'Keep Trying!'
      }
    default:
      return {
        rankTitle: 'yet to play',
        imageKey: 'default-circle.png',
        stars: 0,
        color: 'gray',
        message: ''
      }
  }
}
