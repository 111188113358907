export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export function emptyCache() {
  if ('caches' in window) {
    caches.keys().then(names => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name)
      })
    })

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true)
  }
}

export function isObject(obj) {
  return obj && typeof obj === 'object'
}

export function isArray(obj) {
  return isObject(obj) && obj instanceof Array
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

// Page Loader
const loader = document.querySelector('.loader')

// if you want to show the loader when React loads data again
export const showLoader = text => {
  if (text && typeof text === 'string') {
    document.getElementById('loading-title').innerHTML = `${text}`
  }
  loader.classList.remove('loader--hide')
}

export const hideLoader = () => {
  const el = document.getElementById('loading-title')
  el.innerHTML = ''
  loader.classList.add('loader--hide')
}

// Get filename extension
export const getFileExtension = filename => {
  return filename.split('.').pop()
}

// Get random number with min and max config
export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

//To clear all data from localStorage and redirect the user to the login page
export const afterLogoutUser = () => {
  localStorage.clear()
  window.location.href = '/auth/login'
}
