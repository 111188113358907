import React, { useContext } from 'react'
import styles from './PrimaryButton.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import colorsContext from '../../contexts/ColorsContext'

const PrimaryButton = (
  {
    onClick,
    children,
    disabled,
    type,
    linkTo,
    linkState,
    isWhite,
    className,
    color,
    backgroundColor,
    borderColor,
    style,
    dir = 'auto',
    loading,
    ...otherProps
  },
  ref
) => {
  const { colors } = useContext(colorsContext)

  const btnStyle = {
    background: backgroundColor ?? (isWhite ? colors['C160'] : colors['C11']),
    borderColor: borderColor ?? colors['C160'],
    color: color ?? (isWhite ? '#000' : colors['C179']),
    opacity: loading ? 0.5 : 1,
    ...style
  }

  if (linkTo) {
    return (
      <Link
        className={classNames(styles.buttonPrimary, { [styles.buttonDisabled]: disabled }, className)}
        to={linkTo}
        state={linkState}
        disabled={loading || disabled}
        style={btnStyle}
        dir={dir}
        {...otherProps}
      >
        {children}
        <span
          className={classNames(styles.spinner, { [styles.startSpin]: loading })}
          style={{ '--spinner-color': disabled ? '#C4C9CE' : color ?? (isWhite ? '#000' : colors['C179']) }}
        />
      </Link>
    )
  }
  return (
    <button
      className={classNames(styles.buttonPrimary, { [styles.buttonDisabled]: disabled }, className)}
      onClick={!disabled && !loading ? onClick : null}
      disabled={loading || disabled}
      type={type}
      style={btnStyle}
      dir={dir}
      {...otherProps}
    >
      {children}
      <span
        className={classNames(styles.spinner, { [styles.startSpin]: loading })}
        style={{ '--spinner-color': disabled ? '#C4C9CE' : color ?? (isWhite ? '#000' : colors['C179']) }}
      />
    </button>
  )
}

export default PrimaryButton
