import { TextAlignmentEnums } from '../../../enums/globalEnums/globalEnums'

const fileMapper = data => {
  const result = {
    id: data?.id || '',
    userId: data?.userId || 0,
    title: data?.title || '',
    color: data?.color || '',
    parentFolderId: data?.parentFolderId || '',
    text: data?.text || '',
    date: data?.createdDate || '2000-01-01',
    textConfig: {
      backgroundColor: data?.textConfig?.backgroundColor || '',
      fontColor: data?.textConfig?.fontColor || '',
      notepadTextDirection: data?.textConfig?.notepadTextDirection || 'LTR',
      textConfigFontSize: data?.textConfig?.textConfigFontSize || 'LARGER',
      textConfigTextAlign: data?.textConfig?.textConfigTextAlign || TextAlignmentEnums.LEFT
    },
    path: data?.path || []
  }

  result.path.splice(0, 1)

  return result
}

export default fileMapper
