import { initializeApp } from 'firebase/app'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

// Request permission to generate a token
const requestPermission = async () => {
  return await Notification.requestPermission()
}

// Get Token From Cloud Messaging
export const getFirebaseMessagingToken = async setTokenFound => {
  return requestPermission().then(permission => {
    if (permission === 'granted') {
      return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
        .then(currentToken => {
          if (currentToken) {
            setTokenFound(true)
            return currentToken
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            setTokenFound(false)
            return null
            // shows on the UI that permission is required
          }
        })
        .catch(err => {
          // catch error while creating client token
        })
    } else {
      // console.log("User Notification Permission Denied.");
    }
  })
}

onMessage(messaging, payload => {
  console.log('Message received. ', payload)
  // ...
})
