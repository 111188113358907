import React, { useContext } from 'react'
import colorsContext from '../../contexts/ColorsContext'

const HeaderWizard = ({ currentLevel, currentLevelName, levels, currentStep, steps, headerStyle }) => {
  const { colors } = useContext(colorsContext)

  const renderItems = () => {
    const items = []
    for (let i = 1; i <= levels; i++) {
      let x = currentLevel - i
      if (x < 4 && x > 0) {
        items.push(
          <span
            key={x}
            className={`header-wizard-circle header-wizard-circle-before ${x === 3 ? 'd- d-none d-md-flex' : ''}`}
            style={{ borderColor: colors['C69'], background: colors['C160'], color: colors['C69'] }}
          >
            {i}
          </span>
        )
      }
      if (x === 0) {
        items.push(
          <span key={x} className='header-wizard-current' style={{ backgroundColor: colors['C110'] }}>
            {currentLevelName}
            {steps ? (
              <span className='header-wizard-current-steps' style={{ background: colors['C110'] }}>
                {currentStep + '/' + steps}
              </span>
            ) : null}
          </span>
        )
      }
      if (x < 0 && x > -4) {
        items.push(
          <span
            key={x}
            className={`header-wizard-circle header-wizard-circle-after ${x === -3 ? 'd-none d-md-flex' : ''}`}
          >
            {i}
          </span>
        )
      }
    }
    return items
  }
  return (
    <div className={`header-wizard header-wizard-${headerStyle}`}>
      <div className='header-wizard-inner'>{renderItems()}</div>
    </div>
  )
}

export default HeaderWizard
