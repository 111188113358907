export const BLINK = 'BLINK'
export const MATCH_UP = 'MATCH_UP'
export const LIMBIC_POWER = 'LIMBIC_POWER'
export const PHONO_METRIX = 'PHONO_METRIX'
export const PHONO_TYPE = 'PHONO_TYPE'
export const DUAL_ONLINE = 'DUAL_ONLINE'
export const DUAL_OFFLINE = 'DUAL_OFFLINE'

export const offlineGamesArrayList = [
  {
    gameType: BLINK,
    gameTitle: 'Blink',
    iconName: 'blink_icon.png'
  },
  {
    gameType: MATCH_UP,
    gameTitle: 'Match-up',
    iconName: 'match_up_icon.png'
  },
  {
    gameType: LIMBIC_POWER,
    gameTitle: 'Limbic Power',
    iconName: 'limbic_power_icon.png'
  },
  {
    gameType: PHONO_METRIX,
    gameTitle: 'PhonoMetrix',
    iconName: 'phono_metrix_icon.png'
  },
  {
    gameType: PHONO_TYPE,
    gameTitle: 'PhonoType',
    iconName: 'phono_type_icon.png'
  }
]

export const getGameDataByType = type => {
  switch (type) {
    case BLINK:
      return {
        gameTitle: 'Blink',
        routeName: 'blink',
        iconName: 'blink_icon.png'
      }
    case MATCH_UP:
      return {
        gameTitle: 'Match-up',
        routeName: 'match-up',
        iconName: 'match_up_icon.png'
      }
    case LIMBIC_POWER:
      return {
        gameTitle: 'Limbic Power',
        routeName: 'limbic-power',
        iconName: 'limbic_power_icon.png'
      }
    case PHONO_METRIX:
      return {
        gameTitle: 'PhonoMetrix',
        routeName: 'phonometrix',
        iconName: 'phono_metrix_icon.png'
      }
    case PHONO_TYPE:
      return {
        gameTitle: 'PhonoType',
        routeName: 'phonotype',
        iconName: 'phono_type_icon.png'
      }
    case DUAL_ONLINE:
      return {
        gameTitle: 'Vellso Dual-Online',
        routeName: 'vellso-dual-online',
        iconName: 'vellso_dual_icon.png'
      }
    case DUAL_OFFLINE:
      return {
        gameTitle: 'Vellso Dual-Offline',
        routeName: 'vellso-dual-offline',
        iconName: 'vellso_dual_icon.png'
      }
  }
}
