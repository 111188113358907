import removeWrapperTag from '../../../operations/removeWrapperTag'

const reviewMistakeDragAndDropConversationMapper = (data, filterByIds = []) => {
  return {
    ...data,
    exerciseStacks: data.exerciseStacks?.map(stack => {
      return {
        ...stack,
        items: stack.items.map(item => {
          const keepAnswerField = filterByIds.includes(item.id)
          return {
            ...item,
            data: {
              answers: keepAnswerField ? item.data.answers : [],
              givens: keepAnswerField
                ? item.data.givens
                : item.data.givens.map((given, givenIndex) => {
                    const fullTextAnswer = item.extra.fullTextAnswers[givenIndex]?.sentence
                    return {
                      ...given,
                      text: {
                        ...given.text,
                        sentence: fullTextAnswer ? removeWrapperTag(fullTextAnswer, 's') : given.text.sentence
                      }
                    }
                  }),
              choices: item.data.choices
            }
          }
        })
      }
    })
  }
}

export default reviewMistakeDragAndDropConversationMapper
