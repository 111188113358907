const LogoutSvg = ({ color }) => {
  return (
    <svg
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={512}
      height={512}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <g>
            <g>
              <g transform='matrix(1.33333 0 0 -1.33333 0 682.667)'>
                <path d='M211.3,128.7H61.9c-33.3,0-60.4,27.1-60.4,60.4v261.8c0,33.3,27.1,60.3,60.4,60.3h149.5c33.2,0,60.3-27,60.3-60.3 l0.8-75.1c0.1-8.7-6.9-15.8-15.6-15.9c-0.1,0-0.1,0-0.2,0c-8.6,0-15.7,6.9-15.7,15.6l-0.8,75.2c0,0.1,0,0.1,0,0.2 c0,15.9-12.9,28.8-28.8,28.8H61.9C46,479.7,33,466.8,33,450.9V189.1c0-15.9,12.9-28.9,28.9-28.9h149.4 c15.9,0,28.9,12.9,28.9,28.9c0,0.1,0,0.1,0,0.2l0.8,72.2c0.1,8.7,7.2,15.7,15.9,15.6c8.7-0.1,15.7-7.2,15.6-15.9l-0.8-72.1 C271.6,155.8,244.5,128.7,211.3,128.7z' />
                <path d='M364.6,303.6H163c-8.7,0-15.8,7.1-15.8,15.8c0,8.7,7.1,15.8,15.8,15.8h201.6c8.7,0,15.8-7.1,15.8-15.8 C380.3,310.6,373.3,303.6,364.6,303.6z' />
                <path d='M328.4,254.4c-4,0-8.1,1.5-11.1,4.6c-6.2,6.2-6.2,16.1,0,22.3l33.3,33.3c2.5,2.5,2.5,6.7,0,9.2l-33.3,33.3 c-6.2,6.2-6.2,16.1,0,22.3c6.2,6.2,16.1,6.2,22.3,0l33.3-33.3c14.8-14.8,14.8-38.9,0-53.8l-33.3-33.3 C336.5,256,332.4,254.4,328.4,254.4z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoutSvg
