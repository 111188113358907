import serviceCall from '../../services'
import UnitDataStorage from '../../classes/UnitDataStorage'
import { studyTypeEnum } from '../../enums/userStudyEnums/userStudyEnums'
import cleanSentenceForCheck from '../../operations/cleanSentenceForCheck'
import exerciseCheckFactory from '../../factory/exerciseCheckFactory'

const checkExerciseUseCase = async ({ data, isVoiceRecognition }) => {
  const unitDataStorage = new UnitDataStorage()
  const unitData = unitDataStorage.getUnitData()

  data = {
    ...data,
    userAnswerItems: data?.userAnswerItems?.map(answerItem => {
      return {
        ...answerItem,
        userAnswers: answerItem.userAnswers?.map(it => {
          return {
            ...it,
            text: it.text ? cleanSentenceForCheck(it.text) : it.text
          }
        })
      }
    })
  }

  let firstResponse = await serviceCall(
    isVoiceRecognition ? 'exerciseMatchV2' : 'exerciseMatch',
    { data, studyType: unitData.studyStatus || studyTypeEnum.NEW, isVoiceRecognition },
    'cloud'
  )

  if (isVoiceRecognition) {
    const checkResult = exerciseCheckFactory(firstResponse?.data)
    firstResponse.data = checkResult
    await serviceCall(
      'saveExerciseCheckResult',
      {
        data: { ...firstResponse.data, stackId: firstResponse.id },
        studyType: unitData.studyStatus || studyTypeEnum.NEW,
        isVoiceRecognition
      },
      'cloud'
    )
  }
  return firstResponse
}

export default checkExerciseUseCase
