import { WORD } from '../../../enums/toolsEnums/spellingEnums'

const wordDataMapper = (data, phrase) => {
  const obj = data?.[0]
  return {
    type: WORD,
    id: obj?.id ?? null,
    title: phrase,
    givenTitle: obj?.title,
    unitId: obj?.unitId ?? null,
    translation: obj?.translation ?? null,
    accentAndAudios: obj?.accentAndAudios ?? [],
    partOfSpeeches: obj?.partOfSpeeches ?? [],
    syllable: obj?.syllable ?? null,
    phonetic: obj?.phonetic ?? null,
    favoriteId: obj?.favoriteId,
    memoryBoxId: obj?.memoryBoxId
  }
}
export default wordDataMapper
