import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getVocabularyTableTypeUseCase from '../../../useCase/VocabularyUseCase/getVocabularyTableTypeUseCase'
import getPhraseTableTypeUseCase from '../../../useCase/VocabularyUseCase/getPhraseTableTypeUseCase'
import getVocabulariesSummariesUseCase from '../../../useCase/VocabularyUseCase/getVocabulariesSummariesUseCase'

const initialState = {
  loading: false,
  getVocabulariesSummariesLoading: false,
  vocabulariesData: null,
  phrasesData: null,
  summaries: null,
  searchTerm: '',
  sortBy: 'title',
  append: false,
  sortType: 'ASC',
  pageNumber: 0,
  totalPages: 0,
  pageSize: 20,
  error: ''
}

export const getVocabulariesTableType = createAsyncThunk(
  'vocabulary/getVocabularies',
  ({ unitId, searchTerm, paged, sortBy, sortType, page, size, isFullTable }, { getState }) => {
    const { vocabulary } = getState()
    return getVocabularyTableTypeUseCase({
      unitId,
      searchTerm,
      sortBy,
      sortType,
      paged,
      page,
      size,
      isFullTable,
      baseData: vocabulary.append && vocabulary.vocabulariesData ? vocabulary.vocabulariesData : null
    })
  }
)

export const getVocabulariesSummaries = createAsyncThunk('vocabulary/getVocabulariesSummaries', () => {
  return getVocabulariesSummariesUseCase()
})

export const getPhrasesTableType = createAsyncThunk(
  'vocabulary/getPhrasesTableType',
  ({ unitId, searchTerm, sortBy, sortType, paged, page, size, isFullTable }, { getState }) => {
    const { vocabulary } = getState()
    return getPhraseTableTypeUseCase({
      unitId,
      searchTerm,
      sortBy,
      sortType,
      paged,
      page,
      size,
      baseData: vocabulary.append && vocabulary.phrasesData ? vocabulary.phrasesData : null
    })
  }
)

const vocabularySlice = createSlice({
  name: 'vocabularySlice',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
      state.pageNumber = 0
    },
    setSortType: (state, action) => {
      state.sortType = action.payload
      state.pageNumber = 0
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload
      state.pageNumber = 0
    },
    loadPage: state => {
      if (!state.loading && state.pageNumber + 1 < state.totalPages) {
        state.pageNumber++
        state.append = true
      }
    },
    clearVocabularyStates: state => {
      state.loading = false
      state.vocabulariesData = null
      state.phrasesData = null
      state.summaries = null
      state.searchTerm = ''
      state.sortBy = 'title'
      state.append = false
      state.sortType = 'ASC'
      state.pageNumber = 0
      state.totalPages = 0
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(getVocabulariesTableType.pending, state => {
      state.loading = true
    })
    builder.addCase(getVocabulariesTableType.fulfilled, (state, action) => {
      state.append = false
      state.loading = false
      state.vocabulariesData = action.payload?.content
      state.totalPages = action.payload?.totalPages
    })
    builder.addCase(getVocabulariesTableType.rejected, (state, action) => {
      state.append = false
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getPhrasesTableType.pending, state => {
      state.loading = true
    })
    builder.addCase(getPhrasesTableType.fulfilled, (state, action) => {
      state.append = false
      state.loading = false
      state.phrasesData = action.payload?.content
      state.totalPages = action.payload?.totalPages
    })
    builder.addCase(getPhrasesTableType.rejected, (state, action) => {
      state.append = false
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(getVocabulariesSummaries.pending, state => {
      state.getVocabulariesSummariesLoading = true
    })
    builder.addCase(getVocabulariesSummaries.fulfilled, (state, action) => {
      state.getVocabulariesSummariesLoading = false
      state.summaries = action.payload
    })
    builder.addCase(getVocabulariesSummaries.rejected, (state, action) => {
      state.getVocabulariesSummariesLoading = false
      state.error = action.error.message
    })
  }
})

export const { setSearchTerm, setSortType, setSortBy, loadPage, clearVocabularyStates } = vocabularySlice.actions

export default vocabularySlice.reducer
