import Modal from '../../Modal'
import React, { useContext } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import componentStyle from './PurchaseRequestModal.module.scss'
import SolidLockSvg from '../../../icons/SolidLockSvg'
import classNames from 'classnames'
import BuyButton from '../../BuyButton/BuyButton'
import TranslatedText from '../../TranslatedText/TranslatedText'
import useTranslatedText from '../../../hooks/useTranslatedText'
import PrimaryButton from '../../PrimaryButton/PrimaryButton'
import { useNavigate } from 'react-router-dom'

const PurchaseRequestModal = ({ closeModal }) => {
  const { dir } = useTranslatedText() // rtl, ltr
  const { colors } = useContext(colorsContext)
  const navigate = useNavigate()

  return (
    <Modal closeModal={closeModal} isCenter>
      <div className={classNames(componentStyle.modalContainer, 'modal-type-1')} style={{ background: colors['C73'] }}>
        <div className={componentStyle.lockIcon}>
          <img src={`${window.location.origin}/assets/gif/purchaseGif.gif`} />
        </div>
        <div className={componentStyle.textAndButton}>
          <p dir={dir} style={{ color: colors['C589'] }}>
            <TranslatedText translationKey='purchase.modal.description' />
          </p>
          <PrimaryButton
            onClick={() => {
              navigate('/payment')
              closeModal()
            }}
          >
            <TranslatedText translationKey={'subscribe'} />
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default PurchaseRequestModal
