import React, { useContext } from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import HtmlTextWrapper from '../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import PutInOrderInputArea from './PutInOrderInputArea'
import InlineInputField from '../../../../components/InlineInputField/InlineInputField'
import { moreOptionsList } from '../../../../data'
import { AnswerStatusEnums } from '../../../../enums/globalEnums/globalEnums'

const PutInOrderGivenInputTypeSeparated = ({ exerciseItem, stackIndex, itemIndex, enabled }) => {
  const { colors } = useContext(colorsContext)

  if (
    exerciseItem.checked &&
    (exerciseItem.userAnswerStatus !== AnswerStatusEnums.INCORRECT || exerciseItem.unlocked) &&
    exerciseItem.fullTextAnswers.length > 0
  ) {
    return (
      <div className='exercise-paragraph'>
        {exerciseItem.given?.text?.sentence && (
          <HtmlTextWrapper
            textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
            optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
            data={exerciseItem.given.text}
            showPhraseOptions
          />
        )}
        <HtmlTextWrapper
          data={{ ...exerciseItem.fullTextAnswers[0], sentence: '' }}
          style={{ display: 'inline' }}
          optionsColor={colors['C233']}
          showPhraseOptions
          moreOptionsList={moreOptionsList}
        />
        <InlineInputField
          value={
            <HtmlTextWrapper
              data={{ ...exerciseItem.fullTextAnswers[0] }}
              style={{ display: 'inline' }}
              textColor={exerciseItem.userAnswerStatus === AnswerStatusEnums.CORRECT ? colors['C30'] : colors['C233']}
            />
          }
          inputColorCode={'MC3'}
        />
      </div>
    )
  }

  return (
    <>
      <div className='exercise-paragraph'>
        {exerciseItem.given?.text?.sentence && (
          <HtmlTextWrapper
            textColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
            optionsColor={exerciseItem.checked ? colors['C233'] : colors['C23']}
            data={exerciseItem.given.text}
            showPhraseOptions
          />
        )}
        {exerciseItem.checked && exerciseItem.fullTextAnswers?.length > 0 && (
          <HtmlTextWrapper
            data={{ ...exerciseItem.fullTextAnswers[0], sentence: '' }}
            style={{ display: 'inline' }}
            optionsColor={colors['C233']}
            showPhraseOptions
            phraseOptionsDisabled
          />
        )}
        <PutInOrderInputArea
          exerciseItem={exerciseItem}
          stackIndex={stackIndex}
          itemIndex={itemIndex}
          enabled={enabled}
        />
        <span style={{ color: exerciseItem.checked ? colors['C233'] : colors['C23'] }}>{exerciseItem.endMark}</span>
      </div>
    </>
  )
}

export default PutInOrderGivenInputTypeSeparated
