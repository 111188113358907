import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import tedVideoUseCase from '../../useCase/tedVideoUseCase/tedVideoUseCase'

const initialState = {
  tedVideos: [],
  selectedVideo: {},
  error: null,
  loading: false
}

export const getTedVideos = createAsyncThunk('tedVideo/getTedVideos', () => {
  return tedVideoUseCase()
})

const tedVideoSlice = createSlice({
  name: 'tedVideo',
  initialState,
  reducers: {
    setSelectedVideo: (state, action) => {
      state.selectedVideo = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTedVideos.pending, state => {
        state.loading = true
      })
      .addCase(getTedVideos.fulfilled, (state, action) => {
        state.loading = false
        state.tedVideos = action.payload
      })
      .addCase(getTedVideos.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const { setSelectedVideo } = tedVideoSlice.actions
export default tedVideoSlice.reducer
