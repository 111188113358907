import { min } from 'lodash'
import structureBaseTitleMapper from './structureBaseTitleMapper'

const structureTextTemplateMapper = ({ item, events }) => {
  return {
    ...item,
    data: {
      cards: item.data.cards.map(card => {
        return {
          ...card,
          texts: card.texts.map(text => {
            return {
              ...text,
              text: structureBaseTitleMapper({ title: text?.text, events })
            }
          }),
          hiddenTime:
            min(
              events?.view
                ?.filter(it => it?.templateId === item?.id && it?.cardId === card?.id)
                .map(it => parseInt(it?.startTime) || 0)
            ) || 0
        }
      })
    },
    hiddenTime:
      min(events?.view?.filter(it => it?.templateId === item?.id).map(it => parseInt(it?.startTime) || 0)) || 0
  }
}

export default structureTextTemplateMapper
