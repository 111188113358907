import serviceCall from '../../services'
import unitsMapper from '../../services/mapper/levelAndUnit/unitsMapper'

const unitsUseCase = async ({ level }) => {
  const response = await serviceCall('fetchUnits', { level: level.level }, 'cloud')

  return {
    level: level.level,
    units: unitsMapper(response, level)
  }
}

export default unitsUseCase
