import * as React from 'react'

const WordMarkLogoSvg = ({ primaryColor, secondaryColor, oInnerColor }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 165.2 36.9'
    width={165.2}
    height={36.9}
    xmlSpace='preserve'
  >
    <g>
      <path fill={primaryColor} d='M86.8,15.3' />
      <rect fill={secondaryColor} x={62.7} y={0.2} width={9.4} height={36.7} />
      <rect fill={secondaryColor} x={77.4} y={0.2} width={9.4} height={36.7} />
      <path
        fill={primaryColor}
        d='M146.8,0.3c-10.1,0-18.4,8.2-18.4,18.4S136.7,37,146.8,37s18.4-8.2,18.4-18.4C165.2,8.5,157,0.3,146.8,0.3z  M146.8,27.6c-4.9,0-9-4-9-9s4-9,9-9s9,4,9,9C155.8,23.6,151.8,27.6,146.8,27.6z'
      />
      <path
        fill={primaryColor}
        d='M27.3,18.5c-0.1-7.2,4.1-18.2,4.1-18.2h-8.2c0,0-6.9,9-5.4,21.7l-8-21.9L0,0l13.5,36.8h9.8h0h8.2 C31.5,36.8,27.4,25.6,27.3,18.5z'
      />
      <path
        fill={primaryColor}
        d='M57.4,9.7V0.3H46.5h-5.8h-2.4c0,0-12.7,16.6,0,36.6h2.4h5.8h10.9v-9.4H43.7c-0.3-1.4-0.6-2.8-0.9-4.1h14.6 v-9.4H42.7c0.2-1.4,0.5-2.8,0.8-4.1L57.4,9.7L57.4,9.7z'
      />
      <path
        fill={secondaryColor}
        d='M146.8,13.4c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2S149.7,13.4,146.8,13.4z M146.8,20.6c-1.1,0-2-0.9-2-2 c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C148.9,19.7,147.9,20.6,146.8,20.6z'
      />
      <path
        fill={primaryColor}
        d='M123.5,35.1L123.5,35.1c0.7-2.2,0.9-4.5,0.8-6.8c-0.1-2.3-0.8-4.4-2.1-6.4c-1.6-2.5-3.8-4.3-6.4-5.8 c-1.7-0.9-3.4-1.8-5.1-2.5c-1.4-0.6-2.8-1.1-4.2-1.8c-1.3-0.7-2.6-1.5-3.6-2.6c-1.8-2-2.5-4.3-2-6.9c0.2-0.7,0.4-1.4,0.7-2.1H97 c-1.2,0-2.4,0-3.6,0c0,0,0,0-0.1,0H93l-0.2,0.7l0,0c-0.1,0.1-0.1,0.3-0.2,0.4c-0.8,2.3-1,4.6-0.9,7c0.2,3.5,1.6,6.5,4.2,8.8 c1.6,1.4,3.4,2.6,5.3,3.5c1.7,0.8,3.4,1.6,5.1,2.3c1.9,0.9,3.7,1.8,5.4,3c0.9,0.7,1.7,1.4,2.4,2.3c0.9,1.2,1.3,2.5,1.2,3.9 c-0.1,1.4-0.4,2.6-1,3.9l-0.5,0.8h9l0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.6L123.5,35.1z'
      />
    </g>
    <circle fill={oInnerColor} cx={146.8} cy={18.6} r={2} />
  </svg>
)

export default WordMarkLogoSvg
