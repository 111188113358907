import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import publicAxiosInstance from '../../apis/publicAxiosInstance'
import serviceCall from '../../services'

const initialState = {
  loading: false,
  testNumber: null,
  questions: [],
  userAnswers: {},
  result: null,
  error: ''
}

export const getPlacementTest = createAsyncThunk('placementTest/fetchPlacementTest', () => {
  return serviceCall('fetchPlacementTypeTest', null, 'cloud')
})

export const submitPlacementTestExam = createAsyncThunk(
  'placementTest/submitPlacementTestExam',
  (arg, { getState }) => {
    const { placementTest } = getState()

    const userAnswers = []
    placementTest.questions.map(q => {
      return userAnswers.push({
        answerNumber: placementTest.userAnswers[q.questionNumber],
        correctAnswer: q.correctAnswer,
        questionLevel: q.questionLevel,
        questionNumber: q.questionNumber
      })
    })

    return serviceCall(
      'postPlacementTypeTest',
      {
        testNumber: placementTest.testNumber,
        testType: 'PLACEMENT_TYPE',
        placementUserAnswerDTOS: userAnswers
      },
      'cloud'
    )
  }
)

const placementTestSlice = createSlice({
  name: 'placementTest',
  initialState,
  reducers: {
    onSelectOption: (state, action) => {
      let userAnswers = Object.assign({}, state.userAnswers)
      userAnswers[action.payload.questionNumber] = action.payload.answerNumber
      state.userAnswers = userAnswers
    },
    resetExam: state => {
      const userAnswers = state.userAnswers
      Object.keys(userAnswers).forEach(function (key) {
        userAnswers[key] = 0
      })
      state.userAnswers = userAnswers
    },
    setResult: (state, action) => {
      state.result = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getPlacementTest.pending, state => {
      state.loading = true
    })
    builder.addCase(getPlacementTest.fulfilled, (state, action) => {
      state.loading = false
      state.questions = action.payload.questions
      state.testNumber = action.payload.testNumber
      state.userAnswers = action.payload.userAnswers
    })
    builder.addCase(getPlacementTest.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(submitPlacementTestExam.pending, state => {
      state.loading = true
    })
    builder.addCase(submitPlacementTestExam.fulfilled, (state, action) => {
      state.loading = false
      state.result = action.payload
    })
    builder.addCase(submitPlacementTestExam.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { onSelectOption, resetExam, setResult } = placementTestSlice.actions

export default placementTestSlice.reducer
