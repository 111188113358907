import { createSlice } from '@reduxjs/toolkit'
import homeStatisticsUseCase from '../../useCase/homepageServices/homeStatisticsUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  todayStudyLoading: false,
  statisticLoading: false,
  todayStudyData: null,
  statisticsData: null,
  error: ''
}

export const getHomeStatistics = baseAsyncThunk('homepage/getHomeStatistics', (arg, thunkAPI) => {
  const { appUser } = thunkAPI.getState()
  return homeStatisticsUseCase({ hasPlan: appUser?.appUserData?.access.hasPlan })
})

const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    /*
     * Statistics
     */
    builder.addCase(getHomeStatistics.pending, state => {
      state.statisticLoading = true
    })
    builder.addCase(getHomeStatistics.fulfilled, (state, action) => {
      state.statisticLoading = false
      state.statisticsData = action.payload
    })

    builder.addCase(getHomeStatistics.rejected, (state, action) => {
      state.statisticLoading = false
      state.error = action.error.message
    })
  }
})

export const {} = homepageSlice.actions

export default homepageSlice.reducer
