import styles from './CourseIntensityItemIntro.module.scss'
import useTranslatedText from '../../../hooks/useTranslatedText'
import { languagesEnums } from '../../../enums/languageEnums/languageEnums'
import { useSelector } from 'react-redux'

const CourseIntensityItemIntro = ({ firstLineText, secondLineText, typeOfCourse, background, onClick, isSelected }) => {
  const { dir } = useTranslatedText()
  const { selectedTranslationLanguage } = useSelector(state => state.translation)
  const direction = selectedTranslationLanguage?.key === languagesEnums.ENGLISH_LANGUAGE

  return (
    <>
      <button
        onClick={onClick}
        dir={dir}
        className={styles.card}
        style={{ background: isSelected ? `linear-gradient(to right, ${background}, white)` : 'transparent' }}
      >
        <div className={styles.borderLayout} />
        <div className={styles.circle} style={{ background }}>
          <span>{typeOfCourse}</span>
        </div>
        <div dir={dir} className={styles.text} style={{ fontSize: direction ? '22px' : '24px' }}>
          <span>{firstLineText}</span>
          <span>{secondLineText}</span>
        </div>
      </button>
    </>
  )
}
export default CourseIntensityItemIntro
