import React, { useEffect, useState, useRef, useContext } from 'react'
import ChatBox from '../../../components/ChatBox/ChatBox'
import { Link, useNavigate } from 'react-router-dom'
import HelpButton from '../../../components/HelpButton/HelpButton'
import makeUrl from '../../../operations/makeUrl'
import { useDispatch, useSelector } from 'react-redux'
import PhrasesOptions from '../../../components/PhrasesOptions/PhrasesOptions'
import TitleBoxSvg from '../../../icons/TitleBoxSvg'
import {
  postArchiveRolePlay,
  resetRolePlay,
  setRolePlayActiveDialog,
  setRolePlayCharacterSelected,
  setRolePlayFinalAudio,
  setRolePlayFinished,
  setRolePlayOnDialog,
  setRolePlaySelectedCharacter,
  setRolePlayShowText
} from '../../../redux/features/conversationSlice'
import HeadphoneSvg from '../../../icons/HeadphoneSvg'
import RecordingSvg from '../../../icons/RecordingSvg'
import OverlayScrollbar from '../../../layouts/OverlayScrollbar'
import Image from '../../../components/Image'
import useAudioRecorder from '../../../hooks/useAudioRecorder'
import { gsap } from 'gsap'
import AudioPlayer from '../../../components/AudioPlayer/AudioPlayer'
import moment from 'moment'
import getBinaryData from '../../../operations/getBinaryData'
import ConversationCharacterSelect from '../../../layouts/ConversationCharacterSelect'
import { moreOptionsList } from '../../../data'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import offsetMiddleParent from '../../../operations/offsetMiddleParent'
import getCachedMediaUrl from '../../../operations/getCachedMediaUrl'
import colorsContext from '../../../contexts/ColorsContext'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import htmlToText from '../../../operations/htmlToText'
import { v4 } from 'uuid'
import { hideLoader, showLoader } from '../../../helper'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import Switch from '../../../atomicComponents/molecules/Switch/Switch'
import useSectionEvents from '../../../hooks/sectionHooks/useSectionEvents'
import SmallButton from '../../../components/SmallButton/SmallButton'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const RolePlay = ({ data, unit, getDialogBgColor }) => {
  const { colors } = useContext(colorsContext)
  const [onVoiceDialogIndex, setOnVoiceDialogIndex] = useState(null)
  const [userData, setUserData] = useState([])
  const [audio] = useState(new Audio())

  const dialogsRef = useRef([])

  const { logPending } = useSelector(state => state.studyLog)
  const states = useSelector(state => state.conversation)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { navigateToNextSection } = useSectionEvents()

  const { startRecording, stopRecording, audioBlob, timer, recording } = useAudioRecorder({})

  useEffect(() => {
    return () => {
      stopRecording()
      dispatch(resetRolePlay())
    }
  }, [])

  useEffect(() => {
    if (states.archiveLoading) {
      showLoader()
    } else {
      hideLoader()
    }

    return () => {
      hideLoader()
    }
  }, [states.archiveLoading])

  useEffect(() => {
    if (audioBlob instanceof Blob) {
      const activeDialog = states.data.dialogData.find((obj, i) => i === states.rolePlayActiveDialog)
      if (activeDialog) {
        storeRecord(activeDialog.dialogNumber, audioBlob)
        goNext()
      }
    }
    return () => {
      if (audio) audio.pause()
    }
  }, [audioBlob])

  useEffect(() => {
    const userDialogsCount = states?.data?.dialogData?.filter(
      it => it.characterName === states.rolePlaySelectedCharacter
    )?.length
    if (states.rolePlayFinished && userDialogsCount === userData?.length) {
      const makeFiles = async () => {
        const blobs = []
        for (let i = 0; i < states.data.dialogData.length; i++) {
          const dialog = states.data.dialogData[i]
          const dialogUserData = userData.find(obj => obj.dialogNumber === dialog.dialogNumber)
          if (dialogUserData && dialogUserData.audio instanceof Blob) {
            blobs.push(dialogUserData.audio)
          } else {
            const blobUrl = await getCachedMediaUrl(makeUrl(dialog.voiceUri))
            const r = await fetch(blobUrl).then(res => res.blob())
            blobs.push(r)
          }
        }
        return blobs
      }

      makeFiles().then(blobs => {
        const finalBlob = new Blob(blobs)

        const finalBlobUrl = URL.createObjectURL(finalBlob)

        const conversationTitle = htmlToText(states.data.title.sentence)

        dispatch(
          setRolePlayFinalAudio({
            blobUrl: finalBlobUrl,
            title: `Unit ${unit} - ${conversationTitle}`,
            name: null,
            createdDate: moment(new Date()).format('YYYY.MM.DD'),
            id: v4()
          })
        )
      })
    }
  }, [states.rolePlayFinished, userData])

  useEffect(() => {
    const onAudioEnded = () => {
      dispatch(setRolePlayOnDialog(null))
      // If user finished the last dialog, set finish state
      goNext()
    }

    if (states.rolePlayActiveDialog != null) {
      const activeDialog = states.data.dialogData.find((obj, i) => i === states.rolePlayActiveDialog)
      if (activeDialog) {
        // Scroll to current dialog
        if (dialogsRef.current.length >= states.rolePlayActiveDialog + 1) {
          const cardInner = document.querySelector('.convs-card-inner')
          let dialogsScrollContainer
          if (isMobile) {
            dialogsScrollContainer = cardInner
          } else {
            dialogsScrollContainer = cardInner.parentNode
          }

          gsap.to(dialogsScrollContainer, {
            scrollTop: offsetMiddleParent(dialogsRef.current[states.rolePlayActiveDialog])
          })
        }

        if (activeDialog.characterName === states.rolePlaySelectedCharacter) {
          // Start recording
          dispatch(setRolePlayOnDialog(activeDialog.dialogNumber))
          startRecording()
        } else {
          const handleCanPlayThrough = () => {
            audio.play().then(() => {
              dispatch(setRolePlayOnDialog(activeDialog.dialogNumber))
            })
          }

          // Start playing audio
          getBinaryData(makeUrl(activeDialog.voiceUri)).then(res => {
            audio.src = res
            // audio.oncanplaythrough = () => {
            handleCanPlayThrough()
            // }
            audio.addEventListener('ended', onAudioEnded)
          })
        }
      }
      return () => {
        audio.removeEventListener('ended', onAudioEnded)
      }
    }
  }, [states.rolePlayActiveDialog])

  const clearRolePlayStates = () => {
    setOnVoiceDialogIndex(null)
    setUserData([])
    dispatch(resetRolePlay())
  }

  const storeRecord = (dialogNumber, audio) => {
    const recordedDialog = userData.find(obj => obj.dialogNumber === dialogNumber)
    if (recordedDialog) {
      setUserData(old =>
        [...old].map(item => {
          if (item.dialogNumber === dialogNumber) {
            return {
              ...item,
              audio
            }
          }
          return item
        })
      )
    } else {
      setUserData(old => [
        ...old,
        {
          dialogNumber: dialogNumber,
          audio
        }
      ])
    }
  }

  const finishRecording = dialogIndex => {
    const activeDialog = states.data.dialogData.find((obj, i) => i === dialogIndex)
    if (activeDialog) {
      if (activeDialog.characterName === states.rolePlaySelectedCharacter) {
        stopRecording()
      }
    }
  }

  const cancelPlaying = () => {
    const activeDialog = states.data.dialogData.find((obj, i) => i === states.rolePlayActiveDialog)

    if (audio && !audio.paused && audio.currentTime > 0) {
      audio.pause()

      audio.removeEventListener('ended', () => {
        dispatch(setRolePlayOnDialog(null))
        goNext()
      })
      audio.removeEventListener('play', () => {
        dispatch(setRolePlayOnDialog(activeDialog.dialogNumber))
      })
    }
  }

  const goNext = () => {
    if (states.rolePlayActiveDialog != null) {
      if (recording) {
        finishRecording(states.rolePlayActiveDialog)
      } else {
        if (states.rolePlayActiveDialog + 1 < states.data.dialogData.length) {
          dispatch(setRolePlayActiveDialog(states.rolePlayActiveDialog + 1))
        } else {
          dispatch(setRolePlayActiveDialog(null))
          dispatch(setRolePlayFinished(true))
        }
      }
    }
  }

  const renderControls = () => {
    const onSaveFile = async () => {
      const audioBlob = await fetch(states.finalAudio.blobUrl).then(res => res.blob())
      const file = new File([audioBlob], `${states.finalAudio.title}.mp3`, { type: 'audio/mpeg' })

      let bodyFormData = new FormData()
      bodyFormData.append('file', file)
      bodyFormData.append('conversationTitle', htmlToText(states.data.title.sentence))
      if (states.finalAudio.name) {
        bodyFormData.append('fileName', states.finalAudio.name)
      }
      bodyFormData.append('unitId', unit)
      bodyFormData.append('sectionType', 'CONVERSATION')

      dispatch(
        postArchiveRolePlay({
          formData: bodyFormData
        })
      ).then(() => {
        navigate('?archive=true')
      })
    }

    if (states.rolePlayFinished) {
      if (states.finalAudio) {
        return (
          <div className='mb-3 mb-md-4'>
            <AudioPlayer
              audioId={states.finalAudio.id}
              audioTitle={states.finalAudio.title}
              audioName={states.finalAudio.name}
              audioDate={states.finalAudio.createdDate}
              audioSrc={states.finalAudio.blobUrl}
              isBlobUrl={true}
              onClickRemove={clearRolePlayStates}
              onClickSave={() => {
                onSaveFile()
              }}
            />
          </div>
        )
      } else {
        return null
      }
    } else {
      if (states.rolePlayActiveDialog == null) {
        return (
          <div className='d-flex align-items-center justify-content-center mb-xl-2'>
            <button
              className='start-button'
              style={{
                borderColor: colors['C213'],
                color: colors['C213']
              }}
              onClick={() => dispatch(setRolePlayActiveDialog(0))}
              id='start_role_play_button'
            >
              Start
            </button>
          </div>
        )
      } else {
        return (
          <div className='d-flex align-items-center justify-content-between'>
            <div className='recording-section-right-items' />
            <div>
              {recording ? (
                <button
                  className='start-button'
                  onClick={stopRecording}
                  style={{
                    borderColor: colors['C213'],
                    color: colors['C213']
                  }}
                >
                  <RecordingSvg />
                </button>
              ) : (
                <div
                  className='start-button'
                  style={{
                    borderColor: colors['C213'],
                    color: colors['C213']
                  }}
                >
                  <HeadphoneSvg color={colors['C213']} />
                </div>
              )}
              <p className='text-center' style={{ color: colors['C213'] }}>
                {recording ? <span>Tap to Pause ({timer})</span> : <span className='invisible'>00:00</span>}
              </p>
            </div>
            <div className='recording-section-right-items'>
              <div className='cancel-button-container'>
                <SmallButton
                  backgroundColor='#FFF'
                  color='#575756'
                  borderColor='#FFF'
                  onClick={() => {
                    stopRecording()
                    cancelPlaying()
                    clearRolePlayStates()
                  }}
                  style={{
                    boxShadow: 'inset 0 3px 6px rgba(0, 0, 0, 0.10), 0 3px 6px rgba(0, 0, 0, 0.10)'
                  }}
                >
                  Cancel
                </SmallButton>
              </div>
              <p style={{ color: colors['C213'] }} className='text-center'>
                {states.rolePlayActiveDialog + 1}/{states.data.dialogData.length}
              </p>
            </div>
          </div>
        )
      }
    }
  }

  const renderDialogs = () => {
    return data.dialogData.map((dialog, i) => {
      const active = states.rolePlayOnDialog === dialog.dialogNumber && !states.rolePlayFinished
      const dialogSelected = states.rolePlaySelectedCharacter === dialog.characterName

      let dialogContent

      if (dialogSelected && !states.rolePlayShowText) {
        dialogContent = '...'
      } else {
        dialogContent = dialog.sentence
      }

      return (
        <div
          className={`single-convs-wrapper mb-3 ${active ? 'rp-dialog-active' : ''} ${
            onVoiceDialogIndex === i ? 'dialog-on-voice' : ''
          }`}
          ref={el => (dialogsRef.current[i] = el)}
          key={i}
        >
          <div
            className={classNames('d-flex align-items-start w-100', {
              ['tw-pointer-events-none']: states.rolePlayActiveDialog != null
            })}
          >
            <div className='profile'>
              <div
                className='avatar'
                style={{
                  borderColor:
                    active || onVoiceDialogIndex === i
                      ? dialogSelected
                        ? colors['C224']
                        : colors['C221']
                      : 'transparent'
                }}
              >
                <Image uri={dialog.pictureUri} />
              </div>
              <span
                className='username font-size-17'
                style={{
                  color: dialogSelected ? colors['C224'] : colors['C221']
                }}
              >
                {dialog.characterName}
              </span>
            </div>
            <ChatBox
              bgColor={
                states.rolePlayFinished || states.rolePlayOnDialog >= dialog.dialogNumber
                  ? getDialogBgColor(dialog.characterName)
                  : colors['C226']
              }
            >
              <HtmlTextWrapper
                className={`font-size-30 ${!states.rolePlayFinished ? 'c-inherit-important' : ''}`}
                style={{
                  color:
                    states.rolePlayFinished || states.rolePlayOnDialog >= dialog.dialogNumber
                      ? colors['C23']
                      : colors['C64']
                }}
                data={{
                  sentence: dialogContent
                }}
              />
            </ChatBox>
          </div>
          <div className='dialog-right-items'>
            {states.rolePlayFinished && (
              <div className='tools'>
                <PhrasesOptions
                  handlePlaying={playing => {
                    if (playing) {
                      setOnVoiceDialogIndex(i)
                    } else if (onVoiceDialogIndex === i) {
                      setOnVoiceDialogIndex(-1)
                    }
                  }}
                  moreOptionsList={moreOptionsList}
                  data={dialog}
                />
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  if (!states.rolePlayCharacterSelected) {
    return (
      <ConversationCharacterSelect
        RolePlay
        helpCharacterSelectLink={pageGuidEnums.ROLE_PLAY_BEFORE_SELECTION}
        characters={states.data.characters}
        onClickDone={() => dispatch(setRolePlayCharacterSelected(true))}
        selectText='Select a character.'
        title={'Role-play'}
        selectedCharacters={states.rolePlaySelectedCharacter ? [states.rolePlaySelectedCharacter] : []}
        onSelectCharacter={name => dispatch(setRolePlaySelectedCharacter(name))}
      />
    )
  } else {
    return (
      <div data-jsx='Conversation'>
        <div className='container-fluid'>
          <div className='d-flex justify-content-end mt-3'>
            <div className='d-flex align-items-center me-3 me-md-5 ms-2'>
              <HelpButton color={colors['C2']} pageType={pageGuidEnums.CONVERSATION_ROLE_PLAY} />
            </div>
          </div>
          <div className='conversation-wrapper'>
            <section className='first-section mt-2 mt-lg-4'>
              <div className='first-section-inner'>
                <div style={{ maxWidth: '100%' }}>
                  <div>
                    <div className='convs-img-container'>
                      <Image uri={states.data.pictureUri} className='convs-img' onClickZoomIn />
                    </div>
                    <div className='buttons-control justify-content-between align-items-center'>
                      <div className='characters-wrapper'>
                        {states.data.characters.map((c, i) => {
                          return (
                            <button
                              className={`character-container ${
                                states.rolePlaySelectedCharacter === c.name ? 'active' : ''
                              }`}
                              disabled={states.rolePlayActiveDialog !== null}
                              onClick={() => {
                                if (states.rolePlayActiveDialog === null) {
                                  dispatch(setRolePlaySelectedCharacter(c.name))
                                }
                              }}
                              key={i}
                            >
                              <div
                                className='avatar'
                                style={{
                                  borderColor: colors['C66'],
                                  background: '#ccc'
                                }}
                              >
                                <Image uri={c.pictureUri} />
                              </div>
                              <div className='character-name' style={{ color: colors['C66'] }}>
                                {c.name}
                              </div>
                            </button>
                          )
                        })}
                      </div>
                      <Switch
                        value={states.rolePlayShowText}
                        text='Show Text'
                        onChange={() => dispatch(setRolePlayShowText(!states.rolePlayShowText))}
                      />
                    </div>
                  </div>
                  {renderControls()}
                </div>
              </div>
            </section>
            <section className='second-section'>
              <div className='convs-card-container'>
                <div className='convs-card hidden-mobile' style={{ backgroundColor: colors['C62'] }}>
                  <div className='title-box'>
                    <TitleBoxSvg />
                    <HtmlTextWrapper className='title-value' textColor={colors['C160']} data={states.data.title} />
                  </div>
                  <OverlayScrollbar>
                    <div className={classNames('convs-card-inner', { ['isMobile']: isMobile })}>{renderDialogs()}</div>
                  </OverlayScrollbar>
                </div>
              </div>
              <div className='button-group'>
                <Link
                  to='?archive=true'
                  className='link-style archive-button font-weight-600'
                  style={{ color: colors['C2'] }}
                >
                  Archive
                </Link>
                <div className='d-flex justify-content-center'>
                  <PrimaryButton
                    loading={logPending}
                    onClick={navigateToNextSection}
                    disabled={!states.rolePlayFinished}
                  >
                    Next
                  </PrimaryButton>
                </div>
                <button
                  onClick={navigateToNextSection}
                  disabled={states.rolePlayFinished || logPending}
                  className={classNames('link-style skip-button font-weight-600', {
                    ['disabled']: states.rolePlayFinished
                  })}
                  style={{ color: colors['C2'] }}
                >
                  Skip
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default RolePlay
