import React, { useState, useRef, useEffect, useContext } from 'react'
import ChatBox from '../../../components/ChatBox/ChatBox'
import { Link } from 'react-router-dom'
import PhrasesOptions from '../../../components/PhrasesOptions/PhrasesOptions'
import { useSelector } from 'react-redux'
import makeUrl from '../../../operations/makeUrl'
import TitleBoxSvg from '../../../icons/TitleBoxSvg'
import OverlayScrollbar from '../../../layouts/OverlayScrollbar'
import Image from '../../../components/Image'
import { gsap } from 'gsap'
import HelpButton from '../../../components/HelpButton/HelpButton'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import PlayButtonPrimaryAudio from '../../../components/PlayButtonPrimary/PlayButtonPrimaryAudio'
import offsetMiddleParent from '../../../operations/offsetMiddleParent'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import colorsContext from '../../../contexts/ColorsContext'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useWakeLockApi from '../../../hooks/useWakeLockApi'
import scrollIntoViewByElement from '../../../operations/scrollIntoViewByElement'

const ConversationMain = ({ nextPage, getDialogBgColor }) => {
  const { colors } = useContext(colorsContext)
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [audioCurrentTime, setAudioCurrentTime] = useState(0)
  const [focusedDialogIndex, setFocusedDialogIndex] = useState(null)

  const dialogsRef = useRef([])
  const conversationCardRef = useRef(null)

  const states = useSelector(state => state.conversation)

  const { requestWakeLock, releaseWakeLock } = useWakeLockApi()
  useEffect(() => {
    if (audioPlaying) {
      requestWakeLock()
      scrollIntoViewByElement(conversationCardRef.current)
    } else releaseWakeLock()
  }, [audioPlaying])

  useEffect(() => {
    if (audioPlaying) {
      const dialogIndex = states.data.dialogData.findIndex(
        obj => audioCurrentTime >= obj.startPosition && audioCurrentTime < obj.endPosition
      )
      if (dialogIndex === -1) {
        setFocusedDialogIndex(null)
      } else {
        setFocusedDialogIndex(dialogIndex)

        const cardInner = document.querySelector('.convs-card-inner')
        let dialogsScrollContainer
        if (isMobile) {
          dialogsScrollContainer = cardInner
        } else {
          dialogsScrollContainer = cardInner.parentNode
        }

        gsap.to(dialogsScrollContainer, { scrollTop: offsetMiddleParent(dialogsRef.current[dialogIndex]) })
      }
    } else {
      setFocusedDialogIndex(-1)
    }
  }, [audioPlaying, audioCurrentTime])

  const renderDialogs = () => {
    return states.data.dialogData.map((dialog, i) => {
      return (
        <div
          className={`single-convs-wrapper mb-3 ${focusedDialogIndex === i ? 'dialog-on-voice' : ''}`}
          ref={el => (dialogsRef.current[i] = el)}
          key={i}
        >
          <div className='d-flex w-100'>
            <div className='profile'>
              <div className='avatar' style={{ borderColor: focusedDialogIndex === i ? colors['C66'] : 'transparent' }}>
                <Image uri={dialog.pictureUri} />
              </div>
              <span className='username font-size-17' style={{ color: colors['C66'] }}>
                {dialog.characterName}
              </span>
            </div>
            <ChatBox bgColor={getDialogBgColor(dialog.characterName)}>
              <HtmlTextWrapper
                className='font-size-30'
                moreOptionsList={moreOptionsList}
                textColor={colors['C23']}
                optionsColor={colors['C23']}
                data={dialog}
              />
            </ChatBox>
          </div>
          <div className='dialog-right-items'>
            <div className='tools'>
              <PhrasesOptions
                handlePlaying={playing => {
                  if (playing) {
                    setFocusedDialogIndex(i)
                  } else if (focusedDialogIndex === i) {
                    setFocusedDialogIndex(null)
                  }
                }}
                moreOptionsList={moreOptionsList}
                data={dialog}
                color={colors['C23']}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div data-jsx='Conversation'>
      <div className='container-fluid'>
        <div className='d-flex justify-content-end align-content-center mt-3'>
          <div className='d-flex align-items-center me-3 me-md-5 ms-2'>
            <HelpButton color={colors['C2']} pageType={pageGuidEnums.CONVERSATION_MAIN} />
          </div>
        </div>
        <div className='conversation-wrapper'>
          <section className='first-section mt-2 mt-lg-4'>
            <div className='first-section-inner'>
              <div style={{ maxWidth: '100%' }}>
                <div className='convs-img-container'>
                  <Image uri={states.data.pictureUri} className='convs-img' onClickZoomIn />
                </div>
                <div className='buttons-control justify-content-center'>
                  <PlayButtonPrimaryAudio
                    iconColor={colors['C160']}
                    bgColor={colors['C60']}
                    borderColor={colors['C60']}
                    handlePlaying={setAudioPlaying}
                    handleCurrentTime={setAudioCurrentTime}
                    audioUrl={makeUrl(states.data.voiceUri)}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className='second-section'>
            <div className='convs-card-container'>
              <div ref={conversationCardRef} className='convs-card' style={{ background: colors['C62'] }}>
                <div className='title-box'>
                  <TitleBoxSvg />
                  <HtmlTextWrapper className='title-value' textColor={colors['C160']} data={states.data.title} />
                </div>
                <OverlayScrollbar>
                  <div className={classNames('convs-card-inner', { ['isMobile']: isMobile })}>{renderDialogs()}</div>
                </OverlayScrollbar>
              </div>
            </div>
            <div className='button-group'>
              <Link
                to='?archive=true'
                className='link-style archive-button font-weight-600'
                style={{ color: colors['C2'] }}
              >
                Archive
              </Link>
              <div className='d-flex justify-content-center'>
                <PrimaryButton onClick={nextPage}>Next</PrimaryButton>
              </div>
              <Link
                to='?archive=true'
                className='link-style disabled skip-button font-weight-600'
                style={{ color: colors['C2'] }}
              >
                Skip
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ConversationMain
