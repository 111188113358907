import { useDispatch, useSelector } from 'react-redux'
import { removeDisplayingBottomSheet } from '../redux/features/bottomSheetSlice'
import { isFunction } from 'lodash'
import { useEffect } from 'react'

const useBottomSheet = () => {
  const { displayingBottomSheets } = useSelector(state => state.bottomSheet)
  const dispatch = useDispatch()

  useEffect(() => {
    if (displayingBottomSheets.length > 0) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = null
    }
  }, [JSON.stringify(displayingBottomSheets)])

  const closeBottomSheet = (onClose, id) => {
    dispatch(removeDisplayingBottomSheet(id))
    if (isFunction(onClose))
      setTimeout(() => {
        onClose()
      }, 300)
  }

  return { closeBottomSheet }
}

export default useBottomSheet
