import serviceCall from '../../services'
import { inquiryActionEnums } from '../../enums/inquiryEnums/inquiryEnums'
import farsiToEnglishNum from '../../util/farsiToEnglishNum/farsiToEnglishNum'

const inquiryUseCase = async data => {
  const response = await serviceCall(
    'postInquiry',
    {
      authData: {
        platform: data?.authData?.platform,
        deviceId: data?.authData?.deviceId,
        username: farsiToEnglishNum(data?.authData?.username?.trim()),
        password: data?.authData?.password,
        rePassword: data?.authData?.rePassword,
        code: data?.authData?.code,
        region: data?.authData?.region,
        state: data?.authData?.state,
        phoneNumberPrefix: data?.authData?.phoneNumberPrefix,
        authenticationType: data?.authData?.authenticationType
      },
      captchaToken: data?.captchaToken,
      extraData: data?.extraData
    },
    'cloud'
  )

  return { ...response, action: response.action ?? inquiryActionEnums.DONE }
}

export default inquiryUseCase
