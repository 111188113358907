import { generateExerciseTemplateClassName, htmlTextConversion } from '../mapperConversions'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchMultipleChoiceMapper = data => {
  return {
    pageType: pageGuidEnums.MULTIPLE_CHOICE_EXERCISE,
    data: {
      immediateCheck: true,
      paginable: true,
      type: data.type,
      title: data.title ?? null,
      stacks: [...data.exerciseStacks].map(stack => {
        return {
          id: stack.id,
          title: stack.title,
          joined: stack.joined,
          items: [...stack.items].map(item => {
            const given = item.data.givens[0]
            const choices = item.data.choices[0]
            const answers = item.data.answers[0]
            return {
              id: item.id,
              enabled: false,
              unlocked: false,
              checked: false,
              userAnswer: null,
              given: {
                ...given,
                index: given.index,
                type: given.type,
                template: given.template,
                templateClassName: generateExerciseTemplateClassName(given.type, given.template),
                text: htmlTextConversion(given.text, true),
                imageUri: given.imageUri ?? null,
                voiceUri: given.voiceUri ?? null
              },
              choices: [...choices.items].map(choice => {
                const isAnswer = answers.items.find(answer => answer.index === choice.index)
                return {
                  index: choice.index,
                  text: htmlTextConversion(choice.text),
                  template: choice.template,
                  selected: false,
                  isAnswer
                }
              }),
              extra: {
                tip: item.extra.tip ?? null,
                fullTextAnswers: item.extra.fullTextAnswers
                  ? [...item.extra.fullTextAnswers].map(text => {
                      return htmlTextConversion(text)
                    })
                  : []
              }
            }
          })
        }
      }),
      checked: false,
      checkable: false
    }
  }
}

export default fetchMultipleChoiceMapper
