import * as React from 'react'

const FavoritesSvg = ({ color }) => (
  <svg
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width={512}
    height={512}
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <style type='text/css'>
      {
        '.FavoritesSvg-st0{clip-path:url(#SVGID_00000152986402536407312110000006574293089060574372_);}.FavoritesSvg-st1{fill:none;stroke-width:25.2177;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}'
      }
    </style>
    <g>
      <g>
        <defs>
          <rect width={512} height={512} />
        </defs>
        <clipPath>
          <use
            xlinkHref='#SVGID_1_'
            style={{
              overflow: 'visible'
            }}
          />
        </clipPath>
        <g
          transform='matrix(1.33333 0 0 -1.33333 0 682.667)'
          style={{
            clipPath: 'url(#SVGID_00000146467654657364149290000008840549737050119089_)'
          }}
        >
          <path
            className='FavoritesSvg-st1'
            stroke={color}
            d='M280.4,477.5c-40.9,0-75.5-28.7-88.4-68.6c-12.9,39.9-47.6,68.6-88.4,68.6c-51.9,0-93.5-46.1-93.9-102.9 C9,270.8,192,165.2,192,165.2s181.7,105.6,182.3,209.4C374.7,431.4,332.3,477.5,280.4,477.5z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default FavoritesSvg
