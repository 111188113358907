const fetchDrillMapper = data => {
  return data?.drillingData?.map(it => {
    return {
      sentence: it.sentence,
      voiceOver: it.voiceOver,
      voiceUri: it.voiceUri,
      trackerNumber: it.trackerNumber,
      dialogNumber: it.dialogNumber,
      translate: it.translate,
      hints: it.hints,
      more: it.more,
      speechType: null
    }
  })
}

export default fetchDrillMapper
