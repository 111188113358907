import * as React from 'react'

const SpeakerOffSvg = ({ color }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' xmlSpace='preserve' width='100%' height='100%'>
    <path
      d='M39.96 47.98c-.43 0-.86-.1-1.26-.3l-.06-.03-18.96-12.7h-9.33c-2.82 0-5.11-2.39-5.11-5.33V18.13c0-2.94 2.29-5.33 5.11-5.33l10.1-.03L38.63.36l.07-.03c.88-.44 1.9-.39 2.73.12.83.52 1.33 1.41 1.33 2.39v42.33c0 .98-.5 1.87-1.33 2.39-.45.27-.96.42-1.47.42z'
      fill={color}
    />
  </svg>
)

export default SpeakerOffSvg
