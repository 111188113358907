import serviceCall from '../../services'
import boxMapper from '../../services/mapper/memoryBox/boxMapper'

const getBoxesUseCase = async ({ categoryId }) => {
  const boxes = await serviceCall('fetchBoxes', { categoryId }, 'cloud')

  return boxes.map(item => {
    return boxMapper(item)
  })
}

export default getBoxesUseCase
