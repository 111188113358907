import * as React from 'react'

const LockOpenSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 21.533 25.667'>
    <defs>
      <linearGradient id='lock_open_svg_gradient_a' y1={0.5} x2={1} y2={0.5} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#ebca83' />
        <stop offset={0} stopColor='#e5ba5d' />
        <stop offset={0.414} stopColor='#e2b854' />
        <stop offset={0.586} stopColor='#f4c96c' />
        <stop offset={0.772} stopColor='#d09f26' />
        <stop offset={1} stopColor='#c2973b' />
      </linearGradient>
      <linearGradient id='lock_open_svg_gradient_b' y1={0.5} x2={1} y2={0.5} gradientUnits='objectBoundingBox'>
        <stop offset={0} stopColor='#ebca83' />
        <stop offset={0} stopColor='#e5ba5d' />
        <stop offset={0.228} stopColor='#ffffd0' />
        <stop offset={0.414} stopColor='#e2b854' />
        <stop offset={0.586} stopColor='#f4c96c' />
        <stop offset={0.772} stopColor='#d09f26' />
        <stop offset={1} stopColor='#c2973b' />
      </linearGradient>
    </defs>
    <g>
      <path
        d='M332.154 32.664a.117.117 0 0 1-.086-.113V28.87a.058.058 0 0 1 .058-.058h1.114a.088.088 0 0 0 .088-.088v-.912a.088.088 0 0 0-.088-.088h-.717a.059.059 0 0 1-.059-.059v-.574a.058.058 0 0 1 .059-.058h.889a.088.088 0 0 0 .088-.088v-.912a.088.088 0 0 0-.088-.088h-1.287a.058.058 0 0 1-.058-.059v-.219a.7.7 0 0 0-.666-.707.691.691 0 0 0-.716.691v6.9a.117.117 0 0 1-.086.113 2.936 2.936 0 1 0 1.554 0Zm-.777 4.74a1.908 1.908 0 1 1 1.909-1.908 1.908 1.908 0 0 1-1.909 1.904Z'
        transform='translate(-316.94 -12.766)'
        fill='url(#lock_open_svg_gradient_a)'
      />
      <path
        d='M71.71 32.826H59.445l-.876.876v7.74l.876.876H71.71l.876-.876V33.7Z'
        transform='translate(-51.312 -24.243)'
        fill='url(#lock_open_svg_gradient_b)'
      />
      <path
        d='m58.569 33.7.876-.876H71.71l.876.876Z'
        transform='translate(-51.311 -24.243)'
        fill='url(#lock_open_svg_gradient_b)'
      />
      <path d='m21.275 17.271-.876.876H8.134l-.876-.876Z' fill='#e6a64a' />
      <path
        d='M20.505 18.333H8.024l-1.027-1.028V9.352l1.027-1.027h12.481l1.028 1.027v7.953ZM8.24 17.818h12.052l.726-.726V9.566l-.726-.726H8.24l-.725.726v7.526Z'
        fill='#333030'
      />
      <g fill='#333030'>
        <path d='M7.258 16.99h10.819v.309H7.258z' />
        <path d='M18.077 16.99h3.274v.309h-3.274z' />
      </g>
      <g fill='#333030'>
        <path d='M12.748 13.153h.438v.438h-.438z' />
        <path d='M13.997 13.153h.438v.438h-.438z' />
        <path d='M15.247 13.153h.438v.438h-.438z' />
      </g>
      <g>
        <path
          d='M5.772.258a5.514 5.514 0 0 1 5.514 5.514v2.692H9.554V5.785a3.782 3.782 0 0 0-3.782-3.786c-2.088 0-3.781 1.035-3.781 3.124v.131h-.005a.93.93 0 0 0-.93.93h.935v.947H.258V5.115C.258 2.07 2.727.258 5.772.258Z'
          fill='#e7e6e6'
        />
        <path
          d='M9.295 5.785a3.527 3.527 0 0 0-3.523-3.523c-1.943 0-3.523.923-3.523 2.865v.131l-.005.258h-.258a.673.673 0 0 0-.62.413h.883v1.464h-2.25v-2.28c0-3.183 2.589-5.115 5.772-5.115a5.779 5.779 0 0 1 5.773 5.774v2.95h-2.25Zm1.733-.013A5.262 5.262 0 0 0 5.772.516c-2.9 0-5.256 1.7-5.256 4.6v1.763h1.216v-.431H.797V6.19a1.191 1.191 0 0 1 .935-1.161v.1c0-2.228 1.812-3.382 4.04-3.382a4.044 4.044 0 0 1 4.04 4.039v2.42h1.215Z'
          fill='#333030'
        />
      </g>
    </g>
  </svg>
)

export default LockOpenSvg
