import serviceCall from '../../services'
import homeStatisticsMapper from '../../services/mapper/homepage/homeStatisticsMapper'

const homeStatisticsUseCase = async ({ hasPlan }) => {
  let result

  if (hasPlan) {
    result = await serviceCall('fetchHomeStatistics', null, 'cloud')
  } else {
    result = []
  }

  return homeStatisticsMapper(result)
}

export default homeStatisticsUseCase
