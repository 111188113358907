import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalAudioId } from '../../redux/features/audioSlice'
import { isMobile } from 'react-device-detect'
import usePageVisibility from '../usePageVisibility'

const useAudioPlaylist = ({ playlistUrls, playLimit, playing, handlePlaying }) => {
  const pageVisibilityHidden = usePageVisibility()
  const [audio] = useState(new Audio())
  const [pausedNow, setPausedNow] = useState(false)
  const [i, setI] = useState(0)
  // Generate unique audio id for this component (purpose: play one audio at the same time)
  // With prefix "ACI_" => Audio Component Id
  const [audioId, setAudioId] = useState(`ACI_${uuidv4()}`)

  const audioState = useSelector(state => state.audio)
  const dispatch = useDispatch()

  useEffect(() => {
    setAudioId(`ACI_${uuidv4()}`)
  }, [playlistUrls])

  useEffect(() => {
    if (audioState.currentGlobalAudioId !== audioId && audio) {
      handlePlaying(false)
    }
  }, [audioState.currentGlobalAudioId])

  useEffect(() => {
    if (isMobile && pageVisibilityHidden && playing) {
      handlePlaying(false)
    }
  }, [pageVisibilityHidden])

  const play = () => {
    if (playlistUrls.length > 0) {
      if (audioState.currentGlobalAudioId !== audioId) {
        dispatch(setGlobalAudioId(audioId))
      }

      if (audio.src !== playlistUrls[i]) {
        audio.src = playlistUrls[i]
      }

      audio.play()

      audio.onerror = () => {
        setI(i + 1)
        setPausedNow(true)
      }

      audio.onended = () => {
        setI(i + 1)
        setPausedNow(true)
      }
    }
  }

  const onPause = () => {
    if (audio && !audio.paused) {
      audio.pause()
    }
  }

  const afterPause = () => {
    handlePlaying(false)
    // setI(0)
  }

  useEffect(() => {
    let isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended
    if (pausedNow && !isPlaying) {
      if ((typeof playLimit !== 'number' || i < playLimit + 1) && i < playlistUrls.length) {
        play()
      } else {
        audio.pause()
        setI(0)
      }
      setPausedNow(false)
    }
  }, [pausedNow])

  const onPlayEvent = () => {
    handlePlaying(true)
  }

  useEffect(() => {
    if (audio) {
      audio.addEventListener('pause', () => afterPause())
      audio.addEventListener('play', () => onPlayEvent())

      return () => {
        audio.pause()
        audio.removeEventListener('pause', () => afterPause())
        audio.removeEventListener('play', () => onPlayEvent())
      }
    }
  }, [])

  useEffect(() => {
    if (audio) {
      playing ? play() : audio.pause()
    }
  }, [playing])

  // useEffect(() => {
  //   handlePlaying(playing)
  // }, [handlePlaying, playing])

  return {
    currentIndex: i
  }
}

export default useAudioPlaylist
