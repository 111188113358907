import React, { useContext } from 'react'
import HtmlTextWrapper from '../HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../data'
import colorsContext from '../../contexts/ColorsContext'
import componentStyle from './Instruction.module.scss'

const Instruction = ({ color, data }) => {
  const { colors } = useContext(colorsContext)
  return (
    <div className='d-flex align-items-baseline mb-md-3 mb-2'>
      <span className={componentStyle.introLeftLineText} style={{ background: colors['C170'] }} />
      <HtmlTextWrapper
        textColor={color ?? colors['C2']}
        optionsColor={color ?? colors['C2']}
        showPhraseOptions={data?.more}
        moreOptionsList={moreOptionsList}
        className='res:fs-md'
        data={data}
      />
    </div>
  )
}

export default Instruction
