import React from 'react'

const Pause = props => {
  return (
    <button className='pause-button' onClick={props.handleClick}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 25'>
        <g transform='translate(-141 -347)'>
          <line
            y2='22'
            transform='translate(142.5 348.5)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeWidth='3'
          />
          <line
            y2='22'
            transform='translate(154.5 348.5)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeWidth='3'
          />
        </g>
      </svg>
    </button>
  )
}

export default Pause
